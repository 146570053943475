import React from "react";
import Context from "../context/Context";
import Constants from "../context/Constants";
//import "bootstrap/dist/js/bootstrap.bundle"; //TODO: Liberati di bootstrap
//import "bootstrap/dist/css/bootstrap.min.css";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    Switch,
    Typography,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faUser,} from "@fortawesome/free-solid-svg-icons";
import {SlideUpTransition} from "../components/Transitions";
import axios from "axios";
import {logout, setLowSpecDevice, setThemeMode, setUseElasticSearch} from "../context/GlobalFunctions";
import {brandingDarkTheme as theme} from "../context/Theme";


export default class Profilo extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.handleTokenDialogClose = this.handleTokenDialogClose.bind(this);
        this.handleTokenDialogOpen = this.handleTokenDialogOpen.bind(this);
        this.handleLogoutDialogClose = this.handleLogoutDialogClose.bind(this);
        this.handleLogoutDialogOpen = this.handleLogoutDialogOpen.bind(this);
        this.clearLocalStorage = this.clearLocalStorage.bind(this);
        this.clearBrowserCache = this.clearBrowserCache.bind(this);
        this.destroyAllSessions = this.destroyAllSessions.bind(this);
        this.state = {
            tokenDialogOpen: false,
            logoutDialogOpen: false,
            dangerZoneExpanded: false,
            clearLocalStorageDialogOpen: false,
            clearBrowserCacheDialogOpen: false,
            destroyAllSessionsDialogOpen: false,
        };
        this.styles = {
            root: {
                left: 0,
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center"
            },
            actionArea: {
                justifyContent: "flex-end",
            },
            dialogBody: {
                overflowWrap: "anywhere",
            },
            spaceEvenly: {
                justifyContent: "space-evenly",
            },
            dangerZone: {
                text: {
                    fontWeight: 'bold',
                    color: theme.palette.warning.contrastText
                }
            }
        };
    }

    componentDidMount() {
        // this.context.functions.setActiveRouteName("profilo");
    }

    handleTokenDialogClose() {
        this.setState({
            tokenDialogOpen: false,
        });
    }

    handleTokenDialogOpen() {
        this.setState({
            tokenDialogOpen: true,
        });
    }

    handleLogoutDialogClose() {
        this.setState({
            logoutDialogOpen: false,
        });
    }

    handleLogoutDialogOpen() {
        this.setState({
            logoutDialogOpen: true,
        });
    }

    clearLocalStorage() {
        localStorage.clear();
        // this.context.functions.logout();
        logout(this.context);
    }

    clearBrowserCache() {
        window.Cache.delete();
        window.location.reload(true);
    }

    destroyAllSessions() {
        axios
            .get(
                Constants.paths.ajaxBasePath +
                "login/?action=destroy&token=" +
                this.context.user.token
            )
            .then(() => logout(this.context));
    }

    render() {
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <React.Fragment>
                        <Grid container justifyContent="center">
                            <Grid item xs={10} md={6} lg={4}>
                                <Box
                                    // style={{
                                    //   padding: consumer_data.theme.spacing(15)
                                    // }}
                                >
                                    <Card variant="outlined" style={this.styles.root}>
                                        <FontAwesomeIcon
                                            style={{
                                                margin: consumer_data.theme.spacing(3),
                                                fontSize: 32,
                                            }}
                                            icon={faUser}
                                            size="lg"
                                        />
                                        <CardHeader
                                            title={
                                                consumer_data.user.firstName +
                                                " " +
                                                consumer_data.user.lastName
                                            }
                                            subheader={consumer_data.user.username}
                                        ></CardHeader>
                                        <CardContent>
                                            <Typography variant="subtitle1">Ruoli</Typography>
                                            <Box
                                                style={{
                                                    padding: consumer_data.theme.spacing(1),
                                                }}
                                            >
                                                {consumer_data.user.roles.map((r, i) => {
                                                    return (
                                                        <Chip
                                                            key={"role-" + i}
                                                            variant="outlined"
                                                            size="small"
                                                            label={r}
                                                            style={{
                                                                margin: consumer_data.theme.spacing(0.2),
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                            <br/>
                                            <FormControl component="fieldset"
                                                         style={{width: '100', textAlign: 'center'}}>
                                                <Typography variant="subtitle1">Interfaccia</Typography>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={consumer_data.ui.dark}
                                                                onChange={
                                                                    (e) =>
                                                                        setThemeMode(e.target.checked ? "dark" : "light", consumer_data)
                                                                }
                                                                value="dark"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Tema scuro"
                                                    />
                                                </FormGroup>
                                                <br/>
                                                <Typography variant="subtitle1">Dispositivo</Typography>
                                                <br/>

                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={consumer_data.device.lowSpec}
                                                                onChange={(e) => {
                                                                    setLowSpecDevice(
                                                                        e.target.checked,
                                                                        consumer_data
                                                                    );
                                                                }}
                                                                value="dark"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Dispositivo a basse prestazioni"
                                                    />
                                                    <FormHelperText>Rimuove animazioni, immagini dei prodotti e altre
                                                        funzionalità non essenziali</FormHelperText>
                                                </FormGroup>
                                                <br/>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={consumer_data.device.useElasticSearch}
                                                                onChange={(e) => {
                                                                    setUseElasticSearch(
                                                                        e.target.checked
                                                                    );
                                                                }}
                                                                value="dark"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Utilizza ricerca accellerata (ElasticSearch)"
                                                    />
                                                </FormGroup>
                                                {/* <FormHelperText>Be careful</FormHelperText> */}
                                                <br/>
                                            </FormControl>
                                        </CardContent>
                                        <CardActions style={this.styles.actionArea}>
                                            <IconButton
                                                size="medium"
                                                onClick={(e) => {
                                                    this.setState((prev) => ({
                                                        dangerZoneExpanded: !prev.dangerZoneExpanded,
                                                    }));
                                                }}
                                            >
                                                <Box color={consumer_data.theme.palette.warning.main}>
                                                    <FontAwesomeIcon
                                                        size="sm"
                                                        icon={faExclamationTriangle}
                                                    />
                                                </Box>
                                            </IconButton>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                onClick={this.handleLogoutDialogOpen}
                                            >
                                                Logout
                                            </Button>
                                        </CardActions>
                                        <Collapse in={this.state.dangerZoneExpanded} timeout="auto">
                                            <CardContent>
                                                <Card variant="outlined">
                                                    <Box
                                                        color={consumer_data.theme.palette.grey.A400}
                                                        bgcolor={consumer_data.theme.palette.warning.main}
                                                    >
                                                        <CardContent>
                                                            <Typography variant="overline" color=""
                                                                        style={this.styles.dangerZone.text}>
                                                                Zona pericolo
                                                            </Typography>
                                                            <br/>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                elevation={0}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        clearLocalStorageDialogOpen: true,
                                                                    })
                                                                }
                                                            >
                                                                Svuota local storage
                                                            </Button>
                                                            <br/>
                                                            <br/>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                elevation={0}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        clearBrowserCacheDialogOpen: true,
                                                                    })
                                                                }
                                                            >
                                                                Cache bust
                                                            </Button>
                                                            <br/>
                                                            <br/>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                elevation={0}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        destroyAllSessionsDialogOpen: true,
                                                                    })
                                                                }
                                                            >
                                                                Distruggi tutte le sessioni
                                                            </Button>
                                                            <br/>
                                                            <br/>
                                                            <Button
                                                                fullWidth
                                                                onClick={this.handleTokenDialogOpen}
                                                                variant="contained"
                                                                elevation={0}
                                                            >
                                                                Mostra chiave API
                                                            </Button>
                                                            <br/>
                                                            <br/>
                                                            <Typography variant="caption"
                                                                        style={this.styles.dangerZone.text}>
                                                                Per assistenza contatta{" "}
                                                                <a href="mailto:s.mancuso@kartiell.it">
                                                                    s.mancuso@kartiell.it
                                                                </a>
                                                                .
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Card>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                        {/*
          ////
          ////
          ////
            */}
                        <Dialog
                            open={this.state.tokenDialogOpen}
                            TransitionComponent={SlideUpTransition}
                            keepMounted
                            onClose={this.handleTokenDialogClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <Box
                                color={consumer_data.theme.palette.grey.A400}
                                bgcolor={consumer_data.theme.palette.warning.main}
                            >
                                <DialogTitle id="alert-dialog-slide-title">
                                    {"Token d'accesso"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText
                                        style={this.styles.dialogBody}
                                        id="alert-dialog-slide-description"
                                    >
                                        {" "}
                                        <Box color={consumer_data.theme.palette.grey.A400}>
                                            <Typography>{consumer_data.user.token}</Typography>
                                        </Box>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        onClick={this.handleTokenDialogClose}
                                    >
                                        Chiudi
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        {/*---------------------------------*/}
                        <Dialog
                            open={this.state.logoutDialogOpen}
                            TransitionComponent={SlideUpTransition}
                            keepMounted
                            onClose={this.handleLogoutDialogClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">
                                {"Conferma logout"}
                            </DialogTitle>
                            <DialogContent>
                                {/* <DialogContentText style={styles.dialogBody} id="alert-dialog-slide-description">

                </DialogContentText> */}
                            </DialogContent>
                            <DialogActions style={this.styles.spaceEvenly}>
                                <Button onClick={this.handleLogoutDialogClose}>Annulla</Button>
                                <Button onClick={e => logout(this.context)}>Logout</Button>
                            </DialogActions>
                        </Dialog>
                        {/*---------------------------------*/}
                        <Dialog
                            open={this.state.clearLocalStorageDialogOpen}
                            TransitionComponent={SlideUpTransition}
                            keepMounted
                            onClose={() =>
                                this.setState({clearLocalStorageDialogOpen: false})
                            }
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <Box
                                color={consumer_data.theme.palette.grey.A400}
                                bgcolor={consumer_data.theme.palette.warning.main}
                            >
                                <DialogTitle id="alert-dialog-slide-title">
                                    {"Conferma svuotamento Local Storage"}
                                </DialogTitle>
                                <DialogContent>
                                    <Typography>
                                        Così facendo eliminerai tutti i dati su questo dispositivo non presenti sul
                                        nostro Database. Dovrai rieffettuare il login.
                                        <br/>
                                        (Es. Bozze, Preferenze, Parte della cache ecc.)
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() =>
                                            this.setState({clearLocalStorageDialogOpen: false})
                                        }
                                    >
                                        <Box color={consumer_data.theme.palette.grey.A400}>
                                            Annulla
                                        </Box>
                                    </Button>
                                    <Button variant="contained" onClick={this.clearLocalStorage}>
                                        Svuota
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        {/*---------------------------------*/}
                        <Dialog
                            open={this.state.clearBrowserCacheDialogOpen}
                            TransitionComponent={SlideUpTransition}
                            keepMounted
                            onClose={() =>
                                this.setState({clearBrowserCacheDialogOpen: false})
                            }
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <Box
                                color={consumer_data.theme.palette.grey.A400}
                                bgcolor={consumer_data.theme.palette.warning.main}
                            >
                                <DialogTitle id="alert-dialog-slide-title">
                                    {"Conferma svuotamento della Browser Cache"}
                                </DialogTitle>
                                <DialogContent>
                                    <Typography>
                                        Così facendo svuoterai l'intera cache del browser e forzerai
                                        un completo ricaricamento dell'intera Web App.
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() =>
                                            this.setState({clearBrowserCacheDialogOpen: false})
                                        }
                                    >
                                        <Box color={consumer_data.theme.palette.grey.A400}>
                                            Annulla
                                        </Box>
                                    </Button>
                                    <Button variant="contained" onClick={this.clearBrowserCache}>
                                        Svuota
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        {/*---------------------------------*/}
                        <Dialog
                            open={this.state.destroyAllSessionsDialogOpen}
                            TransitionComponent={SlideUpTransition}
                            keepMounted
                            onClose={() =>
                                this.setState({destroyAllSessionsDialogOpen: false})
                            }
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <Box
                                color={consumer_data.theme.palette.grey.A400}
                                bgcolor={consumer_data.theme.palette.warning.main}
                            >
                                <DialogTitle id="alert-dialog-slide-title">
                                    {"Conferma distruzione sessioni"}
                                </DialogTitle>
                                <DialogContent>
                                    <Typography>
                                        Così facendo distruggerai ogni sessione attiva su questo e altri dispositivi.
                                        Dovrai rieffettuare il login.
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() =>
                                            this.setState({destroyAllSessionsDialogOpen: false})
                                        }
                                    >
                                        <Box color={consumer_data.theme.palette.grey.A400}>
                                            Annulla
                                        </Box>
                                    </Button>
                                    <Button variant="contained" onClick={this.destroyAllSessions}>
                                        Distruggi
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                    </React.Fragment>
                )}
            </Context.Consumer>
        );
    }
}
