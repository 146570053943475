import React, {ContextType, CSSProperties, useContext, useState} from "react";
import {SlideUpTransition} from "./Transitions";
import axios from "axios";
import {
    Dialog,
    Button,
    IconButton,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Tooltip,
    Typography, IconButtonOwnProps,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLifeRing} from "@fortawesome/free-solid-svg-icons";
import Context from "../context/Context";

import Constants from "../context/Constants";
import {enqueueSnackbar, useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {SupportRounded} from "@mui/icons-material";
import useBreakpoints from "../functions/breakpoints";

interface TState {
    open: boolean,
    userMessage: string,
    fetching: boolean
}

function Feedback({
                      renderButton = true,
                      openProp = false,
                      buttonSize = 'sm',
                      info = '',
                      onClose = () => {
                      },
                      iconSize = 'medium',
                      style = {},

                  }: {
    renderButton?: boolean;
    openProp?: boolean;
    buttonSize?: string;
    info: string;
    onClose?: () => void;
    iconSize?: IconButtonOwnProps['size'];
    style?: CSSProperties;
}) {
    const context = useContext(Context);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const matches = useBreakpoints();

    const [open, setOpen] = useState(false);
    const [userMessage, setUserMessage] = useState("");
    const [fetching, setFetching] = useState(false);

    const sendFeedback = async () => {
        setFetching(true);
        try {
            await fetch(
                Constants.paths.ajaxBasePath + "feedback/",
                {
                    body: JSON.stringify({
                        token: context.user.token,
                        info: info,
                        message: userMessage,
                    })
                }
            );
            handleClose();
            enqueueSnackbar("Feedback inviato!", {variant: "success"});

        } catch (err) {
            alert("Non sono riuscito ad inviare il feedback.");
            console.error(err);
        }
    }

    const handleMessageChange = (msg: string) => {
        setUserMessage(msg);
    }

    const handleClose = () => {
        setOpen(false);
        setUserMessage("");
        onClose();
    }

    return (
        <>
            {renderButton && (
                <Tooltip title="Invia feedback">
                    <IconButton
                        style={{...style} as any}
                        onClick={(e) => setOpen(true)}
                        size={iconSize}>
                        <SupportRounded/>
                    </IconButton>
                </Tooltip>
            )}
            <Dialog
                fullWidth={false}
                fullScreen={!matches.sm}
                maxWidth="sm"
                //style={{ top: 0, bottom: "auto" }}
                open={open || openProp}
                TransitionComponent={SlideUpTransition as any}
                onClose={() => handleClose()}
                //onOpen={this.props.onOpen}
            >
                <DialogTitle>Invia feedback</DialogTitle>
                <DialogContent>
                    <Typography
                        style={{
                            fontStyle: "italic",
                            marginBottom: context.theme.spacing(2),
                        }}
                        variant="body2"
                    >
                        {info}
                    </Typography>
                    <TextField
                        //Uncontrolled
                        value={userMessage}
                        onChange={e => handleMessageChange(e.target.value)}
                        variant="outlined"
                        label="Messaggio"
                        multiline
                        rows={4}
                        maxRows={10}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Annulla</Button>
                    <Button
                        disabled={fetching || userMessage === ""}
                        variant="contained"
                        onClick={() => sendFeedback()}
                    >
                        Invia
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Feedback;
