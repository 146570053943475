import axios from "axios";
import Constants from "../context/Constants";
import Sede from "../interfaces/sedi/Sede";

export interface Corriere {
    id: number,
    id_sede: Sede['id'],
    nome: string
}

export default async function getCouriers(): Promise<Corriere[]> {
    const token = localStorage.getItem("token");
    try {
        let req = await fetch(`${Constants.paths.ajaxBasePath}spedizioni/corrieri/?token=${token}`);
        return await req.json();
    } catch (error) {
        console.error(error);
        return [];
    }
}