function calcDiscount(base: number, percDiscount: number, actualPrice: number, decimals: number = 2) {
    if (percDiscount > 0) {
        return parseFloat(
            (base - (base * percDiscount) / 100)
                .toFixed(2));
    } else {
        if (base > 0) return parseFloat(base.toFixed(decimals));
        return (actualPrice).toFixed(decimals);
    }
}

export {calcDiscount};