import Constants from "../context/Constants";
import Cliente from "../interfaces/clienti/Cliente";

export default async function getCustomers<T extends Cliente | Cliente[]>(
    ids: number[] | number
): Promise<T> {
    const token = localStorage.getItem("token");
    let idsString;

    if (typeof ids === "object") {
        idsString = ids.join(",");
    } else {
        idsString = ids;
    }
    const req = await fetch(
        `${Constants.paths.ajaxBasePath}clienti/?token=${token}&id=${idsString}`
    );
    const res = await req.json();
    return res as T;
}