import {Box, Pagination, Typography} from "@mui/material";
import React from "react";

function TablePagination(props) {
    return (
        <Box sx={{justifyContent: "flex-end", display: 'flex', flexWrap: 'wrap'}}>
            <Box sx={{display: 'block'}}>
                <Typography variant='overline' color='textPrimary' style={{display: 'block'}}>
                    <b>{props.rowCount || 0}</b> risultati
                </Typography>
            </Box>
            <Box sx={{width: '100%'}}></Box>
            <Box>
                <Pagination
                    disabled={!props.rowCount}
                    page={props.page}
                    onChange={(e, p) => props.onChange(e, p)}
                    variant="outlined"
                    shape="rounded"
                    count={Math.ceil(
                        props.rowCount / props.pageSize
                    )}
                />
            </Box>
        </Box>
    );
}

export default TablePagination;
