import React, {useContext, useEffect, useRef, useState} from "react";
import Context from "../../context/Context";
import Constants from "../../context/Constants";
import axios, {AxiosError} from "axios";
// @ts-ignore
import localization from "moment/locale/it";
import moment from "moment";
import BozzePanel, {TBozza, TBozze} from "./BozzePanel";
import OrdinePanel from "./OrdinePanel";
import StoricoPanel from "./StoricoPanel";
import {enqueueSnackbar} from "notistack";
import {Navigation} from "../../components/Navigation";
import {ArchiveRounded, ContentPasteRounded, LightbulbRounded} from "@mui/icons-material";
import {useConfirm} from "material-ui-confirm";
import {TDataOrdine, TOfferta} from "./OrdinePanel/OrdinePanel";

moment.locale("it", localization);

type TTab = 'ordine' | 'bozze' | 'storico';

function Ordini({}) {
    const context = useContext(Context);
    const confirm = useConfirm();

    const [activeTab, setActiveTab] = useState<TTab>('ordine');
    const [fetching, setFetching] = useState<boolean>(false);
    const [dataProdotti, setDataProdotti] = useState<any>({});
    const [dataOfferta, setDataOfferta] = useState<TOfferta>(undefined);
    const [dataBozze, setDataBozze] = useState<TBozze>({});
    const [dataStorico, setDataStorico] = useState({});
    const [saveDraftDialogOpen, setSaveDraftDialogOpen] = useState(false);
    const [draftsCloudSyncStatus, setDraftsCloudSyncStatus] = useState({});
    const [draftsCloudSyncing, setDraftsCloudSyncing] = useState(false);
    const [orderToCloneId, setOrderToCloneId] = useState(0);
    const [activeOffertaId, setActiveOffertaId] = useState("DEFAULT");

    const updateTimer = useRef<ReturnType<typeof setInterval>>();

    useEffect(() => {
        syncDrafts().then();
        updateTimer.current = setInterval(
            () => {
                syncDrafts().then();
            },
            Constants.heartbeat.slow
        );
        return () => {
            if (updateTimer.current) {
                clearInterval(updateTimer.current);
            }
        }
    }, []);

    const getDraftsRemote = async (ids: number[] = []) => {
        const local = await getDraftsFromLocalStorage(true);
        const presentUuids: string[] = Object.keys(local);
        try {
            const resp = await fetch(
                Constants.paths.ajaxBasePath +
                "ordini-agenti/bozze/",
                {
                    method: "POST",
                    body: JSON.stringify(
                        {
                            token: context.user.token,
                            presentUuids: presentUuids
                        }),
                }
            );
            return await resp.json() as unknown as TBozze;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    const syncDrafts = async (forceEnableIds: string[] = []) => {
        // console.log('Richiesta Sync.');
        let promises = [];
        setDraftsCloudSyncing(true);

        let lDrafts = await getDraftsFromLocalStorage(true);

        let activeOrder = JSON.parse(localStorage.getItem("orderActive"));
        if (!lDrafts) lDrafts = {}; // Normalizza drafts per evitare storie
        let rDrafts: TBozze | false = await getDraftsRemote();

        if (!rDrafts) rDrafts = {};
        // Inserisci nel local storage tutte le bozze assenti
        let rUuids = Object.keys(rDrafts); //Remote UUIDS
        let lUuids = Object.keys(lDrafts); //Local UUIDS
        let allUuids: string[] = [...new Set([...lUuids, ...rUuids])]; //Set per evitare duplicati, array per avere le funzioni giuste

        for (let i = 0; i < allUuids.length; i++) { //For every uuid
            let thisUuid = allUuids[i];
            let thisDraftL = lUuids.includes(thisUuid) ? lDrafts[thisUuid] : null;
            let thisDraftR = rUuids.includes(thisUuid) ? rDrafts[thisUuid] : null;
            let thisDraftRActive: boolean = !!(rUuids.includes(thisUuid) ? rDrafts[thisUuid] : false);

            if (!thisDraftL) {
                if (thisUuid !== activeOrder?.["id"]) { //Non salvare nuovamente se si tratta dell'ordine attivo
                    if (thisDraftRActive) { //Se non presente in locale, importalo
                        await saveDraft(
                            thisUuid,
                            thisDraftR,
                            true);
                        // console.log('Import from remote: ', thisDraftR);
                        continue; // Non c'è altro da fare
                    }
                }
            } else { //È presente nel LS, controlla se va eliminato
                if (!thisDraftRActive) { //Sul DB è disattivato?
                    if (!forceEnableIds.includes(thisUuid)) { //Non eliminare se va riattivato
                        await deleteDraft(thisUuid, true);
                        continue; // Non c'è altro da fare
                    }
                }
            }

            //Invia tutte le bozze non presenti nel DB
            if (!thisDraftR && thisDraftL) { //Presente solo in L
                // console.log('Invio Draft non presente in DB...');
                await sendDraft(thisUuid, thisDraftL);
                continue; //Non c'è altro da controllare
            }

            //Aggiorna tutte le bozze più recenti sul device
            if (thisDraftL && thisDraftR) { //Aggiorna se presente in L ed R
                let remoteLastChange =
                    parseInt(thisDraftR?.["ultimaModifica"] as unknown as string) || 0;

                let localLastChange =
                    parseInt(thisDraftL?.["ultimaModifica"] as unknown as string) || 0;

                if (remoteLastChange !== localLastChange) {
                    if (remoteLastChange > localLastChange) {
                        //Il DB è più recente
                        await saveDraft(thisUuid, thisDraftR, true);
                    } else {
                        //Il locale è più recente
                        promises.push(() => sendDraft(thisUuid, thisDraftL));
                    }
                }
            }
        }

        await Promise.all(promises);
        await getDraftsFromLocalStorage();
        setDraftsCloudSyncing(false);
    }


    const getDraftsFromLocalStorage = async (returnOnly = false): Promise<TBozze> => {
        let draftsstring = localStorage.getItem("orderDrafts");
        let drafts: TBozze = {};
        if (draftsstring)
            drafts = JSON.parse(draftsstring);
        if (!returnOnly) {
            setDataBozze({...drafts});
        }
        return drafts;
    }

    const sendDraft = async (id: string, draftObj: TDataOrdine) => {
        const toPost = {
            token: context.user.token,
            uuid: id,
            object: draftObj,
        };
        try {
            await fetch(
                Constants.paths.ajaxBasePath + "ordini-agenti/bozze/",
                {
                    method: "PUT",
                    body: JSON.stringify(toPost),
                }
            );
            return true;
        } catch (e) {
            alert("Bozza non inviata al server! [" + e + "]");
            return false;
        }
    }

    const saveDraft = async (
        id: string,
        draftObj: TBozza,
        localOnly = false) => {
        let storage: TBozze = JSON.parse(localStorage.getItem("orderDrafts"));
        if (!storage) {
            storage = {}; //Normalize
        }
        draftObj['ultimaModifica'] = Date.now(); // Aggiorna ultima modifica con ora
        storage[id] = draftObj;
        localStorage.setItem("orderDrafts", JSON.stringify(storage));
        if (!localOnly) {
            if (!await sendDraft(id, draftObj)) return false;

        }
        await getDraftsFromLocalStorage();
        return true;
    }

    const deleteDraftRemote = async (id: string) => {
        try {
            await axios
                .delete(
                    Constants.paths.ajaxBasePath +
                    "ordini-agenti/bozze/?token=" +
                    context.user.token +
                    "&uuid=" +
                    id
                );
        } catch (error: any | AxiosError) {
            alert("Non è stato possibile eliminare la bozza dal DB [" + error.message + "]");
            return false;
        }
        return true;
    }

    const deleteDraft = async (id: string, localOnly = false) => {
        if (!localOnly) await deleteDraftRemote(id);
        let newStorage = await getDraftsFromLocalStorage(true);
        // newStorage = JSON.parse(newStorage);
        delete newStorage[id];
        let newStorageString = JSON.stringify(newStorage);
        localStorage.setItem("orderDrafts", newStorageString);
        if (!localOnly) {
            enqueueSnackbar("Bozza eliminata.", {
                variant: "warning",
            });
        }
        await getDraftsFromLocalStorage();
    }

    const loadActive = (
        orderObj: TDataOrdine = undefined,
        draftIDToDelete: string = undefined
    ) => {
        confirm({
            title: "Hai un ordine in corso!",
            description: "Proseguendo con la bozza verrà eliminato definitivamente l'ordine in corso."
        }).then(() => {
            if (typeof orderObj != "undefined") {
                const orderStr = JSON.stringify(orderObj);
                localStorage.setItem("orderActive", orderStr);
                enqueueSnackbar("Ordine caricato", {
                    variant: "info",
                });
            }
            setActiveOffertaId(orderObj.offerta.id);
            setActiveTab('ordine');
            if (draftIDToDelete != undefined)
                deleteDraft(draftIDToDelete, false).then(); //Elimina la bozza anche dal db in caso di utilizzo

        }).catch(() => {
            console.log("Ripristino bozza annullato.");
        })
    }

    const handleTabChange = (newVal: TTab) => setActiveTab(newVal);

    const cloneOrderTrigger = (toCloneId: number) => {
        setActiveTab('ordine');
        setOrderToCloneId(toCloneId);
    }

    // const handleSaveDraftDialogOpen = () =>
    //     setSaveDraftDialogOpen(true);
    //
    // const handleSaveDraftDialogClose = () =>
    //     setSaveDraftDialogOpen(false);

    // const {enqueueSnackbar, closeSnackbar} = this.props;
    return (
        <>
            <Navigation actions={[
                {
                    value: 'ordine',
                    icon: <ContentPasteRounded/> as any,
                    label: 'Ordine'
                },
                {
                    value: 'bozze',
                    label: 'Bozze',
                    icon: <LightbulbRounded/>
                },
                {
                    value: 'storico',
                    label: 'Storico',
                    icon: <ArchiveRounded/>
                }
            ]}
                        handleChange={(v) => handleTabChange(v as TTab)}
                        active={activeTab}/>
            <br/>
            {activeTab === 'ordine'
                ? <OrdinePanel
                    onRefreshDrafts={(ids) => syncDrafts(ids)}
                    onClearOrder={() => {
                    }}
                    // onClearOrder={(cb, saveDraftFn) => this.initClearOrder(cb, saveDraftFn)}
                    onChangeOrderId={(id) => setActiveOffertaId(id)}
                    triggerCloneOrderId={orderToCloneId}
                    onCloneOrderTriggered={() => setOrderToCloneId(0)}
                    saveDraft={(id, draftObj) => saveDraft(id, draftObj, false)}
                />
                : activeTab === 'bozze'
                    ? <BozzePanel
                        // theme={context.theme}
                        onSetActive={(draft, id) => loadActive(draft, id)}
                        onDeleteDraft={id => deleteDraft(id)}
                        syncDrafts={() => syncDrafts()}
                        // draftsCloudSyncStatus={draftsCloudSyncStatus}
                        drafts={dataBozze}
                        // fetching={fetching}
                        syncing={draftsCloudSyncing}
                    /> : activeTab === 'storico'
                        ? <StoricoPanel
                            theme={context.theme}
                            activeTab={activeTab}
                            fetching={fetching}
                            // loadActiveFn={this.loadActive}
                            onCloneOrder={(orderId: number) => cloneOrderTrigger(orderId)}
                        />
                        : null}
        </>
    );
}

export default Ordini;
