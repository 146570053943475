import {
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Constants from "../context/Constants";
import Context from "../context/Context";
import moment from "moment";
// @ts-ignore
import localization from "moment/locale/it";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faClone} from "@fortawesome/free-solid-svg-icons";
import {Prodotto} from "../interfaces/prodotti/Prodotto";
import {string} from "prop-types";

moment.locale("it", localization);

const Cols = [
    {
        id: "_date",
        label: "Data",
        minWidth: "150px",
    },
    {
        id: "v_prezzo",
        label: "Base",
    },
    {
        id: "user_sconto",
        label: "Sconto",
    },
    {
        id: "user_prezzo",
        label: "Prezzo",
    },
    {
        id: "qta",
        label: "Qtà",
        maxWidth: "25px",
    },
    {
        id: "_total",
        label: "Totale",
    },
    {
        id: "action",
        label: "Clona",
    },
];

type DataPoint = {
    "v_prezzo": number;
    "user_sconto": number;
    "user_prezzo": number;
    "qta": number;
    "timestamp": string;
}

type MinMax = {
    "user_prezzo": DataPoint['user_prezzo']
}

type Data = {
    _last: DataPoint;
    _min: MinMax;
    _max: MinMax;
    _all: DataPoint[];
    _source: Partial<Prodotto>
};

type DataCollection = {
    [key: string]: Data;
}

export default function ProductOrderHistory({
                                                id_prod,
                                                id_cliente,
                                                // v_prezzo,
                                                // user_prezzo,
                                                // user_sconto,
                                                // qta,
                                                overrideFn,
                                                handleClose
                                            }: {
    id_prod: number;
    id_cliente: number;
    // v_prezzo: number;
    // user_prezzo: number;
    // user_sconto: number;
    // qta: number;
    overrideFn: (v_prezzo: number, user_sconto: number, qta: number, user_prezzo: number) => void;
    handleClose: () => void;
}) {
    const context = useContext(Context);

    const [data, setData] = useState<Data>();

    useEffect(() => {
        getData().then();
    })

    const handleAction = (v_prezzo: number, user_sconto: number, qta: number, user_prezzo: number) => {
        overrideFn(v_prezzo, user_sconto, qta, user_prezzo);
        handleClose();
    }

    const getData = async () => {
        try {
            let res = await fetch(
                Constants.paths.ajaxBasePath +
                `prodotti/storici/ordini/clienti/?` +
                "&token=" +
                context.user.token +
                "&id_cliente=" +
                id_cliente +
                "&id_prodotti=" +
                id_prod
            );
            let result = await res.json() as DataCollection;
            setData(result["" + id_prod as string]);
        } catch (error) {
            console.log(error);
        }
    }

    const generateRows = () => {
        if (!data?._all || data?._all?.length < 1)
            return (
                <TableRow>
                    <TableCell>
                        <i>Storico non disponibile</i>
                    </TableCell>
                </TableRow>
            );

        return data._all.map((el, i) => (
            <TableRow>
                <TableCell>
                    {moment.unix(Number(el.timestamp)).format("DD/MM/YYYY")} •{" "}
                    {moment.unix(Number(el.timestamp)).fromNow()}
                </TableCell>
                <TableCell>
                    {el.v_prezzo.toFixed(2).replace(".", ",")} €
                </TableCell>
                <TableCell>-{el.user_sconto}%</TableCell>
                <TableCell>
                    {el.user_prezzo.toFixed(2).replace(".", ",")} €
                </TableCell>
                <TableCell>x{el.qta}</TableCell>
                <TableCell>
                    <strong>
                        {(el.user_prezzo * el.qta)
                            .toFixed(2)
                            .replace(".", ",")}
                        €
                    </strong>
                </TableCell>
                <TableCell>
                    <IconButton
                        onClick={e => handleAction(el.v_prezzo, el.user_sconto, el.qta, el.user_prezzo)}
                        size="large">
                        <FontAwesomeIcon size='sm' icon={faClone}/>
                    </IconButton>
                </TableCell>
            </TableRow>
        ));
    }

    return (
        <Paper style={{padding: context.theme.spacing(1)}}>
            <React.Fragment>
                <Grid container style={{textAlign: "center"}} spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="overline">Statistiche</Typography>
                    </Grid>
                    <Grid container item xs={5}>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                <FontAwesomeIcon
                                    style={{color: context.theme.palette.success.main}}
                                    icon={faArrowDown}
                                />{" "}
                                Min
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>
                                    {data?._min?.user_prezzo
                                        ? data._min.user_prezzo
                                        .toFixed(2)
                                        .replace(".", ",") + " €"
                                        : "N/D"}
                                </strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Divider
                            style={{display: "inline-flex"}}
                            orientation="vertical"
                        />
                    </Grid>
                    <Grid container item xs={5}>
                        <Grid item xs={12}>
                            <Typography variant="overline">
                                <FontAwesomeIcon
                                    style={{color: context.theme.palette.error.main}}
                                    icon={faArrowUp}
                                />{" "}
                                Max
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <strong>
                                    {data?._max?.user_prezzo
                                        ? data._max.user_prezzo
                                        .toFixed(2)
                                        .replace(".", ",") + " €"
                                        : "N/D"}
                                </strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline">Ultimo ordine</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {data?._last?.timestamp
                            ? moment.unix(Number(data._last.timestamp)).fromNow()
                            : "N/D"}
                    </Grid>
                    <Grid item xs={12}>
                        {data?._last?.timestamp
                            ? moment.unix(Number(data._last.timestamp)).format("L")
                            : "N/D"}
                    </Grid>
                    <Grid item xs={12}>
                        {data?._last?.v_prezzo
                            ? data._last.v_prezzo
                            .toFixed(2)
                            .replace(".", ",") + " €"
                            : "N/D"}
                        {" - "}
                        {(data?._last?.user_sconto
                            ? data._last.user_sconto
                            : "0") + "%"}
                        <Grid item xs={12}>
                            <br/>
                            <Typography variant="h6">
                                {data?._last?.user_prezzo
                                    ? data._last.user_prezzo
                                    .toFixed(2)
                                    .replace(".", ",") + " €"
                                    : "N/D"}{" "}
                                x {data?._last?.qta || "N/D"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
            <br/>
            <Grid container justifyContent='center'>
                <Grid item>
                    <Typography style={{textAlign: 'center', width: '100%'}} variant="overline">Storico</Typography>
                </Grid>
            </Grid>
            <TableContainer style={{maxHeight: "75vh"}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {Cols.map((c) => (
                                <TableCell key={c.id}>{c.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {generateRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

