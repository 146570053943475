import React, {SetStateAction} from 'react';
import {brandingLightTheme} from "./Theme";
import Constants from "./Constants";
// import {MediaQueryObject} from "react-media";

type TDevice = {
    lowSpec: boolean;
    useElasticSearch: boolean;
}

type TNetwork = {
    showSnackbar: boolean;
}

type TUI = {
    dark: boolean;
    sidebarOpen: boolean;
}

type TUser = {
    id?: number;
    username?: string;
    assignedZones?: string[];
    branchId?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    loggedIn: boolean;
    roles?: string[];
    token?: string;
}

interface TContext {
    device: TDevice;
    // matches?: MediaQueryObject;
    network: TNetwork;
    theme?: typeof brandingLightTheme;
    ui: TUI;
    user: TUser;
    setContext?: (newState: this) => void;
}

const defaultContext: TContext = {
    user: {
        loggedIn: false
    },
    ui: {
        sidebarOpen: false,
        dark: true
    },
    network: {
        showSnackbar: false,
    },
    device: {
        lowSpec: false,
        useElasticSearch: true
    },
    theme: brandingLightTheme,
    setContext: null,
    // matches: null,
}

const Context = React.createContext<TContext>(defaultContext);
export type {TContext};
export {defaultContext};
export default Context;
