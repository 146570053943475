import {faInfoCircle, faPlusCircle,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import {ProductAttributesSelector} from "../../components/DialogSelector";
import Constants from "../../context/Constants";
import Context from "../../context/Context";
import materialTableIcons from "../../support/materialTableIcons";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";

export default class Attributi extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.handleClassRowAdd = this.handleClassRowAdd.bind(this);
        this.handleClassRowUpdate = this.handleClassRowUpdate.bind(this);
        // this.handleAttrRowAdd = this.handleAttrRowAdd.bind(this); //Gestito al di fuori della material-table
        this.handleAttrRowUpdate = this.handleAttrRowUpdate.bind(this);
        this.handleAttrRowDelete = this.handleAttrRowDelete.bind(this);
        this.state = {
            fetching: false,
            addAttributesDialogOpen: false,
            addAttributesDialogSelectedIds: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData(returnOnly = false) {
        axios
            .get(
                `${Constants.paths.ajaxBasePath}prodotti/classificazioni/?token=${this.context.user.token}`
            )
            .then((res) => {
                let newData = res.data.map((d) => ({
                    ...d,
                    _count_attr: d.attrs?.length || 0,
                }));
                this.setState({
                    classificazioniData: newData.map((d) => ({
                        ...d,
                        attrs: d.attrs.map((a) => ({
                            ...a,
                            essenziale: a.essenziale == "1",
                        })),
                    })),
                });
            });
    }

    handleClassRowAdd(newData, onSuccess = false, onReject = false) {
        const toPost = {
            token: this.context.user.token,
            row: newData,
            action: "create",
        };
        axios
            .post(`${Constants.paths.ajaxBasePath}prodotti/classificazioni/`, toPost)
            .then((res) => {
                if (onSuccess) onSuccess();
                this.getData();
            })
            .catch((err) => {
                alert("Nome tipico non valido o duplicato");
                if (onReject) onReject();
            });
    }

    handleClassRowUpdate(newData, oldData, onSuccess = false, onReject = false) {
        const toPost = {
            token: this.context.user.token,
            row: newData,
            action: "update",
        };
        axios
            .patch(`${Constants.paths.ajaxBasePath}prodotti/classificazioni/`, toPost)
            .then((res) => {
                if (onSuccess) onSuccess();
                this.getData();
            })
            .catch((err) => {
                alert("Classificazione non valida o duplicata");
                if (onReject) onReject();
            });
    }

    handleAttrRowUpdate(
        idClass,
        newData,
        oldData,
        onSuccess = false,
        onReject = false
    ) {
        const toPost = {
            token: this.context.user.token,
            row: {
                id_classificazione: idClass,
                id_attr: newData.id,
                priorita: newData.priorita,
                essenziale: newData.essenziale ? 1 : 0,
            },
        };
        axios
            .post(
                `${Constants.paths.ajaxBasePath}prodotti/classificazioni/rel-attributi/`,
                toPost
            )
            .then((res) => {
                if (onSuccess) onSuccess();
                this.getData();
            })
            .catch((err) => {
                alert("Attributo non valido");
                if (onReject) onReject();
            });
    }

    handleAttrRowDelete(idClass, oldData, onSuccess = false, onReject = false) {
        const toPost = {
            token: this.context.user.token,
            row: {
                id_classificazione: idClass,
                id_attr: oldData.id,
            },
        };
        axios
            .delete(
                `${Constants.paths.ajaxBasePath}prodotti/classificazioni/rel-attributi/`,
                {
                    data: toPost,
                }
            )
            .then((res) => {
                if (onSuccess) onSuccess();
                this.getData();
            })
            .catch((err) => {
                alert("Attributo non eliminato");
                if (onReject) onReject();
            });
    }

    openAddAttributesDialog(idClass) {
        console.log(this.state.classificazioniData);
        this.setState({
            addAttributesDialogOpen: true,
            addAttributesDialogSelectedClassId: idClass,
            addAttributesDialogSelectedIds: this.state.classificazioniData
                .find((cd) => cd.id == idClass)
                .attrs.map((attr) => attr.id),
        });
    }

    closeAddAttributesDialog() {
        this.setState({
            addAttributesDialogOpen: false,
            addAttributesDialogSelectedClass: null,
            addAttributesDialogSelectedIds: [],
        });
    }

    async handleSelectAttributes(attr, id_class) {
        for (let i = 0; i < attr.length; i++) {
            const a = attr[i];
            const toPost = {
                row: {
                    id_attr: a.id,
                    id_classificazione: id_class,
                    priorita: 0,
                    essenziale: 0,
                },
                token: this.context.user.token,
            };
            await new Promise((resolve) =>
                axios
                    .post(
                        `${Constants.paths.ajaxBasePath}/prodotti/classificazioni/rel-attributi/`,
                        toPost
                    )
                    .catch((err) => alert(err))
                    .finally(() => resolve())
            );
        }
        this.closeAddAttributesDialog();
        this.getData();
    }

    render() {
        return (
            <React.Fragment>
                <MaterialTable
                    title="Classificazioni"
                    icons={materialTableIcons}
                    localization={materialTableLocalisationIT}
                    isLoading={this.state.fetching}
                    columns={[
                        {
                            title: "ID",
                            field: "id",
                            editable: "never",
                            width: "10%",
                        },
                        {
                            title: "Nome",
                            field: "nome",
                            editable: "always",
                            width: "80%",
                        },
                        {
                            title: "# Attr",
                            field: "_count_attr",
                            editable: "never",
                            width: "10%",
                        },
                    ]}
                    options={{
                        // tableLayout: "fixed",
                        search: true,
                        pageSize: 10,
                        //   filtering: true,
                        //   sorting: true,
                        addRowPosition: "first",
                        actionsColumnIndex: 4,
                    }}
                    data={this.state.classificazioniData}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((res, rej) => {
                                this.handleClassRowAdd(newData, res, rej);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((res, rej) => {
                                this.handleClassRowUpdate(newData, oldData, res, rej);
                            }),
                    }}
                    detailPanel={[
                        {
                            tooltip: "Modifica attributi collegati",
                            icon: () => (
                                <FontAwesomeIcon
                                    style={{margin: this.context.theme.spacing(0.5)}}
                                    icon={faInfoCircle}
                                />
                            ),
                            render: (rowData) => (
                                <MaterialTable
                                    title="Attributi"
                                    data={rowData.attrs}
                                    style={{
                                        maxWidth: "95%",
                                        margin: "auto",
                                        //   padding: this.context.theme.spacing(2),
                                    }}
                                    options={{
                                        tableLayout: "fixed",
                                        addRowPosition: "first",
                                        actionsColumnIndex: -1,
                                    }}
                                    actions={[
                                        {
                                            icon: () => (
                                                <FontAwesomeIcon
                                                    style={{margin: this.context.theme.spacing(0)}}
                                                    icon={faPlusCircle}
                                                />
                                            ),
                                            tooltip: "Aggiungi attributi",
                                            isFreeAction: true,
                                            onClick: (e) =>
                                                this.openAddAttributesDialog(
                                                    rowData.id
                                                ),
                                        },
                                    ]}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((res, rej) => {
                                                this.handleAttrRowUpdate(
                                                    rowData.id,
                                                    newData,
                                                    oldData,
                                                    res,
                                                    rej
                                                );
                                            }),
                                        onRowDelete: (oldData) =>
                                            new Promise((res, rej) => {
                                                this.handleAttrRowDelete(
                                                    rowData.id,
                                                    oldData,
                                                    res,
                                                    rej
                                                );
                                            }),
                                    }}
                                    icons={materialTableIcons}
                                    localization={materialTableLocalisationIT}
                                    columns={[
                                        {
                                            title: "ID",
                                            field: "id",
                                            editable: "never",
                                        },
                                        {
                                            title: "LG Prefisso",
                                            field: "legacy_prefisso",
                                            editable: "never",
                                        },
                                        {
                                            title: "Prefisso",
                                            field: "prefisso",
                                            editable: "never",
                                        },

                                        {
                                            title: "Nome",
                                            field: "nome",
                                            editable: "never",
                                        },
                                        {
                                            title: "Suffisso",
                                            field: "suffisso",
                                            editable: "never",
                                        },
                                        {
                                            title: "LG Suffisso",
                                            field: "legacy_suffisso",
                                            editable: "never",
                                        },
                                        {
                                            title: "Essenziale",
                                            field: "essenziale",
                                            editable: "always",
                                            type: "boolean",
                                            cellStyle: {textAlign: "center"},
                                            headerStyle: {textAlign: "center"},
                                            width: "15%",
                                        },
                                        {
                                            title: "Priorità",
                                            field: "priorita",
                                            editable: "always",
                                            type: "numeric",
                                            width: "15%",
                                        },
                                    ]}
                                />
                            ),
                        },
                    ]}
                />
                <ProductAttributesSelector
                    multiple
                    open={this.state.addAttributesDialogOpen}
                    onClose={(e) => this.closeAddAttributesDialog()}
                    onSelect={(attr) =>
                        this.handleSelectAttributes(
                            attr,
                            this.state.addAttributesDialogSelectedClassId
                        )
                    }
                    onFetchingChange={this.onFetchingChange}
                    selectedIds={this.state.addAttributesDialogSelectedIds}
                />
            </React.Fragment>
        );
    }
}
