export default {
    pagination: {
        labelDisplayedRows: "{from}-{to} di {count}",
        labelRowsSelect: "righe",
        labelRowsPerPage: "Righe per pagina:",
        firstTooltip: "Prima pagina",
        lastTooltip: "Ultima pagina",
        previousTooltip: "Pagina precedente",
        nextTooltip: "Pagina successiva"
    },
    toolbar: {
        addRemoveColumns: "Aggiungi o rimuovi colonne",
        nRowsSelected: "{0} righe selezionate",
        searchPlaceholder: "Cerca...",
        searchTooltip: "Cerca",
        exportTitle: 'Esporta',
        exportName: 'Esporta come CSV'
    },
    body: {
        editRow: {
            deleteText: "Sei sicuro di voler eliminare questa riga?",
            cancelTooltip: "Annulla",
            saveTooltip: "Conferma"
        },
        emptyDataSourceMessage: "Nessun dato trovato.",
        editTooltip: "Varia",
        deleteTooltip: "Elimina",
        addTooltip: "Nuovo"
    }
}