import React from "react";
// import makeStyles from '@mui/styles/makeStyles';
import {Card, CardContent, Chip, Typography} from "@mui/material";
import Context from "../context/Context";

const styles = {
    card: {
        minWidth: 275,
        maxWidth: 600,
        margin: "auto",
        marginTop: "2em",
        marginBottom: "2em",
        textAlign: 'center'
    },
    cardGrid: {
        maxWidth: "100%",
        minWidth: 275,
        margin: "auto"
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
};

export default function ProfileCard(props) {
    const classes = styles;

    return (
        <Context.Consumer>
            {data => (
                <Card variant="outlined" elevation={0} sx={props.inGrid ? classes.cardGrid : classes.card}>
                    <CardContent>
                        <Typography
                            sx={classes.title}
                            color="textSecondary"
                            // gutterBottom
                        >
                            Accesso effettuato come:
                        </Typography>
                        <Typography variant="h4" sx={{marginBottom: data.theme.spacing(1)}}>
                            {data.user.firstName + " " + data.user.lastName}
                        </Typography>
                        {data.user.roles.map((r, i) => {
                            return (
                                <Chip
                                    key={"role-" + i}
                                    variant="outlined"
                                    size="small"
                                    label={r}
                                    style={{
                                        margin: data.theme.spacing(0.2)
                                    }}
                                />
                            );
                        })}
                        <Typography variant="body2" component="p">
                            <Typography component="strong">Su questa scheda puoi:</Typography>
                            <br/>
                            Leggere e Modificare
                        </Typography>
                        {data.env === 'dev' && <Chip size={'small'} style={{
                            background: data.theme.palette.warning.main,
                            marginTop: data.theme.spacing(1)
                        }} label={'STAGING'}/>}
                    </CardContent>
                </Card>
            )}
        </Context.Consumer>
    );
}
