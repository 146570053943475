import React, {useContext, useState} from "react";
import Context from "../context/Context";
import {IconButton, InputBase, Paper} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import useBreakpoints from "../functions/breakpoints";

function SearchBar({
                       placeholder,
                       forcedQuery,
                       onChange,
                       onBlur,
                       onClear
                   }: {
    placeholder?: string;
    forcedQuery?: string;
    onChange: (val: string) => void;
    onBlur?: (val: string) => void;
    onClear?: () => void;
}) {
    const context = useContext(Context);
    const [query, setQuery] = useState("");
    const matches = useBreakpoints();

    const catchReturn = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.key === "Enter") {
            try {
                onBlur(query);
            } catch {
                //onBlur disattivato nei dispositivi ad alte prestazioni
            }
        }
    }


    return (
        <>
            <Paper
                elevation={5}
                style={{
                    marginTop: context.theme.spacing(3),
                    marginBottom: context.theme.spacing(3),
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: context.theme.spacing(1),
                    paddingLeft: context.theme.spacing(3),
                    paddingRight: context.theme.spacing(3),
                    maxWidth: !matches.sm
                        ? "80%" //XS
                        : !matches.md
                            ? "70%"
                            : !matches.lg
                                ? "40%"
                                : "33%",
                }}
            >
                <InputBase
                    fullWidth
                    placeholder={placeholder || "Cerca..."}
                    value={forcedQuery || query}
                    disabled={!!forcedQuery}
                    onChange={(e) => {
                        setQuery(e.target.value);
                        onChange(e.target.value);
                    }}
                    onBlur={e => onBlur?.(e.target.value)}
                    onKeyUp={e => catchReturn(e)}
                    startAdornment={
                        <IconButton size="large">
                            <FontAwesomeIcon size="xs" icon={faSearch}/>
                        </IconButton>
                    }
                    endAdornment={
                        <IconButton
                            onClick={(e) => {
                                setQuery("");
                                onClear?.();
                            }}
                            size="large">
                            <FontAwesomeIcon size="xs" icon={faTimes}/>
                        </IconButton>
                    }
                />
            </Paper>
        </>
    );
}

export default SearchBar;
