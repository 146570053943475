import React, {ComponentProps, useContext, useEffect, useMemo, useState} from "react";
import {
    Button,
    ButtonBase,
    ButtonGroup,
    Card,
    Collapse,
    Divider,
    Grid,
    IconButton,
    IconButtonOwnProps,
    InputAdornment,
    Popover,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import Context from "../../../context/Context";

import {GeneratePricesPopupContent} from "../../../components/PricesPopupContent";

import TableProductCod from "../../../components/TableProductCod";
import ProductImage from "../../../components/ProductImage";
import ListInventoryDisplay from "../../../components/ListInventoryDisplay";
import getPricesNr from "../../../functions/countAvailablePrices";
import ProductOrderHistory from "../../../components/ProductOrderHistory";
import Feedback from "../../../components/Feedback";
import formatPrice from "../../../functions/formatPrice";
import {EuroRounded, Info, RemoveCircle} from "@mui/icons-material";
import {TCliente, TDataOrdine, TFNHandleProductChange, TOfferta, TOrderError} from "./OrdinePanel";
import {TListiniOrdine} from "../../../interfaces/prices/priceMeta";
import {enqueueSnackbar} from "notistack";
import {preFormatNumberForInputChange} from "../../../functions/inputHelper";
import {extractListini} from "../../../functions/productHelpers";
import ProductExtendedPopup from "../../../components/ProductExtendedPopup";
import {Prodotto} from "../../../interfaces/prodotti/Prodotto";
import useBreakpoints from "../../../functions/breakpoints";

function calcRowTotal(productObj: Prodotto, asFloat = false): number | React.JSX.Element {
    let floatVal = parseFloat(productObj.user_prezzo as any) * parseInt(productObj.qta as any);

    if (asFloat) return floatVal;
    return (
        <>
            {formatPrice(floatVal)}
        </>
    );
}

function Description({
                         editable = false,
                         desc,
                         extendedDesc,
                         onChange,
                         prod
                     }: {
    editable: boolean;
    desc: string;
    extendedDesc: string;
    onChange: (text: string, prod: Prodotto, property: string) => void;
    prod: Prodotto;
}) {
    return <>
        {editable ? (
            <TextField
                variant="outlined"
                multiline
                fullWidth
                value={desc}
                rows={3}
                onChange={(e) =>
                    onChange(
                        e.target.value,
                        prod,
                        "desc"
                    )
                }
            />
        ) : (
            <Typography variant="body1" align="left">
                {desc}
            </Typography>
        )}
    </>
}

function SideButton({
                        vertical,
                        removeFn,
                        id_cliente,
                        feedbackText,
                        id_prod,
                        overrideProductFromHistory,
                        iconSize = 'large'
                    }: {
    vertical: boolean;
    removeFn: () => void;
    id_cliente: number;
    feedbackText: string;
    id_prod: number;
    overrideProductFromHistory: any; //TODO: fix
    iconSize: IconButtonOwnProps['size'];
}) {
    const context = useContext(Context);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);

    return <>
        <Grid container
              direction={vertical ? "column" : "row"}
              style={{alignSelf: 'center'}}
        >
            <Grid item xs={3} alignSelf={'center'}>
                <ProductExtendedPopup
                    id_prod={id_prod}
                />
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
                <Tooltip title={'Storico'}>
                    <IconButton
                        disabled={!id_cliente}
                        style={{textAlign: "right"}}
                        onClick={(e) => {
                            setAnchor(e.currentTarget);
                            setHistoryOpen(!historyOpen);
                        }}
                        size={iconSize}>
                        <HistoryRoundedIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
                <Feedback
                    info={feedbackText}
                    iconSize={iconSize}
                />
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
                <Tooltip title={"Rimuovi"}>
                    <IconButton
                        style={{textAlign: "right"}}
                        onClick={() => removeFn()}
                        size={iconSize}>
                        <RemoveCircle style={{color: context.theme.palette.error.main}}/>
                        {/* <Box color="text.error">Rimuovi</Box> */}
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
        <Popover
            open={historyOpen}
            onClose={() => setHistoryOpen(false)}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
        >
            <ProductOrderHistory
                id_cliente={id_cliente}
                id_prod={id_prod}
                handleClose={() => setHistoryOpen(false)}
                overrideFn={overrideProductFromHistory}
            />
        </Popover>
    </>
}

function NotesField({
                        onBlur,
                        initValue,
                        style
                    }:
                    {
                        onBlur: (value: string) => void;
                        initValue: string;
                        style?: React.CSSProperties;
                    }) {
    const context = useContext(Context);
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(initValue)
    }, []);


    return <>
        <Typography
            sx={{marginLeft: context.theme.spacing(1), float: 'left'}}
            variant="overline"
        > Note </Typography>
        <TextField
            style={{
                ...style,
                width: "100%",
            }}
            fullWidth
            label=""
            placeholder={'Note'}
            variant="outlined"
            margin="none"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={e => onBlur(e.target.value)}
        />
    </>;
}

function PointsField({
                         availablePoints,
                         onChange,
                         onBlur,
                         onUseAll,
                         usedPoints
                     }: {
    availablePoints: number;
    onChange: (val: string) => void;
    onBlur: (val: string) => void;
    onUseAll: (points: number) => void;
    usedPoints: number;
}) {
    const context = useContext(Context);
    return <>
        <Typography
            sx={{marginLeft: context.theme.spacing(1), float: 'left'}}
            variant="overline"
        > Punti (Su Totale) </Typography>
        <Tooltip
            title={'Arrotondamento automatico in base al decimale del prezzo singolo minimo possibile con i punti accumulati'}
            sx={{float: 'right'}}>
            <IconButton size={'small'}>
                <Info fontSize={'small'}/>
            </IconButton>
        </Tooltip>
        <TextField
            className="input-align-right"
            type="number"
            error={parseFloat((availablePoints).toFixed(2)) < 0}
            helperText={
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        {"Disp: " + formatPrice(availablePoints)}
                    </Grid>
                    <Grid item>
                        <ButtonBase sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: context.theme.palette.primary.main,
                            fontSize: '100%'
                        }}
                                    onClick={() => onUseAll(availablePoints + usedPoints)}>
                            Usa Max
                        </ButtonBase>
                    </Grid>
                </Grid>
            }
            placeholder="0,00"
            value={usedPoints}
            onChange={e => onChange(e.target.value)}
            onBlur={e => onBlur(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start"
                                    sx={{color: context.theme.palette.success.main}}>
                        <StarsRoundedIcon/>
                    </InputAdornment>
                ),
            }}
        />
    </>
}

function QuantityField({
                           qty,
                           packaging = 1,
                           onChange,
                           onBlur,
                           hasError = false
                       }: {
    qty: number | string;
    packaging: number;
    onChange: (newVal: string, prop: "qta" | "qtaMinus" | "qtaPlus") => void;
    onBlur: (newVal: string, prop: "qta" | "qtaMinus" | "qtaPlus") => void;
    hasError: boolean;
}) {
    const context = useContext(Context);
    return <>
        <Typography
            style={{marginLeft: context.theme.spacing(1)}}
            variant="overline"
        >
            Quantità
        </Typography>
        <br/>
        <ButtonGroup
            variant="outlined"
        >
            <Button
                onClick={
                    () => onChange(
                        "1",
                        "qtaMinus"
                    )
                }
            >
                <RemoveRoundedIcon/>
            </Button>
            <TextField
                error={
                    hasError ?
                        true
                        : packaging
                            ? parseInt(qty as string) %
                            packaging !==
                            0
                            : false
                }
                placeholder="Quantità"
                style={{
                    minWidth: 0,
                    borderRadius: 0,
                }}
                className="input-align-center input-no-border-radius"
                value={qty}
                onChange={(e) =>
                    onChange(
                        e.target.value,
                        "qta"
                    )
                }
                onBlur={(e) =>
                    onBlur(
                        e.target.value,
                        "qta"
                    )
                }
            />
            <Button
                onClick={(e) =>
                    onChange("1",
                        "qtaPlus"
                    )
                }
            >
                <AddRoundedIcon/>
            </Button>
        </ButtonGroup>
        {
            packaging > 1 && (
                <Typography
                    style={{textAlign: "left"}}
                    align="left"
                    className="MuiFormHelperText-contained MuiFormHelperText-root"
                >
                    Imballo:{" "}
                    {packaging}
                    pz
                </Typography>
            )
        }</>
}

function DiscountField({
                           onChange,
                           onBlur,
                           maxSconto = 100,
                           noteSconto = "",
                           userSconto,
                           disabled = false,
                           placeholder = "",
                           hasError = false
                       }: {
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
    userSconto: number | string;
    maxSconto?: number;
    noteSconto?: string;
    disabled: boolean;
    placeholder?: string;
    hasError?: boolean;
}) {
    const context = useContext(Context);

    return <>
        <Typography
            style={{marginLeft: context.theme.spacing(1)}}
            variant="overline">
            Sconto
        </Typography>
        <br/>
        <TextField
            disabled={disabled}
            error={hasError}
            fullWidth
            helperText={
                (maxSconto &&
                    maxSconto !== 100)
                    ? noteSconto
                        ? noteSconto
                        : `Max ${maxSconto}%`
                    : false
            }
            className="input-align-center"
            type="tel"
            placeholder={(disabled && placeholder) ? placeholder : '0'}
            variant="outlined"
            value={preFormatNumberForInputChange(userSconto) || placeholder}
            onChange={e => onChange(preFormatNumberForInputChange(e.target.value))}
            onBlur={e => onBlur(preFormatNumberForInputChange(e.target.value))}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <PercentRoundedIcon/>
                    </InputAdornment>
                ),
            }}
        />
    </>
}


function PriceField({
                        onPriceChange,
                        onAnyBlur,
                        onBasePriceChange,
                        onDiscountChange,
                        points = 0,
                        usedPoints = 0,
                        availablePoints = 0,
                        containerProps = {},
                        isPointsOffer = false,
                        minPossiblePrice,
                        hasError = false,
                        pricesNr = 1,
                        user_price = 0,
                        allowDiscount = false,
                        listini = [],
                        v_price = 0,
                        options = {}
                    }: {
    onPriceChange: (value: string) => void;
    onAnyBlur: () => void;
    onBasePriceChange: (value: string | number) => void;
    onDiscountChange: (value: string | number) => void;
    points?: number;
    usedPoints?: number;
    availablePoints?: number;
    containerProps?: ComponentProps<typeof Grid>;
    isPointsOffer: boolean;
    minPossiblePrice: number;
    hasError?: boolean;
    user_price: number | string;
    pricesNr: number;
    listini: TListiniOrdine[];
    allowDiscount?: boolean;
    v_price: number;
    options: Prodotto["options"];
}) {

    const context = useContext(Context);
    const [popupAnchor, setPopupAnchor] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const matches = useBreakpoints();

    const handlePricePopupOpen = (target: React.MouseEvent<HTMLButtonElement, MouseEvent>['target']) => {
        setPopupAnchor(target);
        setPopupOpen(true);
    }

    const handlePricePopupClose = () => {
        setPopupOpen(false);
    }
    return <Grid container {...containerProps}>
        <Grid item xs={6}>
            {/*Mezza colonna perché doveva contenere i punti*/}
            <Typography
                style={{marginLeft: context.theme.spacing(1)}}
                variant="overline"
            >
                {pricesNr > 0 ? (
                    <>
                        <Button
                            style={{
                                marginLeft:
                                    parseFloat(context.theme.spacing(0.75)) * -1,
                                marginTop: parseFloat(context.theme.spacing(0.25)) * -1,
                                position: "absolute",
                                fontSize: 12,
                            }}
                            onClick={e => handlePricePopupOpen(e.target)}
                        >
                            PREZZI ({pricesNr})
                            <ArrowDropDownRoundedIcon/>
                        </Button>
                        <Popover
                            open={popupOpen}
                            onClose={e => handlePricePopupClose()}
                            anchorEl={popupAnchor}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <GeneratePricesPopupContent
                                listini={listini}
                                onClick={
                                    (newPrice) => {
                                        onPriceChange(String(newPrice));
                                        handlePricePopupClose();
                                    }}
                                allowDiscount={allowDiscount}
                                // acquisto={false}
                            />
                        </Popover>
                    </>
                ) : (
                    "Prezzo"
                )}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <TextField
                sx={{width: '100%'}}
                error={hasError}
                className="input-align-right"
                type="text"
                helperText={
                    <Grid container justifyContent={'space-between'}>
                        <Grid item xs={12} sm={6}>
                            {((!matches.md) ? "B" : "Base")
                                + ": "
                                + (v_price
                                    ? formatPrice(v_price)
                                    : "N/D")}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Collapse
                                sx={{textAlign: matches.lg ? 'right' : 'center'}}
                                in={
                                    parseFloat(v_price as any).toFixed(2) != parseFloat(
                                        user_price as string
                                    ).toFixed(2)
                                }
                            >
                                <ButtonBase
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: context.theme.palette.primary.main,
                                        fontSize: '100%',
                                        textAlign: 'center'
                                    }}
                                    onClick={(e) => {
                                        onBasePriceChange(user_price);
                                        onDiscountChange(0);
                                    }}
                                    // size="small"
                                    // variant="text"
                                    // fullWidth
                                >
                                    Base
                                </ButtonBase>
                            </Collapse>
                        </Grid>
                        {
                            isPointsOffer
                            && <Grid item
                                     xs={12}
                                     sx={{
                                         color:
                                             options.punti?.uso
                                                 ? (
                                                     (
                                                         listini.find(({key}) => key === 'prezzo_l3').prezzo || 0)
                                                     - availablePoints
                                                     - usedPoints
                                                 ) > parseFloat(user_price as string)
                                                     ? context.theme.palette.error.main
                                                     : context.theme.palette.success.main
                                                 : context.theme.palette.success.main
                                     }}>
                                {options.punti?.uso
                                    ? (matches.md ? "Min" : "Min") + ": " + formatPrice(minPossiblePrice, 3)
                                    : (matches.md ? "Punti" : "Pnt") + ": " + formatPrice(points, 3)
                                }
                            </Grid>
                        }
                    </Grid>
                }
                placeholder="0,000"
                value={preFormatNumberForInputChange(user_price)}
                // onBlur={(e) => onPriceBlur(e.target.value)}
                onBlur={() => onAnyBlur()}
                onChange={(e) => onPriceChange(preFormatNumberForInputChange(e.target.value))}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EuroRounded/>
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    </Grid>
}

function RowTotalsField({
                            total,
                            rowPoints = false
                        }: {
    total: number;
    rowPoints?: false | number;
}) {
    const context = useContext(Context);
    return <Grid container>
        <Grid item xs={12} sx={{textAlign: 'right'}}>
            <Typography
                sx={{
                    display: "inline-block",
                    fontWeight: "normal",
                    marginTop: context.theme.spacing(2.5)
                }}
                variant="h6"
            >
                Totale
            </Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'right'}}>
            <Typography
                variant="h6"
                color="textPrimary"
                align="right"
                style={{
                    marginTop: context.theme.spacing(0.5),
                    minWidth: context.theme.spacing(10),
                    display: "inline-block",
                    fontWeight: 'bold',
                    fontSize: '150%'
                }}
            >
                {formatPrice(total)}
            </Typography>
        </Grid>
        <Grid item
              xs={12}
              sx={{textAlign: 'right'}}
        >
            {rowPoints !== false
                && <Typography
                    variant={'caption'}
                    sx={{
                        color: (context.theme.palette.success.main),
                        textAlign: 'right'
                    }}>
                    {
                        "Punti: " + formatPrice(rowPoints)
                    }
                </Typography>
            }
        </Grid>
    </Grid>
}

const inlineNotesImgCss =
    "<style>.product-info-popover img{max-width:100%;border-radius:4px}</style>";

function SchedaProdotto(
    {
        product,
        offer,
        customer,
        handleProductChange,
        handleProductChangeAnyBlur,
        handleProductRemove,
        index,
        isPointsOffer = false,
        usesPoints = false,
        availablePoints,
        totalPoints,
        orderType,
        minPossiblePrice,
        points,
        errors
    }
    : {
        product: Prodotto;
        offer: TOfferta;
        customer: TCliente;
        handleProductChange: TFNHandleProductChange;
        handleProductChangeAnyBlur: (prod: Prodotto) => void;
        handleProductRemove: (prod: Prodotto) => void;
        index: number;
        isPointsOffer: boolean;
        usesPoints: boolean;
        availablePoints?: number;
        orderType: TDataOrdine["tipo"],
        minPossiblePrice?: number;
        totalPoints?: number;
        points?: number;
        errors: TOrderError[];
    }) {
    const context = useContext(Context);
    const matches = useBreakpoints();
    const pricesNr = useMemo(() => getPricesNr(product), [product]);

    const [pricePopupOpen, setPricePopupOpen] = useState<boolean>(false);
    const [infoPopupOpen, setInfoPopupOpen] = useState<boolean>(false);
    const [historyPopupOpen, setHistoryPopupOpen] = useState<boolean>(false);
    // const [points, setPoints] = useState<number>(0);
    const [infoPopupAnchor, setInfoPopupAnchor] = useState<null | HTMLElement>(null);

    const handleInfoPopupOpen = (target: React.MouseEvent<HTMLButtonElement, MouseEvent>['target']) => {
        setInfoPopupAnchor(target as HTMLElement);
        setInfoPopupOpen(true);
    }
    const handleInfoPopupClose = () => setInfoPopupOpen(false);

    // const handleUserNotesChange = (value: string) => {
    //     handleProductChange(value, product, "note");
    // }

    return <>
        <Card
            key={product.id}
            elevation={0}
            variant={"outlined"}
            style={{
                margin: !matches.sm
                    ? context.theme.spacing(0) //XS
                    : !matches.md
                        ? context.theme.spacing(1) //SM
                        : context.theme.spacing(2), //MD+
                padding: !matches.sm
                    ? context.theme.spacing(2) //XS
                    : !matches.md
                        ? context.theme.spacing(2) //SM
                        : context.theme.spacing(3), //MD+
                marginBottom: !matches.sm
                    ? context.theme.spacing(2)//XS
                    : !matches.md
                        ? context.theme.spacing(2) //SM
                        : context.theme.spacing(3), //MD+
                borderColor: errors.length ? (orderType === 'preventivo' ? context.theme.palette.warning.main : context.theme.palette.error.main) : null
            }}
        >
            {/*ALL*/}
            <Grid
                container
                alignContent="flex-start"
                alignItems={'center'}
                justifyContent={'space-between'}
                spacing={2}
                style={{
                    textAlign:
                        matches.xs && !matches.md
                            ? "inherit"
                            : "initial",
                }}
            >
                {/*COD*/}
                <Grid
                    item
                    xs={3}
                    sm={2}
                    md={2}
                    lg={1}
                    style={{textAlign: "left"}}
                >
                    <ProductImage
                        containerSize={context.theme.spacing(8)}
                        sku={product.cod || product.v_cod}
                        usesAlt={offer.id !== "DEFAULT"}
                        idProd={product.id}
                    />
                </Grid>
                <Grid item
                      xs={5}
                      sm={7}
                      md={2}
                      lg={2}
                >
                    <Typography variant={'caption'}>
                        #{index}
                    </Typography>
                    <br/>
                    <TableProductCod
                        noEan
                        allowCustomisation={false}
                        manualUfCod={product.id === 99999}
                        onUfCodChange={(val: string) =>
                            handleProductChange(
                                val,
                                product,
                                "ufCod"
                            )
                        }
                        productObj={product}
                    />
                    {product.note_commerciali && (
                        <Typography color={"secondary"} variant="caption">
                            [
                            <i>{product.note_commerciali}</i>
                            ]
                        </Typography>
                    )}
                    {product.posizione && (
                        <Typography variant="caption">
                            Pos.:{" "}
                            <strong>{product.posizione}</strong>
                        </Typography>
                    )}
                    {product.note ? (
                        <IconButton
                            onClick={e => handleInfoPopupOpen(e.target)}
                            style={{
                                marginTop: parseFloat(context.theme.spacing(0.5)) * -1,
                            }}
                            size="small"
                        >
                            <InfoIcon/>
                        </IconButton>
                    ) : (
                        ""
                    )}
                </Grid>
                {
                    matches.md &&
                    <Grid item
                          xs={5}
                          md={4}
                          lg={5}
                          style={{textAlign: "left"}}>
                        <Description
                            editable={product.id === 99999}
                            desc={product.descrizione}
                            extendedDesc={product.descrizione_estesa}
                            onChange={val => handleProductChange(val, product, 'desc')}
                            // onBlur={() => handleProductChangeAnyBlur(product)}
                            prod={product}
                        />
                    </Grid>
                }
                <Grid item
                      xs={2}
                      sm={1}
                      md={1}
                      lg={2}
                >
                    <ListInventoryDisplay
                        style={{background: "none"}}
                        extended={!!matches.lg}
                        id_prod={product.id}
                    />
                </Grid>

                <Grid item
                      xs={2}
                      md={2}
                      lg={2}
                      style={{textAlign: "right"}}>
                    <SideButton
                        id_prod={product.id}
                        id_cliente={customer.id}
                        removeFn={() => handleProductRemove(product)}
                        feedbackText={`(${product.cod}) ${product.descrizione}`}
                        vertical={!matches.md}
                        iconSize={!matches.md
                            ? "medium" // SM
                            : "large" // LG
                        }
                        overrideProductFromHistory={(
                            user_prezzo: number,
                            user_sconto: number,
                            qta: number
                        ) => {
                            // Attento al setState asinchorno, risolvi con una catena di callback, in futuro trasforma in async / await
                            handleProductChange(String(user_prezzo), product, "prezzoBase", true);
                            handleProductChange(String(user_sconto), product, "sconto", true);
                            handleProductChange(String(qta), product, "qta", true);
                            handleProductChangeAnyBlur(product);
                        }}
                    />
                </Grid>

                {!matches.md
                    && <Grid item xs={12}>
                        <Description
                            editable={product.id === 99999}
                            desc={product.descrizione}
                            extendedDesc={product.descrizione_estesa}
                            onChange={e => handleProductChange}
                            prod={product}
                        />
                    </Grid>}
            </Grid>
            <Divider
                style={{
                    minWidth: "100%",
                    marginTop: context.theme.spacing(2),
                    marginBottom: context.theme.spacing(2),
                }}
            />
            <Grid
                item
                container
                xs={12}
                spacing={matches.lg ? 2 : 1}
                style={{
                    justifyContent: !matches.md
                        ? "space-between"
                        : "flex-end",
                    alignContent: "center",
                }}
            >
                {/*SECONDA FILA*/}
                {matches.md && //MD+
                    <>
                        <Grid item
                            // xs={2}
                              md={(usesPoints)
                                  ? 2
                                  : 3}
                              lg={(usesPoints)
                                  ? 2
                                  : 4}>
                            <NotesField
                                initValue={product.user_note}
                                onBlur={value => handleProductChange(value, product, "note")}
                            />
                        </Grid>
                    </>}
                <Grid
                    item
                    xs={4}
                    sm={3}
                    md={(usesPoints)
                        ? 2
                        : 3}
                    lg={2}
                    style={{textAlign: "initial"}}
                >
                    <QuantityField
                        qty={product.qta}
                        onChange={(val, prop) => handleProductChange(val, product, prop)}
                        onBlur={() => handleProductChangeAnyBlur(product)}
                        packaging={product.imballo}
                        hasError={false} //TODO: Gestisci nel componente SchedaProdotto
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sm={3}
                    md={2}
                    style={{textAlign: "initial"}}
                >
                    {/*Sconto*/}
                    <DiscountField
                        userSconto={product.user_sconto}
                        onChange={val => handleProductChange(val, product, 'sconto')}
                        onBlur={() => handleProductChangeAnyBlur(product)}
                        maxSconto={product.max_sconto}
                        noteSconto={product.note_sconto}
                        disabled={isPointsOffer && usesPoints}
                        placeholder={(isPointsOffer && usesPoints)
                            ? "(Utilizza punti)"
                            : ""}
                        // hasError={hasDiscountError}
                    />
                </Grid>

                <Grid
                    item
                    xs={4}
                    sm={3}
                    md={2}
                    lg={2}
                >
                    {/*PREZZO*/}
                    <PriceField
                        containerProps={{xs: 12}}
                        onPriceChange={(val) => handleProductChange(val, product, 'prezzo')}
                        onAnyBlur={() => handleProductChangeAnyBlur(product)}
                        isPointsOffer={isPointsOffer}
                        usedPoints={product.uso_punti as number}
                        points={points}
                        availablePoints={availablePoints}
                        minPossiblePrice={minPossiblePrice}
                        // hasError={hasPriceError}
                        pricesNr={pricesNr}
                        listini={extractListini(product)}
                        onBasePriceChange={val => handleProductChange(val as string, product, 'prezzoBase')}
                        onDiscountChange={val => handleProductChange(val as string, product, 'sconto')}
                        options={product.options}
                        user_price={product.user_prezzo}
                        v_price={product.v_prezzo as number}
                    />
                </Grid>
                {usesPoints
                    && matches.md
                    && <Grid item md={2}>
                        <PointsField
                            availablePoints={availablePoints}
                            usedPoints={product.uso_punti as number}
                            onChange={(value) =>
                                handleProductChange(
                                    value,
                                    product,
                                    "usoPunti"
                                )
                            }
                            onBlur={() => handleProductChangeAnyBlur(product)}
                            onUseAll={(value) => handleProductChange(
                                String(value),
                                product,
                                "usoPunti")}
                        />
                    </Grid>
                }

                {/*PREZZO*/}
                {!matches.md //SM
                    ? (
                        <>
                            <Grid item xs={usesPoints ? 5 : 9}>
                                <NotesField
                                    initValue={product.user_note}
                                    onBlur={value => handleProductChange(value, product, 'note')}
                                />
                            </Grid>
                            {usesPoints
                                && <Grid item xs={4}>
                                    <PointsField
                                        availablePoints={availablePoints}
                                        usedPoints={product.uso_punti as number}
                                        onChange={(value) =>
                                            handleProductChange(
                                                value,
                                                product,
                                                "usoPunti"
                                            )
                                        }
                                        onBlur={() => handleProductChangeAnyBlur(product)}
                                        onUseAll={ //TODO: testa, senza un blur darà propblemi
                                            (value) => handleProductChange(
                                                String(value),
                                                product,
                                                "usoPunti"
                                            )
                                        }
                                    />
                                </Grid>
                            }
                            <Grid item
                                  xs={3}
                                  md={2}
                                  sx={{textAlign: 'right'}}
                                  alignSelf={'center'}
                            >
                                <RowTotalsField total={calcRowTotal(product, true) as number}/>
                            </Grid>
                        </>
                    ) : ( //SM+
                        <Grid item sm={3} md={2} lg={2}>
                            <RowTotalsField
                                total={calcRowTotal(product, true) as number}
                                rowPoints={
                                    isPointsOffer
                                        ? usesPoints
                                            ? false
                                            : (points * (product.qta as number))
                                        : false
                                }
                            />
                        </Grid>
                    )}
            </Grid>
            <Collapse in={!!errors.length}>
                <Grid item xs={12}>
                    <Typography
                        sx={{color: orderType === 'preventivo' ? context.theme.palette.warning.main : context.theme.palette.error.main}}
                        variant={'body2'}>
                        {errors.map(err => <>{err.message}</>)}
                    </Typography>
                </Grid>
            </Collapse>
            <Popover
                open={infoPopupOpen}
                onClose={() => handleInfoPopupClose()}
                anchorEl={infoPopupAnchor}
                className="product-info-popover"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Typography
                    style={{
                        whiteSpace: "pre-wrap",
                        padding: context.theme.spacing(2),
                    }}
                >{product.note}
                </Typography>
            </Popover>
        </Card>
    </>
}

export default SchedaProdotto;
