import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    GridSize,
    IconButton,
    InputAdornment,
    Popover,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import React, {CSSProperties, useContext, useEffect, useRef, useState} from "react";
import Context from "../context/Context";
import Constants from "../context/Constants";
import axios from "axios";
import {SlideUpTransition} from "./Transitions";
import PriceMetaRoot from "../interfaces/prices/priceMeta";
import PriceStatsRoot from "../interfaces/prices/priceStats";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StoricoPrezziAcquisto} from "./StoricoPrezzi";
import {
    faArrowRight,
    faArrowUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faHistory,
} from "@fortawesome/free-solid-svg-icons";
import ClearIcon from '@mui/icons-material/Clear';
import {ProductSelector} from "./DialogSelector";
import ProductImage from "./ProductImage";
import getProducts from "../functions/getProducts";
import ProductExtendedDescriptionPopup from "./ProductExtendedDescriptionPopup";
import Feedback from "./Feedback";
import moment from "moment";
// @ts-ignore
import localization from "moment/locale/it";
import {enqueueSnackbar} from "notistack";

moment.locale("it", localization);

type Direction = "up" | "down" | "left" | "right";
type VerticalAvailability = [[nome: string, numero: number]?];
type DefaultDiscountsOptions = {
    [nomeListino: string]: {
        [numeroListino: number]: [discountPerc?: number | string];
    };
};

type LastBuyPrice = {
    prezzo: number;
    prezzoNetto: number;
    sconti: number[];
    iva: number;
    numDoc: string;
    tipoDoc: string;
    data: Date | string;
};


function numberToString(
    val: number | string,
    decimals = 2,
    addEuroSign = false
): string {
    if (!val) val = 0.0;
    if (typeof val === "number") val = val.toFixed(decimals);
    return val.replace(".", ",") + (addEuroSign ? "€" : "");
}

// function stringToNumber(val: string): number {
//     return parseFloat(val.replace(",", "."));
// }

function getMaxScaglioni(withBase: boolean = true): number {
    let maxVal: number = 0;
    for (const l of Constants.metaListini) {
        if (l.nScaglioni > maxVal) maxVal = l.nScaglioni;
    }
    if (withBase) maxVal++;
    return maxVal;
}

function blurMaterialTextFieldInput(
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
): void {
    event.currentTarget.getElementsByTagName("input")[0].blur();
}

function CalculatorPaper({
                             onApply
                         }: {
    onApply: (newBuy: number) => void
}) {
    const [l, setL] = useState<number | string>("");
    const [a, setA] = useState<number | string>("");
    const [g, setG] = useState<number | string>("");
    const [q, setQ] = useState<number | string>("");
    // const [weight, setWeight] = useState<number>(0);
    const [priceKg, setPriceKg] = useState<number | string>(0);

    const calcWeight = (): number => {
        return (((parseFloat(l as string) * parseFloat(a as string)) * parseInt(g as string)) / 10000000) * parseInt(q as string);
    }

    const calcPrice = (): number => {
        return calcWeight() * parseFloat(priceKg as string);
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <TextField value={l}
                           type={'number'}
                           onChange={e => setL(e.target.value)}
                           onBlur={e => setL(e.target.value ? parseFloat(e.target.value) : "")}
                           size={'small'} label={'Largh.'}
                           InputProps={{endAdornment: "cm"}}/>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    value={a}
                    type={'number'}
                    onChange={e => setA(e.target.value)}
                    onBlur={e => setA(e.target.value ? parseFloat(e.target.value) : "")}
                    size={'small'}
                    label={'Alt.'}
                    InputProps={{endAdornment: "cm"}}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    value={g}
                    type={'number'}
                    onChange={e => setG(e.target.value)}
                    onBlur={e => setG(e.target.value ? parseFloat(e.target.value) : "")}
                    size={'small'}
                    label={'Gramm.'}
                    InputProps={{endAdornment: "g/mq"}}/>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    value={q}
                    type={'number'}
                    onChange={e => setQ(e.target.value)}
                    onBlur={e => setQ(e.target.value ? parseFloat(e.target.value) : "")}
                    size={'small'}
                    label={'Quant.'}
                    InputProps={{endAdornment: "ff"}}/>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant={'standard'}
                    value={calcWeight().toFixed(3)}
                    type={'number'}
                    size={'small'}
                    disabled
                    label={'Peso'}
                    InputProps={{endAdornment: "kg"}}/>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={priceKg}
                    type={'number'}
                    onChange={e => setPriceKg(e.target.value)}
                    onBlur={e => setPriceKg(e.target.value ? parseFloat(e.target.value) : "")}
                    size={'small'}
                    label={'Prezzo/kg'}
                    InputProps={{endAdornment: "€"}}/>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant={'standard'}
                    value={calcPrice().toFixed(2)}
                    type={'number'}
                    size={'small'}
                    disabled
                    label={'Prezzo'}
                    InputProps={{endAdornment: "€"}}/>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth onClick={() => onApply(calcPrice())} variant={'contained'}><FontAwesomeIcon
                    icon={faArrowUp}/>{" Ricopia"}</Button>
            </Grid>
        </Grid>
    </>
}

function LastBuyPricesPopup({lastBuyPrices, open, onClose, onCopyPrice, anchorRef}: {
    lastBuyPrices: LastBuyPrice[];
    open: boolean;
    onClose: () => void;
    onCopyPrice: (lbp: LastBuyPrice) => void;
    anchorRef: any;
}) {
    return <Popover open={open} onClose={onClose} anchorEl={anchorRef.current} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Prezzo</TableCell>
                        <TableCell>Sc. 1</TableCell>
                        <TableCell>Sc. 2</TableCell>
                        <TableCell>Sc. 3</TableCell>
                        <TableCell>Sc. 4</TableCell>
                        <TableCell>Sc. 5</TableCell>
                        <TableCell>Sc. 6</TableCell>
                        <TableCell>Netto</TableCell>
                        <TableCell>Doc</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Ricp.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lastBuyPrices?.map(row =>
                        <TableRow>
                            <TableCell>{numberToString(row['prezzo'], 2, true)}</TableCell>
                            {row['sconti'].map(sc =>
                                <TableCell style={{
                                    fontWeight: sc ? 'bold' : 'normal',
                                    opacity: sc ? 1 : .75
                                }}>{sc}%</TableCell>
                            )}
                            <TableCell>{numberToString(row['prezzoNetto'], 2, true)}</TableCell>
                            <TableCell>{row['numDoc']}</TableCell>
                            <TableCell>{moment(row['data']).format('DD/MM/YYYY')} • {moment(row['data']).fromNow()}</TableCell>
                            <TableCell>{row['tipoDoc']}</TableCell>
                            <TableCell>
                                <IconButton size={'small'}
                                            onClick={() => onCopyPrice(row)}>
                                    <FontAwesomeIcon icon={faArrowUp}
                                                     size={'sm'}/>
                                </IconButton></TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </Popover>
}

function ProductPricesDialogPriceInput({
                                           title,
                                           isBase,
                                           relativeCalc,
                                           relativeCalcValue,
                                           minQty,
                                           margin,
                                           discount,
                                           absolute,
                                           proceeds,
                                           legacy_uv,
                                           canCopyDirections,
                                           setMinQty,
                                           setRelativeCalc,
                                           onRelativeCalcValueChange,
                                           onRelativeCalcValueBlur,
                                           onCopyPrice,
                                           onApplyBuyPrice,
                                           onApplyDefaultDiscounts,
                                           customName,
                                           onCustomNameChange
                                       }: {
    title: string;
    isBase: boolean;
    relativeCalc: string;
    relativeCalcValue: number;
    minQty: number;
    margin: number;
    discount: number;
    absolute: number;
    proceeds: number;
    legacy_uv: number;
    canCopyDirections: [Direction?];
    setMinQty: (minQty: number) => void;
    setRelativeCalc: (mode: string) => void;
    onRelativeCalcValueChange: (value: string | number) => void;
    onRelativeCalcValueBlur: () => void;
    onCopyPrice: (direction: Direction) => void;
    onApplyBuyPrice: () => void;
    onApplyDefaultDiscounts?: () => void;
    customName?: string;
    onCustomNameChange?: (name: string) => void;
}) {
    //? Globals
    const context = useContext(Context);

    //? State

    // const [_thisCustomName, set_thisCustomName] = useState<string>(null);

    const [_thisSinglePrice, set_thisSinglePrice] = useState<string | number>(
        null
    );
    const [_thisRelativeCalcValue, set_thisRelativeCalcValue] =
        useState<string>(null);

    //? Functions

    const getProceeds = (): string => proceeds.toFixed(2).replace(".", ",");

    const getSingleProceeds = (): string =>
        (proceeds / legacy_uv).toFixed(3).replace(".", ",");

    const getSinglePrice = (): string =>
        (absolute / legacy_uv).toFixed(3).replace(".", ",");


    const handleSinglePriceChange = (price: string | number): void => {
        set_thisSinglePrice(price);
    };

    const handleRelativeCalcValueChange = (val: string): void => {
        onRelativeCalcValueChange(val);
        set_thisRelativeCalcValue(val);
    };

    const handleRelativeCalcValueBlur = (): void => {
        onRelativeCalcValueBlur();
        set_thisRelativeCalcValue(null);
    };

    const handleSinglePriceBlur = (): void => {
        let price: string | number = _thisSinglePrice;
        if (typeof price !== "number") price = parseFloat(price);
        if (!isNaN(price)) onRelativeCalcValueChange(price * legacy_uv);
        set_thisSinglePrice(null);
    };

    //? Hooks
    return (
        <Card
            elevation={8}
            variant="outlined"
            style={{
                width: "250px",
                padding: context.theme.spacing(1),
                ...(isBase ? {borderColor: context.theme.palette.primary.main} : {}),
            }}
        >
            <Typography
                style={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
                align="center"
                variant="overline"
            >
                {title}
            </Typography>
            <Grid
                container
                spacing={1}
                style={{padding: context.theme.spacing(0.5), minWidth: 230}}
            >
                <Grid item xs={12}>
                    <ToggleButtonGroup
                        exclusive
                        style={{width: "100%"}}
                        value={relativeCalc}
                        onChange={(_, value) => setRelativeCalc(value)}
                        size="small"
                    >
                        <ToggleButton
                            size="small"
                            style={{width: "100%"}}
                            value="absolute"
                        >
                            Netto
                        </ToggleButton>
                        <ToggleButton style={{width: "100%"}} size="small" value="margin">
                            Margine
                        </ToggleButton>
                        <ToggleButton
                            style={{width: "100%"}}
                            size="small"
                            value="discount"
                        >
                            Sconto
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                {isBase || (
                    <Grid item xs={4}>
                        <TextField
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    blurMaterialTextFieldInput(e);
                                }
                            }}
                            fullWidth
                            type="number"
                            size="small"
                            //! error={this.props.errMinQty}
                            label={"Qtà"}
                            variant="standard"
                            value={minQty}
                            onChange={(e) => setMinQty(parseInt(e.target.value))}
                            onFocus={(e) => e.target.select()}
                            onWheel={(e) => blurMaterialTextFieldInput(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">≥</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={isBase ? 6 : 4}>
                    <TextField
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                blurMaterialTextFieldInput(e);
                            }
                        }}
                        fullWidth
                        type="number"
                        size="small"
                        label={"Margine"}
                        variant="standard"
                        disabled={relativeCalc !== "margin"}
                        value={
                            relativeCalc !== "margin" ? "" : _thisRelativeCalcValue || ""
                        }
                        placeholder={margin?.toFixed(2).replace(".", ",") || ""}
                        onChange={(e) => {
                            handleRelativeCalcValueChange(e.target.value);
                        }}
                        onBlur={(_) => handleRelativeCalcValueBlur()}
                        onFocus={(e) => e.target.select()}
                        onWheel={(e) => blurMaterialTextFieldInput(e)}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={isBase ? 6 : 4}>
                    <TextField
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                blurMaterialTextFieldInput(e);
                            }
                        }}
                        fullWidth
                        type="number"
                        size="small"
                        label={"Sconto"}
                        variant="standard"
                        value={
                            relativeCalc !== "discount" ? "" : _thisRelativeCalcValue || ""
                        }
                        disabled={relativeCalc !== "discount"}
                        placeholder={discount?.toFixed(2).replace(".", ",") || ""}
                        onChange={(e) => {
                            handleRelativeCalcValueChange(e.target.value);
                        }}
                        onBlur={(_) => handleRelativeCalcValueBlur()}
                        onFocus={(e) => e.target.select()}
                        onWheel={(e) => blurMaterialTextFieldInput(e)}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                </Grid>
                {legacy_uv > 1 && (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                onKeyDown={(e) => {
                                    if (e.code === "Enter") {
                                        blurMaterialTextFieldInput(e);
                                    }
                                }}
                                fullWidth
                                type="number"
                                size="small"
                                label={"Singolo"}
                                variant="standard"
                                disabled={relativeCalc !== "absolute"}
                                placeholder={getSinglePrice()}
                                value={_thisSinglePrice || ""}
                                onChange={(e) => handleSinglePriceChange(e.target.value)}
                                onBlur={(_) => {
                                    handleSinglePriceBlur();
                                }}
                                onFocus={(e) => e.target.select()}
                                onWheel={(e) => blurMaterialTextFieldInput(e)}
                                InputLabelProps={{shrink: true}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">€</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </>
                )}
                <Grid item style={{alignSelf: "center"}} xs={legacy_uv > 1 ? 6 : 12}>
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            Ricv.:
                        </Grid>
                        <Grid item style={{textAlign: "right"}} xs={7}>
                            <strong>{getProceeds()}€</strong>
                        </Grid>
                        {legacy_uv > 1 && (
                            <>
                                <Grid item xs={5}>
                                    Sing.:
                                </Grid>
                                <Grid item style={{textAlign: "right"}} xs={7}>
                                    <strong>{getSingleProceeds()}€</strong>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                blurMaterialTextFieldInput(e);
                            }
                        }}
                        fullWidth
                        size="medium"
                        type="number"
                        label={"Prezzo"}
                        variant="outlined"
                        value={
                            relativeCalc !== "absolute" ? "" : _thisRelativeCalcValue || ""
                        }
                        placeholder={
                            typeof absolute === "number"
                                ? absolute.toFixed(2).replace(".", ",")
                                : absolute || ""
                        }
                        disabled={relativeCalc !== "absolute"}
                        onChange={(e) => handleRelativeCalcValueChange(e.target.value)}
                        onBlur={(_) => handleRelativeCalcValueBlur()}
                        onFocus={(e) => e.target.select()}
                        onWheel={(e) => blurMaterialTextFieldInput(e)}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            style: {color: "white"}, //Imposta il colore bianco per affrontare l'input disabled
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                    />
                </Grid>
                {isBase
                    || (title.includes('ex')
                        && <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Nome'}
                                value={customName}
                                onChange={e => onCustomNameChange(e.target.value)}
                                InputProps={
                                    {
                                        endAdornment:
                                            <IconButton onClick={() => onCustomNameChange("")} size={'small'}>
                                                <ClearIcon/>
                                            </IconButton>
                                    }
                                }
                            />
                        </Grid>)}
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={5}>
                    <ButtonGroup size="small" orientation="vertical">
                        <Button
                            onClick={(_) => onApplyBuyPrice()}
                            variant="outlined"
                            fullWidth
                        >
                            Acquisto
                        </Button>
                        {isBase && (
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={(_) => onApplyDefaultDiscounts()}
                            >
                                Sconti
                            </Button>
                        )}
                    </ButtonGroup>
                </Grid>
                <Grid container xs={7} style={{textAlignLast: "center"}}>
                    <Grid xs={4} item>
                        <></>
                    </Grid>
                    <Grid xs={4} item>
                        <IconButton
                            disabled={!canCopyDirections.includes("up")}
                            size="small"
                            onClick={(_) => onCopyPrice("up")}
                        >
                            <FontAwesomeIcon size="sm" icon={faChevronUp}/>
                        </IconButton>
                    </Grid>
                    <Grid xs={4} item>
                        <></>
                    </Grid>
                    <Grid xs={4} item>
                        <IconButton
                            disabled={!canCopyDirections.includes("left")}
                            size="small"
                            onClick={(_) => onCopyPrice("left")}
                        >
                            <FontAwesomeIcon size="sm" icon={faChevronLeft}/>
                        </IconButton>
                    </Grid>
                    <Grid xs={4} item>
                        <Typography color="textSecondary">
                            <small>COPIA</small>
                        </Typography>
                    </Grid>
                    <Grid xs={4} item>
                        <IconButton
                            size="small"
                            disabled={!canCopyDirections.includes("right")}
                            onClick={(_) => onCopyPrice("right")}
                        >
                            <FontAwesomeIcon size="sm" icon={faChevronRight}/>
                        </IconButton>
                    </Grid>
                    <Grid xs={4} item>
                        <></>
                    </Grid>
                    <Grid xs={4} item>
                        <IconButton
                            size="small"
                            disabled={!canCopyDirections.includes("down")}
                            onClick={(_) => onCopyPrice("down")}
                        >
                            <FontAwesomeIcon size="sm" icon={faChevronDown}/>
                        </IconButton>
                    </Grid>
                    <Grid xs={4} item>
                        <></>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

export default function ProductPricesDialog({
                                                idProd,
                                                open,
                                                onClose,
                                                lastSearchValue
                                            }: {
    idProd: number;
    open: boolean;
    onClose: Function;
    lastSearchValue: string;
}) {
    //* Types
    type VisibilityOptions = { [key: string]: boolean };
    type StoricoAcquisto = { open: boolean };

    //* Globals
    const context = useContext(Context);
    const metaCancelSource = axios.CancelToken.source();
    const statsCancelSource = axios.CancelToken.source();

    //* Constants
    const MAX_SCAGLIONI: number = getMaxScaglioni(true);
    const tableCellRightBorderStyle = {
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 1,
        borderRightColor: context.theme.palette.divider,
        borderStyle: "solid",
    } as CSSProperties;

    //* States
    const [stats, setStats] = useState<PriceStatsRoot>(null);
    const [meta, setMeta] = useState<PriceMetaRoot>(null);
    const [visibilityOptions, setVisibilityOptions] = useState<VisibilityOptions>(null);
    const [defaultDiscountsOptions, setDefaultDiscountsOptions] = useState<DefaultDiscountsOptions>(null);
    const [_singleBuyPrice, set_singleBuyPrice] = useState<number>(0);
    const [storicoAcquisto, setStoricoAcquisto] = useState<StoricoAcquisto>({
        open: false,
    });
    const [lastBuyPricesPopupOpen, setLastBuyPricesPopupOpen] = useState<boolean>(false);
    const [defaultDiscountsIn, setDefaultDiscountsIn] = useState<boolean>(false);
    const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false);
    const [lastBuyPrices, setLastBuyPrices] = useState<LastBuyPrice[]>([]);
    const [toolsActiveTab, setToolsActiveTab] = useState<number>(0);
    const [product, setProduct] = useState<any>(null);

    //* Refs
    const lastBuyPricesButtonRef = useRef<any>(null);

    //* Functions
    const getLastBuyPrices = async (): Promise<void> => {
        // const thisSku = await getSkuFromIds(idProd);
        // console.log(thisSku);
        setLastBuyPrices([]); //Azzera così da evitare casini
        if (!idProd) return; //Non c'è nulla da richiamare
        const res = await axios
            .get(
                Constants.paths.ajaxBasePath +
                "prezzi/documenti-fiscali/fornitori/ultimo-prezzo-acquisto/?token=" +
                context.user.token
                + "&prodIds=" + idProd
            );
        setLastBuyPrices(res.data[idProd]);
        return;
    }

    const hasAtLeastOneOfPricesListName = (name: string): boolean =>
        !!Object.entries(visibilityOptions || {}).find(
            (o) => o[0].includes(name) && o[1] === true
        );

    const getMeta = async (): Promise<void> => {
        if (idProd) {
            try {
                let res = await axios.get(
                    `${Constants.paths.ajaxBasePath}prezzi/utils/meta/?token=${context.user.token}&idsProd=${idProd}`,
                    {cancelToken: metaCancelSource.token}
                );
                setMeta(res.data[idProd] || null);
                console.log("Meta: ", res.data[idProd]);
            } catch (err) {
                if (!axios.isCancel(err)) console.error(err);
            }
        } else setMeta(null);
    };

    const getProduct = async (): Promise<void> => {
        if (idProd) {
            try {
                let prod = await getProducts(idProd);
                setProduct(prod);
            } catch (err) {
                if (!axios.isCancel(err)) console.error(err);
            }
        } else setProduct(null);
    };

    const getStats = async (): Promise<void> => {
        const toPost: Object = {
            token: context.user.token,
            directMeta: meta,
            stats: true,
            save: false,
        };
        if (idProd) {
            try {
                let res = await axios.post(
                    `${Constants.paths.ajaxBasePath}prezzi/utils/generate-prices/`,
                    toPost,
                    {cancelToken: statsCancelSource.token}
                );
                setStats(res.data[0]);
                console.log("Stats: ", res.data[0]);
            } catch (err) {
                if (!axios.isCancel(err)) console.error(err);
            }
        } else {
            setStats({});
        }
    };

    const confirmData = async (): Promise<any> => {
        const toPost: Object = {
            token: context.user.token,
            directMeta: meta,
            idProd: idProd,
            save: true,
        };

        if (idProd) {
            try {
                await axios.put(
                    `${Constants.paths.ajaxBasePath}prezzi/utils/generate-prices/`,
                    toPost
                );
                enqueueSnackbar("Prezzi aggiornati!", {
                    variant: "success",
                    preventDuplicate: true,
                });
            } catch (err) {
                if (!axios.isCancel(err)) {
                    console.error(err);
                    alert("Errore " + err);
                }
            }
        } else {
            // setStats({});
        }
    };

    const clonePrices = async (destIds: [number]): Promise<any> => {
        // console.log('ToClone', destIds);
        const toPost: Object = {
            token: context.user.token,
            srcId: idProd,
            destIds: destIds,
        };

        if (idProd && destIds) {
            try {
                await axios.post(
                    `${Constants.paths.ajaxBasePath}prezzi/utils/clone-prices/`,
                    toPost
                );
                enqueueSnackbar("Prezzi clonati!", {
                    variant: "success",
                    preventDuplicate: true,
                });
                handleCloneDialogClose();
            } catch (err) {
                if (!axios.isCancel(err)) {
                    console.error(err);
                    alert("Errore " + err);
                }
            }
        } else {
            // setStats({});
        }
    };

    const calcSingleBuyPrice = (newPrice?: null | number, newUv?: null | number) => {
        if (newPrice == null) newPrice = meta?.acquisto?.prezzo;
        if (newUv == null) newUv = meta?.legacy_uv;
        console.log(newPrice, newUv);
        set_singleBuyPrice(newPrice / newUv);
    }

    // const getSku = async (): Promise<void> => {
    //   const thisSku: number = (await getSkuFromIds(idProd)) as number;
    //   setSku(thisSku);
    // };

    const handleCopyLastBuyPrice = (lastBuyPrice: LastBuyPrice): void => {
        handleBuyPriceChange(lastBuyPrice.prezzo);
        lastBuyPrice.sconti.forEach((sc, i) =>
            i < 5 && handleBuyPriceDiscountChange(sc, i + 1));
    };

    const handleCloneDialogOpen = async (): Promise<any> => {
        await confirmData(); //Async testato
        setCloneDialogOpen(true);
    };

    const handleCloneDialogClose = (): void => {
        setCloneDialogOpen(false);
    };

    const initDefaultDiscountsOptions = (): void => {
        let final: DefaultDiscountsOptions = {};
        let opts: any = localStorage.getItem("pricesDefaultDiscountsOptions");
        try {
            opts = JSON.parse(opts) as DefaultDiscountsOptions;
        } catch (error) {
            opts = {};
            console.log("Default discounts resetted because of thrown error", error);
        }
        for (const thisListino of Constants.metaListini) {
            final[thisListino.nome] = {}; //Init
            for (
                let thisListinoNumber = 1;
                thisListinoNumber <= thisListino.nListini;
                thisListinoNumber++
            ) {
                final[thisListino.nome][thisListinoNumber] = []; //Init
                for (
                    let thisScaglione = 0;
                    thisScaglione <= thisListino.nScaglioni;
                    thisScaglione++
                ) {
                    let thisDiscount: number;
                    try {
                        thisDiscount =
                            opts[thisListino.nome][thisListinoNumber][thisScaglione] || 0;
                    } catch (error) {
                        thisDiscount = 0;
                    }
                    final[thisListino.nome][thisListinoNumber][thisScaglione] =
                        thisDiscount;
                }
            }
        }

        handleDefaultDiscountsOptionsChange(final);
        localStorage.setItem(
            "pricesDefaultDiscountsOptions",
            JSON.stringify(final)
        );
    };

    const initVisibilityOptions = (): void => {
        let opts: any = localStorage.getItem("pricesVisibilityOptions");
        if (opts) {
            opts = JSON.parse(opts) as VisibilityOptions;
        } else {
            opts = {};
            Constants.metaListini.forEach((l) => {
                Array(l.nListini)
                    .fill(1)
                    .forEach((_, lN) => {
                        opts[l.nome + (lN + 1)] = true;
                    });
            });
        }
        handelVisibilityOptionsChange(opts);
    };

    const applyBuyPrice = (
        name: string,
        number: number,
        scaglione: number
    ): void => {
        console.log(name, number, scaglione);
        console.log(meta);
        let thisBuyPrice = stats?.acquisto?.stats?.afterDiscounts || 0;
        let newMeta = {
            ...meta,
            vendita: {
                ...meta?.vendita,
                [name]: {
                    ...meta?.vendita?.[name],
                    [number]: {
                        ...meta?.vendita?.[name]?.[number],
                        [scaglione]: {
                            ...meta?.vendita?.[name]?.[number]?.[scaglione],
                            relativeCalc: "absolute",
                            relativeCalcValue: thisBuyPrice,
                        },
                    },
                },
            },
        };
        setMeta(newMeta);
    };

    const applyDefaultDiscounts = (
        pricesListName: string,
        pricesListNumber: number
    ): void => {
        let theseDiscounts =
            defaultDiscountsOptions[pricesListName][pricesListNumber];
        let newMeta = {...meta};
        for (
            let i = 1;
            i <
            Constants.metaListini.find((l) => l.nome === pricesListName).nScaglioni +
            1;
            i++
        ) {
            newMeta = {
                ...newMeta,
                vendita: {
                    ...newMeta?.vendita,
                    [pricesListName]: {
                        ...newMeta?.vendita?.[pricesListName],
                        [pricesListNumber]: {
                            ...newMeta?.vendita?.[pricesListName][pricesListNumber],
                            [i]: {
                                ...newMeta?.vendita?.[pricesListName][pricesListNumber][i],
                                relativeCalc: "discount",
                                relativeCalcValue: theseDiscounts[i] as number,
                            },
                        },
                    },
                },
            };
        }
        setMeta(newMeta);
    };

    //* Handle events
    const handelVisibilityOptionsChange = (options: VisibilityOptions): void => {
        setVisibilityOptions(options);
        localStorage.setItem("pricesVisibilityOptions", JSON.stringify(options));
    };

    const handleDefaultDiscountsOptionsChange = (
        options: DefaultDiscountsOptions
    ): void => {
        setDefaultDiscountsOptions({...options});
    };

    const handleDefaultDiscountsOptionsBlur = (): void => {
        let opts: DefaultDiscountsOptions = {...defaultDiscountsOptions};
        for (const thisListinoName in opts) {
            for (const thisListinoNumber in opts[thisListinoName]) {
                const theseDiscounts = opts[thisListinoName][thisListinoNumber];
                for (
                    let thisDiscountI = 0;
                    thisDiscountI <=
                    Constants.metaListini.find((meta) => meta.nome === thisListinoName)
                        .nScaglioni;
                    thisDiscountI++
                ) {
                    let d: number | string = theseDiscounts[thisDiscountI];
                    d =
                        typeof d === "number"
                            ? d
                            : d === null
                                ? 0
                                : parseFloat(d.replace(",", "."));
                    if (isNaN(d as number)) d = 0;
                    opts[thisListinoName][thisListinoNumber][thisDiscountI] = d;
                }
            }
        }
        setDefaultDiscountsOptions({...opts});
        localStorage.setItem("pricesDefaultDiscountsOptions", JSON.stringify(opts));
    };

    const handleNotesChange = (notes: string): void => {
        setMeta(
            (prev: any) =>
                ({
                    ...prev,
                    acquisto: {...prev.acquisto, note: notes},
                } as PriceMetaRoot)
        );
    };

    const handleCustomNameChange = (nome: string,
                                    numero: number,
                                    scaglione: number,
                                    nuovoNome: string
    ): void => {
        setMeta(
            (prev: any) =>
                (
                    {
                        ...prev,
                        vendita: {
                            ...prev.vendita,
                            [nome]: {
                                ...prev.vendita?.[nome],
                                [numero]: {
                                    ...prev.vendita?.[nome]?.[numero],
                                    [scaglione]: {
                                        ...prev.vendita?.[nome]?.[numero]?.[scaglione],
                                        customName: nuovoNome,
                                    },
                                },
                            },
                        },
                    }
                )
        )
    }

    const getVerticalAvailability = (): VerticalAvailability => {
        let verticalAvailability: VerticalAvailability = [];
        for (const listino of Constants.metaListini) {
            // Fa in modo che vengano copiati i dati soltanto nei listini effettivamente visibili
            for (let i = 1; i < listino.nListini + 1; i++) {
                if (visibilityOptions[listino.nome + i])
                    verticalAvailability.push([listino.nome, i]);
            }
        }
        return verticalAvailability;
    };

    const getCopyDirectionsAvailability = (
        name: string,
        number: number,
        scaglione: number
    ): [Direction?] => {
        const verticalAvailability = getVerticalAvailability();
        let result: [Direction?] = [];
        let thisVerticalPosition: number = verticalAvailability.findIndex(
            (a) => a[0] === name && a[1] === number
        );
        if (
            typeof verticalAvailability[thisVerticalPosition - 1] !== "undefined" &&
            Constants.metaListini.find(
                (m) => m.nome === verticalAvailability[thisVerticalPosition - 1][0]
            ).nScaglioni >= scaglione
        )
            result.push("up");
        if (
            typeof verticalAvailability[thisVerticalPosition + 1] !== "undefined" &&
            Constants.metaListini.find(
                (m) => m.nome === verticalAvailability[thisVerticalPosition + 1][0]
            ).nScaglioni >= scaglione
        )
            result.push("down");
        if (!(scaglione < 1)) result.push("left");
        if (
            !(
                scaglione >
                Constants.metaListini.find((m) => m.nome === name).nScaglioni - 1
            )
        )
            result.push("right");

        return result;
    };

    const handlePriceCopy = (
        fromName: string,
        fromNumber: number,
        fromScaglione: number,
        direction: Direction
    ): void => {
        let toName: string = fromName,
            toNumber: number = fromNumber,
            toScaglione: number = fromScaglione;

        let verticalAvailability = getVerticalAvailability();

        let fromVerticalAvailabilityPosition: number =
            verticalAvailability.findIndex(
                (a) => a[0] === fromName && a[1] === fromNumber
            );

        if (direction === "up") {
            toName = verticalAvailability[fromVerticalAvailabilityPosition - 1][0];
            toNumber = verticalAvailability[fromVerticalAvailabilityPosition - 1][1];
        } else if (direction === "down") {
            toName = verticalAvailability[fromVerticalAvailabilityPosition + 1][0];
            toNumber = verticalAvailability[fromVerticalAvailabilityPosition + 1][1];
        } else if (direction === "left") {
            toScaglione--;
        } else if (direction === "right") {
            toScaglione++;
        }

        //? Il listino nuovo è pronto
        setMeta((prev: PriceMetaRoot) => {
            return {
                ...prev,
                vendita: {
                    ...prev?.vendita,
                    [toName]: {
                        ...prev?.vendita?.[toName],
                        [toNumber]: {
                            ...prev?.vendita?.[toName]?.[toNumber],
                            [toScaglione]:
                                prev?.vendita?.[fromName]?.[fromNumber]?.[fromScaglione],
                        },
                    },
                },
            };
        });
    };

    //* Hooks
    useEffect(() => {
        //Apertura
        getMeta().then();
        // getSku();
        getProduct().then();
        getLastBuyPrices().then();
        initVisibilityOptions();
        initDefaultDiscountsOptions();
        return () => metaCancelSource.cancel();
    }, [idProd, open]);

    useEffect(() => {
        //Aggiornamento stats dopo meta
        if (meta) {
            getStats().then();
        }
        calcSingleBuyPrice();
        return () => statsCancelSource.cancel();
    }, [meta]);

    useEffect(() => {
        calcSingleBuyPrice();
    }, [meta?.legacy_uv]);

    useEffect(() => {
        console.log("Changed: ", defaultDiscountsOptions);
    }, [defaultDiscountsOptions]);

    //* Refs
    const storicoAcquistoButtonRef = useRef(null);

    //* State functions
    const handleUvChange = (newUv: string): void => {
        setMeta((prev: any): any => {
            return {
                ...prev,
                legacy_uv: newUv,
            };
        });
    };

    const handleUvBlur = (): void => {
    };

    const handleBuyPriceChange = (newPrice: string | number): void => {
        // if (typeof newPrice === "string") {
        // newPrice = parseFloat(newPrice.replace(",", "."));
        // }
        let newPriceNumber =
            typeof newPrice === "string"
                ? parseFloat(newPrice.replace(",", "."))
                : newPrice;
        setMeta((prev: any) => {
            return {
                ...prev,
                acquisto: {
                    ...prev.acquisto,
                    prezzo: newPrice,
                },
            };
        });
        calcSingleBuyPrice(newPriceNumber, meta?.legacy_uv)
    };

    const handleBuyPriceBlur = (): void => {
    };

    const handleSingleBuyPriceChange = (newPrice: any): void => {
        set_singleBuyPrice(newPrice);
    };

    const handleSingleBuyPriceBlur = (): void => {
        handleBuyPriceChange(_singleBuyPrice * meta?.legacy_uv);
    };

    const handleBuyPriceDiscountChange = (
        discount: string | number,
        number: number
    ) => {
        setMeta((prev: any) => {
            return {
                ...prev,
                acquisto: {
                    ...prev.acquisto,
                    sconti: {
                        ...prev.acquisto.sconti,
                        [number]: discount,
                    },
                },
            };
        });
    };

    const handleBuyPriceDiscountBlur = (): void => {
    };

    const handleDiscountOnProductChange = (checked: boolean): void => {
        setMeta((prev: PriceMetaRoot) => ({
            ...prev,
            sconto_articolo: checked,
        }));
    };

    const handleVatChange = (idVat: number): void => {
        if (idVat) setMeta((prev: PriceMetaRoot) => ({...prev, id_iva: idVat}));
    };

    //* Sell
    const handleMinQtyChange = (
        nome: string,
        numero: number,
        scaglione: number,
        minQty: number
    ): void => {
        setMeta((prev) => ({
            ...prev,
            vendita: {
                ...prev.vendita,
                [nome]: {
                    ...prev.vendita?.[nome],
                    [numero]: {
                        ...prev.vendita?.[nome]?.[numero],
                        [scaglione]: {
                            ...prev.vendita?.[nome]?.[numero]?.[scaglione],
                            minQty: minQty,
                        },
                    },
                },
            },
        }));
    };

    const handleRelativeCalcChange = (
        nome: string,
        numero: number,
        scaglione: number,
        mode: string
    ): void => {
        if (!mode) return;
        let legacyValue: number;
        switch (mode) {
            case "absolute":
                legacyValue =
                    stats.vendita?.[nome]?.[numero]?.[scaglione]?.absolute || 0;
                break;
            case "margin":
                legacyValue =
                    stats.vendita?.[nome]?.[numero]?.[scaglione]?.stats?.margin || 0;
                break;
            case "discount":
                legacyValue =
                    stats.vendita?.[nome]?.[numero]?.[scaglione]?.stats?.discount || 0;
                break;
        }
        setMeta((prev) => ({
            ...prev,
            vendita: {
                ...prev.vendita,
                [nome]: {
                    ...prev.vendita?.[nome],
                    [numero]: {
                        ...prev.vendita?.[nome]?.[numero],
                        [scaglione]: {
                            ...prev.vendita?.[nome]?.[numero]?.[scaglione],
                            relativeCalc: mode,
                            relativeCalcValue: legacyValue,
                        },
                    },
                },
            },
        }));
    };

    const handleRelativeCalcValueChange = (
        nome: string,
        numero: number,
        scaglione: number,
        value: string | number
    ): void => {
        setMeta((prev) => ({
            ...prev,
            vendita: {
                ...prev.vendita,
                [nome]: {
                    ...prev.vendita?.[nome],
                    [numero]: {
                        ...prev.vendita?.[nome]?.[numero],
                        [scaglione]: {
                            ...prev.vendita?.[nome]?.[numero]?.[scaglione],
                            relativeCalcValue: value,
                        },
                    },
                },
            },
        }));
    };

    const handleRelativeCalcValueBlur = (): void => {
        //? Fai un cleanup di tutto: meno performante ma più sicuro.
        let newMeta = {...meta};
        for (const meta of Constants.metaListini) {
            const thisName = meta.nome;
            for (let nL = 1; nL <= meta.nListini; nL++) {
                for (let nS = 0; nS <= meta.nScaglioni; nS++) {
                    //Non escludere lo scaglione base!
                    try {
                        let thisVal =
                            newMeta["vendita"][thisName][nL][nS]["relativeCalcValue"];
                        //? Trasforma qui
                        if (typeof thisVal === "string") thisVal = parseFloat(thisVal);
                        else thisVal = parseFloat(thisVal.toFixed(2));
                        if (isNaN(thisVal)) thisVal = 0;
                        //? #############
                        newMeta["vendita"][thisName][nL][nS]["relativeCalcValue"] = thisVal;
                    } catch (error) {
                        console.log("Errore imprevisto nel meta", error);
                    }
                }
            }
        }
        setMeta(() => newMeta);
    };

    //* Render
    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={() => onClose()}
                PaperProps={{
                    style: {backgroundColor: context.theme.palette.background.default},
                }}
                TransitionComponent={SlideUpTransition as any}
            >
                <DialogTitle>
                    <Grid container spacing={3}>
                        <Grid item xs={"auto"}>
                            <Typography
                                variant="h4"
                                style={{marginRight: context.theme.spacing(0)}}
                            >
                                Gestione prezzi
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ProductImage containerSize="4.5rem" sku={product?.cod}/>
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item>
                                <Typography
                                    variant="caption"
                                    style={{marginLeft: context.theme.spacing(0)}}
                                >
                                    {product?.cod || product?.old_cod}
                                    {product?.uf_cod && " • " + product?.uf_cod}
                                    {product?.this_cod_famiglia && " • " + product?.this_cod_famiglia}
                                    {product?.cod &&
                                        <ProductExtendedDescriptionPopup
                                            withButton
                                            buttonVariant={"text"}
                                            buttonStyle={{margin: 0}}
                                            id={product?.id}
                                        />}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{marginLeft: context.theme.spacing(0)}}
                                >
                                    {product?.descrizione}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={3} justifyContent={"flex-end"} alignItems={"center"}>
                            <Grid item>
                                <Feedback
                                    info={`(${product?.id}) ${product?.cod || product?.old_cod} • ${product?.uf_cod} => ${product?.descrizione}`}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent
                    style={{
                        backgroundColor:
                            context.theme.palette.mode === "dark"
                                ? context.theme.palette.common.black
                                : context.theme.palette.common.white,
                    }}
                >
                    <Grid
                        container
                        spacing={4}
                        sx={{mt: 1}}
                        style={{textAlign: "center", position: "relative"}}
                    >
                        <Grid
                            alignContent="flex-start"
                            container
                            item
                            className="buyPricesContainer"
                            spacing={2}
                            xs={12}
                            xl={12}
                        >
                            <Grid container item xs={12} md={4}>
                                <Grid item xs={12}>
                                    <Card variant="outlined" style={{width: "100%"}}>
                                        <br/>
                                        <Typography variant="h4">Sommario</Typography>
                                        <br/>
                                        <CardContent>
                                            <Grid container spacing={1}>
                                                {Constants.metaListini.map((l) => (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography variant="overline">
                                                                {l.titolo.toUpperCase()}
                                                            </Typography>
                                                        </Grid>
                                                        {Array(l.nListini)
                                                            .fill(1)
                                                            .map((_, nL) => (
                                                                <Grid item xs={3}>
                                                                    <Card
                                                                        variant="outlined"
                                                                        style={{
                                                                            padding: context.theme.spacing(1),
                                                                        }}
                                                                    >
                                                                        <FormControlLabel
                                                                            labelPlacement="end"
                                                                            control={
                                                                                <Switch
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    checked={
                                                                                        visibilityOptions?.[l.nome + (nL + 1)]
                                                                                    }
                                                                                    onChange={(_, checked) =>
                                                                                        handelVisibilityOptionsChange({
                                                                                            ...visibilityOptions,
                                                                                            [l.nome + (nL + 1)]: checked,
                                                                                        } as VisibilityOptions)
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={
                                                                                <small>
                                                                                    {l.nome.toUpperCase() + (nL + 1)}
                                                                                </small>
                                                                            }
                                                                        />
                                                                        <Grid
                                                                            container
                                                                            justifyContent="space-between"
                                                                        >
                                                                            <Grid item xs={7}>
                                                                                <Typography align="left">
                                                                                    <small>
                                                                                        <strong>
                                                                                            {numberToString(
                                                                                                stats?.vendita?.[l.nome]?.[
                                                                                                nL + 1
                                                                                                    ]?.[1]?.absolute,
                                                                                                2,
                                                                                                true
                                                                                            )}
                                                                                        </strong>
                                                                                    </small>
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={5}
                                                                                style={{
                                                                                    display: "inline-flex",
                                                                                    placeContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    style={{
                                                                                        color:
                                                                                            stats?.vendita?.[l.nome]?.[
                                                                                            nL + 1
                                                                                                ]?.[0]?.absolute > 0
                                                                                                ? context.theme.palette.success
                                                                                                    .main
                                                                                                : context.theme.palette.error
                                                                                                    .main,
                                                                                    }}
                                                                                >
                                                                                    <small>
                                                                                        <strong>B</strong>
                                                                                    </small>
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        color:
                                                                                            stats?.vendita?.[l.nome]?.[
                                                                                            nL + 1
                                                                                                ]?.[1]?.absolute > 0
                                                                                                ? context.theme.palette.success
                                                                                                    .main
                                                                                                : context.theme.palette.error
                                                                                                    .main,
                                                                                    }}
                                                                                >
                                                                                    <small>
                                                                                        <strong>P</strong>
                                                                                    </small>
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        color:
                                                                                            stats?.vendita?.[l.nome]?.[
                                                                                            nL + 1
                                                                                                ]?.[4]?.absolute > 0
                                                                                                ? context.theme.palette.success
                                                                                                    .main
                                                                                                : context.theme.palette.warning
                                                                                                    .main,
                                                                                    }}
                                                                                >
                                                                                    <small>
                                                                                        <strong>S</strong>
                                                                                    </small>
                                                                                </Typography>
                                                                                {l.hasOffer ? (
                                                                                    <Typography
                                                                                        style={{
                                                                                            color: stats?.vendita?.[l.nome]?.[
                                                                                            nL + 1
                                                                                                ]?.[1]
                                                                                                ? context.theme.palette.success
                                                                                                    .main
                                                                                                : context.theme.palette.error
                                                                                                    .main,
                                                                                        }}
                                                                                    >
                                                                                        <small>
                                                                                            <strong>O</strong>
                                                                                        </small>
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                </Grid>
                                                            ))}
                                                    </>
                                                ))}
                                            </Grid>
                                            <br/>
                                            <FormHelperText>
                                                B = Listino base | P = Prezzo | S = Scaglione | O =
                                                Offerta
                                            </FormHelperText>
                                            <Divider
                                                style={{
                                                    marginTop: context.theme.spacing(2),
                                                    marginBottom: context.theme.spacing(2),
                                                }}
                                            />
                                            <Grid container justifyContent="space-between" spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1">Sconti standard</Typography>
                                                </Grid>
                                                <Collapse
                                                    style={{minWidth: "100%"}}
                                                    in={!defaultDiscountsIn}
                                                >
                                                    <Grid item container xs={12}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell
                                                                        style={{
                                                                            ...tableCellRightBorderStyle,
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        Nome
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            ...tableCellRightBorderStyle,
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        Scagl 1
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            ...tableCellRightBorderStyle,
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        Scagl 2
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            ...tableCellRightBorderStyle,
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        Scagl 3
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{
                                                                            ...tableCellRightBorderStyle,
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        Scagl 4
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Constants.metaListini.map((m) => (
                                                                    <>
                                                                        {Array(m.nListini)
                                                                            .fill(1)
                                                                            .map((_, nL) => {
                                                                                nL++;
                                                                                return visibilityOptions?.[
                                                                                m.nome + nL
                                                                                    ] ? (
                                                                                    <TableRow>
                                                                                        <TableCell
                                                                                            style={{
                                                                                                ...tableCellRightBorderStyle,
                                                                                                textAlign: "right",
                                                                                            }}
                                                                                        >
                                                                                            <strong>
                                                                                                {(m.nome + nL).toUpperCase()}
                                                                                            </strong>
                                                                                        </TableCell>
                                                                                        {Array(MAX_SCAGLIONI - 1)
                                                                                            .fill(1)
                                                                                            .map((_, nS) => {
                                                                                                nS++; //Escludi scaglione base
                                                                                                return (
                                                                                                    <TableCell
                                                                                                        style={{
                                                                                                            textAlign: "right",
                                                                                                            ...tableCellRightBorderStyle,
                                                                                                        }}
                                                                                                        key={m.nome + nL + "" + nS}
                                                                                                    >
                                                                                                        {nS <= m.nScaglioni
                                                                                                            ? (defaultDiscountsOptions?.[
                                                                                                                m.nome
                                                                                                                ]?.[nL]?.[nS] || "0") +
                                                                                                            "%"
                                                                                                            : ""}
                                                                                                    </TableCell>
                                                                                                );
                                                                                            })}
                                                                                    </TableRow>
                                                                                ) : (
                                                                                    <></>
                                                                                );
                                                                            })}
                                                                    </>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                    <br/>
                                                </Collapse>
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        onClick={() => {
                                                            setDefaultDiscountsIn(!defaultDiscountsIn);
                                                        }}
                                                    >
                                                        {defaultDiscountsIn ? "Chiudi" : "Modifica"}
                                                    </Button>
                                                </Grid>
                                                <Collapse
                                                    style={{minWidth: "100%"}}
                                                    in={defaultDiscountsIn}
                                                >
                                                    <br/>
                                                    <Grid item container xs={12} spacing={1}>
                                                        {Constants.metaListini.map((m) => (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1" align="left">
                                                                        {m.titolo}
                                                                    </Typography>
                                                                </Grid>

                                                                {Array(m.nListini)
                                                                    .fill(1)
                                                                    .map((_, nL) => {
                                                                        nL++;
                                                                        return (
                                                                            <>
                                                                                <Grid item xs={12}>
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        align="left"
                                                                                    >
                                                                                        {(m.nome + nL).toUpperCase()}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                {Array(m.nScaglioni)
                                                                                    .fill(1)
                                                                                    .map((_, nS) => {
                                                                                        nS++; //Escludi scaglione base
                                                                                        return (
                                                                                            <Grid
                                                                                                key={m.nome + nL + "" + nS}
                                                                                                xs={3 as GridSize}
                                                                                                item
                                                                                            >
                                                                                                <TextField
                                                                                                    type="number"
                                                                                                    variant="outlined"
                                                                                                    size="small"
                                                                                                    label={"Sc " + nS}
                                                                                                    value={
                                                                                                        defaultDiscountsOptions?.[
                                                                                                            m.nome
                                                                                                            ]?.[nL]?.[nS] || ""
                                                                                                    }
                                                                                                    InputProps={{
                                                                                                        endAdornment: (
                                                                                                            <InputAdornment
                                                                                                                position="end">
                                                                                                                %
                                                                                                            </InputAdornment>
                                                                                                        ),
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true,
                                                                                                    }}
                                                                                                    onFocus={(e) =>
                                                                                                        e.target.select()
                                                                                                    }
                                                                                                    onBlur={() =>
                                                                                                        handleDefaultDiscountsOptionsBlur()
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        let thisVal = e.target.value;
                                                                                                        let opts = {
                                                                                                            ...defaultDiscountsOptions,
                                                                                                        };
                                                                                                        opts[m.nome][nL][nS] =
                                                                                                            thisVal;
                                                                                                        handleDefaultDiscountsOptionsChange(
                                                                                                            opts
                                                                                                        );
                                                                                                    }}
                                                                                                    onWheel={(e) => blurMaterialTextFieldInput(e)}
                                                                                                />
                                                                                            </Grid>
                                                                                        );
                                                                                    })}
                                                                            </>
                                                                        );
                                                                    })}
                                                                <Grid item xs={12}>
                                                                    <Divider
                                                                        style={{
                                                                            marginTop: context.theme.spacing(1),
                                                                            marginBottom: context.theme.spacing(1),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        ))}
                                                        <Grid item xs={12}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                onClick={() => {
                                                                    setDefaultDiscountsIn(false);
                                                                }}
                                                            >
                                                                Chiudi
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Collapse>
                                            </Grid>
                                            <br/>
                                            <FormHelperText>
                                                Saranno salvati soltanto su questo dispositivo
                                            </FormHelperText>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={4}>
                                <Grid item xs={12}>
                                    <Card elevation={8} variant="outlined">
                                        <br/>
                                        <Typography variant="h4">Acquisto</Typography>
                                        <br/>
                                        <Typography
                                            style={{
                                                marginTop: context.theme.spacing(1),
                                                textAlign: "center",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                            align="center"
                                            variant="overline"
                                        >
                                            Listino
                                        </Typography>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        size="medium"
                                                        label="UV"
                                                        onBlur={() => {
                                                            handleUvBlur();
                                                        }}
                                                        onChange={(e) => {
                                                            handleUvChange(e.target.value);
                                                        }}
                                                        onFocus={(e) => e.target.select()}
                                                        onWheel={(e) => blurMaterialTextFieldInput(e)}
                                                        value={meta?.legacy_uv}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">pz</InputAdornment>
                                                            ),
                                                        }}
                                                        InputLabelProps={{shrink: true}}
                                                    />
                                                </Grid>
                                                {meta?.legacy_uv > 1 && (
                                                    <>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                size="medium"
                                                                label="Singolo"
                                                                onBlur={() => {
                                                                    handleSingleBuyPriceBlur();
                                                                }}
                                                                onChange={(e) => {
                                                                    handleSingleBuyPriceChange(e.target.value);
                                                                }}
                                                                onFocus={(e) => e.target.select()}
                                                                onWheel={(e) => blurMaterialTextFieldInput(e)}
                                                                value={
                                                                    typeof _singleBuyPrice === "string"
                                                                        ? _singleBuyPrice
                                                                        : _singleBuyPrice.toFixed(3)
                                                                }
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            €
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                InputLabelProps={{shrink: true}}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={meta?.legacy_uv > 1 ? 5 : 10}>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        size="medium"
                                                        label="Prezzo"
                                                        onBlur={() => handleBuyPriceBlur()}
                                                        onChange={(e) => {
                                                            handleBuyPriceChange(e.target.value);
                                                        }}
                                                        onFocus={(e) => e.target.select()}
                                                        onWheel={(e) => blurMaterialTextFieldInput(e)}
                                                        value={
                                                            typeof meta?.acquisto?.prezzo === "string"
                                                                ? meta?.acquisto?.prezzo
                                                                : meta?.acquisto?.prezzo.toFixed(2)
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">€</InputAdornment>
                                                            ),
                                                        }}
                                                        InputLabelProps={{shrink: true}}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    justifyContent="space-between"
                                                >
                                                    {Array(5)
                                                        .fill(1)
                                                        .map((_, s) => {
                                                            const thisS: number = s + 1;
                                                            return (
                                                                <Grid item xs={"auto"}>
                                                                    <TextField
                                                                        style={{maxWidth: "70px"}}
                                                                        type="number"
                                                                        size="small"
                                                                        label={thisS}
                                                                        onBlur={() => handleBuyPriceDiscountBlur()}
                                                                        onChange={(e) =>
                                                                            handleBuyPriceDiscountChange(
                                                                                e.target.value,
                                                                                thisS
                                                                            )
                                                                        }
                                                                        onFocus={(e) => e.target.select()}
                                                                        onWheel={(e) => blurMaterialTextFieldInput(e)}
                                                                        value={meta?.acquisto?.sconti?.[thisS] || 0}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    %
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        InputLabelProps={{shrink: true}}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                                {meta?.legacy_uv == 1 || (
                                                    <Grid item xs={6}>
                                                        <Typography variant="overline">A pezzo</Typography>
                                                        <Typography
                                                            variant="h6"
                                                            style={{fontWeight: "bold"}}
                                                        >
                                                            {numberToString(
                                                                stats?.acquisto?.stats?.afterDiscounts /
                                                                meta?.legacy_uv || 0
                                                            )}
                                                            €
                                                        </Typography>
                                                        <Typography variant="caption">IVA escl.</Typography>
                                                    </Grid>
                                                )}
                                                <Grid item xs={meta?.legacy_uv == 1 ? 12 : 6}>
                                                    <Typography variant="overline">Netto</Typography>
                                                    <Typography variant="h6" style={{fontWeight: "bold"}}>
                                                        {numberToString(
                                                            stats?.acquisto?.stats?.afterDiscounts || 0
                                                        )}
                                                        €
                                                    </Typography>
                                                    <Typography variant="caption">IVA escl.</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        ref={storicoAcquistoButtonRef}
                                                        variant="contained"
                                                        onClick={() => setStoricoAcquisto({open: true})}
                                                    >
                                                        <FontAwesomeIcon
                                                            style={{
                                                                marginRight: context.theme.spacing(1),
                                                            }}
                                                            icon={faHistory}
                                                        />
                                                        {" Storico"}
                                                    </Button>
                                                    <br/><br/>
                                                    {lastBuyPrices?.[0]?.['prezzo'] ?
                                                        <>
                                                            <Typography variant='overline'>Ultimo prezzo da
                                                                fornitore: </Typography><br/>
                                                            <Button size={"large"}
                                                                    onClick={() => setLastBuyPricesPopupOpen(true)}
                                                                    ref={lastBuyPricesButtonRef}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Typography>
                                                                        <span
                                                                            style={{fontWeight: (lastBuyPrices[0].sconti.some((v) => v) ? 'normal' : 'bold')}}>{numberToString(lastBuyPrices[0].prezzo, 2, true)}</span>
                                                                            {lastBuyPrices[0].sconti.map((v) => {
                                                                                // @ts-ignore
                                                                                if (v) return <> - {v}%</>;
                                                                                return ''
                                                                            })}{
                                                                            lastBuyPrices[0].sconti.some((v) => v) //Se ha almeno uno sconto
                                                                                ? <>
                                                                                    <FontAwesomeIcon icon={faArrowRight}
                                                                                                     size='sm'
                                                                                                     style={{
                                                                                                         marginLeft: context.theme.spacing(1),
                                                                                                         marginRight: context.theme.spacing(1)
                                                                                                     }}/>

                                                                                    <span
                                                                                        style={{fontWeight: 'bold'}}>{numberToString(lastBuyPrices[0].prezzoNetto, 2, true)}</span>
                                                                                </>
                                                                                : <></>
                                                                        }
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant='caption'>
                                                                            {lastBuyPrices[0].numDoc}{' • '}{moment(lastBuyPrices[0].data).format("DD/MM/YYYY")}{' • '}
                                                                            {moment(lastBuyPrices[0].data).fromNow()}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Button>
                                                            <Button size='small' variant='contained'
                                                                    onClick={() => handleCopyLastBuyPrice(lastBuyPrices[0])
                                                                    }>
                                                                <FontAwesomeIcon icon={faArrowUp}
                                                                                 size={'sm'}
                                                                                 style={{marginRight: context.theme.spacing(1)}}/>Ricopia</Button>
                                                        </> :
                                                        <i>Ultimo prezzo da fornitore non disponibile</i>
                                                    }
                                                    <StoricoPrezziAcquisto
                                                        anchorEl={storicoAcquistoButtonRef.current}
                                                        priceId={stats?.acquisto?.id}
                                                        open={storicoAcquisto.open}
                                                        onClose={() => setStoricoAcquisto({open: false})}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h4">Opzioni</Typography>
                                            <br/>
                                            <Grid item xs={12}>
                                                <Typography variant="overline">IVA</Typography>
                                                <br/>
                                                <ToggleButtonGroup
                                                    exclusive
                                                    style={{width: "100%"}}
                                                    value={meta?.id_iva || 0}
                                                    onChange={(_, v) => handleVatChange(v)}
                                                    size="small"
                                                >
                                                    <ToggleButton
                                                        size="small"
                                                        style={{width: "100%"}}
                                                        value={1}
                                                    >
                                                        22
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        size="small"
                                                        style={{width: "100%"}}
                                                        value={3}
                                                    >
                                                        5
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        size="small"
                                                        style={{width: "100%"}}
                                                        value={4}
                                                    >
                                                        4
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        size="small"
                                                        style={{width: "100%"}}
                                                        value={2}
                                                    >
                                                        0 (Articolo 74)
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                                <FormHelperText style={{textAlign: "center"}}>
                                                    I prezzi sono visualizzati tutti IVA esclusa.
                                                </FormHelperText>
                                                <Divider
                                                    style={{
                                                        marginTop: context.theme.spacing(1),
                                                        marginBottom: context.theme.spacing(1),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={meta?.sconto_articolo || false}
                                                            onChange={(_, checked) =>
                                                                handleDiscountOnProductChange(checked)
                                                            }
                                                        />
                                                    }
                                                    label="Sconto su articoli"
                                                />
                                                <FormHelperText style={{textAlign: "center"}}>
                                                    Ammettere un ulteriore sconto sul prezzo?
                                                </FormHelperText>
                                                <Divider
                                                    style={{
                                                        marginTop: context.theme.spacing(1),
                                                        marginBottom: context.theme.spacing(1),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Note"
                                                    type="text"
                                                    multiline
                                                    InputLabelProps={{shrink: true}}
                                                    value={meta?.acquisto?.note}
                                                    onChange={(e) => handleNotesChange(e.target.value)}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <br/>
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <br/>
                                        <Typography variant={'h4'}>Calcolatori</Typography>
                                        <CardContent>
                                            <Tabs
                                                centered
                                                value={toolsActiveTab}
                                                onChange={(_, tab) => setToolsActiveTab(tab)}
                                            >
                                                <Tab label='Carta'/>
                                            </Tabs>
                                            <br/>
                                            {
                                                toolsActiveTab === 0 ?
                                                    <CalculatorPaper onApply={newBuy => handleBuyPriceChange(newBuy)}/>
                                                    : <></>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} xl={12}>
                            <Typography variant="h6">Vendita</Typography>
                            <br/>
                            <Table
                                size="small"
                                aria-label="a dense table"
                                style={{display: "block", tableLayout: "fixed"}}
                            >
                                <TableBody>
                                    {Constants.metaListini.map((l) => {
                                        return (
                                            <>
                                                {hasAtLeastOneOfPricesListName(l.nome) ? (
                                                    <TableRow key={l.nome}>
                                                        <TableCell
                                                            style={{textAlign: "center"}}
                                                            colSpan={MAX_SCAGLIONI}
                                                        >
                                                            <Typography align="left" variant="h6">
                                                                {l.titolo}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <></>
                                                )}
                                                {Array(l.nListini)
                                                    .fill(1)
                                                    .map((_, nL) => (
                                                        <>
                                                            {visibilityOptions?.[l.nome + (nL + 1)] ? (
                                                                <TableRow key={l.nome + (nL + 1)}>
                                                                    {Array(l.nScaglioni + 1)
                                                                        .fill(1)
                                                                        .map((_, nS) => {
                                                                            const thisIdChar =
                                                                                l.nome + (nL + 1) + "s" + nS;
                                                                            const thisNome = l.nome;
                                                                            const thisNl = nL + 1;
                                                                            const thisNS = nS;
                                                                            const thisCustomName = meta?.vendita?.[thisNome]?.[
                                                                                thisNl
                                                                                ]?.[thisNS]?.customName;
                                                                            return (
                                                                                <TableCell key={thisIdChar}>
                                                                                    <ProductPricesDialogPriceInput
                                                                                        key={l.nome + (nL + 1) + "s" + nS}
                                                                                        isBase={nS === 0}
                                                                                        relativeCalc={
                                                                                            meta?.vendita?.[thisNome]?.[
                                                                                                thisNl
                                                                                                ]?.[thisNS]?.relativeCalc
                                                                                        }
                                                                                        relativeCalcValue={
                                                                                            meta?.vendita?.[thisNome]?.[
                                                                                                thisNl
                                                                                                ]?.[thisNS]?.relativeCalcValue
                                                                                        }
                                                                                        minQty={
                                                                                            meta?.vendita?.[thisNome]?.[
                                                                                                thisNl
                                                                                                ]?.[thisNS]?.minQty
                                                                                        }
                                                                                        margin={
                                                                                            stats?.vendita?.[thisNome]?.[
                                                                                                thisNl
                                                                                                ]?.[thisNS]?.stats?.margin
                                                                                        }
                                                                                        discount={
                                                                                            stats?.vendita?.[thisNome]?.[
                                                                                                thisNl
                                                                                                ]?.[thisNS]?.stats?.discount
                                                                                        }
                                                                                        absolute={
                                                                                            stats?.vendita?.[thisNome]?.[
                                                                                                thisNl
                                                                                                ]?.[thisNS]?.absolute
                                                                                        }
                                                                                        title={thisIdChar}
                                                                                        customName={thisCustomName}
                                                                                        onCustomNameChange={(nuovoNome) => handleCustomNameChange(
                                                                                            thisNome,
                                                                                            thisNl,
                                                                                            thisNS,
                                                                                            nuovoNome
                                                                                        )}
                                                                                        proceeds={
                                                                                            stats?.vendita?.[thisNome]?.[
                                                                                                thisNl
                                                                                                ]?.[thisNS]?.absolute -
                                                                                            stats?.acquisto?.stats
                                                                                                ?.afterDiscounts
                                                                                        }
                                                                                        legacy_uv={meta?.legacy_uv}
                                                                                        setRelativeCalc={(mode: string) =>
                                                                                            handleRelativeCalcChange(
                                                                                                thisNome,
                                                                                                thisNl,
                                                                                                thisNS,
                                                                                                mode
                                                                                            )
                                                                                        }
                                                                                        onRelativeCalcValueChange={(
                                                                                            value: string | number
                                                                                        ) =>
                                                                                            handleRelativeCalcValueChange(
                                                                                                thisNome,
                                                                                                thisNl,
                                                                                                thisNS,
                                                                                                value
                                                                                            )
                                                                                        }
                                                                                        onRelativeCalcValueBlur={() =>
                                                                                            handleRelativeCalcValueBlur()
                                                                                        }
                                                                                        setMinQty={(minQty: number) =>
                                                                                            handleMinQtyChange(
                                                                                                thisNome,
                                                                                                thisNl,
                                                                                                thisNS,
                                                                                                minQty
                                                                                            )
                                                                                        }
                                                                                        onCopyPrice={(
                                                                                            direction: Direction
                                                                                        ) =>
                                                                                            handlePriceCopy(
                                                                                                thisNome,
                                                                                                thisNl,
                                                                                                thisNS,
                                                                                                direction
                                                                                            )
                                                                                        }
                                                                                        canCopyDirections={getCopyDirectionsAvailability(
                                                                                            thisNome,
                                                                                            thisNl,
                                                                                            thisNS
                                                                                        )}
                                                                                        onApplyDefaultDiscounts={
                                                                                            nS === 0
                                                                                                ? () =>
                                                                                                    applyDefaultDiscounts(
                                                                                                        thisNome,
                                                                                                        thisNl
                                                                                                    )
                                                                                                : () => {
                                                                                                }
                                                                                        }
                                                                                        onApplyBuyPrice={() =>
                                                                                            applyBuyPrice(
                                                                                                thisNome,
                                                                                                thisNl,
                                                                                                thisNS
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </TableCell>
                                                                            );
                                                                        })}
                                                                </TableRow>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    ))}
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button
                                style={{color: context.theme.palette.warning.main}}
                                onClick={() => getMeta()}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => onClose()}>Annulla</Button>
                            <ButtonGroup variant="contained">
                                <Button onClick={() => handleCloneDialogOpen()}>Clona</Button>
                                <Button color="primary" onClick={() => {
                                    confirmData().then(
                                        () => onClose()
                                    );
                                }}>
                                    Conferma
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </DialogActions>
                <ProductSelector
                    open={cloneDialogOpen}
                    onClose={() => handleCloneDialogClose()}
                    onSelect={(selectedProds: any) =>
                        clonePrices(selectedProds.map((p: any) => p.id as number))
                    }
                    filter='attivi'
                    // fullScreen
                    maxWidth="xl"
                    multiple
                    showProdImg
                    initialSearch={lastSearchValue}
                    selectedIds={idProd}
                />
                <LastBuyPricesPopup lastBuyPrices={lastBuyPrices}
                                    open={lastBuyPricesPopupOpen}
                                    onClose={() => setLastBuyPricesPopupOpen(false)}
                                    onCopyPrice={lbp => handleCopyLastBuyPrice(lbp)}
                                    anchorRef={lastBuyPricesButtonRef}
                />
            </Dialog>
        </>
    );
}
