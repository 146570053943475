import React from "react";
import {Slide, SlideProps, Zoom, ZoomProps} from "@mui/material";

const SlideUpTransition = React.forwardRef<unknown, SlideProps>((props, ref) =>
    <Slide direction="up" ref={ref} {...props}/>
);

const ZoomTransition = React.forwardRef<unknown, ZoomProps>((props, ref) =>
    <Zoom ref={ref} {...props}/>
);

export {SlideUpTransition, ZoomTransition};
