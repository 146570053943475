import {Box, Button, CircularProgress, Collapse, Grid, Paper, TextField, Typography,} from "@mui/material";
import axios from "axios";
import {Fragment, React, useContext, useEffect, useState} from "react";
import ProfileCard from "../../../components/ProfileCard";
import {LinearProgressWithLabel} from "../../../components/Progresses";
import Constants from "../../../context/Constants";
import Context from "../../../context/Context";
import {getAllProdIds} from "../../../context/GlobalFunctions";

export default function RigeneraDescrizioni(props) {
    const context = useContext(Context);

    const [allProdIds, setAllProdIds] = useState([]);
    const [payloadSize, setPayloadSize] = useState(1000);
    const [fetching, setFetching] = useState(false);
    const [transferred, setTransferred] = useState(0);
    const [retries, setRetries] = useState(0);

    const MAX_RETRIES = 10;

    useEffect(() => {
        getAllProdIds("stato10").then((res) => setAllProdIds(res));
    }, []);

    const launchRequests = async () => {
        const loops = Math.ceil(allProdIds.length / payloadSize);
        let iter = 0;
        let thisRetries = 0;
        while (iter < loops) {
            console.log(iter);
            console.log(retries);
            console.log(thisRetries);
            if (thisRetries >= MAX_RETRIES) {
                alert("Massimi tentativi raggiunti!");
                break;
            }
            const ids = allProdIds.slice(
                payloadSize * iter,
                payloadSize * (iter + 1)
            );
            const toPost = {
                token: context.user.token,
                idsProd: ids,
                save: true,
            };
            try {
                const response = await axios.post(
                    `${Constants.paths.ajaxBasePath}prodotti/utils/generate-description/`,
                    toPost
                );
                iter++;
            } catch (error) {
                thisRetries++;
                setRetries(thisRetries);
            }
        }
    };

    return (
        <Fragment>
            <ProfileCard/>
            <Paper
                elevation={10}
                style={{
                    marginTop: context.theme.spacing(6),
                    maxWidth: context.theme.breakpoints.values.md,
                    margin: "auto",
                }}
            >
                <Grid container spacing={3} sx={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                        <Typography variant="h2">Rigenera descrizioni</Typography>
                        <Typography>
                            Prodotti nel DB: <strong>{allProdIds.length}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            variant="outlined"
                            label="Payload"
                            margin="dense"
                            value={payloadSize}
                            onChange={(e) =>
                                setPayloadSize(parseInt(e.target.value))
                            }
                        />
                        <br/>
                        <Typography color="textSecondary" variant="caption">
                            Quanti prodotti verranno aggiornati per richiesta AJAX
                        </Typography>
                    </Grid>
                    <Collapse in={fetching} style={{width: "100%"}}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                paddingLeft: context.theme.spacing(4),
                                paddingRight: context.theme.spacing(4),
                            }}
                        >
                            <Box style={{display: "inline-flex"}}>
                                <Typography style={{marginRight: context.theme.spacing(1)}}>
                                    Trasferimento in corso...
                                </Typography>
                                <CircularProgress size={20}/>
                            </Box>
                            <Typography>
                                {/* Step {this.state.progressStep}/{this.state.progressStepTotal} */}
                            </Typography>
                            <LinearProgressWithLabel
                                value={(transferred / allProdIds.length) * 100}
                            />
                            <Typography>
                                {transferred}/{allProdIds.length}
                                {retries > 0 ? (
                                    <Typography
                                        style={{color: context.theme.palette.warning.dark}}
                                    >
                                        Tentativo {retries + 1}/{MAX_RETRIES}
                                    </Typography>
                                ) : (
                                    ""
                                )}
                            </Typography>
                        </Grid>
                    </Collapse>
                    <Grid item xs={12}>
                        <Button
                            disabled={fetching}
                            onClick={(e) => launchRequests()}
                            style={{
                                background: context.theme.palette.warning.main,
                                marginBottom: context.theme.spacing(3)
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Rigenera
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    );
}
