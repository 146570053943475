import React, {ReactElement, useContext} from "react";
import {Divider, Grid, List, ListItem, Typography,} from "@mui/material";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {TListiniOrdine} from "../interfaces/prices/priceMeta";
import formatPrice from "../functions/formatPrice";

function GeneratePricesPopupContent({
                                        listini,
                                        // discounts = [],
                                        allowDiscount,
                                        onClick
                                    }: {
    listini: TListiniOrdine[]; // [nome, prezzo]
    onClick: (price: number) => void;
    allowDiscount: boolean;
    // discounts: number[];
}) {
    const context = useContext(Context);

    let lists: ReactElement<typeof ListItem>[] = [];

    // console.log(listini);

    listini.forEach(({
                         key, nome, scaglione, prezzo
                     }, i) => {
        if (Constants.visibilitaAgentiListini.includes(key)
            && prezzo > 0
        ) {
            lists.push(
                <ListItem
                    button={true}
                    key={key}
                    style={{minWidth: "25em"}}
                    onClick={
                        () => {
                            if (onClick) onClick(prezzo)
                        }
                    }
                >
                    <Grid container spacing={2} justifyContent="space-between" alignItems="baseline">
                        <Grid item container xs={'auto'}>
                            <Grid item xs={12}>
                                <Typography variant="body2"
                                            style={Object.entries(Constants.nomiListini).find(([_, thisName]) => thisName === nome) // Verifica se il nome del listino fa parte di quelli standard
                                                ? {}
                                                : {color: context.theme.palette.warning.main}
                                            }
                                >
                                    {nome}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={'auto'} style={{textAlign: "end"}}>
                            {scaglione > 1 &&
                                <strong style={{color: context.theme.palette.warning.main}}>
                                    {scaglione}pz+
                                </strong>
                            }
                        </Grid>
                        <Grid item xs={"auto"} style={{textAlign: "end"}}>
                            <strong>{formatPrice(prezzo)}</strong>
                        </Grid>
                    </Grid>
                </ListItem>
            );
        }
    });

    return (
        <List>
            {lists.map((l) => l)}
            <>
                <Divider/>
                <ListItem style={{justifyContent: 'center'}}>
                    <Typography variant="caption" align={'center'}>
                        Sconto su articoli:
                        <strong
                            style={{color: allowDiscount ? context.theme.palette.success.main : context.theme.palette.error.main}}>
                            {" "}
                            {allowDiscount ? "Sì" : "No"}
                        </strong>
                    </Typography>
                </ListItem>
            </>
        </List>
    );
}

export {GeneratePricesPopupContent};
