import React, {useContext, useEffect, useState} from 'react';
import Context from "../../context/Context";
import ProfileCard from "../../components/ProfileCard";
import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    CircularProgress,
    Collapse,
    Fab,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {ProductSelector} from "../../components/DialogSelector";
import MaterialTable from "material-table";
import MaterialTableIcons from "../../support/materialTableIcons";
import MaterialTableLocalisationIT from "../../support/materialTableLocalisationIT";
import axios from "axios";
import Constants from "../../context/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Feedback from "../../components/Feedback";
import Prezzo from "../../components/Prezzo";
import ProductImage from "../../components/ProductImage";
import {faLifeRing, faMinus, faPlus, faSave, faSearch, faTrash} from "@fortawesome/free-solid-svg-icons";
import {CellNotesInventarioCompleto} from "../../components/CellNotes";
import ProductExtendedDescriptionPopup from "../../components/ProductExtendedDescriptionPopup";
import Barcode from "react-barcode";
import moment from "moment";
import localization from "moment/locale/it";

moment.locale("it", localization);
const SEZIONI = [
    ...Array(50).keys()
];

const LUOGHI = [
    "Cash",
    "Deposito",
    "Extra"
];

const SEDI = Constants.nomiSedi.filter(s => [1, 2].includes(s.id));

export default function InventarioCompleto() {
    // Const
    const context = useContext(Context);
    const REFRESH_TIMEOUT = 15;
    const PAGE_SIZES = [5, 10, 15, 25, 50];

    // States
    const [sezione, setSezione] = useState(SEZIONI[0]);
    const [luogo, setLuogo] = useState(LUOGHI[0]);
    const [sede, setSede] = useState(SEDI[0]);
    const [searchOpen, setSearchOpen] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [feebackInfo, setFeedbackInfo] = useState(null);
    const [rows, setRows] = useState([]);
    const [putting, setPutting] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [timerCount, setTimerCount] = useState(REFRESH_TIMEOUT);
    const [variatingRowId, setVariatingRowId] = useState(false);
    const [variatingQty, setVariatingQty] = useState(0);
    const [pendingGetRequest, setPendingGetRequest] = useState(false);
    const [getRequestSignal, setGetRequestSignal] = useState();

    //Abort Controllers
    // const getDataController = useRef(new AbortController());

    // Functions
    const getData = async (abortController) => {
        setFetching(true);
        try {
            let res = await axios.get(`${Constants.paths.ajaxBasePath}inventario/completo/?token=${context.user.token}&id_sede=${sede['id']}&luogo=${luogo}&sezione=${sezione}&sorting=id-desc`, {
                signal: abortController.signal
            });
            setRows(res.data);
        } catch (e) {
            if (e.message !== 'canceled') {
                alert(`Errore di connessione, interrompere immediatamente! ${e}`);
                return false;
            }
        }
        setTimerCount(REFRESH_TIMEOUT);
        setFetching(false);
        return true;
    };

    const sendQty = async (rowId, qty) => {
        const body = {
            token: context.user.token,
            id_row: rowId,
            qta: qty,
            stockOnly: true,
            absolute: false
        };
        //********//
        setPutting(true);
        try {
            let req = await axios.put(`${Constants.paths.ajaxBasePath}inventario/completo/`, JSON.stringify(body));
            setVariatingQty(0);
            setVariatingRowId(null);
            setPendingGetRequest(true);
            setPutting(false)
            return true;
        } catch (e) {
            alert('Errore nella variazione della quantità!' + e);
            setPutting(false)
            return false;
        }

        //*******//
    }

    const handleProductSelection = async (prod) => {
        /*Extra:
        _qta
        _prezzo_acquisto
        _note
        */

        setFetching(true);
        let toPost = {
            token: context.user.token,
            id_sede: sede.id,
            id_prod: prod.id,
            luogo: luogo,
            sezione: sezione,
            note: "",
            qta: 0
        };

        toPost = JSON.stringify(toPost);
        await axios.put(Constants.paths.ajaxBasePath + "inventario/completo/", toPost);
        console.log(prod);

        setPendingGetRequest(true);
        setSearchOpen(false);
        setFetching(false);
    };

    const handleProductDelete = async (id) => {
        setFetching(true);
        let toPost = {
            id_prod: id,
            id_sede: sede.id,
            luogo: luogo,
            sezione: sezione,
            token: context.user.token
        };
        toPost = JSON.stringify(toPost);
        try {
            await axios.delete(Constants.paths.ajaxBasePath + "inventario/completo/", {
                data:
                toPost
            });

        } catch (e) {
            alert(`Richiesta fallita! [${e}]`);
            return false;
        }
        // setRows(prev => prev.filter(p => p.id != id));
        setPendingGetRequest(true);
        setFetching(false);
        return true;
    };

    const handleVariationChange = (rowId, qty = 0, skipReset = false) => {
        if (variatingRowId !== rowId) { //Cambio riga
            if (variatingQty !== 0) { //Notifca se ci sono variazioni non salvate
                const oldRow = rows.find(r => r.id === variatingRowId);
                console.log(oldRow);
                if (oldRow) { //Il prodotto è stato cancellato?
                    if (!window.confirm(`Hai modifiche non salvate sulla riga #${variatingRowId}: (${oldRow.prod_meta.v_cod}) [${oldRow.prod_meta.uf_cod}] ${oldRow.prod_meta.descrizione}. Intendi cancellarle?`)) return false;
                }
            }
            setVariatingRowId(rowId);
            if (!skipReset)
                setVariatingQty(0);
        }

        setVariatingQty(qty);
        return true;
    };

    // Hooks
    useEffect(() => { // On mount
        const RefreshTimer = setInterval(_ => setTimerCount(prev => fetching ? prev : prev - 1), 1000);
        setPendingGetRequest(true);
        return () => {
            clearInterval(RefreshTimer);
        };
    }, []);

    // Timer Tick
    useEffect(() => {
            if (fetching) {
                setTimerCount(REFRESH_TIMEOUT);
            } else {
                if (timerCount < 1) {
                    setTimerCount(2);
                    setPendingGetRequest(false); //Forza il reset
                    setFetching(false);
                    setTimeout(() => {
                        setFetching(true);
                        setPendingGetRequest(true);
                    }, 200);
                }
            }
        },
        [timerCount]
    );

    //Cambio sezione
    useEffect(() => {
        if (getRequestSignal) getRequestSignal.abort();
        setPendingGetRequest(true);
        return () => {
            setRows([]);
        };
    }, [sede, luogo, sezione]);

    //Get data
    useEffect(() => {
        const thisAC = new AbortController();
        if (pendingGetRequest) {
            if (getRequestSignal) getRequestSignal.abort();
            setGetRequestSignal(thisAC);
            getData(thisAC).then(() => setPendingGetRequest(false));
        }

        return () => {
            // thisAC.abort();
        };
    }, [pendingGetRequest]);


    return <>
        <ProfileCard/>
        <Paper style={{padding: context.theme.spacing(4)}}>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        disabled
                        label={"Operatore"}
                        value={context.user.username}
                        variant={'outlined'}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        variant={'outlined'}
                        fullWidth
                        label={"Sede"}
                        value={sede.id}
                        select
                        onChange={
                            ev => setSede(SEDI.find(sed => sed.id === parseInt(ev.target.value)))
                        }
                    >
                        {SEDI.map(sed => <MenuItem key={sed.id} value={sed.id}>
                            {sed.short}
                        </MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        variant={'outlined'}
                        fullWidth
                        label={"Luogo"}
                        value={luogo}
                        select
                        onChange={ev => setLuogo(ev.target.value)}
                    >
                        {LUOGHI.map(luo => <MenuItem key={luo} value={luo}>
                            {luo}
                        </MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        variant={'outlined'}
                        fullWidth
                        label={"Sezione"}
                        value={sezione}
                        select
                        onChange={ev => setSezione(ev.target.value)}
                    >
                        {SEZIONI.map(sez => <MenuItem key={sez} value={sez}>
                            {sez}
                        </MenuItem>)}
                    </TextField>
                </Grid>
            </Grid>
        </Paper>
        <Box style={{marginTop: context.theme.spacing(2)}}>
            <Grid container justifyContent={"center"} alignContent={'center'}>
                <Grid item style={{alignSelf: 'center'}}>
                    <Typography style={{
                        alignSelf: 'center',
                        color: fetching
                            ? context.theme.palette.warning.dark
                            : context.theme.palette.success.dark
                    }}>
                        {fetching
                            ? "Sincronizzo..."
                            : "Sincronizzato con Odin"}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton size={"small"} style={{marginLeft: context.theme.spacing(1)}}
                                onClick={_ => {
                                    setPendingGetRequest(false);
                                    setPendingGetRequest(true)
                                }}>

                        <Box position="relative" display="inline-flex">
                            <CircularProgress variant={fetching ? 'indeterminate' : 'determinate'}
                                              value={(timerCount / REFRESH_TIMEOUT) * 100}/>
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="caption" component="div"
                                            color="textSecondary">{`${timerCount}s`}</Typography>
                            </Box>
                        </Box>
                    </IconButton>
                </Grid>
            </Grid>

        </Box>
        <Paper style={{marginTop: context.theme.spacing(2)}}>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={'data-table inventario-completo-table'}>
                        <MaterialTable
                            icons={MaterialTableIcons}
                            localization={MaterialTableLocalisationIT}
                            title={'Inventario completo'}
                            isLoading={putting}
                            // className={'inventario-completo-table'}
                            options={{
                                draggable: false,
                                actionsColumnIndex: -1,
                                pageSize: PAGE_SIZES[0],
                                pageSizeOptions: PAGE_SIZES,
                                // tableLayout: 'fixed'
                                headerStyle: {
                                    width: "2000px"
                                },
                                rowStyle: rd => (!context.device.lowSpec && rd.id === variatingRowId && variatingQty) ? {
                                    backgroundColor: context.theme.palette.primary.dark,
                                    color: context.theme.palette.common.white,
                                    borderColor: context.theme.palette.common.white
                                } : {}
                            }}
                            actions={[
                                {
                                    icon: () => <FontAwesomeIcon size={'sm'} icon={faTrash}
                                                                 style={{color: context.theme.palette.error.main}}/>,
                                    iconProps: {color: 'error'},
                                    tooltip: 'Elimina riga',
                                    onClick: (ev, rd) => {
                                        if (rd.user_meta.id == context.user.id) {
                                            if (window.confirm(`Eliminare (${rd.prod_meta.v_cod}) ${rd.prod_meta.descrizione}?`)) handleProductDelete(rd.prod_meta.id);
                                        } else {
                                            alert('Non sei il proprietario di questa riga!');
                                        }
                                    }
                                },
                                {
                                    icon: () => <FontAwesomeIcon icon={faLifeRing}/>,
                                    tooltip: "Feedback",
                                    onClick: (ev, rd) => {
                                        setFeedbackInfo(`(${rd.prod_meta.cod ? rd.prod_meta.cod : rd.prod_meta.old_cod}) ${rd.prod_meta.descrizione}`);
                                        setFeedbackOpen(true);
                                    }
                                }
                            ]}
                            columns={
                                [
                                    (!context.device.lowSpec ? {
                                        // title: "IMG",
                                        render: (rd => <ProductImage buttonStyle={{width: "100%"}}
                                                                     containerSize={"2.5em"}
                                                                     sku={rd.prod_meta.cod}/>),
                                        cellStyle: {width: '90px'},
                                        headerStyle: {
                                            width: '90px',
                                            minWidth: '90px',
                                            maxWidth: '90px'
                                        },
                                        // width: '90px'

                                    } : null),
                                    {
                                        title: "Riga",
                                        render: (rd => <>
                                                <Typography
                                                    variant={'body1'}><strong>#{rd.n_row}</strong></Typography>
                                                <Typography
                                                    variant={'body1'}><>{rd.user_meta.username}</>
                                                </Typography>
                                                <Typography
                                                    variant={'body2'}><small>{moment(rd.data_creazione).format("DD/MM/YYYY • HH:MM")}</small></Typography>
                                                <Typography
                                                    variant={'body2'}><small>Modificato {moment(rd.ultima_modifica).fromNow()}</small></Typography>
                                                <Typography variant={"caption"}><small>ID: {rd.id}</small></Typography>
                                            </>
                                        ),
                                        cellStyle: {width: '165px', textAlign: 'left'},
                                        headerStyle: {width: '165px', minWidth: '165px', maxWidth: '165px'},
                                    },
                                    {
                                        title: "SKU",
                                        field: "prod_meta.cod",
                                        editable: 'never',
                                        cellStyle: {width: '100px'},
                                        // width: "20%",
                                        render: (rd => <>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant={'overline'}>{rd.prod_meta.cod || rd.prod_meta.old_cod}</Typography>
                                                    <Typography
                                                        variant={'body2'}><strong>{rd.prod_meta.uf_cod}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={12}
                                                      style={{marginTop: context.theme.spacing(1)}}
                                                      className={'react-barcode-grid-item'}
                                                >
                                                    {
                                                        (rd.prod_meta.cod_barre
                                                            ? context.device.lowSpec
                                                                ? (<Typography
                                                                    variant={'subtitle2'}
                                                                >
                                                                    <small>{rd.prod_meta.cod_barre}</small>
                                                                </Typography>)
                                                                : (<Barcode
                                                                    style={{
                                                                        display: "block",
                                                                        left: 0,
                                                                        right: 0,
                                                                        margin: "auto",
                                                                        borderRadius: '200px'
                                                                    }}
                                                                    value={rd.prod_meta.cod_barre}
                                                                    //margin='auto'
                                                                    height={10}
                                                                    width={1}
                                                                    fontSize={12}
                                                                />)
                                                            : "")
                                                    }
                                                </Grid>
                                            </Grid>
                                        </>)
                                    },
                                    {
                                        title: "_searchonly_uf_cod",
                                        hidden: true,
                                        searchable: true,
                                        field: "prod_meta.uf_cod"
                                    },
                                    {
                                        title: "_searchonly_cod_barre",
                                        hidden: true,
                                        searchable: true,
                                        field: "prod_meta.cod_barre"
                                    },
                                    {
                                        title: "_searchonly_old_cod",
                                        hidden: true,
                                        searchable: true,
                                        field: "prod_meta.old_cod"
                                    },
                                    {
                                        title: "Descrizione",
                                        field: "prod_meta.descrizione",
                                        editable: 'never',
                                        // width: "40%",
                                        cellStyle: {
                                            width: "30%"
                                        },
                                        render: rd => <>
                                            <Typography variant={'body2'}>{rd.prod_meta.descrizione}</Typography>
                                            {!context.device.lowSpec &&
                                                <ProductExtendedDescriptionPopup id={rd.prod_meta.id}
                                                                                 buttonStyle={{margin: 0, minWidth: 2}}
                                                                                 buttonProps={{variant: 'contained'}}
                                                                                 buttonChildren={<FontAwesomeIcon
                                                                                     icon={faSearch}/>}/>

                                            }</>
                                    },
                                    {
                                        title: 'Prezzo',
                                        cellStyle: {
                                            width: '120px'
                                        },
                                        field: "",
                                        render: (rd) => <Grid container>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    <Chip label={"A"} size={'small'} style={{
                                                        fontSize: "75",
                                                        marginRight: context.theme.spacing(0.5)
                                                    }}/>
                                                    {rd.prod_meta.prz_acq_finito ?
                                                        <Prezzo small>
                                                            {rd.prod_meta.prz_acq_finito}
                                                        </Prezzo> : <small>N/D</small>}
                                                </Typography>
                                                <Typography>
                                                    <Chip label={"V"} size={'small'} style={{
                                                        fontSize: "75%",
                                                        marginRight: context.theme.spacing(0.5)
                                                    }}/>
                                                    {rd.prod_meta.prz_vendita ?
                                                        <Prezzo small>
                                                            {rd.prod_meta.prz_vendita}
                                                        </Prezzo> : <small>N/D</small>}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    },
                                    {
                                        title: "Qta",
                                        field: "qta",
                                        render: rd => <>
                                            <Grid container justifyContent={"space-between"}>
                                                {rd.user_meta.id == context.user.id &&
                                                    <Grid container xs={8}>
                                                        <Grid item xs={12} style={{textAlign: 'left', lineHeight: 0}}>
                                                            {
                                                                rd.user_meta.id == context.user.id
                                                                && <Typography variant={'overline'}>
                                                                    <small>Variazione</small>
                                                                </Typography>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>{rd.user_meta.id == context.user.id
                                                            && <ButtonGroup orientation={'horizontal'} fullWidth
                                                                            className={(rd.id === variatingRowId && variatingQty !== 0)
                                                                                ? 'force-white-outline'
                                                                                : ""}>
                                                                <Button
                                                                    onClick={e => {
                                                                        if (rd.id !== variatingRowId)
                                                                            handleVariationChange(rd.id, -1, true);
                                                                        else handleVariationChange(rd.id, variatingQty - 1);
                                                                    }
                                                                    }><FontAwesomeIcon
                                                                    icon={faMinus}/></Button>
                                                                <TextField type={'number'}
                                                                           variant={"outlined"}
                                                                           style={{minWidth: 75}}
                                                                           onFocus={e => {
                                                                               if (rd.id !== variatingRowId) {
                                                                                   if (!handleVariationChange(rd.id)) e.target.blur();
                                                                               }
                                                                           }
                                                                           }
                                                                           onBlur={e => variatingQty || handleVariationChange(false)}
                                                                           onChange={e => handleVariationChange(rd.id, (parseInt(e.target.value)))}
                                                                           value={rd.id === variatingRowId ? variatingQty : 0}
                                                                />
                                                                <Button onClick={e => {
                                                                    if (rd.id !== variatingRowId)
                                                                        handleVariationChange(rd.id, 1, true);
                                                                    else handleVariationChange(rd.id, variatingQty + 1);
                                                                }}><FontAwesomeIcon
                                                                    icon={faPlus}/></Button>
                                                            </ButtonGroup>}
                                                        </Grid>
                                                    </Grid>}
                                                <Grid container xs={rd.user_meta.id == context.user.id ? 4 : 12}>
                                                    <Grid item xs={12} style={{textAlign: 'right', lineHeight: 0}}>
                                                        <Typography variant={'overline'}>
                                                            <small>Attuale</small>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography align={"right"} variant={'h6'}>{rd.qta}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{marginTop: context.theme.spacing(1)}}>
                                                <Grid item xs={8}>
                                                    {rd.user_meta.id == context.user.id &&
                                                        <Button
                                                            fullWidth
                                                            variant={"contained"}
                                                            style={{width: "100%"}}
                                                            onClick={ev => sendQty(variatingRowId, variatingQty)}
                                                            disabled={!(variatingRowId === rd.id && variatingQty !== 0)}
                                                        >
                                                            {(variatingRowId === rd.id && variatingQty !== 0)
                                                                ? <>
                                                                    <FontAwesomeIcon icon={faSave}
                                                                                     style={{marginRight: context.theme.spacing(1)}}/>
                                                                    Invia
                                                                </>
                                                                : "Nessuna variazione"
                                                            }
                                                        </Button>}
                                                </Grid>
                                                <Grid item xs={4} style={{textAlign: 'end'}}>
                                                    <Collapse in={(variatingRowId === rd.id && variatingQty !== 0)}>

                                                        <Typography style={{lineHeight: 0}}
                                                                    variant={'overline'}><small>Totale</small></Typography>
                                                        <Typography
                                                            style={{lineHeight: 0.5}}><small><strong>{parseInt(rd.qta) + (rd.id === variatingRowId ? variatingQty : 0)}</strong></small></Typography>
                                                    </Collapse>
                                                </Grid>
                                            </Grid>
                                        </>
                                    },
                                    {
                                        title: "Note",
                                        field: "note",
                                        cellStyle: {width: "14em"},
                                        render: (rd) => <> {rd.user_meta.id == context.user.id ?
                                            <CellNotesInventarioCompleto idRiga={rd.id} value={rd.note}
                                                                         isDirect
                                                                         className={(rd.id === variatingRowId && variatingQty !== 0) ? 'force-white-outline' : ""}
                                                                         requestUpdate={_ => setPendingGetRequest(true)}/> : <>{rd.note}</>
                                        }</>
                                    }

                                ].filter(_ => _) // Rimuovi i null
                            } data={rows}/>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
        <ProductSelector
            showProdImg={!context.device.lowSpec}
            open={searchOpen}
            onClose={_ => setSearchOpen(false)}
            filter={'attivi'}
            onSelect={p => handleProductSelection(p)}
            selectedIds={rows.map(r => context.user.id == r.user_meta.id ? r.prod_meta.id : null)}
        />
        <Feedback
            renderButton={false}
            open={feedbackOpen}
            onClose={() => setFeedbackOpen(false)}
            info={feebackInfo}

        />
        <Fab variant={"extended"}
             style={{
                 position: 'fixed',
                 top: 'auto',
                 bottom: context.theme.spacing(4),
                 left: 'auto',
                 right: context.theme.spacing(4)
             }}
             disabled={variatingQty !== 0}
             onClick={_ => setSearchOpen(true)}>
            <FontAwesomeIcon icon={faPlus} style={{marginRight: context.theme.spacing(1)}}/>Aggiungi
        </Fab>
    </>
}