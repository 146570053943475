import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {SnackbarProvider} from "notistack";
import {Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import {ConfirmProvider} from "material-ui-confirm";
import {SlideUpTransition} from "./components/Transitions";
import Context, {defaultContext, TContext} from "./context/Context";
import Constants from "./context/Constants";
import routes from "./context/Routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./routes/Login";
import {Box, Button, StyledEngineProvider} from "@mui/material";
import {brandingDarkTheme, brandingLightTheme} from "./context/Theme";
import "./App.css";
import "./Style.scss";
import useBreakpoints from "./functions/breakpoints";


function prepareRoutes(roles: string[]) {
    let idCount = 0;
    let returnArray: JSX.Element[] = [];
    const returnRoute = (r: any) => (
        <Route key={idCount} path={r.link} element={r.component}/>
    );
    const returnRoutes = (r: any) => {
        if (r.type === "link") {
            if (r.isRoute) {
                idCount++;
                returnArray.push(returnRoute(r));
            }
        } else if (r.type === "nested") {
            r.children.map((c: any) => returnRoutes(c));
        }
    };
    routes.forEach((r: any) => returnRoutes(r));
    return returnArray;
}

const rootStyle = {minHeight: "100vh"};

export default function App() {
    // region Refs
    const notistackRef = useRef(null);
    //endregion

    //region States
    const [appState, setAppState] = useState<TContext>(defaultContext);
    //endregion

    //region Effects
    useEffect(() => {
        let loadState: TContext = null;
        try {
            loadState = {
                user: {
                    id: parseInt(localStorage.getItem("userId")),
                    loggedIn: localStorage.getItem("loggedIn") === "true",
                    username: localStorage.getItem("username"),
                    firstName: localStorage.getItem("firstName"),
                    lastName: localStorage.getItem("lastName"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                    roles: JSON.parse(localStorage.getItem("roles") || null),
                    assignedZones: JSON.parse(
                        localStorage.getItem("assignedZones") || null
                    ),
                    branchId: parseInt(localStorage.getItem("branchId")),
                },
                ui: {
                    sidebarOpen: localStorage.getItem("sidebarOpen") === "true",
                    dark: localStorage.getItem("darkUi") === "true",
                },
                network: {
                    showSnackbar: false,
                },
                device: {
                    lowSpec: localStorage.getItem("lowSpecDevice") === "true",
                    useElasticSearch:
                        (localStorage.getItem("useElasticSearch") || "true") === "true",
                },
            };
        } catch {
            loadState = null;
            localStorage.clear();
        }
        setAppState({
            ...loadState,
            setContext: (newState) => setAppState(newState),
            theme: loadState.ui.dark ? brandingDarkTheme : brandingLightTheme,
        });
    }, []);

    useEffect(() => {
        const thisInterval = setInterval(() => {
            if (appState.user.loggedIn) {
                axios
                    .get(
                        Constants.paths.ajaxBasePath +
                        "user/?action=isAuthorized&token=" +
                        localStorage.getItem("token")
                    )
                    .catch((e) => {
                        try {
                            console.log(e.response.status);
                            if (e.response.status === 403) {
                                appState.setContext({
                                        ...appState,
                                        user: {
                                            loggedIn: false
                                        },
                                    }
                                );
                            }
                        } catch (error) {
                            console.error(
                                "Cannot check user's permission (Network down?)"
                            );
                        }
                    });
            }
        }, Constants.heartbeat.main);

        return clearInterval(thisInterval);
    }, [appState.user.loggedIn]);

    useEffect(() => {
        if (appState.device.lowSpec)
            document.body.classList.add("low-spec-device");
        else document.body.classList.remove("low-spec-device");
    }, [appState.device.lowSpec]);
    //endregion

    // region Hooks
    const matches = useBreakpoints();
    // endregion

    //functions
    const snackbarOnClickDismiss = useCallback((key: any) => () => {
        notistackRef.current.closeSnackbar(key);
    }, [notistackRef]);

    //endregion


    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider
                theme={appState.ui.dark ? brandingDarkTheme : brandingLightTheme}
            >
                <ConfirmProvider
                    defaultOptions={{
                        title: "Confermi?",
                        description: "",
                        confirmationText: "Conferma",
                        cancellationText: "Annulla",
                        dialogProps: {
                            TransitionComponent: SlideUpTransition,
                        },
                    }}
                >
                    <Context.Provider
                        value={appState}
                    >
                        <SnackbarProvider
                            ref={notistackRef}
                            action={(key: any) => (
                                <Button onClick={snackbarOnClickDismiss(key)}>
                                    Chiudi
                                </Button>
                            )}
                        >
                            <Box
                                id={"App"}
                                className={[
                                    appState.ui.dark ? "dark-theme" : "light-theme",
                                    appState.device.lowSpec ? "low-spec-device" : "",
                                ].join(" ")}
                                style={rootStyle}
                            >
                                {appState.user.loggedIn ? (
                                    <>
                                        <Header/>
                                        <Box
                                            className="page-content-wrapper"
                                            style={{
                                                margin: !matches.md
                                                    ? 0
                                                    : !matches.lg
                                                        ? appState.theme.spacing(2)
                                                        : !matches.xl
                                                            ? appState.theme.spacing(2.5)
                                                            : !matches.xxl
                                                                ? appState.theme.spacing(3)
                                                                : appState.theme.spacing(5),
                                                marginTop: appState.theme.spacing(3),
                                                marginBottom: appState.theme.spacing(3),
                                            }}
                                        >
                                            <Routes>
                                                {prepareRoutes(appState.user.roles || [])}
                                            </Routes>
                                        </Box>
                                        <Footer/>
                                    </>
                                ) : (
                                    <Box
                                        className={
                                            "App " + (appState.ui.dark ? "dark-theme" : "")
                                        }
                                        style={rootStyle}
                                    >
                                        <Login/>
                                    </Box>
                                )}
                            </Box>
                        </SnackbarProvider>
                    </Context.Provider>
                </ConfirmProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}