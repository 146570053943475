import {TListiniOrdine} from "../interfaces/prices/priceMeta";
import Constants from "../context/Constants";
import {Prodotto} from "../interfaces/prodotti/Prodotto";

function extractListini(productObj: Prodotto) {
    const listiniCodes = [
        "l1",
        "l2",
        "l3",
        "l4",
        "x1",
        "x2",
        "x3",
        "x4"
    ];
    let result: TListiniOrdine[] = [];
    for (const lisKey of listiniCodes) {
        let thisPrezzoKey = "prezzo_" + lisKey;
        let thisScaglioneKey = "scaglione_" + lisKey;
        let thisNomeKey = "prezzo_" + lisKey + "_nome";
        result.push(
            {
                key: thisPrezzoKey,
                nome: productObj[thisNomeKey as keyof Prodotto] ?? Constants.mappaturaNomiListini[thisPrezzoKey as keyof typeof Constants.mappaturaNomiListini],
                scaglione: productObj[thisScaglioneKey as keyof Prodotto] ?? 0,
                prezzo: productObj[thisPrezzoKey as keyof Prodotto] ?? 0
            }
        );
    }
    return result;
}

export {extractListini};