import {
    Button,
    Card,
    FormControlLabel,
    Grid,
    InputAdornment,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import React, {useContext, useState} from "react";
import ProfileCard from "../../../components/ProfileCard";
import Constants from "../../../context/Constants";
import Context from "../../../context/Context";

export default function GeneraFileImportWc(props) {
    const context = useContext(Context);
    const [days, setDays] = useState(1);
    const [site, setSite] = useState('kartiell');
    const [isUpdate, setIsUpdate] = useState(false);
    // const requestFile = async () =>
    //   window.open(
    //     `${Constants.paths.ajaxBasePath}prodotti/utils/woocommerce/generate-import-file?token=${context.user.token}&daysThreshold=${days}&filename=Import.csv&action=download` // <- This is what makes it open in a new window.
    //   );

    return (
        <>
            <ProfileCard/>
            <Typography variant="h2" color="textPrimary" textAlign={'center'}>
                Genera <code style={{color: context.theme.palette.warning.main}}>Import.csv</code> per importazione su
                WooCommerce
            </Typography>
            <br/>
            <Grid container style={{width: "100%", textAlign: 'center'}} justifyContent="center">
                <Grid item alignContent="center" xs={5}>
                    <Card style={{padding: context.theme.spacing(4)}}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} style={{justifyContent: 'center'}}>
                                <Typography variant={'overline'}>
                                    I prodotti devono essere stati modificati entro gli ultimi:
                                </Typography>
                                <br/>
                                <TextField
                                    value={days}
                                    onChange={e => setDays(e.target.value)}
                                    type={"number"}
                                    variant={"outlined"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">Giorni</InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'overline'}>Sito</Typography>
                                <br/>
                                <ToggleButtonGroup
                                    value={site}
                                    exclusive
                                    onChange={(e, v) => setSite(v)}
                                >
                                    <ToggleButton value={'kartiell'}>Kartiell</ToggleButton>
                                    <ToggleButton value={'kartoclick'}>KartoClick</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'overline'}>
                                    Aggiungi nuovi o aggiorna esistenti
                                </Typography>
                                <br/>
                                <FormControlLabel
                                    control={
                                        <Switch color={'primary'} checked={isUpdate}
                                                onChange={e => setIsUpdate(e.target.checked)}/>
                                    }
                                    label="Update"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    download={`${context.env === 'dev' ? 'STAGING_' : ''}${site}_${isUpdate ? 'update' : 'new'}_wc_import.csv`}
                                    href={`${Constants.paths.ajaxBasePath}prodotti/utils/woocommerce/generate-import-file/?token=${context.user.token}&daysThreshold=${days}&site=${site}${isUpdate ? '&update' : ''}`}
                                    variant="contained"
                                    color="primary"
                                >
                                    Download
                                </Button>
                                <br/>
                                <Button
                                    // download="Import.csv"
                                    href={`${Constants.paths.ajaxBasePath}prodotti/utils/woocommerce/generate-import-file/?token=${context.user.token}&daysThreshold=${days}&site=${site}${isUpdate ? '&update' : ''}&getQuery`}
                                    rel="noopener noreferrer"
                                    target={'_blank'}
                                    variant="text"
                                    color="secondary"
                                    small
                                >
                                    Mostra SQL
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
