import {Button, Chip} from "@mui/material";
import axios from "axios";
import MaterialTable, {Column} from "material-table";
import React, {Component} from "react";
import {ProductAttributesSelector} from "../../components/DialogSelector";
import Constants from "../../context/Constants";
import Context, {TContext} from "../../context/Context";
import materialTableIcons from "../../support/materialTableIcons";
import materialTableLocalisationIT from "../../support/materialTableLocalisationIT";

interface AttributeData {
    id: number;
    legacy_prefisso: string;
    prefisso: string;
    nome: string;
    abbreviazione: string;
    suffisso: string;
    legacy_suffisso: string;
    tipo: string;
    regola: string;
    legacy_regola: string;
    disambiguazione: { id: number; nome: string }[];
    note: string;
}

interface DisambSelectorState {
    attrId: number;
    selectedIds: number[];
    open: boolean;
}

interface AttributiState {
    fetching: boolean;
    attrData: AttributeData[];
    disambSelector: DisambSelectorState;
}

export default class Attributi extends Component<{}, AttributiState> {
    static contextType = Context;
    context!: TContext;

    constructor(props: {}) {
        super(props);
        this.state = {
            fetching: false,
            attrData: [],
            disambSelector: {
                attrId: 0,
                selectedIds: [],
                open: false,
            },
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData(returnOnly = false) {
        axios
            .get(
                `${Constants.paths.ajaxBasePath}prodotti/attributi/?token=${this.context.user.token}`
            )
            .then((res) => {
                console.log(res.data);
                let newData = res.data.map((d: AttributeData) => ({
                    ...d,
                }));
                this.setState({attrData: newData});
                console.log(newData);
            });
    }

    handleDisambDialogOpen(attrId: number) {
        console.log(attrId);
        this.setState((prev) => ({
            disambSelector: {
                ...prev.disambSelector,
                open: true,
                attrId: attrId,
                selectedIds: this.state.attrData
                    .find((a) => a.id == attrId)
                    .disambiguazione.map((d) => d.id),
            },
        }));
    }

    handleDisambDialogClose() {
        this.setState((prev) => ({
            disambSelector: {...prev.disambSelector, open: false},
        }));
    }

    async handleDisambSelect(attrId: number, disambAttrs: { id: number; nome: string }[]) {
        let promises = [];
        for (let i = 0; i < disambAttrs.length; i++) {
            const d = disambAttrs[i];
            const toPost = {
                token: this.context.user.token,
                ids: [attrId, d.id],
            };
            promises.push(
                new Promise<void>((res, rej) =>
                    axios
                        .post(
                            `${Constants.paths.ajaxBasePath}prodotti/attributi/disambiguazione/`,
                            toPost
                        )
                        .then(() => res())
                )
            );
        }
        await Promise.all(promises);
        this.getData();
        this.handleDisambDialogClose();
    }

    async handleDisambDelete(attrId: number, disambIds: number[]) {
        let promises = [];
        for (let i = 0; i < disambIds.length; i++) {
            const d = disambIds[i];
            const toPost = {
                token: this.context.user.token,
                ids: [attrId, d],
            };
            promises.push(
                new Promise<void>((res, rej) =>
                    axios
                        .delete(
                            `${Constants.paths.ajaxBasePath}prodotti/attributi/disambiguazione/`,
                            {data: toPost}
                        )
                        .then(() => res())
                )
            );
        }
        await Promise.all(promises);
        this.getData();
    }

    handleRowAdd(
        newData: AttributeData,
        onSuccess: () => void = () => {
        },
        onReject: () => void = () => {
        }
    ) {
        const toPost = {
            token: this.context.user.token,
            row: {
                nome: newData.nome,
                abbreviazione: newData.abbreviazione,
                prefisso: newData.prefisso,
                legacy_prefisso: newData.legacy_prefisso,
                suffisso: newData.suffisso,
                legacy_suffisso: newData.legacy_suffisso,
                tipo: newData.tipo,
                regola: newData.regola,
                legacy_regola: newData.legacy_regola,
                note: newData.note,
            },
        };
        axios
            .post(`${Constants.paths.ajaxBasePath}prodotti/attributi/`, toPost)
            .then((res) => {
                if (onSuccess) onSuccess();
                this.getData();
            })
            .catch(() => {
                alert("Attributo non valido o duplicato");
                if (onReject) onReject();
            });
    }

    handleRowUpdate(
        newData: AttributeData,
        oldData: AttributeData,
        onSuccess: () => void = () => {
        },
        onReject: () => void = () => {
        }
    ) {
        const toPost = {
            token: this.context.user.token,
            row: {
                id: newData.id,
                nome: newData.nome,
                abbreviazione: newData.abbreviazione,
                prefisso: newData.prefisso,
                legacy_prefisso: newData.legacy_prefisso,
                suffisso: newData.suffisso,
                legacy_suffisso: newData.legacy_suffisso,
                tipo: newData.tipo,
                regola: newData.regola,
                legacy_regola: newData.legacy_regola,
                disambiguazione: newData.disambiguazione.map((d) => d.id),
                note: newData.note,
            },
        };
        axios
            .patch(`${Constants.paths.ajaxBasePath}prodotti/attributi/`, toPost)
            .then((res) => {
                if (onSuccess) onSuccess();
                this.getData();
            })
            .catch(() => {
                alert("Attributo non valido o duplicato");
                if (onReject) onReject();
            });
    }

    render() {
        const columns: Column<AttributeData>[] = [
            {
                title: "ID",
                field: "id",
                editable: "never",
            },
            {
                title: "LG Prefisso",
                field: "legacy_prefisso",
                editable: "always",
                width: "5%",
            },
            {
                title: "Prefisso",
                field: "prefisso",
                editable: "always",
                width: "5%",
            },
            {
                title: "Nome",
                field: "nome",
                editable: "always",
                width: "70%",
            },
            {
                title: "Abbrev.",
                field: "abbreviazione",
                editable: "always",
                width: "40%",
            },
            {
                title: "Suffisso",
                field: "suffisso",
                editable: "always",
                width: "5%",
            },
            {
                title: "LG Suffisso",
                field: "legacy_suffisso",
                editable: "always",
                width: "5%",
            },
            {
                title: "Tipo",
                field: "tipo",
                editable: "always",
                width: "5%",
                lookup: {
                    numerico: "Numerico",
                    libero: "Libero",
                    selezione: "Selezione",
                    colore: "Colore",
                    bool: "Bool",
                    dimensione: "Dimensione",
                    dimensione1d: "Dimensione 1D",
                    dimensione2d: "Dimensione 2D",
                    dimensione3d: "Dimensione 3D",
                    quantita: "Quantità",
                    regex: "Regex",
                    peso: "Peso",
                },
            },
            {
                title: "Regola",
                field: "regola",
                editable: "always",
                width: "10%",
            },
            {
                title: "LG Regola",
                field: "legacy_regola",
                editable: "always",
                width: "10%",
            },
            {
                title: "Disambig.",
                field: "disambiguazione",
                editable: "always",
                editComponent: (row_data) => (
                    <React.Fragment>
                        {row_data.rowData.disambiguazione?.map((d) => (
                            <Chip
                                onDelete={() =>
                                    this.handleDisambDelete(row_data.rowData.id, [d.id])
                                }
                                size="small"
                                label={d.nome}
                            />
                        ))}
                        <Button
                            onClick={() =>
                                this.handleDisambDialogOpen(row_data.rowData.id)
                            }
                            size="small"
                            fullWidth
                            variant="outlined"
                        >
                            Aggiungi
                        </Button>
                    </React.Fragment>
                ),
                render: (row_data) =>
                    row_data.disambiguazione.map((d) => <Chip size="small" label={d.nome}/>),
            },
            {
                title: "Note",
                field: "note",
                editable: "always",
            },
        ];

        return (
            <React.Fragment>
                <MaterialTable
                    //TODO: Migrate
                    title="Attributi"
                    icons={materialTableIcons}
                    localization={materialTableLocalisationIT}
                    isLoading={this.state.fetching}
                    columns={columns}
                    options={{
                        tableLayout: "auto",
                        search: true,
                        pageSize: 50,
                        pageSizeOptions: [25, 50, 100, 150, 200],
                        addRowPosition: "first",
                        actionsColumnIndex: -1,
                    }}
                    data={this.state.attrData}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise<void>((res, rej) => {
                                this.handleRowAdd(newData, res, rej);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise<void>((res, rej) => {
                                this.handleRowUpdate(newData, oldData, res, rej);
                            }),
                    }}
                />
                <ProductAttributesSelector
                    multiple
                    open={this.state.disambSelector.open}
                    onClose={() => this.handleDisambDialogClose()}
                    onSelect={(attr: any) =>
                        this.handleDisambSelect(this.state.disambSelector.attrId, attr)
                    }
                    selectedIds={[
                        ...this.state.disambSelector.selectedIds,
                        this.state.disambSelector.attrId,
                    ]}
                    enableSelected
                />
            </React.Fragment>
        );
    }
}