import axios from "axios";
import Constants from "./Constants";
import {TContext} from "./Context";

function logout(context: TContext): void {
    context.setContext({
        ...context,
        user: {loggedIn: false}
    });
    const darkTheme = localStorage.getItem("darkUi");
    localStorage.clear();
    localStorage.setItem("darkUi", darkTheme);
}

function capitalizeFirstLetter(string: string): string {
    if (typeof string !== "undefined") {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return "";
    }
}

function setLowSpecDevice(bool: boolean, context: TContext, refreshPage: boolean = true): void {
    context.setContext({
        ...context,
        device: {
            ...context.device,
            lowSpec: bool,
        },
    });
    localStorage.setItem("lowSpecDevice", bool ? "true" : "false");
    if (refreshPage) window.location.reload();
}

function setUseElasticSearch(bool: boolean, context: TContext): void {
    context.setContext({
        ...context,
        device: {
            ...context.device,
            useElasticSearch: bool,
        },
    });
    localStorage.setItem("useElasticSearch", bool ? "true" : "false");
}

// function returnTiersFromListinoName(name: string) {
//     let domain,
//         tier,
//         role,
//         isOffer = false;
//     if (/^prezzo_(ev)([0-9])s([0-9])$/.test(name)) {
//         //Listino standard
//         let matches = name.match(/^prezzo_(ev|ex|ec)([0-9])s([0-9])$/);
//         domain = matches[1];
//         role = matches[2];
//         tier = matches[3];
//     } else if (/^prezzo_(ex|ec)([0-9])(o)?$/.test(name)) {
//         // Listini senza scaglioni
//         let matches = name.match(/^prezzo_(ex|ec)([0-9])(o)?$/);
//         domain = matches[1];
//         role = matches[2];
//         tier = null;
//         isOffer = matches?.[3] === "o";
//     } else {
//         throw "È stato passato un nome di listino non riconosciuto!";
//     }
//     return {
//         domain,
//         tier,
//         role,
//         isOffer,
//     };
// }

async function getAllProdIds(filter: string = "attivi"): Promise<any[]> {
    const token = localStorage.getItem("token");
    let finalData: any[] = [];
    await axios
        .get(
            Constants.paths.ajaxBasePath + `prodotti/?filter=${filter}&token=` + token
        )
        .then((resp) => {
            finalData = resp.data.map((d: any) => d.id);
        });
    return finalData;
}

async function getAllClientiIds(filter = "attivi") {
    const token = localStorage.getItem("token");
    let finalData: any[] = [];
    await axios
        .get(
            Constants.paths.ajaxBasePath + `clienti/?filter=${filter}&token=${token}`
        )
        .then((resp) => {
            finalData = resp.data.map((d: any) => d.id);
        });
    return finalData;
}

function setThemeMode(to: "light" | "dark" = 'light', context: TContext): void {
    let useDark = false;
    if (to === 'dark') useDark = true
    context.setContext({
        ...context,
        ui: {
            ...context.ui,
            dark: useDark
        }
    });
    localStorage.setItem("darkUi", useDark ? "true" : "false");
}

async function getAgenti(ids: false | number | number[] = false, restrictToIdGest = true): Promise<any[] | false> {
    const token = localStorage.getItem('token');
    let idString = Array.isArray(ids) ? (ids.join(',')) : ids;
    try {
        let res = await axios.get(
            Constants.paths.ajaxBasePath + "agenti/"
            + "?token=" + token
            + (ids ? ("&ids" + idString) : "")
            + (restrictToIdGest ? "&restrictToIdGest=1" : "")
        );
        return res.data;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export {
    logout,
    capitalizeFirstLetter,
    setLowSpecDevice,
    setUseElasticSearch,
    // returnTiersFromListinoName,
    getAllClientiIds,
    getAllProdIds,
    setThemeMode,
    getAgenti
};