function preFormatNumberForInputChange(value: string | number): string {
    if (typeof value !== 'string') value = String(value); // Make value always a string
    if (!value) return "0,00";
    value = value.replace('.', ','); // We're in Europe
    value = value.replace(/[^\d,]+/g, ''); // Only numbers
    value = value.replace(/,(?=.*,)/g, ''); // One comma max
    value = value.replace(/,(\d{2})\d*$/, ',$1'); //2 digits max
    return value;
}

function parseFormattedNumberForInput(value: string | number): number {
    if (typeof value !== 'string') value = String(value);
    value = value.replace(',', '.');
    return parseFloat(value);
}

export {preFormatNumberForInputChange, parseFormattedNumberForInput}