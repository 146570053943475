const Constants = {
    paths: {
        ajaxBasePath:
            typeof process === 'undefined' ?
                // @ts-ignore
                process.env.NODE_ENV === "development"
                    ? "https://phpstack-444534-1778757.cloudwaysapps.com/api/"
                    : "https://odin.kartiell.it/api/"
                : "https://phpstack-444534-1778757.cloudwaysapps.com/api/",
        productImgBasePath: "https://odin.kartiell.it/_files/img_prodotti/mini/",
    },
    sizes: {
        bigButtonHeight: "3.4rem",
    },
    heartbeat: {
        fastest: 1500,
        fast: 5000,
        main: 15000,
        slow: 30000,
        slowest: 60000,
    },
    mappaturaNomiListini: {
        "prezzo_l1": "Dettaglio",
        "prezzo_l2": "Dett. + IVA",
        "prezzo_l3": "Rivenditore",
        "prezzo_l4": "Ingrosso",
        "prezzo_x1": "Speciale 1",
        "prezzo_x2": "Speciale 2",
        "prezzo_x3": "Speciale 3",
        "prezzo_x4": "Speciale 4"
    },
    visibilitaAgentiListini: [
        // "prezzo_l1",
        // "prezzo_l2",
        "prezzo_l3",
        // "prezzo_l4",
        // "prezzo_l5",
        "prezzo_x1",
        "prezzo_x2",
        "prezzo_x3",
        "prezzo_x4",
    ],
    chiaviListini: [
        "prezzo_l1",
        "prezzo_l2",
        "prezzo_l3",
        "prezzo_l4",
        // "prezzo_l5",
        "prezzo_x1",
        "prezzo_x2",
        "prezzo_x3",
        "prezzo_x4",
        "prezzo_ec1",
        "prezzo_ec1o",
    ],
    nomiListini: {
        0: "Dettaglio",
        1: "Dett. + IVA",
        2: "Rivenditore",
        3: "Ingrosso",
        4: "Extra",
        5: "Speciale 1",
        6: "Speciale 2",
        7: "Speciale 3",
        8: "Speciale 4",
        9: "Ecommerce dettaglio",
        10: "Ecommerce dettaglio offerta",
    },
    ufNomiListini: {
        ex: "EX",
        ev: "EV",
        ec: "EC",
        lv: "OLD",
    },
    metaListini: [
        {
            titolo: "Listini Standard",
            nome: "ev",
            nListini: 5,
            nScaglioni: 4,
            hasOffer: false,
        },
        {
            titolo: "Listini AUX",
            nome: "ex",
            nListini: 4,
            nScaglioni: 1,
            hasOffer: false,
        },
        {
            titolo: "Listini KartoClick",
            nome: "ec",
            nListini: 1,
            nScaglioni: 1,
            hasOffer: true,
        },
    ],
    nomiSedi: [
        {
            id: 1,
            short: "Feroleto A.",
            var: "feroleto",
            long: "Feroleto Antico",
        },
        {
            id: 2,
            short: "Rende",
            var: "rende",
            long: "Rende",
        },
        {
            id: 3,
            short: "Odin",
            var: "odin",
            long: "Odin (eCommerce)",
        },
        {
            id: 4,
            short: "Amazon",
            var: "amazon",
            long: "Amazon",
        },
    ],
    replaceRules: {
        barcode: [[' ', '']],
        numerico: [[",", "."]],
        numericoIntero: [[",", "."]],
        dimensione: [
            [",", "."],
            ["x", "X"],
            [/mm/gi, "mm"],
            [/cm/gi, "cm"],
            [/dm/gi, "dm"],
            [/m/gi, "m"],
            [/km/gi, "km"],
        ],
        dimensione1d: [
            [",", "."],
            ["x", "X"],
            [/mm/gi, "mm"],
            [/cm/gi, "cm"],
            [/dm/gi, "dm"],
            [/m/gi, "m"],
            [/km/gi, "km"],
        ],
        dimensione2d: [
            [",", "."],
            ["x", "X"],
            [/mm/gi, "mm"],
            [/cm/gi, "cm"],
            [/dm/gi, "dm"],
            [/m/gi, "m"],
            [/km/gi, "km"],
        ],
        dimensione3d: [
            [",", "."],
            ["x", "X"],
            [/mm/gi, "mm"],
            [/cm/gi, "cm"],
            [/dm/gi, "dm"],
            [/m/gi, "m"],
            [/km/gi, "km"],
        ],
        quantita: [
            [",", "."],
            [/ml/gi, "ml"],
            [/cl/gi, "cl"],
            [/l/gi, "l"],
            [/g/gi, "g"],
            [/kg/gi, "kg"],
        ],
    },
    regexMatchRules: {
        barcode: /^\d{8}$|^\d{12}$|^\d{13}$|^\d{14}$/g,
        quantita: /^[0-9]*(\.[0-9]{1,2})?\s(g|kg|ml|cl|l)$/g,
        numericoIntero: /^[0-9]*$/g,
        numerico: /^[0-9]*(\.[0-9]{1,2})?$/g,
        dimensione1d:
            /^([0-9]{1,3})(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?\s(mm|cm|dm|m|km)$/g,
        dimensione2d:
            /^([0-9]{1,3})(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?( X )[0-9]{1,3}(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?\s(mm|cm|dm|m|km)$/g,
        dimensione3d:
            /^([0-9]{1,3})(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?( X )[0-9]{1,3}(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?( X )[0-9]{1,3}(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?\s(mm|cm|dm|m|km)$/g,
        dimensione:
            /^(([0-9]{1,3})(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?)|(([0-9]{1,3})(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?( X )[0-9]{1,3}(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?)|(([0-9]{1,3})(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?( X )[0-9]{1,3}(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?( X )[0-9]{1,3}(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?)\s(mm|cm|dm|m|km)$/g,
        peso: /^([0-9]{1,3})(\.[0-9]{1,2})?( - ([0-9]{1,3})(\.[0-9]{1,2})?)?\s(mg|g|kg)$/g,
    },
};

export default Constants;
