import React from "react";
import axios from "axios";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Fab,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";

import {SlideUpTransition} from "../components/Transitions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

import ProfileCard from "../components/ProfileCard";
import ProductImage from "../components/ProductImage";
import TablePagination from "../components/TablePagination";
import MaterialTable from "material-table";
import materialTableIcons from "../support/materialTableIcons";
import materialTableLocalisationIT from "../support/materialTableLocalisationIT";
import SearchBar from "../components/SearchBar";
import Feedback from "../components/Feedback";
import TableProductCod from "../components/TableProductCod";
import {CreateProduct, UpdateProduct} from "../components/CreateProduct";

import handyScroll from "handy-scroll";
import "handy-scroll/dist/handy-scroll.css";

import {Edit} from "@mui/icons-material";
import ProductExtendedDescriptionPopup from "../components/ProductExtendedDescriptionPopup";
import ListInventoryDisplay from "../components/ListInventoryDisplay";

class RilevazioneDimensioni extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.setNewData = this.setNewData.bind(this);
        this.canSend = this.canSend.bind(this);
        this.updateDialogData = this.updateDialogData.bind(this);
        this.cancelToken = null;
        this.state = {
            a: 0,
            l: 0,
            p: 0,
            peso: 0,
            cod_barre: "",
            cod_ricerca_extra: "",
            imballo: 0,
            done: true,
            rilevatore: "",
            dialogOpen: false,
            sendingAJAX: false,
        };
    }

    getData(callback) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        try {
            if (this.cancelToken)
                this.cancelToken.cancel("Annullo per nuovo caricamento");
            this.cancelToken = source;
            axios
                .get(
                    `${Constants.paths.ajaxBasePath}inventario/rilevazione/dati/?token=${this.context.user.token}&prod_ids=${this.props.id_prod}`,
                    {
                        cancelToken: source.token,
                    }
                )
                .then((res) => {
                    let resp = res.data[0];
                    // console.log(res);
                    this.setState(
                        {
                            a: resp?.dim_a || 0,
                            l: resp?.dim_l || 0,
                            p: resp?.dim_p || 0,
                            imballo: resp?.imballo || 0,
                            peso: resp?.dim_peso || 0,
                            cod_barre: resp?.cod_barre || "",
                            cod_ricerca_extra: resp?.cod_ricerca_extra || "",
                            // rilevatore: resp?.rilevatore || "",
                        },
                        () => {
                            callback && callback(resp);
                        }
                    );
                }).catch(err => console.log(err));
        } catch (err) {
            // console.log(err);
        }
    }

    componentWillUnmount() {
        if (this.cancelToken) this.cancelToken.cancel("Annullo per unmount");
    }

    updateDialogData(callback) {
        this.setState(
            {
                new_a: this.state.a != 0 ? this.state.a : "",
                new_l: this.state.l != 0 ? this.state.l : "",
                new_p: this.state.p != 0 ? this.state.p : "",
                new_imballo: this.state.imballo != 0 ? this.state.imballo : "",
                new_peso: this.state.peso != 0 ? this.state.peso : "",
                new_cod_barre: this.state.cod_barre,
                //new_cod_ricerca_extra: this.state.cod_ricerca_extra,
            },
            () => callback && callback()
        );
    }

    componentDidMount() {
        this.getData();
        this.updateTimer = setInterval(
            () => this.getData(),
            Constants.heartbeat.slowest
        );
    }

    componentWillUnmount() {
        clearInterval(this.updateTimer);
    }

    handleDialogOpen() {
        this.updateDialogData(() => {
            this.setState({
                dialogOpen: true,
            });
        });
    }

    handleDialogClose() {
        this.setState({
            dialogOpen: false,
        });
    }

    setNewData(prop, val, callback = false) {
        this.setState(
            (prev) => ({
                ...prev,
                [`new_${prop}`]: val,
            }),
            () => callback && callback()
        );
    }

    canSend() {
        let required = [
            "new_a",
            "new_l",
            "new_p",
            "new_peso",
            "new_imballo",
            "new_cod_barre",
            //"new_cod_ricerca_extra",
        ];
        if (this.state.new_cod_barre_nd)
            required = required.filter((val) => val != "new_cod_barre");

        // if (this.state.new_cod_ricerca_extra_nd)
        //   required = required.filter((val) => val != "new_cod_ricerca_extra");

        let toReturn = true;

        required.forEach((val) => {
            if (!this.state[val] || this.state[val] == 0 || this.state[val] == "")
                toReturn = false;
        });
        return toReturn;
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //   if (this.state.done !== nextState.done) return true;
    //   else return false;
    // }

    render() {
        return (
            <React.Fragment>
                <Card variant="outlined">
                    <Grid container style={{padding: this.context.theme.spacing(1)}}>
                        <Grid container item justifyContent="space-evenly" alignItems={"center"}>
                            <Grid item>
                                <Typography variant="caption">
                                    <b>{this.state.a?.toString().replace(".", ",")}</b> cm
                                </Typography>
                            </Grid>
                            <FontAwesomeIcon icon={faTimes} style={{alignSelf: 'center', fontSize: '80%'}}/>
                            <Grid item>
                                <Typography variant="caption">
                                    <b>{this.state.l?.toString().replace(".", ",")}</b> cm
                                </Typography>
                            </Grid>
                            <FontAwesomeIcon icon={faTimes} style={{alignSelf: 'center', fontSize: '80%'}}/>
                            <Grid item>
                                <Typography variant="caption">
                                    <b>{this.state.p?.toString().replace(".", ",")}</b> cm
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider
                            style={{
                                width: "100%",
                                marginTop: this.context.theme.spacing(0.5),
                                marginBottom: this.context.theme.spacing(0.5),
                            }}
                        />
                        <Grid item container xs={12} justifyContent="space-between">
                            <Grid item xs={5} style={{textAlign: "center", width: "100%"}}>
                                <Typography variant="caption" style={{float: "left"}}>
                                    Peso:
                                </Typography>
                                <Typography variant="caption" style={{float: "right"}}>
                                    <b>{this.state.peso}</b> g
                                </Typography>
                            </Grid>
                            <Divider
                                style={{
                                    left: 0,
                                    right: 0,
                                    margin: "auto",
                                }}
                                orientation="vertical"
                            />
                            <Grid item xs={5} style={{textAlign: "center", width: "100%"}}>
                                <Typography variant="caption" style={{float: "left"}}>
                                    Imballo:
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{
                                        float: "right",
                                        color: this.state.imballo
                                            ? "inherit"
                                            : this.context.theme.palette.error.main,
                                    }}
                                >
                                    <b>{this.state.imballo}</b> pz
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider
                            style={{
                                width: "100%",
                                marginTop: this.context.theme.spacing(0.5),
                                marginBottom: this.context.theme.spacing(0.5),
                            }}
                        />
                        <Grid item xs={12}>
                            <Typography variant="caption" style={{float: "left"}}>
                                EAN
                            </Typography>
                            <Typography variant="caption" style={{float: "right"}}>
                                {this.state.cod_barre || "N/D"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
                <Dialog
                    scroll="body"
                    fullWidth={true}
                    fullScreen={!window.matchMedia(this.context.theme.breakpoints.up('sm').replace("@media ", "")).matches}
                    maxWidth="sm"
                    open={this.state.dialogOpen}
                    TransitionComponent={SlideUpTransition}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle>Censisci prodotto</DialogTitle>
                    <DialogContent
                        style={{
                            alignSelf: "center",
                            overflow: "hidden",
                            padding: this.context.theme.spacing(3),
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} style={{textAlign: "center"}}>
                                <ProductImage
                                    style={{textAlign: "center"}}
                                    containerSize={"100%"}
                                    sku={this.props.cod}
                                />
                            </Grid>
                            <Grid container item xs={12} md={8}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={0}
                                    justifyContent="space-between"
                                    alignItems="baseline"
                                >
                                    <Grid item>
                                        <Typography variant="">{this.props.uf_cod}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{fontWeight: "bold"}}>
                                            {this.props.cod}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Chip
                                            avatar={<Avatar>{this.props.num_varianti}</Avatar>}
                                            size="small"
                                            label={`${this.props.this_cod_famiglia}`}
                                            style={{marginBottom: this.context.theme.spacing(0.3)}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                        <Divider/>
                                        <br/>
                                    </Grid>
                                </Grid>
                                <div
                                    style={{
                                        height: !window.matchMedia(this.context.theme.breakpoints.up('md').replace("@media ", "")).matches ? "7em" : "8em",
                                        overflowY: "auto",
                                    }}
                                >
                                    <Typography>
                                        {this.props.descrizione_estesa || this.props.descrizione}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{height: this.context.theme.spacing(3)}}></div>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    label="Alt."
                                    variant="outlined"
                                    InputProps={{
                                        type: "numeric",
                                        endAdornment: (
                                            <InputAdornment position="end">cm</InputAdornment>
                                        ),
                                    }}
                                    value={this.state.new_a?.toString().replace(".", ",") || ""}
                                    onChange={(e) => this.setNewData("a", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Larg."
                                    variant="outlined"
                                    InputProps={{
                                        type: "numeric",
                                        endAdornment: (
                                            <InputAdornment position="end">cm</InputAdornment>
                                        ),
                                    }}
                                    value={this.state.new_l?.toString().replace(".", ",") || ""}
                                    onChange={(e) => this.setNewData("l", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Prof."
                                    variant="outlined"
                                    InputProps={{
                                        type: 'numeric',
                                        endAdornment: (
                                            <InputAdornment position="end">cm</InputAdornment>
                                        ),
                                    }}
                                    value={this.state.new_p?.toString().replace(".", ",") || ""}
                                    onChange={(e) => this.setNewData("p", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Peso"
                                    variant="outlined"
                                    InputProps={{
                                        type: "numeric",
                                        endAdornment: (
                                            <InputAdornment position="end">g</InputAdornment>
                                        ),
                                    }}
                                    value={this.state.new_peso || ""}
                                    onChange={(e) => this.setNewData("peso", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Imballo"
                                    variant="outlined"
                                    InputProps={{
                                        type: "numeric",
                                        endAdornment: (
                                            <InputAdornment position="end">pz</InputAdornment>
                                        ),
                                    }}
                                    value={this.state.new_imballo || ""}
                                    onChange={(e) => this.setNewData("imballo", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    style={{width: "100%"}}
                                    label="Cod barre"
                                    variant="outlined"
                                    InputProps={{
                                        type: "numeric",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FontAwesomeIcon icon={faBarcode}></FontAwesomeIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={this.state.new_cod_barre || ""}
                                    onChange={(e) => this.setNewData("cod_barre", e.target.value)}
                                    disabled={this.state.new_cod_barre_nd}
                                />
                            </Grid>
                            <Grid item xs={4} style={{alignSelf: "center"}}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.new_cod_barre_nd}
                                                onChange={(e) => {
                                                    this.setState({
                                                        new_cod_barre_nd: e.target.checked,
                                                    });
                                                }}
                                            />
                                        }
                                        label="Codice assente"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <DialogActions>
                                <Feedback
                                    info={`(${this.props.id_prod}) ${this.props.cod} | ${this.props.uf_cod} => ${this.props.descrizione_estesa}`}
                                />
                            </DialogActions>
                        </Grid>
                        <Grid item>
                            <DialogActions>
                                <Button onClick={this.handleDialogClose}>Annulla</Button>
                                <Button
                                    disabled={this.state.sendingAJAX || !this.canSend()}
                                    onClick={() =>
                                        this.setState({sendingAJAX: true}, () => this.sendData())
                                    }
                                    variant="contained"
                                    color="primary"
                                >
                                    Conferma
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Dialog>
            </React.Fragment>
        );
    }
}

class Inventario extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.progressDataCancelToken = null;
        this.getProgressData = this.getProgressData.bind(this);
        this.updateSingle = this.updateSingle.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleTablePageChange = this.handleTablePageChange.bind(this);
        this.search = this.search.bind(this);
        this.directSetProgressData = this.directSetProgressData.bind(this);
        this.getTableHorizontalScrollContainer =
            this.getTableHorizontalScrollContainer.bind(this);
        // this.handleCreateProductExited = this.handleCreateProductExited.bind(this);
        this.handleCreateProductClose = this.handleCreateProductClose.bind(this);
        this.handleCreateProductOpen = this.handleCreateProductOpen.bind(this);
        // this.handleUpdateProductExited = this.handleUpdateProductExited.bind(this);
        this.handleUpdateProductClose = this.handleUpdateProductClose.bind(this);
        this.handleUpdateProductOpen = this.handleUpdateProductOpen.bind(this);
        this.handleShowExtendedDescriptionOpen =
            this.handleShowExtendedDescriptionOpen.bind(this);
        this.handleShowExtendedDescriptionClose =
            this.handleShowExtendedDescriptionClose.bind(this);

        this.state = {
            data: [],
            results: [],
            fetching: true,
            searchValue: "",
            tableSorting: "rilevanza",
            tableSortingOrder: "down",
            tablePageSize: 10,
            tablePage: 1,
            tablePageCount: 1,
            tableRowCount: 0,
            searchScores: {},
            progressStatus: {}, //{id:2} 0=Stop,1=In esecuzione,2=Terminato
            createProductDialog: {open: false, mounted: false},
            updateProductDialog: {open: false, mounted: false, id_prod: undefined},
            extendedDescriptionPopover: {
                open: false,
                anchorEl: undefined,
                text: "",
            },
        };
    }

    componentWillUnmount() {
        if (this.progressDataCancelToken)
            this.progressDataCancelToken.cancel("Annullo per unmount");
        if (this.elasticSearchCancelToken)
            this.elasticSearchCancelToken.cancel("Annullo per unmount");
    }

    //TODO: PROBLEMA GRAVE DI PERFORMANCE
    getProgressData(callback) {
        return {};
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (this.progressDataCancelToken)
            this.progressDataCancelToken.cancel("Annullo per nuova ricerca");
        this.progressDataCancelToken = source;
        axios
            .get(
                `${Constants.paths.ajaxBasePath}inventario/rilevazione/stato/?token=${this.context.user.token}`,
                {
                    cancelToken: source.token,
                }
            )
            .then((res) => {
                let resp = res.data;
                let newData = {};
                resp.forEach((val, i) => {
                    if (!newData[val["id_prod"]])
                        newData[val["id_prod"]] = {
                            feroleto: 0,
                            rende: 0,
                        };
                    newData[val["id_prod"]][val["id_sede"] == 1 ? "feroleto" : "rende"] =
                        parseInt(val["stato"] || 0);
                });
                // console.log(resp);
                this.setState({progressStatus: newData}, () => {
                    callback && callback();
                });
            });
    }

    directSetProgressData(id, sede, stato) {
        this.setState((prev) => ({
            progressStatus: {
                ...prev.progressStatus,
                [id]: {
                    ...prev.progressStatus[id],
                    [sede]: parseInt(stato),
                },
            },
        }));
    }

    getTableHorizontalScrollContainer() {
        let toReturn;

        try {
            toReturn =
                document.querySelectorAll(".table.inventario")[0].firstChild.children[1]
                    .firstChild.firstChild;
        } catch (error) {
            toReturn = false;
            console.warn(error);
        }

        return toReturn;
    }

    updateSingle(id, newVal, sede, callback = false) {
        let data = this.state.data;
        data = data.map((d) => {
            if (d.id == id) d[`stock_${sede}`] = newVal;
            return d;
        });
        this.setState({data: data}, () => {
            if (callback) callback();
        });
    }

    componentDidMount() {
        // this.getProgressData();
        this.search("");
        let name;
        if ((name = this.getTableHorizontalScrollContainer()))
            handyScroll.mount(name);
        this.resizeTimer = setInterval(() => {
            window.dispatchEvent(new Event("resize")); //Chiama questo evento per far funzionare la floating scrollbar, il motivo di questa 'hack' è il fatto che il componente della tabella sia svilkuppata male
        }, Constants.heartbeat.fastest);

        this.updateTimer = setInterval(
            this.getProgressData,
            Constants.heartbeat.main
        );
    }

    componentWillUnmount() {
        clearInterval(this.updateTimer);
        clearInterval(this.resizeTimer);
    }

    search(query, resetPage = true) {
        this.setState(
            (prev) => ({
                fetching: true,
                searchValue: query,
                tablePage: resetPage ? 1 : prev.tablePage,
            }),
            () => {
                let promise = new Promise((res, rej) => {
                    const thisSearch = typeof query == "undefined" ? " " : query;
                    let results = [];
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    if (this.elasticSearchCancelToken)
                        this.elasticSearchCancelToken.cancel("Annullo per nuova ricerca");
                    this.elasticSearchCancelToken = source;
                    axios
                        .get(
                            Constants.paths.ajaxBasePath +
                            "inventario/elastic/search/?token=" +
                            this.context.user.token +
                            "&filter=nuovi" +
                            "&size=" +
                            this.state.tablePageSize +
                            "&from=" +
                            (this.state.tablePage - 1) * this.state.tablePageSize +
                            "&query=" +
                            encodeURIComponent(thisSearch),
                            {
                                cancelToken: source.token,
                            }
                        )
                        .then((resp) =>
                            this.setState(
                                {
                                    tableData: resp.data.hits,
                                    tablePageCount: Math.ceil(
                                        resp.data.totalCount / this.state.tablePageSize
                                    ),
                                    tableRowCount: resp.data.totalCount,
                                },
                                () => res()
                            )
                        )
                        .catch((err) => {
                            if (axios.isCancel(err)) rej(err);
                            else console.error(err);
                        });
                }).catch((err) => {
                    if (!axios.isCancel(err)) console.error(err);
                });
            }
        );
    }

    handleSearchChange(s) {
        if (!this.context.device.lowSpec) this.search(s);
    }

    handleTablePageChange(page) {
        this.setState(
            {
                tablePage: page,
            },
            () => this.search(this.state.searchValue, false)
        );
    }

    handleCreateProductOpen(callback = false) {
        this.setState(
            (prev) => ({
                createProductDialog: {
                    ...prev.createProductDialog,
                    open: true,
                    mounted: true,
                },
            }),
            () => {
                if (callback) callback();
            }
        );
    }

    handleCreateProductClose(callback = false) {
        this.setState(
            (prev) => ({
                createProductDialog: {
                    ...prev.createProductDialog,
                    open: false,
                },
            }),
            () => {
                this.search(this.state.searchValue, false);
                if (callback) callback();
            }
        );
    }

    // handleCreateProductExited(callback = false) {
    //   this.setState(
    //     (prev) => ({
    //       createProductDialog: {
    //         ...prev.createProductDialog,
    //         mounted: false,
    //       },
    //     }),
    //     () => {
    //       if (callback) callback();
    //     }
    //   );
    // }

    handleUpdateProductOpen(id_prod, callback = false) {
        this.setState(
            (prev) => ({
                updateProductDialog: {
                    ...prev.updateProductDialog,
                    open: true,
                    mounted: true,
                    id_prod: id_prod,
                },
            }),
            () => {
                this.search(this.state.searchValue, false);
                if (callback) callback();
            }
        );
    }

    handleUpdateProductClose(callback = false) {
        this.setState(
            (prev) => ({
                updateProductDialog: {
                    ...prev.updateProductDialog,
                    id_prod: undefined,
                    open: false,
                },
            }),
            () => {
                this.search(this.state.searchValue, false);
                if (callback) callback();
            }
        );
    }

    handleShowExtendedDescriptionOpen(anchorEl, text) {
        this.setState({
            extendedDescriptionPopover: {
                open: true,
                anchorEl: anchorEl,
                text: text,
            },
        });
    }

    handleShowExtendedDescriptionClose() {
        this.setState((prev) => ({
            extendedDescriptionPopover: {
                ...prev.extendedDescriptionPopover,
                open: false,
            },
        }));
    }

    render() {
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <React.Fragment>
                        <ProfileCard/>
                        <SearchBar
                            forcedQuery={this.state.forcedSearchValue}
                            placeholder={null}
                            onChange={s => this.handleSearchChange(s)}
                            onBlur={
                                this.context.device.lowSpec
                                    ? (s) => this.search(s)
                                    : null
                            }
                            onClear={() =>
                                this.setState(
                                    {
                                        forcedSearchValue: null,
                                    },
                                    () => {
                                        this.search();
                                    }
                                )
                            }
                        />
                        <TablePagination
                            rowCount={this.state.tableRowCount}
                            page={this.state.tablePage}
                            pageSize={this.state.tablePageSize}
                            onChange={(e, p) => {
                                this.handleTablePageChange(p);
                            }}

                        />
                        <br/>
                        <br/>
                        <div className="table inventario">
                            <MaterialTable
                                data={this.state.tableData}
                                title="Inventario"
                                icons={materialTableIcons}
                                localization={materialTableLocalisationIT}
                                //isLoading={this.state.fetching}
                                actions={[
                                    {
                                        icon: Edit,
                                        tooltip: "Modifica",
                                        onClick: (event, rowData) =>
                                            this.handleUpdateProductOpen(rowData.id_prod),
                                    },
                                ]}
                                options={{
                                    tableLayout: "auto",
                                    paging: false,
                                    pageSize: 15,
                                    pageSizeOptions: [15, 20, 30, 40, 50],
                                    actionsColumnIndex: 0,
                                    filtering: false,
                                    search: false,
                                    sorting: false,
                                }}
                                columns={[
                                    consumer_data.device.lowSpec ? null : {
                                        title: "Img",
                                        field: "img",
                                        editable: "never",
                                        cellStyle: {
                                            // maxWidth: "7em",
                                            // minWidth: "7em",
                                            textAlign: "center",
                                        },
                                        headerStyle: {
                                            textAlign: "center",
                                        },
                                        render: (row_data) => (
                                            <ProductImage
                                                containerSize={consumer_data.theme.spacing(10)}
                                                sku={row_data.sku}
                                                size={'100%'}
                                                // img={row_data.img}
                                            />
                                        ),
                                    },
                                    {
                                        title: "COD",
                                        field: "sku",
                                        editable: "never",
                                        cellStyle: {
                                            maxWidth: "10em",
                                            minWidth: "10em",
                                        },
                                        headerStyle: {
                                            textAlign: "left",
                                        },
                                        render: (row_data) => (
                                            <TableProductCod
                                                key={row_data.id_prod || row_data.id}
                                                //allUfCodes={this.state.data.map((d) => d.uf_cod)}
                                                allowCustomisation={true}
                                                productObj={row_data}
                                                noFamily
                                                noXCode
                                                onFamilyClick={(famCode) => {
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        title: "Descrizione",
                                        field: "descrizione",
                                        render: (row_data) => (
                                            <React.Fragment>
                                                {row_data["descrizione"]}
                                                {!consumer_data.device.lowSpec && row_data["descrizione_estesa"] && (
                                                    <ProductExtendedDescriptionPopup
                                                        id={row_data['id_prod']}
                                                        buttonStyle={{float: 'right'}}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ),
                                        // row_data["descrizione_estesa"].replace(
                                        //   /(<([^>]+)>)/gi,
                                        //   ""
                                        // )
                                        editable: "never",
                                        cellStyle: {
                                            // verticalAlign:'baseline',
                                            width: "100%",
                                            minWidth: !consumer_data.theme.breakpoints.down("sm")
                                                ? "16em" //XS
                                                : !consumer_data.theme.breakpoints.down("md")
                                                    ? "18em" //SM
                                                    : !consumer_data.theme.breakpoints.down("lg")
                                                        ? "28rem" //MD
                                                        : !consumer_data.theme.breakpoints.down("xl")
                                                            ? "38rem" //LG
                                                            : !consumer_data.theme.breakpoints.down("xxl")
                                                                ? "40rem" //XL
                                                                : "46rem", //XXL
                                        },
                                    },
                                    {
                                        title: "Dimensioni",
                                        field: "",
                                        cellStyle: {
                                            minWidth: "15vw",
                                            textAlign: "center",
                                        },
                                        headerStyle: {
                                            textAlign: "center",
                                        },
                                        render: (row_data) => (
                                            <RilevazioneDimensioni
                                                id_prod={row_data.id_prod}
                                                key={row_data.id_prod}
                                                img={row_data.img}
                                                cod={row_data.sku}
                                                uf_cod={row_data.v_uf_cod}
                                                descrizione_estesa={
                                                    row_data.descrizione || row_data.descrizione_estesa
                                                }
                                                this_cod_famiglia={row_data.cod_famiglia}
                                                num_varianti={row_data.num_varianti}
                                            />
                                        ),
                                    },
                                    {
                                        title: "Disponibilità",
                                        field: "stock_totale",
                                        editable: "never",
                                        cellStyle: {
                                            minWidth: "7vw",
                                            marginLeft: consumer_data.theme.spacing(3),
                                        },
                                        headerStyle: {
                                            width: "100%",
                                            // right: consumer_data.theme.spacing(1.5),
                                            textAlign: "center",
                                        },
                                        render: (row_data) => (
                                            <ListInventoryDisplay
                                                extended
                                                useDirectStocks
                                                directFeroleto={row_data.stock_feroleto}
                                                directRende={row_data.stock_rende}
                                                withTotal

                                            />
                                        ),
                                    },
                                    {
                                        //Dato che questo modulo fa piangere anche i monaci, ho dovuto fare così per dare uno schifo di senso al padding.
                                        headerStyle: {padding: this.context.theme.spacing(1)},
                                        cellStyle: {padding: this.context.theme.spacing(1)},
                                    },
                                ].filter(_ => _)
                                }
                            />
                            <br/>
                            <TablePagination
                                rowCount={this.state.tableRowCount}
                                page={this.state.tablePage}
                                pageSize={this.state.tablePageSize}
                                onChange={(e, p) => {
                                    this.handleTablePageChange(p);
                                }}
                            />
                        </div>
                        <CreateProduct
                            open={this.state.createProductDialog.open}
                            onClose={() => this.handleCreateProductClose()}
                            // onExited={()=> this.handleCreateProductExit()}
                            // disableBackdropClick
                        />
                        <UpdateProduct
                            key={this.state.updateProductDialog.id_prod}
                            open={this.state.updateProductDialog.open}
                            onClose={() => this.handleUpdateProductClose()}
                            // onExited={() => this.handleUpdateProductExited()}
                            // onExited={() => this.handleUpdateProductExit()}
                            // unmountOnExit
                            prodId={this.state.updateProductDialog.id_prod}
                            // disableBackdropClick
                        />
                        <Tooltip title={"Crea nuovo"}>
                            <Fab
                                variant="extended"
                                style={{
                                    zIndex: 99,
                                    position: "fixed",
                                    right: this.context.theme.spacing(2),
                                    bottom: this.context.theme.spacing(2),
                                    // display: true ||this.context.user.roles.includes("magazzino")
                                    //   ? undefined
                                    //   : "none",
                                    // marginBottom: consumer_data.theme.spacing(3),
                                }}
                                onClick={(e) => this.handleCreateProductOpen()}
                            >
                                <FontAwesomeIcon
                                    icon={faPlus}
                                />
                            </Fab>
                        </Tooltip>
                    </React.Fragment>
                )}
            </Context.Consumer>
        );
    }
}

export default Inventario;
