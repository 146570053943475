import React from "react";
import Context from "../context/Context";
import Constants from "../context/Constants";
import axios from "axios";
import {Navigate} from "react-router-dom";
import "animate.css";
import {Button, Card, CardContent, CardMedia, FormGroup, TextField, Typography} from "@mui/material";
import logoCircle from "../media/k-odin-circle.png";

export default class Login extends React.Component {
    static contextType = Context;

    constructor(props, context) {
        super(props, context);
        this.state = {
            username: null,
            password: null,
            loginStatus: {slug: null, text: null},
            shouldShake: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getClasses = this.getClasses.bind(this);
    }

    getClasses() {
        return {
            root: {
                margin: this.context.theme,
                minHeight: "85vh",
                display: "block",
            },
            card: {
                display: "block",
                position: "absolute",
                maxWidth: "20em",
                maxHeight: "30em",
                padding: this.context.theme.spacing(3),
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            cardMedia: {
                //minWidth: "80%",
                minHeight: "5em",
                marginBottom: this.context.theme.spacing(3),
                marginTop: this.context.theme.spacing(3),
                backgroundSize: "contain",
            },
        };
    }

    componentDidMount() {
        // this.context.functions.setActiveRouteName("login");

        if (!this.context?.user?.loggedIn) {
            //Run only if there will be no redirect to /home
            //this.context.functions.setDarkBg();
        }
    }

    // componentWillUnmount() { WTF
    //   this.context.functions.setDarkBg(false);
    // }

    handleChange(e) {
        if (e.target.name === "username") {
            this.setState({
                username: e.target.value,
            });
        } else if (e.target.name === "password") {
            this.setState({
                password: e.target.value,
            });
        }
        this.setState({
            shouldShake: false,
        });
    }

    handleSubmit(target) {
        target.preventDefault();
        axios
            .get(
                Constants.paths.ajaxBasePath +
                `login/?username=${this.state.username}&password=${this.state.password}`,
                {}
            )
            .then((r) => {
                let data = {
                    id: r.data.data.id,
                    roles: r.data.data.roles,
                    email: r.data.data.email,
                    username: r.data.data.username,
                    firstName: r.data.data.first_name,
                    lastName: r.data.data.last_name,
                    token: r.data.token,
                    assignedZones: r.data.data.assigned_zones,
                    idSede: r.data.data.id_sede ?? 1
                };
                // this.context.functions.login(data);
                data.assignedZones = data.assignedZones ? data.assignedZones.split(",") : [];
                data.roles = data.roles ? data.roles.split(",") : [];

                localStorage.setItem("loggedIn", true);
                localStorage.setItem("userId", data.id);
                localStorage.setItem("roles", JSON.stringify(data.roles));
                localStorage.setItem("email", data.email);
                localStorage.setItem("username", data.username);
                localStorage.setItem("firstName", data.firstName);
                localStorage.setItem("lastName", data.lastName);
                localStorage.setItem("token", data.token);
                localStorage.setItem("assignedZones", JSON.stringify(data.assignedZones));
                localStorage.setItem("branchId", data.idSede);
                console.log(this.context);
                this.context.setContext({
                    user: {
                        id: data.id,
                        loggedIn: true,
                        username: data.username,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        token: data.token,
                        email: data.email,
                        roles: data.roles,
                        assignedZones: data.assignedZones,
                        branchId: data.idSede
                    },
                });
            })
            .catch((e) => {
                switch (e.response) {
                    case 403:
                        this.setState({
                            loginStatus: {
                                slug: "wrong-pw",
                                text: "Password errata.",
                            },
                        });
                        break;
                    case 404:
                        this.setState({
                            loginStatus: {
                                slug: "wrong-user",
                                text: "Utente non trovato.",
                            },
                        });
                        break;
                    case 412:
                        this.setState({
                            loginStatus: {
                                slug: "brute-force",
                                text: "Sono stati effettuati troppi tentativi d'accesso.",
                            },
                        });
                        break;
                    default:
                        this.setState({
                            loginStatus: {
                                slug: "unknown-" + e.response,
                                text: `Errore sconosciuto (Response: ${e})`,
                            },
                        });
                        break;
                }
                this.setState({
                    shouldShake: true,
                });
            })
            .finally(() => {
                window.location.reload();
            });
    }

    render() {
        return (
            <React.Fragment>
                <Context.Consumer>
                    {(data) => {
                        if (data.user?.loggedIn) {
                            return <Navigate to="/home"/>;
                        } else {
                            return (
                                <div style={this.getClasses().root}>
                                    <Card
                                        outlined
                                        style={this.getClasses().card}
                                        className={
                                            this.state.shouldShake ? " animated shake fast" : ""
                                        }
                                    >
                                        <CardMedia
                                            style={this.getClasses().cardMedia}
                                            image={logoCircle}
                                            title="Kartiell Verona S.r.l."
                                        />
                                        <Typography variant="h5">Login</Typography>
                                        <strong className="text-danger">
                                            {this.state.loginStatus.text}
                                        </strong>
                                        <CardContent>
                                            <form
                                                onSubmit={this.handleSubmit}
                                                onChange={this.handleChange}
                                            >
                                                <FormGroup>
                                                    <TextField
                                                        label="Email"
                                                        className=""
                                                        type="email"
                                                        required
                                                        name="username"
                                                        autoComplete="username"
                                                        variant="outlined"
                                                        style={{marginBottom: this.context.theme.spacing(2)}}
                                                    />
                                                    <TextField
                                                        label="Password"
                                                        className="form-control input-lg"
                                                        type="password"
                                                        required
                                                        name="password"
                                                        autoComplete="current-password"
                                                        variant="outlined"
                                                        style={{marginBottom: this.context.theme.spacing(2)}}
                                                    />
                                                    <Button type="submit" variant='contained'>Entra</Button>
                                                </FormGroup>
                                            </form>
                                            <p className="card-text">
                                                <small className="text-muted">
                                                    Se hai problemi d'accesso richiedi supporto a{" "}
                                                    <a href="mailto:s.mancuso@kartiell.it">
                                                        s.mancuso@kartiell.it
                                                    </a>
                                                </small>
                                            </p>
                                        </CardContent>
                                    </Card>
                                </div>
                            );
                        }
                    }}
                </Context.Consumer>
            </React.Fragment>
        );
    }
}
