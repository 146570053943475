import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import ProfileCard from "../../components/ProfileCard";
import {
    AppBar,
    Backdrop,
    Checkbox,
    Chip,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    Paper,
    Radio,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import axios, {AxiosResponse} from "axios";
import Context from "../../context/Context";
import Constants from "../../context/Constants";
// @ts-ignore
// import SwipeableViews from 'react-swipeable-views';
// Interfaces
import Classificazione from "../../interfaces/prodotti/Classificazione";
import Categoria from "../../interfaces/prodotti/Categoria";
import Brand from "../../interfaces/prodotti/Brand";
import Tag from "../../interfaces/prodotti/Tag";
import NomeTipico from "../../interfaces/prodotti/NomeTipico";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

interface PanelProps {
    data: any[];
    rels?: number[]; //[[number, number]]
    onChange: (pId: number, checked: boolean) => void;
    searchString?: string;
    selectedId?: number;
}

interface PanelState {
}

const DefaultTagsSearchField: FunctionComponent<{ value: string; onChange: (arg0: string) => void }> = ({
                                                                                                            value,
                                                                                                            onChange
                                                                                                        }) => {
    return <TextField
        fullWidth
        size={'small'}
        variant={'outlined'}
        placeholder={'Cerca...'}
        value={value}
        onChange={e => onChange(e.target.value)}
        InputProps={{
            startAdornment: <InputAdornment position={"start"}>
                <FontAwesomeIcon icon={faSearch}/>
            </InputAdornment>
        }}
    />
}

class Panel extends React.Component<PanelProps, PanelState> {
    static height: number = 450;

    returnName(subject: PanelProps["data"]): string {
        // @ts-ignore
        return subject.nome;
    }

    getFilteredItems() {
        return this.props.data.filter(d => String(d.id + " " + this.returnName(d).toLowerCase()).search(this.props.searchString.toLowerCase()) !== -1);
    }

    render() {
        return <List dense style={{height: Panel.height}}>{this.getFilteredItems().map(d =>
            <ListItem key={d.id}>
                <ListItemIcon>
                    <Radio
                        checked={this.props.selectedId === (d.id)}
                        // checked={this.props.rels.includes(d.id)}
                        onChange={e => this.props.onChange(d.id as number, e.target.checked as boolean)}
                    />
                </ListItemIcon>
                <Chip size={'small'} label={d.id}/>
                <Typography variant={'body1'} style={{marginLeft: 8}}> {this.returnName(d)}</Typography>
            </ListItem>
        )}
        </List>;
    }
}

class BrandsPanel extends Panel {
}

class CategoriesPanel extends Panel {
    returnName(subject: PanelProps["data"]): string {
        // @ts-ignore
        return subject.percorso;
    }
}

class NomiTipiciPanel extends Panel {
}

class ClassesPanel extends Panel {
}

class TagsPanel extends Panel {

    render() {
        return <List dense style={{height: Panel.height}}>{this.getFilteredItems().map(d =>
            <ListItem key={d.id}>
                <ListItemIcon>
                    <Checkbox
                        checked={!!this.props.rels?.includes(d.id)}
                        // checked={this.props.rels.includes(d.id)}
                        onChange={e => this.props.onChange(d.id as number, e.target.checked as boolean)}
                    />
                </ListItemIcon>
                <Chip size={'small'} label={d.id}/>
                <Typography variant={'body1'} style={{marginLeft: 8}}> {this.returnName(d)}</Typography>
            </ListItem>
        )}
        </List>;
    }
}

function DefaultTags() {
    // Types
    type tActiveProp = {
        propType: 'brand' | 'categoria' | 'classificazione' | 'nomeTipico';
        id: number;
    };
    type tRelationship = {
        propType: tActiveProp['propType'];
        idProp: number;
        idTags: number[];
    };

    // Vars
    const context = useContext(Context);

    //States
    const [brands, setBrands] = useState<Brand[]>([]);
    const [categories, setCategories] = useState<Categoria[]>([]);
    const [classificazioni, setClassificazioni] = useState<Classificazione[]>([]);
    const [nomiTipici, setNomiTipici] = useState<NomeTipico[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [searchTags, setSearchTags] = useState<string>("");
    const [searchProps, setSearchProps] = useState<string>("");
    const [activeProp, setActiveProp] = useState<tActiveProp>({
        propType: "brand",
        id: null
    });
    const [relationships, setRelationships] = useState<tRelationship>(null);

    //AJAX
    const [fetchingRelationships, setFetchingRelationships] = useState<boolean>(false);
    // const [categoriesRel, setCategoriesRel] = useState<any[]>([]);
    // const [classificazioniRel, setClassificazioniRel] = useState<any[]>([]);
    // const [nomiTipiciRel, setNomiTipiciRel] = useState<any[]>([]);

    //Functions
    const getAllBrands = () => new Promise<Brand[]>(resolve => axios.get(`${Constants.paths.ajaxBasePath}prodotti/brands/?token=${context.user.token}`).then(resp => resolve(resp.data)));
    const getAllCategories = () => new Promise<Categoria[]>(resolve => axios.get(`${Constants.paths.ajaxBasePath}prodotti/categorie/?token=${context.user.token}`).then(resp => resolve(resp.data)));
    const getAllClasses = () => new Promise<Classificazione[]>(resolve => axios.get(`${Constants.paths.ajaxBasePath}prodotti/classificazioni/?token=${context.user.token}`).then(resp => resolve(resp.data)));
    const getAllNomiTipici = () => new Promise<NomeTipico[]>(resolve => axios.get(`${Constants.paths.ajaxBasePath}prodotti/nomi-tipici/?token=${context.user.token}`).then(resp => resolve(resp.data)));
    const getAllTags = () => new Promise<Tag[]>(resolve => axios.get(`${Constants.paths.ajaxBasePath}prodotti/tags/?all&token=${context.user.token}`).then(resp => resolve(resp.data)));
    const getRelationships = (prop: string, propId: number, mode: "fromProp" | "fromTag" = "fromProp") => new Promise<tRelationship>(resolve => {
        setFetchingRelationships(true);
        axios.get(`${Constants.paths.ajaxBasePath}prodotti/tags/relazioni/?mode=${mode}&propIds=${propId}&prop=${prop}&token=${context.user.token}`).then(resp => {
            setFetchingRelationships(false);
            resolve(resp.data?.[propId])
        });
    });

    //Style
    const paperStyle = {
        padding: context.theme.spacing(2)
    };

    //On Mount
    useEffect(() => { //Al montaggio
        getAllBrands().then(d => setBrands(d));
        getAllTags().then(d => setTags(d));
        getAllCategories().then(d => setCategories(d));
        getAllNomiTipici().then(d => setNomiTipici(d));
        getAllClasses().then(d => setClassificazioni(d));

    }, []);

    useEffect(() => { //Aggiorna le relazioni
        if (activeProp.id && activeProp.propType)
            getRelationships(activeProp.propType, activeProp.id).then((d: any) => setRelationships({
                propType: activeProp.propType,
                idProp: activeProp.id,
                idTags: d as number[]
            }));
        else {
            setRelationships(null);
        }
        return () => {
            setRelationships(null);
        };
    }, [activeProp]);


    const handlePropTabIndexChange = (index: tActiveProp["propType"]): void => {
        const thisId: number = 0; //Resetta la prop selezionata
        setActiveProp({propType: index, id: thisId});
    };

    const handlePropSelect = (id: number) =>
        setActiveProp(prevState => ({
            ...prevState,
            id: id
        }));

    const handleRelationshipChange = async (
        propType: tActiveProp["propType"],
        propId: tActiveProp["id"],
        tagId: number,
        add: boolean
    ) => {
        let resp: AxiosResponse = null;
        setFetchingRelationships(true);
        if (add)
            resp = await axios.post(
                `${Constants.paths.ajaxBasePath}prodotti/tags/relazioni/`,
                {
                    token: context.user.token,
                    propId: propId,
                    propType: propType,
                    tagId: tagId
                }
            );
        else
            resp = await axios.delete(
                `${Constants.paths.ajaxBasePath}prodotti/tags/relazioni/`,
                {
                    data: {
                        token: context.user.token,
                        propId: propId,
                        propType: propType,
                        tagId: tagId
                    }
                }
            );
        if (resp.status === 200) {
            let newRels: any = await getRelationships(propType, propId, "fromProp");
            setRelationships({
                propType: propType,
                idProp: propId,
                idTags: newRels as number[]
            });
            return true;
        }
        setFetchingRelationships(false);
        return false;
    };

    return <>
        <ProfileCard/>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper style={paperStyle}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={'h5'}>Proprietà</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DefaultTagsSearchField value={searchProps} onChange={v => setSearchProps(v)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AppBar position={"static"} color={'default'}
                                    style={{borderRadius: context.theme.shape.borderRadius, overflow: "hidden"}}
                                    variant={'outlined'}>
                                <Tabs value={activeProp?.propType || 'brand'}
                                      onChange={(e, v) => handlePropTabIndexChange(v)}
                                      variant={'fullWidth'}>
                                    <Tab value='brand' label="Brands"/>
                                    <Tab value='categoria' label="Categoire"/>
                                    <Tab value='classificazione' label="Classificazione"/>
                                    <Tab value='nomeTipico' label="Nomi tipici"/>
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12} style={{overflowY: 'scroll'}}>
                            {
                                activeProp.propType === 'brand'
                                    ? <BrandsPanel data={brands}
                                                   selectedId={activeProp.id}
                                                   onChange={(pId: number, chkd: boolean) => handlePropSelect(pId)}
                                                   searchString={searchProps}
                                    />
                                    : activeProp.propType === 'categoria' ?
                                        <CategoriesPanel data={categories}
                                                         selectedId={activeProp.id}
                                                         onChange={(pId: number, chkd: boolean) => handlePropSelect(pId)}
                                                         searchString={searchProps}
                                        />
                                        : activeProp.propType === 'classificazione' ?
                                            <ClassesPanel data={classificazioni}
                                                          selectedId={activeProp.id}
                                                          onChange={(pId: number, chkd: boolean) => handlePropSelect(pId)}
                                                          searchString={searchProps}
                                            />
                                            : activeProp.propType === 'nomeTipico' ?
                                                <NomiTipiciPanel data={nomiTipici}
                                                                 selectedId={activeProp.id}
                                                                 onChange={(pId: number, chkd: boolean) => handlePropSelect(pId)}
                                                                 searchString={searchProps}
                                                />
                                                : <></>
                            }

                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper style={paperStyle}>
                    <Grid container spacing={2} style={{position: "relative"}}>
                        {/*{fetchingRelationships&&<LinearProgress style={{width:'100%'}} />}*/}
                        <Grid item xs={12}>
                            <Typography variant={'h5'}>Tags</Typography>
                        </Grid>
                        <Backdrop open={fetchingRelationships || !(activeProp.propType && activeProp.id)} style={{
                            zIndex: 1,
                            position: "absolute"

                        }}>

                            {!(activeProp.propType && activeProp.id) &&
                                <Typography variant={'h4'}>Seleziona una proprietà</Typography>}
                        </Backdrop>
                        <Grid item xs={12}>
                            <DefaultTagsSearchField value={searchTags} onChange={v => setSearchTags(v)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AppBar position={"static"} color={'default'}
                                    style={{
                                        borderRadius: context.theme.shape.borderRadius,
                                        overflow: "hidden"
                                    }}
                                    variant={'outlined'}>
                                <Tabs value={'tag'}
                                    // onChange={(e, v) => handlePropTabIndexChange(v)}
                                      variant={'fullWidth'}>
                                    <Tab value='tag' label="Tags"/>
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12} style={{overflowY: 'scroll'}}>

                            <TagsPanel
                                data={tags}
                                rels={relationships?.idTags}
                                onChange={
                                    (tId, checked) => handleRelationshipChange(activeProp.propType,
                                        activeProp.id,
                                        tId,
                                        checked)
                                }
                                searchString={searchTags}
                            />

                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    </>
}

export default DefaultTags;