export function assertNumber(value: unknown, message: string = ""): asserts value is number {
    if (typeof value !== 'number') {
        console.error('Value must be a number! ' + message);
        throw 'Value must be a number! ' + message;
    }
}

export function assertString(value: unknown, message: string = ""): asserts value is string {
    if (typeof value !== 'string') {
        console.error('Value must be a string! ' + message);
        throw 'Value must be a string! ' + message;
    }
}