import React from "react";
import {v4 as uuidv4} from "uuid";
// import {it} from "date-fns/locale";
import it from "moment/locale/it";
import {
    DatePicker as DefDatePicker,
    DateTimePicker as DefDateTimePicker,
    LocalizationProvider,
    TimePicker as DefTimePicker,
} from "@mui/x-date-pickers";
import {Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
// import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.id = uuidv4();
    }

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'it'}>
                <DefDatePicker
                    id={"date-picker-dialog-" + this.id}
                    label={this.props.inputProps.label || ""}
                    inputFormat="DD/MM/YYYY"
                    format={"DD/MM/YYYY"}
                    localeText={it}
                    value={this.props.inputProps.value}
                    onChange={this.props.inputProps.onChange}
                    sx={
                        {
                            width: '100%',
                            // this.props?.sx,
                        }
                    }
                    renderInput={(params) => <TextField
                        {...params}
                        fullWidth={this.props.fullWidth || false}

                    />}
                />
            </LocalizationProvider>
        );
    }
}

class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.id = uuidv4();
    }

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DefDateTimePicker
                    id={"datetime-picker-dialog-" + this.id}
                    label={this.props.inputProps.label || ""}
                    format="DD/MM/YYYY HH:ii"
                    value={this.props.inputProps.value}
                    onChange={this.props.inputProps.onChange}
                    fullWidth={this.props.fullWidth || false}
                    // orientation="landscape"
                    // cancelLabel="Annulla"
                    // showTodayButton={true}
                    // todayLabel="Oggi"
                    // clearable={this.props.inputProps.clearable || true}
                    // clearLabel={<span className="text-danger">Svuota</span>}
                    // allowKeyboardControl={
                    //     this.props.inputProps.allowKeyboardControl || false
                    // }
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        );
    }
}

class TimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.id = uuidv4();
    }

    stringToDate(str) {
        const patt = new RegExp("^[0-9]{2}:[0-9]{2}|[0-9]{2}:[0-9]{2}:[0-9]{2}$"); //Controlla se la stringa è ancora da convertire

        if (patt.test(str)) {
            const date = new Date();
            return date.getFullYear() + date.getMonth() + date.getDay() + " " + str;
        } else {
            return str;
        }
    }

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DefTimePicker
                    //format="HH:II"
                    ampm={false}
                    id={"time-picker-dialog-" + this.id}
                    label={this.props.inputProps.label || ""}
                    value={this.props.inputProps.value}
                    minutesStep={this.props.minutesStep || 5}
                    onChange={this.props.inputProps.onChange}
                    cancelLabel="Annulla"
                    clearable={this.props.inputProps.clearable || true}
                    clearLabel={<span className="text-danger">Svuota</span>}
                    allowKeyboardControl={
                        this.props.inputProps.allowKeyboardControl || false
                    }
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        );
    }
}

class TimeRangePicker extends React.Component {
    //! WARNING: Lo sviluppo è stato interrotto a favore del select nativo di material-table
    constructor(props) {
        super(props);
        this.id = uuidv4();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeHours = this.handleChangeHours.bind(this);
        this.handleChangeMinutes = this.handleChangeMinutes.bind(this);
        this.availableHours = this.getAvailableHours(
            this.props.hoursStep || undefined
        );
        this.availableMinutes = this.getAvailableMinutes(
            this.props.minutesStep || undefined
        );
        this.actualValue = "00:00";
        this.state = {value: this.props.inputProps.value};
    }

    getAvailableHours(step = 1) {
        let availH = [];
        for (let i = 0; i < 24; i += step) {
            availH.push(i.toString().padStart(2, "0"));
        }
        return availH;
    }

    getAvailableMinutes(step = 15) {
        let availM = [];
        for (let i = 0; i < 60; i += step) {
            availM.push(i.toString().padStart(2, "0"));
        }
        return availM;
    }

    componentDidMount() {
        this.setState({
            value: this.props.inputProps.value
        });
    }

    handleChangeHours(e) {
        this.handleChange(e, "hours");
    }

    handleChangeMinutes(e) {
        this.handleChange(e, "minutes");
    }

    handleChange(e, which = "minutes") {
        let toEmit = {
            minutes: this.state.value
                .toString()
                .substring(0, 2)
                .padStart(2, "0"),
            hours: this.state.value
                .toString()
                .substring(3, 5)
                .padStart(2, "0")
        };
        if (which == "minutes") {
            toEmit.minutes = e.target.value;
        } else {
            toEmit.hours = e.target.value;
        }
        let newValue = toEmit.hours + ":" + toEmit.minutes;
        this.setState({value: newValue});
        e.target.value = newValue;
        this.props.inputProps.onChange(e);
    }

    render() {
        return (
            <React.Fragment>
                <FormControl>
                    <InputLabel id={"time-range-picker-hours-label-" + this.id}>
                        Ore
                    </InputLabel>
                    <Select
                        id={"time-range-picker-hours-" + this.id}
                        value={
                            this.state.value
                                .toString()
                                .substring(0, 2)
                                .padStart(2, "0") || "00"
                        }
                        onChange={this.handleChangeHours}
                    >
                        {this.availableHours.map((m, i) => {
                            return (
                                <MenuItem key={i} value={m}>
                                    {m}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id={"time-range-picker-minutes-label-" + this.id}>
                        Minuti
                    </InputLabel>
                    <Select
                        id={"time-range-picker-minutes-" + this.id}
                        value={
                            this.state.value
                                .toString()
                                .substring(3, 5)
                                .padStart(2, "0") || "00"
                        }
                        onChange={this.handleChangeMinutes}
                    >
                        {this.availableMinutes.map((m, i) => {
                            return (
                                <MenuItem key={i} value={m}>
                                    {m}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }
}

function DatePresetButtons({
                               onStartDateChange,
                               onEndDateChange
                           }) {
    // const context = useContext(Context);
    return <ButtonGroup fullWidth>
        <Button
            onClick={e => {
                onStartDateChange(moment());
                onEndDateChange(moment());
            }}
        >
            Oggi
        </Button>
        <Button
            onClick={e => {
                onStartDateChange(moment().subtract(1, 'days'));
                onEndDateChange(moment().subtract(1, 'days'));
            }}
        >
            Ieri
        </Button>
        <Button onClick={e => {
            onStartDateChange(moment().startOf('week'));
            onEndDateChange(moment());
        }}>
            Settimana
        </Button>
        <Button onClick={e => {
            onStartDateChange(moment().startOf('month'));
            onEndDateChange(moment());
        }}>
            Mese
        </Button>
        <Button onClick={e => {
            onStartDateChange(moment().startOf('year'));
            onEndDateChange(moment());
        }}>
            Anno
        </Button>
    </ButtonGroup>
}

export {DatePicker, DateTimePicker, TimePicker, TimeRangePicker, DatePresetButtons};
