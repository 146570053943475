import React, {useContext, useEffect, useMemo, useState} from "react";
import ProfileCard from "../components/ProfileCard";
import Context from "../context/Context";
import {
    Button,
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    Grid,
    Grow,
    Paper,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {ResponsivePie} from "@nivo/pie";
import {DatePicker, DatePresetButtons} from "../components/DatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIndustry, faUsers, faUserTie} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Constants from "../context/Constants";
import {ResponsiveBar} from "@nivo/bar";
import moment from "moment";
import {paramsFromArray} from "../functions/axiosSupport";
import {ResponsiveLine} from "@nivo/line";
import formatPrice from "../functions/formatPrice";
import useBreakpoints from "../functions/breakpoints";

const colorsByAgents = {
    "KartoClick eCommerce": '#FFC42F',
    "Antonino Siclari": '#4868d8',
    "Francesco Marchese": '#607D8B',
    "Sebastiano Crinò": '#E64A19',
    "Cash Rende": '#27ad8d',
    "Cash Feroleto": '#6bb22f',
    "Carlo Stabile": '#CDDC39',
    "Giuseppe Miriello": "#825e20",
};

function ChartTooltip({t1, t2, t3}) {
    const [isIn, setIsIn] = useState(false);
    useEffect(() => {
        setIsIn(true);
        return () => setIsIn(false);
    }, [t1, t2, t3]);

    return <Grow duration={50} direction={'up'} in={isIn} sx={{transform: 'translate(-50%,-120%)'}}>
        <Card elevation={10} sx={{position: 'absolute', minWidth: 250}}>
            <CardContent>
                <Typography align={'right'} variant={'overline'}>{t1}</Typography>
                <Typography align={'right'} fontSize={16} variant={'h2'}>{t2}</Typography>
                <Typography align={'right'} fontSize={18} variant={'h6'}>{t3}</Typography>
            </CardContent>
        </Card>
    </Grow>
}

class PieChart extends React.Component {
    static contextType = Context;
    static apiDateFormat = 'YYYY-MM-DD';

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            data: [],
            defaultOptions: {},
            compareWithOthers: false
        }
    }

    componentDidMount() {
        // this.getData();
        this.setState({
            defaultOptions: {
                fillDirection: 'down',
                margin: {
                    top: 100,
                    // right: 0,
                    bottom: 100,
                    left: -300
                },
                colors: {
                    scheme: 'blues'
                },
                borderColor: {
                    from: 'color',
                    modifiers: [
                        ['darker', .6],
                        ['opacity', .5]
                    ]
                },
                innerRadius: 0.6,
                padAngle: 0.7,
                cornerRadius: 3,
                sortByValue: true,
                activeOuterRadiusOffset: 15,
                borderWidth: 1,
                valueFormat: (n) => formatPrice(n),
                arcLinkLabel: e => `${e.label}`,
                tooltip: (e) => <ChartTooltip t1={e.datum.id} t2={e.datum.label} t3={e.datum.formattedValue}/>,
                enableArcLinkLabels: false,
                arcLinkLabelsSkipAngle: 7,
                arcLinkLabelsTextColor: this.context.theme.palette.text.primary,
                arcLinkLabelsThickness: 2,
                arcLinkLabelsColor:
                    {
                        from: 'color'
                    },
                arcLabelsSkipAngle: 10,
                arcLabelsTextColor: {
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                },
                defs: [
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type:
                            'patternLines',
                        background:
                            'inherit',
                        color:
                            'rgba(255, 255, 255, 0.3)',
                        rotation:
                            -45,
                        lineWidth:
                            6,
                        spacing:
                            10
                    }
                ],
                legends: [
                    {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: -200,
                        translateY: 15,
                        itemsSpacing: 4,
                        itemWidth: 120,
                        itemHeight: 15,
                        itemTextColor: this.context.theme.palette.text.primary,
                        itemDirection: 'left-to-right',
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateCounter !== this.props.updateCounter) {
            this.getData().then();
        }
    }

//this.context.ui.dark ? this.context.theme.black : this.context.theme.white
    async getData() {
    }

    render() {
        return <Card sx={{width: this.props.width, overflow: 'visible'}}>
            <CardContent sx={{height: this.props.height, overflow: 'visible'}}>
                <Typography variant={"h6"}>{this.props.title}</Typography>
                <FormControlLabel sx={{marginLeft: 0}}
                                  control={<Switch/>}
                                  label={'Confronto con altri'}
                                  value={this.state.compareWithOthers}
                                  onChange={(e, b) => this.setState({compareWithOthers: b})}/>
                <ResponsivePie
                    {...this.state.defaultOptions}
                    endAngle={this.props.display === 'full' ? 360 : 180}
                    height={this.props.height}
                    data={this.state.compareWithOthers ? this.state?.data : this.state.data.filter(d => d.id !== 'other')}
                    sx={{overflow: 'visible'}}
                />
            </CardContent>

        </Card>
    }
}

PieChart.defaultProps = {
    height: 100,
    width: '100%',
    defaultOptions: {},
    loading: false,
    title: 'Pie Chart',
    updateCounter: 0,
    display: 'full'
}

class RadialBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            data: [],
            defaultOptions: {}
        }
    }
}

RadialBarChart.defaultProps = {
    height: 100,
    width: '100%',
    defaultOptions: {},
    loading: false,
    title: 'Pie Chart',
    updateCounter: 0
}

class LineChart extends React.Component {
    static apiDateFormat = 'YYYY-MM-DD';
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            data: [],
            defaultOptions: {}
        }
    }

    componentDidMount() {
        this.setState({
            defaultOptions: {
                curve: 'monotoneX',
                enableArea: true,
                colors: (data) => colorsByAgents[data.id],
                lineWidth: 3,
                activeLineWidth: 6,
                inactiveLineWidth: 3,
                inactiveOpacity: 0.15,
                pointSize: 11,
                activePointSize: 16,
                inactivePointSize: 2,
                interactive: true,
                enablePoints: true,
                enablePointLabel: false,
                pointBorderWidth: 3,
                activePointBorderWidth: 3,
                pointBorderColor:
                    {
                        from: 'serie.color'
                    },
                xScale: {type: 'point'},
                xFormat: v => `${v}`,
                yFormat: v => formatPrice(v),
                // yScale: {
                //     type: 'point',
                //     min: 'auto',
                //     max: 'auto',
                //     stacked: true,
                //     reverse: false
                // },
                useMesh: true,
                axisTop:
                    {
                        tickSize: 5,
                        tickPadding:
                            5,
                        tickRotation:
                            0,
                        legend:
                            '',
                        legendPosition:
                            'middle',
                        legendOffset:
                            -36
                    },

                axisBottom: null,

                axisLeft:
                    {
                        tickSize: 5,
                        tickPadding:
                            5,
                        tickRotation:
                            0,
                        legend:
                            '',
                        legendPosition:
                            'middle',
                        legendOffset:
                            -40
                    },

                margin: {

                    top: 40,
                    right: 150,
                    bottom: 40,
                    left: 60

                },
                axisRight: null,
                theme: {
                    axis: {
                        ticks: {
                            text: {
                                fill: this.context.theme.palette.text.primary
                            }
                        },
                        legend: {
                            text: {
                                fill: this.context.theme.palette.text.primary
                            }
                        }
                    },
                    legends: {
                        text: {
                            fill: this.context.theme.palette.text.primary
                        },
                        title: {
                            text: {
                                fill: this.context.theme.palette.text.primary
                            }
                        }
                    }
                },
                legends: [
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 170,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 150,
                        itemHeight: 20,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ],
                tooltip: (v) => <ChartTooltip t1={v.point.serieId} t2={v.point.data.xFormatted}
                                              t3={v.point.data.yFormatted}/>,
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateCounter !== this.props.updateCounter) {
            this.getData().then();
        }
    }

    async getData() {
    }

    render() {
        return <Card style={{width: this.props.width}} sx={{overflow: 'visible'}}>
            <CardContent style={{height: this.props.height}}>
                <Typography variant={"h6"}>{this.props.title}</Typography>
                <ResponsiveLine
                    {...this.state.defaultOptions}
                    // width={width}
                    height={this.props.height}
                    sortByValue
                    data={
                        this.state?.data
                    }
                />
            </CardContent>
        </Card>
    }
}

LineChart.defaultProps = {
    height: 100,
    width: '100%',
    defaultOptions: {},
    loading: false,
    title: 'Line Chart',
    interval: 'monthly',
    updateCounter: 0
}

class BarChart extends React.Component {
    static apiDateFormat = 'YYYY-MM-DD';
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            data: [],
            defaultOptions: {}
        }
    }

    componentDidMount() {
        this.setState({
            defaultOptions: {
                valueFormat: (n) => formatPrice(n),
                sortByValue: true,
                fillDirection: 'down',
                colors: {scheme: 'blues'},
                theme: {
                    axis: {
                        ticks: {
                            text: {
                                fill: this.context.theme.palette.text.primary
                            }
                        }
                    }
                },
                colorBy: 'id',
                borderRadius: 2,
                borderWidth: 1,
                borderColor:
                    {
                        from: 'color',
                        modifiers:
                            [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                    },
                margin: {
                    top: 4,
                    right: 4,
                    bottom: 40,
                    left: 115
                },
                innerPadding: 2,
                labelSkipHeight: 40,
                labelSkipWidth: 40,
                tooltip: (v) => <ChartTooltip t1={v.indexValue} t2={v.id} t3={v.formattedValue}/>,
                // legends:
                //     [
                //         {
                //             dataFrom: 'keys',
                //             anchor: 'bottom-right',
                //             direction: 'column',
                //             justify: false,
                //             translateX: 1000,
                //             translateY: 0,
                //             itemWidth: 400,
                //             itemHeight: 20,
                //             itemsSpacing: 2,
                //             symbolSize: 5,
                //             itemDirection: 'left-to-right'
                //         }
                //     ]
            }
        });
        // this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateCounter !== this.props.updateCounter) {
            this.getData().then();
        }
    }

    async getData() {
    }

    render() {
        return <Card style={{width: this.props.width}} sx={{overflow: 'visible'}}>
            <CardContent style={{height: this.props.height}}>
                <Typography variant={"h6"}>{this.props.title}</Typography>
                <ResponsiveBar
                    {...this.state.defaultOptions}
                    height={this.props.height}
                    data={
                        this.state?.data
                    }
                    layout={'horizontal'}
                    theme={this.state.defaultOptions.theme}
                    keys={this.state.keys}
                    indexBy={this.state.indexBy}
                />
            </CardContent>
        </Card>
    }
}

BarChart.defaultProps = {
    dateStart: moment(),
    dateEnd: moment(),
    updateCounter: 0,
    topXCustomers: 10
}


class ChartAgentiConfrontoGlobale
    extends PieChart {

}

class ChartClientiConfrontoGlobale extends PieChart {
    async getData() {
        try {
            const dateStart = this.props.dateStart.format(PieChart.apiDateFormat);
            const dateEnd = this.props.dateEnd.format(PieChart.apiDateFormat);
            const params = [
                ["token", this.context.user.token],
                ["dateStart", dateStart],
                ["dateEnd", dateEnd],
                ["totals", true],
                ["interval", this.props.interval],
                ['withCustomers', true],
                ["topXCustomers", this.props.topXCustomers]
            ];
            const paramsString = paramsFromArray(params);
            let res = await axios.get(
                `${Constants.paths.ajaxBasePath}statistiche/fatturato/clienti/?${paramsString}`);
            let sortedData = res.data.sort((a, b) => (b['totals']?.['net'] || 0) - a['totals']?.['net'] || 0);
            let formattedData = sortedData.map(d => {
                return {
                    'id': d['customer']?.['id'] || 'sconosciuto',
                    'label': d['customer']?.['ragione_sociale'] || 'Sconosciuto',
                    'value': d['totals']?.['net'] || 0
                }
            });

            this.setState({data: formattedData});
        } catch (e) {
            console.error(e);
        }
    }
}

class ChartClientiConfrontoIntervalli extends LineChart {

    async getData() {
        try {
            const dateStart = moment(this.props.dateStart).format(LineChart.apiDateFormat)
            let res = await axios.get(
                `${Constants.paths.ajaxBasePath}statistiche/fatturato/clienti/\
                ?token=${this.context.user.token}\
                &dateStart=${this.props.dateStart}\
                &dateEnd=${this.props.dateEnd}\
                &totals\
                &interval=${this.props.interval}\
                `);
            let sortedData = res.data;
            let formattedData = sortedData.map(d => {
                return {
                    'id': d['customer']['ragione_sociale'],
                    'data': [
                        ...d['docs_intervals'].map(di => ({
                            // y: parseInt(di['interval'].replace(/([0-9]{2})-(.*)/, '$1')),
                            y: di['interval'],
                            x: parseFloat(di['docs_totals']['net'])
                        }))
                    ]
                }
            });
            this.setState({data: formattedData});
        } catch (e) {
            console.error(e);
        }
    }
}

class ChartAgentiFetteCliente extends BarChart {
    componentDidMount() {
        this.setState({indexBy: 'agente', keys: []})
        super.componentDidMount();
    }

    async getData() {
        try {
            const dateStart = this.props.dateStart?.format(BarChart.apiDateFormat);
            const dateEnd = this.props.dateEnd?.format(BarChart.apiDateFormat);
            const params = [
                ["token", this.context.user.token],
                ["dateStart", dateStart],
                ["dateEnd", dateEnd],
                ["totals", true],
                ["interval", this.props.interval],
                ['withCustomers', true],
                ["topXCustomers", this.props.topXCustomers]
            ];
            const paramsString = paramsFromArray(params);
            let res = await axios.get(
                `${Constants.paths.ajaxBasePath}statistiche/fatturato/agenti/?${paramsString}`);
            let allKeys = new Set();
            let formattedData = res.data.map(agent => {
                    let thisEntry = {};
                    thisEntry['agente'] = `${agent['agent']['first_name']} ${agent['agent']['last_name']}`;
                    for (const customer of agent['docs_customers']) {
                        if (customer['customer']) {
                            thisEntry[customer['customer']['ragione_sociale']] = customer['totals']['net'];
                            allKeys.add(customer['customer']['ragione_sociale']);
                        } else {
                            console.warn("Cliente non presente nel DB: ", customer);
                            thisEntry['unknown'] = customer['totals']?.['net'] || 0;
                            allKeys.add('unknown');
                        }
                    }
                    return thisEntry;
                }
            );

            // //Riordina
            // console.log(formattedData);
            // formattedData = formattedData.map((fd) => fd.sort((a, b) => a - b));
            // console.log(formattedData);

            this.setState({data: formattedData, keys: Array.from(allKeys)});
        } catch (e) {
            console.error(e);
        }
    }
}

class ChartAgentiConfrontoIntervalli extends LineChart {
    async getData() {
        try {
            const startDate = this.props.dateStart?.format(LineChart.apiDateFormat);
            const dateEnd = this.props.dateEnd?.format(LineChart.apiDateFormat);
            const params = [
                ["token", this.context.user.token],
                ["dateStart", startDate],
                ["dateEnd", dateEnd],
                ["totals", true],
                ["interval", this.props.interval]
            ];
            const paramsString = paramsFromArray(params);
            let res = await axios.get(
                `${Constants.paths.ajaxBasePath}statistiche/fatturato/agenti/?${paramsString}`);
            let allKeys = new Set();
            let formattedData = res.data?.map(agent => {
                    let thisEntry = {};
                    thisEntry['id'] = `${agent['agent']['first_name']} ${agent['agent']['last_name']}`;
                    thisEntry['data'] = agent['totals_intervals']?.map(ti => ({x: ti.interval, y: ti.totals?.net}))
                    return thisEntry;
                }
            );

            // console.log("ChartAgentiConfrontoIntervalli: ", formattedData);
            this.setState({data: formattedData, keys: Array.from(allKeys)});
        } catch (e) {
            console.error(e);
        }
    }
}


export default function Home() {
    //region State
    const context = useContext(Context);
    const matches = useBreakpoints();
    const [interval, setInterval] = useState('weekly');
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [graphs, setGraphs] = useState([]);
    const [updateCounter, setUpdateCounter] = useState(0);
    const [views, setViews] = useState([
            {
                key: 'agenti',
                name: 'Agenti',
                icon: <FontAwesomeIcon icon={faUserTie}/>,
                charts: []
            },
            {
                key: 'sedi',
                name: 'Sedi',
                icon: <FontAwesomeIcon icon={faIndustry}/>
            },
            {
                key: 'clienti',
                name: 'Clienti',
                icon: <FontAwesomeIcon icon={faUsers}/>
            }
        ]
    );
    const [activeView, setActiveView] = useState('agenti');
    const [fetching, setFetching] = useState(false);
    const viewPermissions = useMemo(
        () => {
            /*
            - report_agenti
            - report_fatturato
            - report_confronto_agenti
             */
            let canView = new Set();
            if (context.user.roles.includes('admin') || context.user.roles.includes('commerciale'))
                canView.add('report_agenti')
                    .add('report_fatturato')
                    .add('report_confronto_agenti');
            if (context.user.roles.includes('agente')) canView.add('report_agenti');
            return canView;
        }, [context.user.roles]);

    const getGraphHeight = useMemo(() => {
        if (!matches.sm) return 400;
        if (!matches.md) return 450;
        if (!matches.lg) return 550;
        if (!matches.xl) return 600;
        return 600;
    }, [
        matches.sm,
        matches.md,
        matches.lg,
        matches.xl,
        matches.xxl
    ]);

    const getGraphHeightXl = useMemo(() => {
        if (!matches.sm) return 450;
        if (!matches.md) return 500;
        if (!matches.lg) return 600;
        if (!matches.xl) return 650;
        return 700;
    }, [
        matches.sm,
        matches.md,
        matches.lg,
        matches.xl,
        matches.xxl
    ]);
//endregion

//region Events
    const handleViewChange = (key) => {
        if (!key) return;
        setActiveView(key);
    }
//endregion

//region Hooks
    useEffect(() => {
        setGraphs([ChartAgentiConfrontoGlobale]);
        setGraphs(prev => [...prev, ...prev, ...prev, ...prev, ...prev].flat());
    }, []);
//endregion

//region Event handlers

//endregion
//region Render
    return <>
        <ProfileCard/>
        <Grid container style={{placeContent: 'center'}}>
            <Grid item>
                <Paper sx={{
                    overflow: 'visible',
                    maxWidth: 'auto',
                    padding: context.theme.spacing(3),
                    marginBottom: context.theme.spacing(3)
                }}>
                    <Typography variant={'h4'} align={'center'}>Configurazione</Typography>

                    <FormControl style={{width: '100%', alignItems: 'center'}}>
                        {/*<Typography variant={'overline'}>*/}
                        {/*    Grafici*/}
                        {/*</Typography>*/}

                        {/*<Tabs value={activeView} onChange={(e, v) => handleViewChange(v)}>*/}
                        {/*    {views.map(v => <Tab value={v.key} icon={v.icon} label={v.name}/>)}*/}
                        {/*</Tabs>*/}
                        <Typography variant={'overline'}>
                            Intervallo
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DatePicker fullWidth={true}
                                            inputProps={{
                                                value: dateStart,
                                                onChange: (d) => setDateStart(d),
                                                size: 'small',
                                                label: 'Da'
                                            }}/>
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker fullWidth={true}
                                            inputStyle={{width: '100%'}}
                                            inputProps={{
                                                value: dateEnd,
                                                onChange: (d) => setDateEnd(d),
                                                size: 'small', label: "A"
                                            }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <DatePresetButtons
                                    onStartDateChange={setDateStart}
                                    onEndDateChange={setDateEnd}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup fullWidth
                                                   exclusive
                                                   size={'small'}
                                                   value={interval}
                                                   onChange={(e, i) => setInterval(i)}>
                                    <ToggleButton value={'daily'}>Giornaliero</ToggleButton>
                                    <ToggleButton value={'weekly'}>Settimanale</ToggleButton>
                                    <ToggleButton value={'monthly'}>Mensile</ToggleButton>
                                    <ToggleButton value={'annualy'}>Annuale</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant={'contained'}
                                        onClick={() => setUpdateCounter((updateCounter + 1))}>
                                    {"Elabora"}
                                </Button>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid
                item
                sx={{overflow: 'visible'}}
                xs={12}
                lg={12}>
                <ChartAgentiConfrontoIntervalli
                    title={'Andamento'}
                    height={getGraphHeightXl}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    interval={interval}
                    updateCounter={updateCounter}
                    topXCustomers={10}
                />
            </Grid>
            {
                viewPermissions.has('report_confronto_agenti') &&
                <Grid
                    item
                    sx={{overflow: 'visible'}}
                    xs={12}
                    xl={6}>
                    <ChartAgentiFetteCliente
                        sx={{overflow: 'visible'}}
                        title={'Fette Clienti per Agente'}
                        height={getGraphHeightXl}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        interval={interval}
                        updateCounter={updateCounter}
                        topXCustomers={10}
                    />
                </Grid>
            }
            <Grid
                item
                xs={12}
                xl={viewPermissions.has('report_confronto_agenti') ? 6 : 12}
                sx={{overflow: 'visible'}}>
                <ChartClientiConfrontoGlobale
                    title={'Migliori clienti'}
                    loading={fetching}
                    height={getGraphHeightXl}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    interval={interval}
                    updateCounter={updateCounter}
                    topXCustomers={15}
                    display={
                        !matches.sm
                            ? 'half'
                            : !matches.md
                                ? 'half' //Prende mezza riga ed è su schermo grande
                                : !context.theme.breakpoints.down("xxl") && (viewPermissions.has('report_confronto_agenti'))
                                    ? 'half'
                                    : 'full'
                    }
                />
            </Grid>
        </Grid>
    </>
//endregion
}
