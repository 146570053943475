import React from 'react';
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";

interface NavigationAction {
    label: string,
    value: string,
    icon: SvgIconComponent
}

function Navigation(
    {
        actions,
        handleChange,
        active
    }:
    {
        actions: NavigationAction[],
        handleChange: (newVal: string) => void,
        active: string
    }
): any {
    return <Paper elevation={24} sx={{
        zIndex: 99,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
    }}>

        <BottomNavigation value={active} onChange={(_, newVal) => handleChange(newVal)}>
            {actions.map(act =>
                <BottomNavigationAction
                    label={act.label}
                    value={act.value}
                    icon={act.icon as any}
                />
            )}

        </BottomNavigation>
    </Paper>
}

export {Navigation};