import React from "react";
import {Link} from "react-router-dom";
import {
    Accordion,
    AccordionSummary,
    AppBar,
    Badge,
    Box,
    Button,
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Slide,
    Toolbar,
    Typography,
    useScrollTrigger,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faBars, faCaretLeft, faFlask, faUserCircle,} from "@fortawesome/free-solid-svg-icons";
import Context from "../context/Context";
import Routes from "../context/Routes";
import logo from "../media/k-odin.png";

const styles = {
    navbar: {
        color: "white !important",
    },
    button: {
        color: "#fff",
    },
    toolbar: {
        justifyContent: "space-between",
    },
    menuBox: {
        display: "inline-flex",
        alignItems: "center",
    },
};

function HideOnScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger();
    //Inserisci trigger nel prop in per riattivare la funzione
    return (
        <Slide appear={false} direction="down" in={true}>
            {children}
        </Slide>
    );
}

function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 8 : 0,
    });
}

class Sidebar extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.prepareRouteLinks = this.prepareRouteLinks.bind(this);
        this.state = {links: []};
    }

    componentDidMount() {
        this.listStyle = {
            paddingTop: this.context.theme.spacing(2),
            // paddingBottom: this.context.theme.spacing(2),
        };
        this.listItemStyle = {
            paddingLeft: this.context.theme.spacing(3),
            paddingRight: this.context.theme.spacing(3),
        };
        this.setState({links: this.prepareRouteLinks(Routes)});
    }

    prepareRouteLinks(r) {
        let idCount = 0;
        // let returnArray = [];
        const returnLink = l => {
            return <Link
                key={"link" + idCount}
                style={{textDecoration: "none", color: "inherit"}}
                to={l.link}
            >
                <ListItem
                    button
                    onClick={this.props.onClose}
                    style={{
                        ...this.listItemStyle,
                    }}
                >
                    <Badge
                        style={{alignItems: "center"}}
                        color="primary"
                        badgeContent={
                            l.beta ? (
                                <FontAwesomeIcon size="sm" icon={faFlask}/>
                            ) : undefined
                        }
                    >
                        <ListItemIcon>{l.icon}</ListItemIcon>
                        <ListItemText>{l.title}</ListItemText>
                    </Badge>
                </ListItem>
            </Link>
        }; //Gestisci il singolo link
        const returnNested = l => {
            return <ListItem style={{...this.listItemStyle}} key={"listItem-" + idCount}>
                <Accordion
                    key={idCount}
                    style={{
                        padding: 0,
                        margin: 0,
                        width: "100%",
                        boxShadow: "none",
                    }}>
                    <AccordionSummary
                        style={{
                            padding: 0,
                            margin: 0,
                            width: "100%",
                            boxShadow: "none",
                        }}
                        expandIcon={<FontAwesomeIcon icon={faAngleDown}/>}
                    >
                        <ListItemIcon style={{alignSelf: "center"}}>
                            {l.icon}
                        </ListItemIcon>
                        <ListItemText>{l.title}</ListItemText>
                    </AccordionSummary>
                    {l.children.map(c => returnLinks(c))}
                </Accordion>
            </ListItem>
        };
        const returnDivider = l => <Divider key={'divider-' + idCount}/>;
        const returnOverline = l => <Typography variant={'overline'} key={"overline-" + idCount}>{l.title}</Typography>
        const returnLinks = l => {
            idCount++;
            switch (l.type) {
                case 'link':
                    if (this.context.user.roles.includes('admin')
                        || (!l.beta && (l.role === "any" || this.context.user.roles.includes(l.role)))) {
                        return returnLink(l);
                    }
                    break;
                case 'nested':
                    if (this.context.user.roles.includes('admin')
                        || l.children.some(c => !c.beta && (c.role === "any" || this.context.user.roles.includes(c.role)))) {
                        return returnNested(l)
                    }
                    break;
                case 'divider':
                    return returnDivider(l);
                case 'overline':
                    return returnOverline(l);
                default:
                    console.error("Passato un link non valido nella Sidebar");
            }
        }; //Crea un loop di smistamento
        //console.log(Routes.map(r => returnLinks(r)));
        return Routes.map(r => returnLinks(r)); //Launch
    }

    render() {
        return (
            <Drawer open={this.props.open} onClose={this.props.onClose}>
                <div style={{minWidth: "20em"}}>
                    <Grid container align="middle">
                        <Grid
                            xs={16}
                            item
                            style={{
                                width: '100%',
                                alignSelf: "center",
                                marginTop: this.context.theme.spacing(2),
                                marginBottom: this.context.theme.spacing(2),
                            }}
                        >
                            <Typography variant="h6" align="center">
                                Menu
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider/>

                    <List style={{...this.listStyle}}>
                        {this.state.links}
                    </List>
                    <List>
                        <ListItem>
                            <Button
                                onClick={this.props.onClose}
                                variant="outlined"
                                style={{
                                    display: "flex",
                                    width: "100%",
                                }}
                                startIcon={<FontAwesomeIcon icon={faCaretLeft}/>}>
                                Chiudi
                            </Button>
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        );
    }
}

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.state = {
            sidebarOpen: false,
        };
    }

    toggleSidebar() {
        this.setState((prev) => ({
            sidebarOpen: !prev.sidebarOpen,
        }));
    }

    render() {
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <>
                        <ElevationScroll {...this.props}>
                            <AppBar
                                style={{
                                    minHeight: consumer_data.theme.spacing(6),
                                    maxHeight: consumer_data.theme.spacing(6),
                                    // backgroundColor: consumer_data.ui.dark
                                    //     ? consumer_data.theme.palette.grey["900"]
                                    //     : consumer_data.theme.palette.secondary.dark,
                                }}
                            >
                                <Toolbar
                                    style={{
                                        ...styles.toolbar,
                                        // minHeight: consumer_data.theme.spacing(5),
                                        // maxHeight: consumer_data.theme.spacing(5),
                                    }}
                                >
                                    <Box style={styles.menuBox}>
                                        <IconButton
                                            edge="start"
                                            className=""
                                            color="inherit"
                                            aria-label="menu"
                                            onClick={this.toggleSidebar}
                                            size="large">
                                            <FontAwesomeIcon icon={faBars}/>
                                        </IconButton>
                                        <img
                                            src={logo}
                                            style={{
                                                marginLeft: consumer_data.theme.spacing(1),
                                                maxHeight: 30,
                                            }}
                                        />
                                    </Box>
                                    <Box style={styles.userBox}>
                                        <Link to="/profilo">
                                            <Button
                                                style={styles.button}
                                                size="small"
                                                variant="text"
                                                startIcon={<FontAwesomeIcon icon={faUserCircle}/>}
                                            >
                                                {consumer_data.user.username || "Login"}
                                            </Button>
                                        </Link>
                                    </Box>
                                </Toolbar>
                            </AppBar>
                        </ElevationScroll>
                        <Toolbar/>
                        <Sidebar
                            open={this.state.sidebarOpen}
                            onClose={this.toggleSidebar}
                        />
                    </>
                )}
            </Context.Consumer>
        );
    }
}
