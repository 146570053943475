import Constants from "../context/Constants";

import {Prodotto} from "../interfaces/prodotti/Prodotto";

function getPricesNr(productObj: Prodotto) {
    let toReturn = 0;
    Constants.chiaviListini.forEach((l, i) => {
        if (parseFloat(productObj[l as keyof Prodotto] as string) > 0) {
            if (Constants.visibilitaAgentiListini.includes(l)) toReturn++; //Conta solo listini che dovrebbero essere visibili
        }
    });
    if (toReturn === 0) if (productObj.prezzo_x > 0) toReturn = 1;
    return toReturn;
}

export default getPricesNr;
