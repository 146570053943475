import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import {faBarcode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Context from "../context/Context";
import {SlideUpTransition} from "./Transitions";
import axios from "axios";
import Constants from "../context/Constants";

class UfCodSender extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.sendData = this.sendData.bind(this);
        this.state = {
            newCod: "",
            fetching: false,
        };
    }

    sendData() {
        this.setState({fetching: true}, () => {
            const toPost = JSON.stringify({
                token: this.context.user.token,
                newUfCod: this.props.actualCod.replace("…", "") + this.state.newCod,
                prodId: this.props.prodId,
            });
            axios
                .post(
                    Constants.paths.ajaxBasePath + "prodotti/utils/user-uf-codes/",
                    toPost
                )
                .then((resp) => {
                    this.props.onClose();
                    this.props.enqueueSnackbar("Codice inviato con successo!", {
                        variant: "success",
                    });
                    this.props.setNewCode(
                        this.props.actualCod.replace("…", "") + this.state.newCod
                    );
                })
                .catch((err) => {
                    alert(err);
                })
                .finally(() => this.setState({fetching: false}));
        });
    }

    isNewCodeValid(code) {
        return /^[a-zA-Z0-9\-\/\.\(\)]*$/.test(code);
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={SlideUpTransition}
                onClose={this.props.onClose}
                // fullScreen={!window.matchMedia(this.context.breakpoints.up('sm').replace("@media ", "")).matches}
                maxWidth="sm"
            >
                <DialogTitle>Inserisci maualmente codice "User Friendly"</DialogTitle>
                <DialogContent>
                    <Typography>
                        Il codice che inserirai non potrà più essere modificato da qui.
                    </Typography>
                    <br/>
                    <TextField
                        value={this.state.newCod || ""}
                        onChange={(e) =>
                            this.isNewCodeValid(e.target.value) &&
                            this.setState({newCod: e.target.value.toUpperCase()})
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {this.props.actualCod?.replace("…", "" || "")}
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        label="Nuovo codice"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Annulla</Button>
                    <Button variant="contained" onClick={this.sendData}>
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

class GetUfCod extends React.Component {
    static empty_uf_pattern = /.{4}-…/;
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.state = {
            dialogOpen: false,
            newCode: false,
        };
    }

    getData() {
        if (
            (!this.props.ufCod || GetUfCod.empty_uf_pattern.test(this.props.ufCod)) &&
            !this.state.newCode
        ) {
            // Prova a richiedere il codice uf dal server in ajax
            axios
                .get(
                    `${Constants.paths.ajaxBasePath}prodotti/utils/user-uf-codes/?token=${this.context.user.token}&prodIds=${this.props.prodId}`
                )
                .then((res) => {
                    let resp = res.data;
                    if (resp) this.setState({newCode: resp[this.props.prodId]});
                })
                .catch((err) => {
                });
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return this.props.allowCustomisation ? (
            (!this.props.ufCod || GetUfCod.empty_uf_pattern.test(this.props.ufCod)) &&
            !this.state.newCode ? (
                <React.Fragment>
                    <Button
                        variant="contained"
                        onClick={(e) => this.setState({dialogOpen: true})}
                    >
                        {this.props.ufCod}
                    </Button>
                    <UfCodSender
                        prodId={this.props.prodId}
                        actualCod={this.props.ufCod}
                        open={this.state.dialogOpen}
                        onClose={() => this.setState({dialogOpen: false})}
                        enqueueSnackbar={this.props.enqueueSnackbar}
                        setNewCode={(setCode) => this.setState({newCode: setCode})}
                    />
                    <br/>
                    <Typography style={{color: this.context.theme.palette.warning.main}}
                                variant='caption'>{this.props.codProduttore || ''}</Typography>
                </React.Fragment>
            ) : (
                this.state.newCode || this.props.ufCod || ""
            )
        ) : (
            this.state.newCode || this.props.ufCod || ""
        );
    }
}

function TableProductCod({
                             productObj,
                             manualUfCod,
                             onUfCodChange,
                             noEan = false,
                             allowCustomisation = false
                         } // To remove?
) {
    //props=row_data
    //onFamilyClick
    return (
        <>
        <span>
            <Typography variant="caption" color={"text.secondary"}>
            {productObj.v_cod || productObj.cod || productObj.sku}
          </Typography>
          <Typography style={{fontWeight: "bold"}}>
            {manualUfCod ? (
                <TextField variant='outlined'
                           size='small'
                           value={productObj.uf_cod}
                           onChange={e => onUfCodChange(e.target.value)}/>
            ) : (
                <GetUfCod
                    allowCustomisation={allowCustomisation || false}
                    prodId={productObj.id_prod || productObj.id}
                    ufCod={productObj.v_uf_cod || productObj.uf_cod}
                    codProduttore={productObj.cod_produttore}
                    // enqueueSnackbar={this.props.enqueueSnackbar}
                />
            )}
          </Typography>
            {/*{!noFamily && productObj.cod_famiglia && (*/}
            {/*    <Chip*/}
            {/*        onClick={() => {*/}
            {/*            onFamilyClick(productObj.cod_famiglia);*/}
            {/*        }}*/}
            {/*        avatar={<Avatar>{productObj.num_varianti}</Avatar>}*/}
            {/*        size="small"*/}
            {/*        label={`${productObj.cod_famiglia}`}*/}
            {/*    />*/}
            {/*)}*/}
            {productObj.x_code && (
                <React.Fragment>
                    <Typography variant="caption">
                        {productObj.x_code}
                    </Typography>
                </React.Fragment>
            )}
            {!noEan && (
                <>
                    {productObj.cod_barre && (
                        <Typography variant="caption" style={{display: "block"}}>
                            <FontAwesomeIcon
                                icon={faBarcode}
                                style={{marginRight: 5}}
                            />
                            {productObj.cod_barre}
                        </Typography>
                    )}
                    {productObj.cod_ricerca_extra && (
                        <Typography variant="caption" style={{display: "block"}}>
                            <FontAwesomeIcon
                                icon={faBarcode}
                                style={{marginRight: 5}}
                            />
                            {productObj.cod_ricerca_extra}
                        </Typography>
                    )}
                </>
            )}
        </span>
        </>
    );
}

export default TableProductCod;
