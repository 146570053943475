import React, {useContext, useEffect, useState} from "react";
import {Avatar, Button, Dialog, DialogContent,} from "@mui/material";
import Constants from "../context/Constants";
import {ZoomTransition} from "./Transitions";
import Context from "../context/Context";

function ProductImage({
                          sku,
                          img = false,
                          size = "",
                          buttonStyle = {},
                          containerSize = "4rem",
                          containerStyle = {},
                          avatarStyle = {},
                          idProd = false,
                          usesAlt = false
                      }: {
    sku?: number | string;
    img?: number | false;
    size?: number | string;
    buttonStyle?: React.CSSProperties;
    containerSize?: React.CSSProperties['height'];
    containerStyle?: React.CSSProperties;
    avatarStyle?: React.CSSProperties;
    usesAlt?: boolean;
    idProd?: number | false;
}) {
    const context = useContext(Context);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [imgCount, setImgCount] = useState(-1);
    const [url, setUrl] = useState("");

    useEffect(() => { //On Load
        init().then();
        return () => reset();
    }, []);

    useEffect(() => {
        if (imgCount || usesAlt) {
            getImg().then();
        }
        return () => reset()
    }, [imgCount, usesAlt]);

    useEffect(() => { //Aggiorna
        init().then();
        return () => reset();
    }, [idProd, sku]);

    const reset = (): void => {
        setImgCount(-1);
        setUrl("");
    }

    const init = async () => {
        if (img === false) { //Conteggio non fornito
            if (sku || idProd) { //Prodotto nuova generazione
                if (!usesAlt) getImgNr().then();
                else setImgCount(0);
            }
        } else {
            setImgCount(img as number);
        }
    }

    const getImgNr = async () => {
        let res = await fetch(`${Constants.paths.ajaxBasePath}prodotti/img/utils/count/?token=${context.user.token}&skus=${sku}&simple`);
        setImgCount(parseInt(await res.text()));
    }

    const getImg = async (full = true) => {
        let path = Constants.paths.productImgBasePath;
        let thisSize = `-${size}x${size}`;

        if (full || !size)
            thisSize = "";


        if (usesAlt) {
            if (!idProd) {
                console.error('Richiesta img alternativa, ma non è stato passato l\'ID Prod!');
                return;
            }

            let res = await fetch(Constants.paths.ajaxBasePath + 'prodotti/img/utils/alt-img/?token=' + context.user.token + '&id_prod=' + idProd);
            setUrl(await res.text());
        } else {
            setUrl(`${path}${imgCount ? (sku || "") : "support/no-img"}${thisSize}.jpg`);
        }
    }

    const onError = (to = true) => setHasError(to);


    const openDialog = () => setDialogOpen(true);

    const closeDialog = () => setDialogOpen(false);


    return <>
        <Button
            onClick={openDialog}
            style={{padding: 0, ...buttonStyle}}
        >
            <Avatar
                style={{
                    height: containerSize,
                    width: containerSize,
                    ...avatarStyle,
                }}
                variant="rounded"
                src={url} //TODO: Add srcset functionality
            />
        </Button>
        <Dialog
            onClose={() => closeDialog()}
            open={dialogOpen}
            TransitionComponent={ZoomTransition}
            style={{
                padding: 0,
            }}
        >
            <DialogContent
                style={{
                    padding: 0,
                }}
            >
                <img
                    alt={'Immagine prodotto'}
                    style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                    }}
                    src={url}
                />
            </DialogContent>
        </Dialog>
    </>

}

export default ProductImage;
