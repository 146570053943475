import {Theme, useMediaQuery, useTheme} from '@mui/material';

type TBreakpoints = {
    [key: string]: boolean;
};

function useBreakpoints(): TBreakpoints { // Hook
    const theme = useTheme<Theme>();
    return {
        xs: useMediaQuery(theme.breakpoints.up('xs')),
        sm: useMediaQuery(theme.breakpoints.up('sm')),
        md: useMediaQuery(theme.breakpoints.up('md')),
        lg: useMediaQuery(theme.breakpoints.up('lg')),
        xl: useMediaQuery(theme.breakpoints.up('xl')),
        xxl: useMediaQuery(theme.breakpoints.up('xxl'))
    };
}

export default useBreakpoints;