function stringIsType(thisString: string, type: "codFamiglia" | "codPrimario" | "barcode"): boolean | "codFamiglia" | "codPrimario" | "barcode" {
    const codFamigliaPat = "^[A-Z]{4}[0-9]{4}$",
        codPrimarioPat = "^[0-9]{4}-[0-9]{6}$",
        barcodePat = "^([0-9]{14}|[0-9]{13}|[0-9]{8})$";
    if (type) {
        //If type is given do only one RegExp
        switch (type) {
            case "codFamiglia":
                return !!thisString.match(codFamigliaPat);
            case "codPrimario":
                return !!thisString.match(codPrimarioPat);
            case "barcode":
                return !!thisString.match(barcodePat);
            default:
                console.error("Invalid type passed");
                break;
        }
    } else {
        //If type is not given return a string with the type
        if (thisString.match(codFamigliaPat)) {
            return "codFamiglia";
        } else if (thisString.match(codPrimarioPat)) {
            return "codPrimario";
        } else if (thisString.match(barcodePat)) {
            return "barcode";
        } else {
            return false;
        }
    }
}

export default stringIsType;
