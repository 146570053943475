import React, {useContext} from "react";
import {Box, Button, Card, Divider, Fab, Grid, Typography} from "@mui/material";
import moment from "moment";
// @ts-ignore
import localization from "moment/locale/it";
import Context from "../../context/Context";
// import {withSnackbar} from "notistack";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TDataOrdine} from "./OrdinePanel/OrdinePanel";

moment.locale("it", localization);

export interface TBozza extends TDataOrdine {
    id: string; // Sovrascrivi da number a uuid4
}

export interface TBozze {
    [key: string]: TBozza
}

function BozzePanel({
                        syncing,
                        syncDrafts,
                        drafts,
                        onSetActive,
                        onDeleteDraft
                    }: {
    syncing: boolean;
    syncDrafts: () => void;
    drafts: TBozze;
    onSetActive: (draft: TBozza, id: string) => void;
    onDeleteDraft: (id: string) => void;
}) {
    const context = useContext(Context);
    return <>
        <>
            <Fab
                variant={syncing ? "extended" : "circular"}
                style={
                    {
                        zIndex: 99,
                        position: "fixed",
                        right: context.theme.spacing(2),
                        bottom: context.theme.spacing(4)
                    }
                }
                onClick={(e) => syncDrafts()}
            >
                {syncing ? "Sincronizzo... " : ""}
                <FontAwesomeIcon
                    icon={faSyncAlt}
                    // style={{ marginRight: consumer_data.theme.spacing(1) }}
                />
                {/* {this.state.unlocked ? "SBLOCCATO" : "BLOCCATO"} */}
            </Fab>
            <Grid container justifyContent="space-around">
                <Grid item xs={12} sx={{width: '100%', textAlign: 'center'}}>
                    <Typography variant={'h1'}>Bozze</Typography>
                    <br/>
                    <Typography color={'text.secondary'} variant={'overline'}
                                sx={{align: 'center', width: '100%'}}>
                        Sincronizzazione automatica attiva</Typography>
                </Grid>
                <Grid item container xs={12} md={8} lg={7}>
                    {Object.keys(drafts).length > 0 ? (
                        Object.keys(drafts)
                            .map((did, i, all) => {
                                let thisDraft = drafts[did];
                                return (
                                    <React.Fragment key={did}>
                                        <br/>
                                        <Card
                                            variant="outlined"
                                            key={did}
                                            style={{
                                                width: "100%",
                                                padding: context.theme.spacing(3),
                                            }}
                                        >
                                            <Typography
                                                style={{fontSize: "70%", opacity: "0.4"}}
                                            >
                                                {did}
                                            </Typography>
                                            <Grid item container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        marginBottom: context.theme.spacing(
                                                            1
                                                        ),
                                                    }}
                                                >
                                                    <Typography variant="overline">
                                                        {moment(thisDraft.dataCreazione).fromNow() +
                                                            " • " +
                                                            moment(thisDraft.dataCreazione).format("DD/MM/YYYY HH:mm")
                                                        }
                                                        {/*<RemoteDraftStatusIcon*/}
                                                        {/*    status={*/}
                                                        {/*        draftsCloudSyncStatus[did]*/}
                                                        {/*    }*/}
                                                        {/*/>*/}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={8} spacing={1}>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{textAlign: "right"}}
                                                    >
                                                        Offerta:
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        style={{
                                                            textAlign: "left",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {thisDraft.offerta.titolo ||
                                                            "Non selezionata"}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{textAlign: "right"}}
                                                    >
                                                        Cliente:
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        style={{
                                                            textAlign: "left",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {thisDraft.cliente.ragione_sociale ||
                                                            "Non selezionato"}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{textAlign: "right"}}
                                                    >
                                                        Righe:
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        style={{
                                                            textAlign: "left",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {thisDraft.articoli.length || 0}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{textAlign: "right"}}
                                                    >
                                                        Totale:
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        style={{
                                                            textAlign: "left",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {parseFloat(thisDraft.totale_ordine as unknown as string)
                                                            .toFixed(2)
                                                            .replace(".", ",") + " €"}
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={4}>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            fullWidth
                                                            style={{height: "100%"}}
                                                            variant="contained"
                                                            onClick={() => {
                                                                onSetActive(
                                                                    thisDraft,
                                                                    thisDraft.id
                                                                );
                                                            }}>
                                                            Riprendi
                                                        </Button>{" "}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            fullWidth
                                                            style={{height: "100%"}}
                                                            onClick={() =>
                                                                onDeleteDraft(did)
                                                            }
                                                        >
                                                            <Typography color="error">
                                                                Elimina
                                                            </Typography>
                                                        </Button>{" "}
                                                    </Grid>
                                                </Grid>
                                                {thisDraft.note && (
                                                    <Grid item xs={12} spacing="2">
                                                        <br/>
                                                        <Divider/>
                                                        <Typography variant="overline">
                                                            Note
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {thisDraft.note}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Card>
                                        {all.length - 1 === i ? undefined : <Divider/>}
                                    </React.Fragment>
                                );
                            })
                    ) : (
                        <Grid item xs={12} style={{textAlign: "center"}}>
                            <Box color="text.secondary">
                                <i>Nessuna bozza salvata</i>
                            </Box>
                            <br/>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    </>
}

export default BozzePanel;
