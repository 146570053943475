import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Pagination,
    Switch,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material";

import {
    CustomerSelector,
    Offerta_ProductSelector,
    OffertaSelector,
    ProductSelector,
    Restock_ProductSelector,
} from "../../../components/DialogSelector";
import {SlideUpTransition} from "../../../components/Transitions";
import Constants from "../../../context/Constants";
import Context from "../../../context/Context";
import SchedaProdotto from "./SchedaProdotto";
import {calcDiscount} from "../../../functions/discounts";
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import {motion} from "framer-motion";
import download from "downloadjs";
import moment from "moment";
import {assertNumber} from "../../../functions/assertion";
import DialogSelectorMeta from "../../../interfaces/prodotti/DialogSelectorMeta";
import {roundUp} from "../../../functions/round";
import formatPrice from "../../../functions/formatPrice";
import {flushSync} from "react-dom";
import {
    parseFormattedNumberForInput,
    preFormatNumberForInputChange as preFormatNumberForInputChangeSource
} from "../../../functions/inputHelper";
import {AddCircleRounded, Cloud, ContentPaste, ReplayCircleFilledRounded, Send, SendRounded} from "@mui/icons-material";
import {calcPercDecrease} from "../../../functions/math";
import {Theme} from "@mui/material/styles";
import {Prodotto} from "../../../interfaces/prodotti/Prodotto";
import {enqueueSnackbar, useSnackbar} from "notistack";
import {useConfirm} from "material-ui-confirm";
import {TBozza} from "../BozzePanel";
import useBreakpoints from "../../../functions/breakpoints";

moment.locale("it");

export interface TCliente extends Partial<DialogSelectorMeta> {
    ragione_sociale: string;
    id: number;
    email: string;
    id_cond_pagamento?: string;
    nome_cond_pagamento?: string;
    p_iva?: string;
    cod_fi?: string;
    tel?: string;
    cell?: string;
    via?: string;
    comune?: string;
    cap?: number;
    prov?: string;
}

export interface TOfferta extends Partial<DialogSelectorMeta> {
    id: string;
    titolo: string;
    descrizione: string | null;
    note: string | null;
    stato?: number;
    tipo?: "semplice" | "punti";
    data_inizio?: Date | null;
}

export interface TDataOrdine {
    id: string;
    cliente: TCliente;
    articoli: Prodotto[];
    note: string;
    offerta: TOfferta;
    id_sede?: number,
    tipo: "ordine" | "preventivo";
    totale_ordine: number;
    totale_ordine_imponibile: number;
    totale_ordine_merci: number;
    sconto_chiusura: number | string;
    sconto_chiusura_mod: "abs" | "per";
    totale_ordine_sconto_chiusura: number;
    totale_ordine_punti_disponibili?: number;
    totale_ordine_punti_accumulati?: number;
    totale_ordine_iva: number;
    costi_spedizione: number | string;
    //*****************************//
    sendToCustomer: boolean;
    dataCreazione: number;
    ultimaModifica: number;
}

type TProductChangeProperties =
    "sconto"
    | "qta"
    | "prezzo"
    | "usoPunti"
    | "note"
    | "prezzoBase"
    | "qtaPlus"
    | "qtaMinus"
    | "desc"
    | "ufCod";

export interface TFNHandleProductChange {
    (value: string,
     aObj: Prodotto,
     property: TProductChangeProperties,
     sync?: boolean): void
}

export interface TFNHandleProductBlur extends TFNHandleProductChange {
    (value: string | number,
     aObj: Prodotto,
     property: TProductChangeProperties,
     directVal?: string | number | false): void
}

interface TPagination {
    thisPage: number;
    pageSize: number;
}

export interface TOrderError {
    id: number;
    type: "order" | "customer" | "branch" | "product.price" | "product";
    message: string;
}


const largeIconSx: SxProps<Theme> = {
    fontSize: 50
}

const recapTableHeadProps: TableCellProps = {
    width: '33%',
    variant: 'head',
    align: 'right'
}

const recapTableBodyProps: TableCellProps = {
    align: 'left'
}

const newOrdine: (id?: string | null) => TDataOrdine = (id = null) => {
    const emptyOfferta: TOfferta = {
        data_inizio: null,
        id: "DEFAULT",
        titolo: "Ordine generico",
        descrizione: "",
        note: null,
        stato: 1,
        tipo: 'semplice'
    };

    let newDataOrdine: TDataOrdine = {
        id: uuidv4(),
        offerta: emptyOfferta,
        cliente: {
            id: 0,
            nome_cond_pagamento: null,
            id_cond_pagamento: null,
            ragione_sociale: "",
            email: ""
        },
        id_sede: undefined,
        totale_ordine: 0.0,
        totale_ordine_imponibile: 0.0,
        totale_ordine_merci: 0.0,
        sconto_chiusura: 0.0,
        sconto_chiusura_mod: "abs",
        totale_ordine_sconto_chiusura: 0.0,
        totale_ordine_iva: 0.0,
        costi_spedizione: 4.9,
        sendToCustomer: false,
        articoli: [],
        dataCreazione: Date.now(),
        ultimaModifica: Date.now(),
        note: "",
        tipo: "ordine"
    };
    return newDataOrdine;
};

const returnOrdineFromJson: (j: any) => TDataOrdine = (j) => {
    const data = typeof j === 'string' ? JSON.parse(j) : j;

    let articoli: Prodotto[] = data.articoli ? JSON.parse(data.articoli) : [];

    articoli = articoli.map(a => {
        // INT
        [
            "qta",
            "iva",
            "imballo",
            "img"
        ].forEach(att => (a as any)[att] = parseInt((a as any)[att] as string));

        // Float
        [
            "prezzo_l1",
            "prezzo_l2",
            "prezzo_l3",
            "prezzo_l4",
            "prezzo_x1",
            "prezzo_x2",
            "prezzo_x3",
            "prezzo_x4",
            "max_sconto",
            "user_sconto",
            "user_prezzo",
            "v_prezzo",
        ].forEach(att => (a as any)[att] = parseFloat((a as any)[att] as string));
        return a;
    });

    const cliente: TCliente = {
        id: parseInt(data.cliente_id),
        ragione_sociale: data.cliente_ragione_sociale || '',
        email: data.cliente_email || '',
        id_cond_pagamento: data.cliente_id_cond_pagamento || undefined,
        nome_cond_pagamento: data.cliente_nome_cond_pagamento || undefined,
        via: data.cliente_via || '',
        comune: data.cliente_comune || '',
        cap: data.cliente_cap ? parseInt(data.cliente_cap) : 0,
        prov: data.cliente_prov || '',
        cell: data.cliente_cell || '',
        tel: data.cliente_tel || '',
        p_iva: data.cliente_p_iva || '',
        cod_fi: data.cliente_cod_fi || ''
    };

    const offerta: TOfferta = {
        id: data.id_offerta || 'DEFAULT',
        titolo: data.offerta_titolo || 'Ordine generico',
        descrizione: data.offerta_descrizione,
        note: data.offerta_note || null,
        stato: data.stato ? parseInt(data.stato) : undefined,
        tipo: 'semplice',
        data_inizio: data.data_ricezione ? new Date(data.data_ricezione) : null,
    };

    const ordine: TDataOrdine = {
        id: data.id,
        cliente: cliente,
        articoli: articoli,
        note: data.note || '',
        offerta: offerta,
        id_sede: data.id_sede ? parseInt(data.id_sede) : undefined,
        tipo: data.tipo as "ordine" | "preventivo",
        totale_ordine: parseFloat(data.totale_ordine) || 0,
        totale_ordine_imponibile: parseFloat(data.totale_ordine_imponibile) || 0,
        totale_ordine_merci: parseFloat(data.totale_ordine_merci) || 0,
        sconto_chiusura: parseFloat(data.sconto_chiusura) || 0,
        sconto_chiusura_mod: data.sconto_chiusura_mod as "abs" | "per",
        totale_ordine_sconto_chiusura: parseFloat(data.totale_ordine_sconto_chiusura) || 0,
        totale_ordine_iva: parseFloat(data.totale_ordine_iva) || 0,
        costi_spedizione: parseFloat(data.costi_spedizione) || 0,
        sendToCustomer: false, // Default value
        dataCreazione: Date.now(),
        ultimaModifica: Date.now()
    };

    return ordine
}

const emptyOrder = newOrdine('empty');

function OrdinePanel({
                         onRefreshDrafts,
                         onChangeOrderId,
                         onClearOrder,
                         triggerCloneOrderId,
                         onCloneOrderTriggered,
                         saveDraft
                     }: {
    onRefreshDrafts: (ids: string[]) => void;
    onChangeOrderId: (id: TDataOrdine['id']) => void;
    onClearOrder: (cb: () => void, saveDraftFn: () => void) => void;
    triggerCloneOrderId: number;
    onCloneOrderTriggered: () => void;
    saveDraft: (id: string, draftObj: TBozza) => Promise<boolean>;
}) {
    //region State
    const context = useContext(Context);
    const [dataOrdine, setDataOrdine] = useState<TDataOrdine>(emptyOrder);
    const [searchCustomerDialogOpen, setSearchCustomerDialogOpen] = useState<boolean>(false);
    const [confirmOrderDialogOpen, setConfirmOrderDialogOpen] = useState<boolean>(false);
    const [searchOfferDialogOpen, setSearchOfferDialogOpen] = useState<boolean>(false);
    const [searchProductDialogOpen, setSearchProductDialogOpen] = useState<boolean>(false);
    const [searchProductRestockDialogOpen, setSearchProductRestockDialogOpen] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TPagination>({
        thisPage: 1,
        pageSize: 10
    });
    const [fetching, setFetching] = useState<boolean>(false);
    const [cannotSendReasons, setCannotSendReasons] = useState<TOrderError[]>([]);
    const [calculatedPercDiscount, setCalculatedPercDiscount] = useState<number>(0);
    const snackbar = useSnackbar();
    //endregion

    //region Functions
    const preFormatNumberForInputChange = useCallback((val: string | number): string => preFormatNumberForInputChangeSource(val), []);
    //endregion

    //region Hooks
    const matches = useBreakpoints();

    useEffect(() => {//Init
        let thisOrderActiveStorage: typeof dataOrdine = JSON.parse(localStorage.getItem("orderActive"));

        setDataOrdine(thisOrderActiveStorage);
        if (!thisOrderActiveStorage) initNewOrder();
        const saveToLSInterval = setInterval(
            () => saveActive(),
            Constants.heartbeat.fastest
        );
        return () => {
            clearInterval(saveToLSInterval);
        }
    }, []);

    useEffect(() => { //On order update
        checkRequiredFields();
    }, [dataOrdine]);

    useEffect(() => { //Ricalcola totali
        const newTotal = calcOrderTotal();
        setDataOrdine(prevState => ({
            ...prevState,
            totale_ordine: newTotal.totale_ordine,
            totale_ordine_imponibile: newTotal.totale_ordine_imponibile,
            totale_ordine_iva: newTotal.totale_ordine_iva,
            totale_ordine_sconto_chiusura: newTotal.totale_ordine_sconto_chiusura,
            totale_ordine_merci: newTotal.totale_ordine_merci,
            totale_ordine_punti_accumulati: newTotal.totale_ordine_punti_accumulati,
            totale_ordine_punti_disponibili: newTotal.totale_ordine_punti_disponibili
        }));
    }, [dataOrdine.costi_spedizione,
        JSON.stringify(dataOrdine.articoli),
        dataOrdine.sconto_chiusura_mod,
        dataOrdine.sconto_chiusura]);

    useLayoutEffect(() => {
        if (triggerCloneOrderId) {
            cloneExistingOrder(triggerCloneOrderId).then(
                () => onCloneOrderTriggered()
            );
        }
    }, [triggerCloneOrderId]);

    const confirm = useConfirm();

    //endregion
    const initNewOrder = () => {
        let newOrder = newOrdine();
        setDataOrdine({...newOrder});
        localStorage.setItem("orderActive", JSON.stringify(newOrder));
    }

    const cloneExistingOrder = async (id: number) => {
        try {
            if (!isOrderEmpty)
                await confirm({
                    title: "Hai un ordine in corso!",
                    description: "Proseguendo con la clonazione verrà eliminato definitivamente l'ordine in corso."
                });

            try {
                let ex = await fetch(
                    Constants.paths.ajaxBasePath
                    + "ordini-agenti/storico/"
                    + "?token=" + context.user.token
                    + "&action=getsingle"
                    + "&order_id=" + id
                );

                let order = returnOrdineFromJson(await ex.json());
                order.id = uuidv4();
                setDataOrdine({...order});
                localStorage.setItem("orderActive", JSON.stringify(order));
                enqueueSnackbar("Ordine #" + id + " clonato",
                    {variant: 'success'});
            } catch (error) {
                alert("Qualcosa è andato storto. Annullo clonazione.");
                initNewOrder();
            }
        } catch (error) {
            console.log("Clonazione annullata.");
        }
    }

    const isOrderEmpty = useMemo<boolean>(() => {
        try {
            if (dataOrdine.cliente.id) return false;
            if (dataOrdine.articoli.length) return false;
            if (dataOrdine.note) return false;
            if (dataOrdine.offerta.id && dataOrdine.offerta.id != "Default") return false;
        } catch (e) {
            return true;
        }
        return true;
    }, [JSON.stringify(dataOrdine)]);

    useEffect(() => {
        onChangeOrderId(dataOrdine.id);
    }, [dataOrdine.id]);
    //endregion

    const handleConfirmOrderDialogOpen = (to: boolean) => setConfirmOrderDialogOpen(to);

    const handleSaveDraft = async () => {
        const id = dataOrdine.id.toString();
        // let storage = JSON.parse(localStorage.getItem("orderDrafts"));
        // if (!storage) {
        //     storage = {}; //Normalize
        // }
        //
        // storage[id] = {
        //     ...dataOrdine,
        //     ultimaModifica: Date.now()
        // }; //Probabile conflitto con gli id!!
        // localStorage.setItem("orderDrafts", JSON.stringify(storage));
        // // console.log("Storage after set", JSON.parse(localStorage.getItem("orderDrafts")));
        if (await saveDraft(id, {...dataOrdine})) {
            enqueueSnackbar("Bozza salvata", {
                variant: "success",
            });
            initNewOrder();
            onRefreshDrafts([dataOrdine.id]);
        }
    }

    const saveActive = (ordine: TDataOrdine = null) => {
        let order = ordine;
        if (!order) {
            setDataOrdine((prevState) => {
                order = prevState;
                return prevState;
            })
        }
        // console.log("LS Saving order...", order);
        localStorage.setItem(
            "orderActive",
            JSON.stringify(order)
        );
    }

    const handlePageChange = (newPage: number) => {
        setPagination({
            ...pagination,
            thisPage: newPage
        });
    }

    const handleProductAnyBlur: (product: Prodotto) => void = (product) => {
        const offerta: TOfferta = dataOrdine.offerta;
        let newArticolo = {...product};

        // Quantità
        newArticolo.qta = parseInt(newArticolo.qta as any);
        newArticolo.qta = newArticolo.qta > 0 ? newArticolo.qta : 1;

        // Sconto
        if (!newArticolo.user_sconto) newArticolo.user_sconto = 0; //Failsafe per evitare sconti vuoti
        newArticolo.user_sconto = parseFormattedNumberForInput(newArticolo.user_sconto);
        let thisMaxDiscount = parseFloat(String(newArticolo.max_sconto)) || 100;
        if (newArticolo.user_sconto > thisMaxDiscount) {
            newArticolo.user_sconto = thisMaxDiscount;
        }

        newArticolo.user_sconto = newArticolo.user_sconto.toFixed(2);
        if (parseFloat(newArticolo.user_sconto)) {
            newArticolo.user_prezzo = calcDiscount(
                parseFormattedNumberForInput(newArticolo.v_prezzo),
                parseFormattedNumberForInput(newArticolo.user_sconto),
                parseFormattedNumberForInput(newArticolo.user_prezzo),
                3);
            newArticolo.uso_punti = 0;
        }

        // Prezzo
        newArticolo.user_prezzo = parseFormattedNumberForInput(newArticolo.user_prezzo);
        if (!newArticolo.user_prezzo) newArticolo.user_prezzo = 0; //Failsafe per evitare prezzi vuoti

        if (offerta.tipo === 'punti') {
            if (newArticolo.options.punti.uso) {
                newArticolo.uso_punti = roundUp(((newArticolo.prezzo_l3 - newArticolo.user_prezzo) * newArticolo.qta), 3);
            }
        }

        // Finally
        setDataOrdine(prevState => {
            const indexToSwap: number = prevState.articoli.findIndex(a => a.id === newArticolo.id);
            let newArticoli = prevState.articoli;
            newArticoli[indexToSwap] = newArticolo;
            return {
                ...prevState,
                articoli: newArticoli
            };
        });
    }

    const handleProductChange: TFNHandleProductChange = (
        value,
        aObj,
        property,
        sync: boolean = false
    ) => {
        let newAObj: Prodotto = aObj;
        value = String(value as any);
        switch (property) {
            case "note":
                newAObj.user_note = value;
                break;

            case "prezzoBase":
                newAObj.v_prezzo = value;
                break;

            case "prezzo":
                newAObj.user_sconto = 0;
                newAObj.user_prezzo = value;
                break;

            case "sconto":
                newAObj.user_sconto = value;
                break;

            case "qta":
                newAObj.qta = value;
                break;

            case "qtaPlus":
            case "qtaMinus":
                aObj.qta = parseInt(aObj.qta as any);
                aObj.imballo = parseInt(aObj.imballo as any);
                if (property === "qtaMinus") value = String(aObj.qta > (aObj.imballo ? aObj.imballo : 2)
                    ? aObj.qta - (aObj.imballo ? aObj.imballo : 1)
                    : aObj.imballo
                        ? aObj.imballo
                        : 1);
                else
                    value = String(aObj.qta + (aObj.imballo ? aObj.imballo : 1));
                property = "qta";
                newAObj.qta = value;
                break;

            case "desc":
                newAObj.descrizione = value;
                // newAObj["v_descrizione"] = value;
                break;

            case "ufCod":
                newAObj.uf_cod = value.toUpperCase();
                break;

            case "usoPunti":
                // value = parseFloat(value);
                // assertNumber(value);
                newAObj.uso_punti = value;
                break;
        }

        const index = dataOrdine.articoli.indexOf(aObj);
        let newArticoli = dataOrdine.articoli;
        newArticoli[index] = newAObj;

        //Finally
        if (sync) flushSync(() => setDataOrdine(prevState => ({
            ...prevState,
            articoli: newArticoli
        })));

        else setDataOrdine(prevState => ({
            ...prevState,
            articoli: newArticoli
        }));
    }

    const calcOrderTotal = (thisDataOrdine: TDataOrdine | null = null): {
        totale_ordine_iva: number,
        totale_ordine_sconto_chiusura: number,
        totale_ordine_imponibile: number,
        totale_ordine: number,
        totale_ordine_merci: number,
        totale_ordine_punti_accumulati: number,
        totale_ordine_punti_disponibili: number
    } => {

        const zero = {
            totale_ordine_iva: 0,
            totale_ordine_sconto_chiusura: 0,
            totale_ordine_imponibile: 0,
            totale_ordine: 0,
            totale_ordine_merci: 0,
            totale_ordine_punti_accumulati: 0,
            totale_ordine_punti_disponibili: 0
        };

        let newOrderTotal: number = 0.0, //Imponibile
            newOrderTotalMerci: number = 0.0,
            newOrderIva: number = 0.0, //Totale IVA
            newOrderAvgIva: number = 0, // Media in perc dell'IVA
            newOrderTotalIVA: number = 0.0, //Totale
            appliedOrderDiscount: number = 0.0,
            newOrderTotalPoints: number = 0.0,
            newOrderAvailablePoints: number = 0.0, //Totale sconto applicato
            thisCostiSpedizione: number = 0;

        if (!thisDataOrdine) thisDataOrdine = dataOrdine;

        // Iter ogni prodotto
        for (const a of thisDataOrdine.articoli) {
            let thisQta = parseFloat(a.qta as string);
            let thisIVA = a.iva;
            let thisPrezzo = parseFloat(a.user_prezzo as string);
            let thisUsoPunti = 0;

            try {
                assertNumber(thisQta, 'in calcOrderTotal, qta');
                assertNumber(thisIVA, 'in calcOrderTotal, iva');
                assertNumber(thisPrezzo, 'in calcOrderTotal, user_prezzo');
            } catch (error) {
                console.log(error);
                return zero;
            }

            newOrderTotal += thisQta * thisPrezzo;
            newOrderIva += thisQta * (thisPrezzo * (thisIVA / 100));

            if (thisDataOrdine.offerta.tipo === 'punti') { //Calcola i punti se necessario
                if (a.uso_punti) {
                    thisUsoPunti = parseFloat(a.uso_punti as string);
                    try {
                        assertNumber(thisUsoPunti, 'in calcOrderTotal, punti, uso_punti');
                    } catch (error) {
                        console.log(error);
                        a.uso_punti = 0;
                    }
                } else a.uso_punti = 0;

                let thisPoints = returnPointsByPrice(thisPrezzo, a.options, a) * thisQta;
                newOrderTotalPoints += thisPoints;
                newOrderAvailablePoints += thisPoints;
                newOrderAvailablePoints -= thisUsoPunti;
                if (newOrderAvailablePoints === -0) newOrderAvailablePoints = 0;
            }
        }

        //Calcola percentuale media IVA SOLTANTO sugli articoli
        newOrderAvgIva = (newOrderIva / newOrderTotal) * 100;
        if (isNaN(newOrderAvgIva)) newOrderAvgIva = 0;

        //Applica sconto sulle merci
        let thisScontoChiusura = parseFloat(thisDataOrdine.sconto_chiusura as string);
        try {
            assertNumber(thisScontoChiusura, 'in calcOrderTotal, sconto_chiusura');

        } catch (error) {
            return zero;
        }

        if (thisDataOrdine.sconto_chiusura_mod === "abs") {
            //Assoluto
            appliedOrderDiscount = thisScontoChiusura;
        } else {
            //Percentuale
            appliedOrderDiscount =
                newOrderTotal *
                (thisScontoChiusura / 100);
        }
        newOrderTotalMerci = newOrderTotal; //Salva il valore merce prima degli sconti
        newOrderTotal -= appliedOrderDiscount;

        newOrderIva = newOrderTotal * (newOrderAvgIva / 100);


        try {
            thisCostiSpedizione = parseFloat(thisDataOrdine.costi_spedizione as string);
            assertNumber(thisDataOrdine.costi_spedizione, 'in calcOrderTotal, costi_spedizione');

        } catch (error) {
            console.log(error);
            thisCostiSpedizione = 0;
        }

        newOrderTotal += thisCostiSpedizione;
        newOrderIva += thisCostiSpedizione * (22 / 100); //IVA sped.
        newOrderTotalIVA = newOrderTotal + newOrderIva; //TOTALE

        if (thisDataOrdine.sconto_chiusura_mod === "abs") {
            setCalculatedPercDiscount(calcPercDecrease(newOrderTotalMerci, (newOrderTotalMerci - appliedOrderDiscount)));
        } else setCalculatedPercDiscount(0);

        return {
            totale_ordine_iva: newOrderIva,
            totale_ordine_sconto_chiusura: appliedOrderDiscount,
            totale_ordine_imponibile: newOrderTotal,
            totale_ordine: newOrderTotalIVA,
            totale_ordine_merci: newOrderTotalMerci,
            totale_ordine_punti_accumulati: newOrderTotalPoints,
            totale_ordine_punti_disponibili: newOrderAvailablePoints
        };
    };

    const returnPointsByPrice = (price: number, options: any, product: Prodotto) => {
        // Viene passato un prezzo, e viene deciso i punti da assegnare per prodotto singolo
        let lastValidPointsObj = null;
        if (!options?.punti) return 0;
        if (options.punti.ex) {
            for (let iex = 5; iex > 0; iex--) {
                if (!options.punti.ex[iex]) continue;
                // @ts-ignore
                if (!product['prezzo_x' + iex]) continue;
                let thisPointsObj = options.punti.ex[iex];
                // @ts-ignore
                let thisListino = parseFloat(product['prezzo_x' + iex] as any);

                if (price >= thisListino) {
                    lastValidPointsObj = thisPointsObj['base'];
                }
            }
        }
        let prezzoEV3Scontato = product.prezzo_l3 - (product.prezzo_l3 * (product.max_sconto / 100));
        if (price >= prezzoEV3Scontato) {
            lastValidPointsObj = options.punti.ev[3]['scontato'];
        }
        if (price >= product.prezzo_l3) {
            lastValidPointsObj = options.punti.ev[3]['base'];
        }

        return lastValidPointsObj;
    }

    const returnMinPossiblePrice = (product: Prodotto, order: TDataOrdine): number => {
        // TODO: Chiedi ad antonio altre regole
        let optionsMinPossiblePrice = 0.00;
        let pointsMinPossiblePrice = 0.00;
        let prezzoBase = product.prezzo_l3;
        let puntiDisponibili = 0.00;
        let usoPunti = 0.00;
        let qta = parseInt(product.qta as any) || 1;
        if (order.offerta?.tipo === 'punti') {
            if (product.options?.punti?.min_price) {
                optionsMinPossiblePrice = product.options.punti.min_price;
            }

            if (product.options?.punti?.uso) {
                puntiDisponibili = order.totale_ordine_punti_disponibili;
                usoPunti = parseInt(product.uso_punti as any);
            }

            pointsMinPossiblePrice = prezzoBase - ((puntiDisponibili + usoPunti) / qta);
            if (pointsMinPossiblePrice > optionsMinPossiblePrice)
                return pointsMinPossiblePrice;
            else return optionsMinPossiblePrice;
        }
        //In futuro può essere inserito un altro filtro qui
        return 0.00;
    }

    const selectCustomer = (clienteObj: TCliente) => {
        setDataOrdine(() => ({
            ...dataOrdine,
            cliente: clienteObj
        }));
        setSearchCustomerDialogOpen(false);
    }

    const selectOfferta = (offertaObj: TOfferta) => {
        setDataOrdine(prevState => ({
            ...prevState,
            offerta: offertaObj
        }));
        setSearchCustomerDialogOpen(false);
    }

    const emptyOfferta = () => {
        setDataOrdine(prevState => ({
            ...prevState,
            offerta: {
                id: null,
                titolo: "",
                descrizione: "",
                note: ""
            },
        }));
        setSearchOfferDialogOpen(false);
    }

    const selectProduct = (value: Prodotto | Prodotto[]) => {
        let newProducts: Prodotto[] = [];
        if (!Array.isArray(value))
            newProducts = [{...value}];
        else {
            value.forEach((a) => newProducts.push({...a}));
            // newProductObj = newProductObj.flat(); ?????
        }
        for (const ai in newProducts) {
            const a = newProducts[ai];
            if (a.id == 99999)
                a.v_cod =
                    a.v_cod +
                    "-" +
                    (dataOrdine.articoli.filter(a => a.id == 99999).length + 1);
            a.qta = parseInt(a.imballo as any) || 1;
            a.imballo = parseInt(a.imballo as any);
            a.user_sconto = 0.0;
            a.user_prezzo = parseFloat(a.v_prezzo as string) || 0.0;
            a.iva = parseFloat(a.iva as any);

            newProducts[ai] = a;
        }

        setDataOrdine(prevState => ({
            ...prevState,
            articoli: [
                ...prevState.articoli,
                ...newProducts
            ]
        }));
        setSearchProductDialogOpen(false);
        setSearchProductRestockDialogOpen(false);

        setPagination(prevState => ({...pagination, thisPage: dataOrdine.articoli.length / prevState.pageSize}));
    }

    const removeProduct = (productObj: Prodotto) => setDataOrdine(prevState => {
            const index = prevState.articoli.indexOf(productObj);
            if (index === -1) return prevState; // Se l'elemento non esiste, non fare nulla
            return {
                ...prevState,
                articoli: [
                    ...prevState.articoli.slice(0, index), // Elementi prima di quello da rimuovere
                    ...prevState.articoli.slice(index + 1) // Elementi dopo quello da rimuovere
                ]
            }
        }
    );

    const selectBranch = (newSede: number): void => {
        setDataOrdine(prevState => ({
            ...prevState,
            id_sede: newSede
        }));
    }

    const selectShipping = (newSpedizione: number | string, blur: boolean = false) => {
        if (blur) newSpedizione = parseFloat(String(newSpedizione).replace(',', '.'));
        setDataOrdine(prevState => ({
            ...prevState,
            costi_spedizione: newSpedizione
        }));
    }

    const handleOffertaDialogOpen = (): void => {
        if (dataOrdine.articoli.length > 0) {
            confirm({
                title: "Hai un ordine in corso!",
                description: "Proseguendo con il cambio offerta verrà eliminato definitivamente l'ordine in corso."
            }).then(() => {
                initNewOrder();
                setSearchOfferDialogOpen(true);
            }).catch(() => {
                console.log("Cambio offerta annullato.");
            })
            // onClearOrder();
        } else setSearchOfferDialogOpen(true);
    }
    // @ts-ignore
    const handleOffertaDialogClose = (): void => setSearchOfferDialogOpen(false);

    const handleProductDialogOpen = () => setSearchProductDialogOpen(true);
    const handleProductDialogClose = () => setSearchProductDialogOpen(false);

    const handleProductRestockDialogOpen = () => setSearchProductRestockDialogOpen(true);

    const handleProductRestockDialogClose = () => setSearchProductRestockDialogOpen(false);


    const handleSearchCustomerDialogClose = (): void => setSearchCustomerDialogOpen(false);


    const handleSearchCustomerDialogOpen = (): void => setSearchCustomerDialogOpen(true);

    const handleOrderNotesChange = (val: string) => setDataOrdine(prev => ({
        ...prev,
        note: val
    }));


    const handleOrderDiscountValueChange = (val: string | number, blur: boolean = false) => {
        if (blur)
            val = parseFloat((val as string).replace(',', '.'));

        setDataOrdine(prevState => ({
            ...prevState,
            sconto_chiusura: val
        }));
    }


    const handleOrderDiscountModeChange = (value: TDataOrdine['sconto_chiusura_mod']) => {
        setDataOrdine(prevState => ({
            ...prevState,
            sconto_chiusura_mod: value
        }));
    }

    const checkRequiredFields = () => {
        let toChk;
        let reasons: typeof cannotSendReasons = [];
        const maxDiscountDefault = 15;
        const maxPriceDifferenceTollerance = 0.001;
        const listiniToCheck = [ //Controlla solo gli aux, altrimenti non sarebbero possibili gli sconti in caso di assenza
            "prezzo_x1",
            "prezzo_x2",
            "prezzo_x3",
            "prezzo_x4"
        ];
        const listinoBase = 'prezzo_l3'

        toChk = {...dataOrdine};
        // if (toChk === null) reasons.push({
        //     message: 'Ordine non definito.',
        //     type: 'order',
        //     id: null
        // });

        if (!toChk.cliente.id) { //È presente il cliente?
            reasons.push({
                message: 'Cliente non selezionato.',
                type: 'customer',
                id: null
            });
        }
        if (toChk.articoli.length < 1) { //C'è almeno un articolo
            reasons.push(
                {
                    message: 'Ordine vuoto.',
                    type: 'order',
                    id: null
                }
            );
        }

        if (toChk.tipo === 'ordine' && ![1, 2].includes(toChk.id_sede)) { //È selezionata la sede
            reasons.push(
                {
                    message: 'Sede non selezionata.',
                    type: 'branch',
                    id: null
                }
            );
        }

        if (toChk.tipo === 'ordine') {
            for (const art of toChk.articoli) { //I prezzi degli articoli sono validi?
                let thisUserPrice = parseFloat(art.user_prezzo as string);
                let thisBasePrice = art[listinoBase];
                let thisMaxDiscount = art.max_sconto > 0 ? art.max_sconto : maxDiscountDefault;

                let prices = listiniToCheck
                    .map(lis => parseFloat(art[lis as keyof Prodotto] as string))
                    .filter(p => p > 0);

                let lowest = prices.sort((a, b) => (a - b))[0];

                if (lowest) {
                    if (thisUserPrice < lowest) {
                        reasons.push(
                            {
                                message: 'Prezzo più basso dell\'ultimo AUX sull\'articolo #' + (dataOrdine.articoli.findIndex(ae => ae.id === art.id) + 1),
                                type: 'product.price',
                                id: art.id
                            }
                        );
                    }
                } else {
                    if (thisBasePrice > 0) {
                        let roundedUserPrice: number = parseFloat((parseFloat(thisUserPrice as any) + maxPriceDifferenceTollerance).toFixed(2));
                        let roundedBasePrice: number = parseFloat(parseFloat(thisBasePrice as any).toFixed(2));

                        let roundedLowestPossiblePrice = parseFloat(((roundedBasePrice - (roundedBasePrice / 100) * thisMaxDiscount) - maxPriceDifferenceTollerance).toFixed(2));
                        if (
                            roundedUserPrice < roundedLowestPossiblePrice) reasons.push(
                            {
                                message: 'Prezzo troppo basso per articolo #' + (dataOrdine.articoli.findIndex(ae => ae.id === art.id) + 1),
                                type: 'product.price',
                                id: art.id
                            }
                        );
                    }
                }
            }
        }
        setCannotSendReasons(reasons);
    }

    const sendOrder = async (isPreview: boolean = false) => {
        setFetching(true);
        let data = {...dataOrdine};
        try {
            //Elimina trash
            delete data.offerta.optionsLabel;
            delete data.offerta.match;
            delete data.offerta.score;
            delete data.offerta.terms;
            delete data.cliente.optionsLabel;
            delete data.cliente.match;
            delete data.cliente.score;
            delete data.cliente.terms;
            for (let i in data.articoli) {
                delete data.articoli[i].optionsLabel;
                delete data.articoli[i].match;
                delete data.articoli[i].score;
                delete data.articoli[i].terms;
            }
        } catch (error) {
            console.log(error);
        }

        const toPost = JSON.stringify({
            ordine: data,
            token: context.user.token,
            action: isPreview ? null : "create",
        });

        try {
            const resp = await axios
                .post(
                    Constants.paths.ajaxBasePath + (isPreview ? "ordini-agenti/anteprima/" : "ordini-agenti/invia-ordine/"),
                    toPost,
                    isPreview ? {responseType: "blob"} : {}
                );

            if (resp.status >= 200 && resp.status < 300) {
                if (isPreview) {
                    download(
                        resp.data,
                        "Anteprima del " + moment().format("DD-MM-YYYY_hh:mm:ss"),
                        "PDF"
                    );
                } else {
                    initNewOrder();
                    enqueueSnackbar("Ordine inviato", {
                        variant: "success",
                    });
                }
            } else {
                alert("Errore: " + resp.statusText);
            }
        } catch (err) {
            alert("Errore nel form:" + err);
        }
        setFetching(false);
        setConfirmOrderDialogOpen(false);
    }


    const onFetchingChange = (isFetching: boolean) => setFetching(isFetching);
    return (
        <>
            {

                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography color={"textPrimary"} textAlign={'center'}
                                    variant="h1">{dataOrdine.tipo === 'preventivo' ? "Preventivo" : "Ordine"}</Typography>
                        <br/>
                    </Grid>
                    <Grid item justifyContent={'center'} xs={12} lg={10} xl={8}>
                        <div
                            style={{
                                // height: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Chip
                                style={{textAlign: 'center'}}
                                label={
                                    isOrderEmpty
                                        ? "Creato ora"
                                        : moment().diff(
                                            dataOrdine.dataCreazione,
                                            "days"
                                        ) < 7
                                            ? "Creato " +
                                            moment(
                                                dataOrdine.dataCreazione
                                            ).fromNow()
                                            : "Creato il " +
                                            moment(
                                                dataOrdine.dataCreazione
                                            ).format("L")
                                }
                            />
                        </div>
                        <br/>
                        <Grid container sx={{textAlign: 'center', placeContent: 'center'}}>
                            <Grid item xs={2}>
                                <Typography textAlign={'right'}
                                            color={dataOrdine.tipo === 'preventivo' ? 'textSecondary' : 'textPrimary'}><b>Ordine</b></Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Switch color={'warning'} checked={dataOrdine.tipo === "preventivo"}
                                        onChange={(_, c) => setDataOrdine(prev => ({
                                                ...prev,
                                                tipo:
                                                    c ? "preventivo" : "ordine"
                                            }
                                        ))}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography textAlign={'left'}
                                            color={dataOrdine.tipo === 'preventivo' ? 'textPrimary' : 'textSecondary'}><b>Preventivo</b></Typography>
                            </Grid>
                        </Grid>
                        {/*<Stack direction={'row'} spacing={2} justifyContent={'space-between'}*/}
                        {/*       sx={{alignItems: 'center'}}>*/}
                        {/*    <Switch/>*/}
                        {/*    <Typography color={'textPrimary'}><b>Preventivo</b></Typography>*/}
                        {/*</Stack>*/}

                        <br/>
                        <Typography style={{fontSize: "70%", opacity: "0.4", textAlign: 'center'}}>
                            {dataOrdine.id}
                        </Typography>
                        {/*<CardContent>*/}
                        <br/>
                        <br/>
                        <Grid spacing={4} container justifyContent="center">
                            <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
                                <Typography color="textPrimary"
                                            variant="overline">Offerta</Typography>
                                <br/>
                                <Button
                                    //! DISATTIVATO PER EVITARE DISAGI
                                    //disabled
                                    //! DISATTIVATO PER EVITARE DISAGI
                                    fullWidth
                                    style={{
                                        padding: 0,
                                        height: Constants.sizes.bigButtonHeight,
                                    }}
                                    id="offerta"
                                    // startIcon={
                                    //   <FontAwesomeIcon icon={faUser} size="sm" />
                                    // }
                                    variant="contained"
                                    size="large"
                                    // color={
                                    //   this.state.dataOrdine.cliente.ragione_sociale
                                    //     ? "textPrimary"
                                    //     : "auto"
                                    // }
                                    onClick={() => handleOffertaDialogOpen()
                                    }
                                >
                                    {dataOrdine.offerta.id ||
                                        "Ordine generico"}
                                </Button>
                            </Grid>
                            <Grid item
                                  xs={12}
                                  md={4}
                                  style={{textAlign: 'center', width: '100%'}}>
                                <Collapse style={{width: '100%'}} orientation={'vertical'}
                                          in={dataOrdine.tipo === "ordine"}>
                                    <Typography color="textPrimary" variant="overline">Sede</Typography>
                                    <br/>
                                    <ToggleButtonGroup
                                        value={dataOrdine.id_sede}
                                        exclusive
                                        onChange={(_, val) => selectBranch(val)}
                                        style={{width: "100%"}}
                                    >
                                        <ToggleButton
                                            style={{
                                                width: "100%",
                                                height: Constants.sizes.bigButtonHeight,
                                                padding: 0,
                                            }}
                                            value={1}
                                        >
                                            Feroleto A.
                                        </ToggleButton>

                                        <ToggleButton
                                            style={{
                                                width: "100%",
                                                height: Constants.sizes.bigButtonHeight,
                                                padding: 0,
                                            }}
                                            value={2}
                                        >
                                            Rende
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
                                <Typography color="textPrimary" variant="overline">Cliente</Typography>
                                <br/>
                                <Button
                                    fullWidth
                                    style={{
                                        padding: 0,
                                        height: Constants.sizes.bigButtonHeight,
                                    }}
                                    id="cliente-offerta"
                                    // startIcon={
                                    //   <FontAwesomeIcon icon={faUser} size="sm" />
                                    // }
                                    variant="contained"
                                    size="large"
                                    onClick={handleSearchCustomerDialogOpen}
                                >
                                    {dataOrdine.cliente.ragione_sociale ||
                                        "Seleziona cliente"}
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                style={{textAlign: "center"}}
                            >
                                <Typography color="textPrimary" variant="overline">
                                    Costi spedizione
                                </Typography>
                                <br/>
                                <ToggleButtonGroup
                                    value={dataOrdine.costi_spedizione}
                                    exclusive
                                    onChange={(_, val) => selectShipping(val, true)}
                                    style={{width: "100%"}}
                                >
                                    <ToggleButton
                                        style={{
                                            width: "100%",
                                        }}
                                        value={0}
                                    >
                                        {matches.md ? "Gratis" : "Grat."}
                                    </ToggleButton>

                                    {/*<ToggleButton*/}
                                    {/*    style={{*/}
                                    {/*        width: "100%",*/}
                                    {/*    }}*/}
                                    {/*    value={3.5}*/}
                                    {/*>*/}
                                    {/*    3,50 €*/}
                                    {/*</ToggleButton>*/}
                                    {/*<ToggleButton*/}
                                    {/*    style={{*/}
                                    {/*        width: "100%",*/}
                                    {/*    }}*/}
                                    {/*    value={3.9}*/}
                                    {/*>*/}
                                    {/*    3,90 €*/}
                                    {/*</ToggleButton>*/}
                                    <ToggleButton
                                        style={{
                                            width: "100%",
                                        }}
                                        value={4.9}
                                    >
                                        4,90 €
                                    </ToggleButton>
                                    <TextField
                                        value={typeof dataOrdine.costi_spedizione === 'number' && (dataOrdine.costi_spedizione === 0 || dataOrdine.costi_spedizione === 4.9)
                                            ? ""
                                            : String(dataOrdine.costi_spedizione)?.replace('.', ',')
                                        }
                                        placeholder={"Altro"}
                                        fullWidth
                                        inputMode={'numeric'}
                                        onChange={e => selectShipping(preFormatNumberForInputChange(e.target.value))}
                                        onBlur={e => selectShipping(preFormatNumberForInputChange(e.target.value), true)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                {" €"}
                                            </InputAdornment>,
                                            sx: {
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0
                                            }
                                        }}
                                    />
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    fullWidth
                                    label="Note"
                                    multiline
                                    rows="3"
                                    value={dataOrdine.note}
                                    onChange={e => handleOrderNotesChange(e.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        {
                            dataOrdine?.offerta?.tipo !== 'semplice'
                                ?
                                <>
                                    <br/>
                                    <Divider/>
                                    <br/>
                                    <Typography variant="overline"
                                                color={'text.secondary'}>
                                        Tipo offerta: <strong>{dataOrdine.offerta.tipo}</strong>
                                    </Typography>
                                </>
                                : null
                        }
                        {
                            dataOrdine?.offerta?.note?.length > 1 ? (
                                    <>
                                        <br/>
                                        <Divider/>
                                        <br/>
                                        <Typography variant="overline">
                                            Note per questa offerta
                                        </Typography>
                                        <Typography
                                            className="offer-notes"
                                            variant="body2"
                                            align="left"
                                            style={{whiteSpace: "pre-line"}}
                                        >{dataOrdine.offerta.note}
                                        </Typography>
                                    </>
                                ) :
                                null}
                        {/*</CardContent>*/}
                        {/*</Card>*/}
                        {/*PRODOTTI*/}
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <br/>
                        <Divider/>
                        <br/>

                        <Typography variant={"h2"} align={"center"}
                                    style={{marginBottom: context.theme.spacing(2)}}>
                            Articoli
                        </Typography>
                        {dataOrdine.articoli
                            // .slice(
                            //     (pagination.thisPage - 1) *
                            //     pagination.pageSize,
                            //     pagination.thisPage *
                            //     pagination.pageSize
                            // )
                            .map((a) => {
                                    const theseErrors =
                                        cannotSendReasons.filter(r => r.id === a.id && r.type.includes('product'));
                                    return (<SchedaProdotto
                                        index={dataOrdine.articoli.findIndex(ae => ae.id === a.id) + 1}
                                        key={a.id}
                                        offer={dataOrdine.offerta}
                                        product={a}
                                        customer={dataOrdine.cliente}
                                        orderType={dataOrdine.tipo}
                                        handleProductRemove={(a) =>
                                            removeProduct(a)
                                        }
                                        isPointsOffer={dataOrdine.offerta.tipo === 'punti'}
                                        usesPoints={
                                            dataOrdine.offerta.tipo === 'punti' && a.options?.punti?.uso
                                        }
                                        points={dataOrdine.offerta?.tipo === 'punti'
                                            ? returnPointsByPrice(parseFloat(a.user_prezzo as string), a.options, a)
                                            : 0}
                                        availablePoints={
                                            dataOrdine.offerta?.tipo === 'punti'
                                                ? dataOrdine.totale_ordine_punti_disponibili
                                                : 0
                                        }
                                        totalPoints={
                                            dataOrdine.offerta?.tipo === 'punti'
                                                ? dataOrdine.totale_ordine_punti_accumulati
                                                : 0
                                        }
                                        minPossiblePrice={
                                            returnMinPossiblePrice(a, dataOrdine)
                                        }
                                        handleProductChange={handleProductChange}
                                        handleProductChangeAnyBlur={(prod: Prodotto) => handleProductAnyBlur(prod)}
                                        errors={theseErrors}
                                    />)
                                }
                            )
                        }
                        {dataOrdine.articoli.length >
                            pagination.pageSize && (
                                <Grid item xs={12}>
                                    <Pagination
                                        style={{display: "flex", justifyContent: "center"}}
                                        count={Math.ceil(
                                            dataOrdine.articoli.length /
                                            pagination.pageSize
                                        )}
                                        page={pagination.thisPage}
                                        onChange={(_, p) => handlePageChange(p)}
                                    />
                                </Grid>
                            )}
                        <br/>
                        <Grid item container xs={12}>
                            <ButtonGroup
                                fullWidth
                                disableElevation
                                sx={{alignItems: 'normal'}}
                            >
                                <Grid item xs={8}

                                >
                                    <Button
                                        disableElevation
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            alignItems: 'normal'
                                        }}
                                        onClick={handleProductDialogOpen}
                                        endIcon={
                                            <AddCircleRounded
                                                // sx={{verticalAlign: 'sub'}}
                                            />
                                        }>
                                        Aggiungi
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        disabled={!dataOrdine.cliente.id}
                                        disableElevation
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleProductRestockDialogOpen}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            borderLeft: "solid 1px",
                                            borderColor:
                                                context.theme.palette.grey[400],
                                            alignItems: 'normal'
                                        }}
                                        endIcon={
                                            <ReplayCircleFilledRounded/>
                                        }>
                                        Riordina
                                    </Button>
                                </Grid>
                            </ButtonGroup>
                        </Grid>
                        <br/>
                        <Grid
                            container
                            item
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <Grid item>
                                <Card
                                    variant={'outlined'}
                                    style={{
                                        marginTop: context.theme.spacing(4),
                                        marginBottom: context.theme.spacing(4),
                                        maxWidth: "24em",
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6">
                                            Sconto chiusura
                                        </Typography>
                                        <Typography variant="caption">
                                            Valore merce:{" "}
                                            <strong>
                                                {formatPrice(dataOrdine.totale_ordine_merci || "N/D")}
                                            </strong>
                                        </Typography>
                                        <br/>
                                        <br/>
                                        <Grid container justifyContent="space-between" spacing={2}>
                                            <Grid item xs={8}>
                                                <ToggleButtonGroup
                                                    orientation="vertical"
                                                    size="small"
                                                    value={
                                                        dataOrdine.sconto_chiusura_mod ||
                                                        "abs"
                                                    }
                                                    exclusive
                                                    onChange={(_, val) => val && handleOrderDiscountModeChange(val)}
                                                    style={{width: "100%"}}
                                                >
                                                    <ToggleButton
                                                        size="small"
                                                        style={{
                                                            width: "100%",
                                                            height: Constants.sizes.bigButtonHeight,
                                                            padding: 0,
                                                        }}
                                                        value="abs"
                                                    >
                                                        Assoluto
                                                    </ToggleButton>

                                                    <ToggleButton
                                                        size="small"
                                                        style={{
                                                            width: "100%",
                                                            height: Constants.sizes.bigButtonHeight,
                                                            padding: 0,
                                                        }}
                                                        value="per"
                                                    >
                                                        Percentuale
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item container xs={4}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        className="input-align-right"
                                                        // type="number"
                                                        placeholder="0,00"
                                                        //variant="outlined"
                                                        // style={{ maxWidth: "7rem" }}
                                                        value={
                                                            preFormatNumberForInputChange(
                                                                dataOrdine.sconto_chiusura)
                                                        }

                                                        onChange={
                                                            e =>
                                                                handleOrderDiscountValueChange(
                                                                    preFormatNumberForInputChange(e.target.value)
                                                                )
                                                        }

                                                        onBlur={e => handleOrderDiscountValueChange(
                                                            preFormatNumberForInputChange(e.target.value), true
                                                        )}

                                                        onFocus={e => {
                                                            if (!(parseFloat(dataOrdine.sconto_chiusura as string) > 0)) e.target.select(); //Seleziona tutto se 0
                                                        }
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    {dataOrdine.sconto_chiusura_mod === "abs" ? "€" : "%"}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} style={{alignSelf: "center"}}>
                                                    <Typography align="right">
                                                        -{" "}
                                                        {dataOrdine
                                                            .sconto_chiusura_mod === "per"
                                                            ? formatPrice(dataOrdine.totale_ordine_sconto_chiusura) // Calcola in Euro
                                                            : calculatedPercDiscount.toFixed(2) + " %" // Calcola in percentuale
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="caption">
                                                    Calcolato sull'imponibile delle merci
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card
                                    variant="outlined"
                                    style={{
                                        marginTop: context.theme.spacing(4),
                                        marginBottom: context.theme.spacing(4),
                                        maxWidth: "16em",
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                        textAlign: 'right'
                                    }}
                                >
                                    <CardContent>
                                        <Grid container>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                justifyContent="space-between"
                                            >
                                                <Grid item xs={5}>
                                                    <Typography
                                                        variant="overline"
                                                        style={{
                                                            color:
                                                            context.theme.palette.text
                                                                .disabled,
                                                        }}
                                                    >
                                                        Imponibile
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            color:
                                                            context.theme.palette.text
                                                                .disabled,
                                                        }}
                                                    >
                                                        {
                                                            dataOrdine.totale_ordine_imponibile
                                                                .toFixed(2)
                                                                .replace(".", ",") + " €"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={"auto"}>
                                                    <Divider orientation="vertical"/>
                                                </Grid>

                                                <Grid item xs={5}>
                                                    <Typography
                                                        variant="overline"
                                                        style={{
                                                            color:
                                                            context.theme.palette.text
                                                                .disabled,
                                                        }}
                                                    >
                                                        IVA
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            color:
                                                            context.theme.palette.text.disabled,
                                                        }}
                                                    >
                                                        {
                                                            dataOrdine.totale_ordine_iva
                                                                .toFixed(2)
                                                                .replace(".", ",") + " €"}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <br/>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                justifyContent="space-between"
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant="overline">
                                                        Totale
                                                    </Typography>
                                                    <Typography variant="h4" sx={{fontWeight: "bold"}}>
                                                        {
                                                            dataOrdine.totale_ordine
                                                                .toFixed(2)
                                                                .replace(".", ",") + " €"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}
                                                      sx={{padding: context.theme.spacing(1)}}>
                                                    <Divider/>
                                                </Grid>
                                                <Grid item xs={12}
                                                      sx={{textAlign: 'center', width: '100%'}}>
                                                    <Typography color={'text.secondary'}
                                                                variant={'caption'} sx={{
                                                        textAlign: 'center',
                                                        width: '100%'
                                                    }}>
                                                        {dataOrdine.cliente.id_cond_pagamento ? `${dataOrdine.cliente.nome_cond_pagamento}` : ""}
                                                    </Typography>
                                                </Grid>
                                                {dataOrdine.offerta?.tipo === 'punti' &&
                                                    <>
                                                        <Grid item xs={12}
                                                              sx={{padding: context.theme.spacing(1)}}>
                                                            <Divider/>
                                                        </Grid>
                                                        <Grid item xs={12}
                                                              sx={{textAlign: 'right', width: '100%'}}>
                                                            <Typography
                                                                color={'text.secondary'}
                                                                variant={'overline'} sx={{
                                                                textAlign: 'right',
                                                                width: '100%'
                                                            }}>
                                                                Punti accumulati
                                                            </Typography>
                                                            <Typography variant="h6" sx={{
                                                                fontWeight: "bold",
                                                                color: context.theme.palette.success.main
                                                            }}>
                                                                {dataOrdine.totale_ordine_punti_accumulati
                                                                    .toFixed(2)
                                                                    .replace(".", ",") + "€"}
                                                            </Typography>
                                                        </Grid>
                                                    </>}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            marginTop: "0",
                        }}
                    >
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            style={{
                                padding: !matches.sm
                                    ? context.theme.spacing(1) //XS
                                    : !matches.md
                                        ? context.theme.spacing(2) //SM
                                        : context.theme.spacing(4), //MD+
                                paddingTop: 0,
                            }}
                        >
                            <Grid item xs={12} md={3}>
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        confirm({
                                            title: "Confermi l'annullamento?"
                                        }).then(() => initNewOrder()).catch(() => console.log('Eliminazione ordine annullata.'))
                                    }}
                                    size="large"
                                    variant="outlined"
                                >
                                    Annulla
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Button
                                    disabled={isOrderEmpty}
                                    fullWidth
                                    size="large"
                                    variant="outlined"
                                    onClick={_ => sendOrder(true)}>
                                    Anteprima PDF
                                </Button>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Button
                                    disabled={isOrderEmpty}
                                    fullWidth
                                    size="large"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleSaveDraft()}
                                >
                                    Salva bozza
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Tooltip
                                    title={
                                        !!cannotSendReasons.length
                                            ? "Compila tutti i campi richiesti"
                                            : ""
                                    }
                                    arrow={true}
                                >
                                                <span style={{display: "flex", height: "100%"}}>
                                                  <Button
                                                      fullWidth
                                                      disabled={!!cannotSendReasons.length}
                                                      size="large"
                                                      variant="contained"
                                                      color={dataOrdine.tipo === 'preventivo' ? 'warning' : 'primary'}
                                                      onClick={
                                                          () => {
                                                              handleConfirmOrderDialogOpen(true);
                                                          }
                                                      }
                                                      endIcon={<Send/>}
                                                  >
                                                    Invia {dataOrdine.tipo === 'preventivo' ? "Preventivo" : "Ordine"}
                                                  </Button>
                                                </span>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: "center"}}>
                                {!cannotSendReasons.length
                                    ? (
                                        <Typography variant="caption">
                                            Ti verrà chiesta conferma prima dell'invio.
                                        </Typography>
                                    )
                                    : <>{cannotSendReasons.map(r =>
                                        <Typography color={'error'} key={r.id}>
                                            {r.message}
                                        </Typography>
                                    )}
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
            <CustomerSelector
                open={searchCustomerDialogOpen}
                onClose={() => handleSearchCustomerDialogClose()}
                onOpen={() => handleSearchCustomerDialogOpen()}
                onSelect={(c: TCliente) => selectCustomer(c)}
            />
            <OffertaSelector
                open={searchOfferDialogOpen}
                onClose={() => handleOffertaDialogClose()}
                onOpen={() => handleOffertaDialogOpen()}
                onSelect={(off: TOfferta) => {
                    selectOfferta(off);
                    handleOffertaDialogClose();
                }}
                onEmpty={() => emptyOfferta()}
                emptyText={"Default"}
                emptyButtonVariant="contained"
                disableLoadingBackdrop
                maxResults={1000}
            />
            {
                dataOrdine.offerta.id != "DEFAULT" ? (
                    <Offerta_ProductSelector
                        showInventory
                        showProdImg
                        fullScreen
                        multiple
                        key={dataOrdine.offerta.id}
                        open={searchProductDialogOpen}
                        onClose={() => handleProductDialogClose()}
                        onOpen={() => handleProductDialogOpen()}
                        onSelect={(p: Prodotto) => selectProduct(p)}
                        idOfferta={dataOrdine.offerta.id}
                        onFetchingChange={onFetchingChange}
                        selectedIds={dataOrdine.articoli.map((a) => a.id)}
                    />
                ) : (
                    <ProductSelector
                        showInventory
                        showProdImg={!context.device.lowSpec}
                        fullScreen
                        multiple
                        open={searchProductDialogOpen}
                        onClose={() => handleProductDialogClose()}
                        onOpen={() => handleProductDialogOpen()}
                        onSelect={(p: Prodotto) => selectProduct(p)}
                        onFetchingChange={onFetchingChange}
                        filter={"vendita"}
                        selectedIds={dataOrdine.articoli.map((a) => a.id)}
                    />
                )
            }
            <Restock_ProductSelector
                key={dataOrdine.cliente.id}
                showInventory
                showProdImg={!context.device.lowSpec}
                fullScreen
                showTitle
                multiple
                returnHistory={false}
                id_cliente={dataOrdine.cliente.id}
                open={searchProductRestockDialogOpen}
                onClose={() => handleProductRestockDialogClose()}
                onOpen={() => handleProductRestockDialogOpen()}
                onSelect={(p: Prodotto) => selectProduct(p)}
                onFetchingChange={(i: boolean) => onFetchingChange(i)}
                filter={"vendita"}
                selectedIds={dataOrdine.articoli.map((a) => a.id)
                }
            />
            <Dialog
                scroll="body"
                fullWidth
                maxWidth="sm"
                TransitionComponent={SlideUpTransition as React.JSXElementConstructor<any>}
                onClose={() => handleConfirmOrderDialogOpen(false)}
                open={confirmOrderDialogOpen}
                PaperProps={{
                    sx: {
                        ...(dataOrdine.tipo === 'preventivo' ? {
                            borderColor: context.theme.palette.warning.main,
                            borderStyle: 'solid',
                            borderWidth: '2px',
                        } : {})
                    }
                }}
            >
                <DialogTitle>Inviare {dataOrdine.tipo === 'preventivo' ? "il preventivo" : "l'ordine"}?</DialogTitle>
                <DialogContent
                    style={{
                        textAlign: "center",
                        overflowY: "hidden"
                    }}
                >
                    <Grid
                        container
                        style={{
                            textAlign: "center",
                            fontSize: 80,
                            marginBottom: context.theme.spacing(2),
                        }}
                    >
                        <Grid item xs={4}>
                            <ContentPaste sx={largeIconSx}/>
                        </Grid>
                        <Grid item xs={4}>
                            <motion.div
                                animate={{
                                    y: [-10],
                                    x: [-120, 0, 120],
                                    opacity: [0, 1, 0],
                                }}
                                transition={{
                                    ease: "linear",
                                    duration: 4,
                                    repeat: Infinity
                                }}
                            >
                                <SendRounded fontSize={'large'}/>
                            </motion.div>
                        </Grid>
                        <Grid item xs={4}>
                            <Cloud style={{fontSize: 60}}/>
                        </Grid>
                    </Grid>
                    {/*<Divider/>*/}
                    <Typography
                        style={{width: "100%"}}
                        variant="overline"
                        align="center"
                    >
                        Riassunto
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell {...recapTableHeadProps}>Agente</TableCell>
                                    <TableCell {...recapTableBodyProps}>{context.user.firstName + " " + context.user.lastName}</TableCell>
                                </TableRow>
                                {dataOrdine.tipo === 'ordine' &&
                                    <TableRow>
                                        <TableCell {...recapTableHeadProps}>Sede</TableCell>
                                        <TableCell {...recapTableBodyProps}>{dataOrdine.id_sede === 1 ? "Feroleto Antico" : "Rende"}</TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell {...recapTableHeadProps}>Cliente</TableCell>
                                    <TableCell {...recapTableBodyProps}>{"(" + dataOrdine.cliente.id + ") " + dataOrdine.cliente.ragione_sociale}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell {...recapTableHeadProps}>Spedizione</TableCell>
                                    <TableCell {...recapTableBodyProps}>{dataOrdine.costi_spedizione ? formatPrice(dataOrdine.costi_spedizione) : "Gratis"}</TableCell>
                                </TableRow>
                                {dataOrdine.offerta.id !== "DEFAULT" &&
                                    <TableRow>
                                        <TableCell {...recapTableHeadProps}>Offerta</TableCell>
                                        <TableCell {...recapTableBodyProps}>{"(" + dataOrdine.offerta.id + ") " + dataOrdine.offerta.titolo}</TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell {...recapTableHeadProps}>Totale ordine</TableCell>
                                    <TableCell {...recapTableBodyProps}>{formatPrice(dataOrdine.totale_ordine) + " IVA incl."}</TableCell>
                                </TableRow>
                                {
                                    dataOrdine.note &&
                                    <TableRow>
                                        <TableCell {...recapTableHeadProps}>Note</TableCell>
                                        <TableCell {...recapTableBodyProps}>{dataOrdine.note}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Divider/>
                    <br/>
                    <FormControl
                        disabled={!dataOrdine.cliente.email}
                        style={{width: "100%"}}
                        component="fieldset"
                    >
                        <FormGroup>
                            <FormControlLabel
                                style={{alignSelf: "end"}}
                                control={<Switch color="primary"/>}
                                onChange={e => setDataOrdine(prevState => ({
                                    ...prevState,
                                    sendToCustomer: (e.target as HTMLInputElement).checked
                                }))
                                }
                                label={
                                    "Inviare al cliente? (" +
                                    (dataOrdine.cliente.email || "N/D") +
                                    ")"
                                }
                                labelPlacement="start"
                                checked={dataOrdine.sendToCustomer}
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={"error"}
                        onClick={() => {
                            handleConfirmOrderDialogOpen(false);
                        }}
                    >
                        Annulla
                    </Button>
                    <Button
                        disabled={fetching}
                        variant="contained"
                        onClick={() => sendOrder()}
                        color="primary"
                    >
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default OrdinePanel;
