import axios from "axios";
import Constants from "../context/Constants";
import {Prodotto} from "../interfaces/prodotti/Prodotto";

export default async function getProducts(
    ids: [number] | number
): Promise<any> {
    const token = localStorage.getItem("token");
    let idsString;
    if (!ids) return null;
    if (typeof ids === "object") {
        idsString = ids.join(",");
    } else {
        idsString = ids;
    }
    let req = await axios.get(
        `${Constants.paths.ajaxBasePath}prodotti/?token=${token}&ids=${idsString}`
    );
    return typeof ids === "object" ? req.data : req.data[0];
}

export async function getProductDescriptionMeta(
    id: number
): Promise<Prodotto> {
    const token = localStorage.getItem("token");
    let data: any | Prodotto;
    try {
        let req = await fetch(`${Constants.paths.ajaxBasePath}prodotti/esamina/?token=${token}&id=${id}`);
        if (!req.ok) {
            throw new Error(req.statusText)
        }
        data = await req.json() as any;
        data['img'] = parseInt(data['img']);
    } catch (error) {
        console.error(error);
    }
    return data as Prodotto;
}