import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    IconButtonOwnProps,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {CSSProperties, useContext, useEffect, useState} from "react";
import {Biotech, WarehouseRounded} from "@mui/icons-material";
import {ZoomTransition} from "./Transitions";
import {Prodotto} from "../interfaces/prodotti/Prodotto";
import {getProductDescriptionMeta} from "../functions/getProducts";
import ProductImage from "./ProductImage";
import Context from "../context/Context";
import formatPrice from "../functions/formatPrice";
import Feedback from "./Feedback";
import {enqueueSnackbar} from "notistack";
import Barcode from "react-barcode";
import useBreakpoints from "../functions/breakpoints";

function ButtonComponentDefault({size = 'medium', style, onClick}: {
    size?: IconButtonOwnProps['size'],
    style?: CSSProperties,
    onClick: () => void
}) {
    return <Tooltip title={'Esamina'}>
        <IconButton size={size} style={style} onClick={() => onClick()}>
            <Biotech/>
        </IconButton>
    </Tooltip>
}

function ProductExtendedPopup({
                                  id_prod,
                                  buttonStyle,
                                  buttonSize,
                                  ButtonComponent = ButtonComponentDefault,
                                  disabled = false
                              }: {
    id_prod: number,
    buttonSize?: IconButtonOwnProps['size'],
    buttonStyle?: CSSProperties,
    ButtonComponent?: any,
    disabled?: boolean
}) {
    //region Context
    const context = useContext(Context);
    const matches = useBreakpoints();
    //endregion

    //region State
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<Prodotto>(null);
    //endregion

    // region Hooks
    useEffect(() => {
        if (!data && open) //Esegui soltanto alla prima apertura
            getProductDescriptionMeta(id_prod).then(d => setData(d));
    }, [open]);

    useEffect(() => {
        if (data) //Esegui soltanto se cambia ID
            getProductDescriptionMeta(id_prod).then(d => setData(d));
    }, [id_prod]);
    //endregion

    //region Functions
    const handleOpen = (): void => {
        setOpen(true);
    }
    const handleClose = (): void => {
        setOpen(false);
    }
    //endregion


    return <>
        <ButtonComponent style={buttonStyle} size={buttonSize} onClick={() => handleOpen()}/>
        <Dialog open={open}
                maxWidth={"md"}
                fullWidth={true}
                TransitionComponent={ZoomTransition as any}
                onClose={() => handleClose()}>
            <DialogTitle>Esamina Prodotto {data?.cod}</DialogTitle>
            <DialogContent>
                {data ? <Grid container spacing={3}>
                        <Grid container item xs={12} md={4} sx={{alignContent: 'start'}}>
                            <Grid item xs={12} sx={{maxHeight: 'fit-content', textAlign: 'center'}}>
                                <ProductImage
                                    avatarStyle={{height: 'auto'}}
                                    buttonStyle={{height: 'min-content', alignItems: 'start'}}
                                    containerSize={"100%"}
                                    sku={data.cod}
                                    img={data.img}
                                />
                            </Grid>
                            <Grid item xs={12}
                                  sx={{
                                      paddingTop: context.theme.spacing(2),
                                      textAlign: 'center',
                                  }}>
                                {data?.cod_barre && (
                                    <div
                                        style={{
                                            display: "block",
                                            left: 0,
                                            right: 0,
                                            margin: "auto",
                                            padding: 2,
                                            borderRadius: context.theme.shape.borderRadius,
                                            overflow: 'hidden',
                                            background: 'white',
                                            width: 'min-content'
                                        }}
                                    >
                                        <Barcode
                                            value={data.cod_barre}
                                            //margin='auto'
                                            height={20}
                                            width={1.9}
                                            fontSize={14}
                                        />
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {data.categoria &&
                                <>
                                    <Typography variant={'caption'}
                                                width={'100%'}>
                                        {data.categoria.percorso.replaceAll('>', ' > ')}
                                    </Typography>
                                    <br/>
                                </>
                            }
                            <Typography variant={'subtitle1'}
                                        fontSize={context.theme.typography.fontSize * 1.75}>
                                {data.brand.nome}
                            </Typography>
                            <Typography variant={'caption'}>
                                {data.uf_cod || data.old_cod}
                            </Typography>
                            <br/>
                            <Typography variant={'h2'}>
                                {formatPrice(data.prezzo_l2)} <small style={{fontSize: '50%', fontWeight: 'normal'}}>IVA
                                escl.</small>
                            </Typography>
                            <br/>
                            <Stack direction={'row'}
                                   sx={{
                                       ...matches.md ? {marginLeft: "-.5em"} : {
                                           left: 0,
                                           right: 0,
                                           margin: 'auto',
                                       },
                                       borderRadius: context.theme.shape.borderRadius,
                                       borderColor: context.theme.palette.divider,
                                       borderWidth: 1,
                                       borderStyle: 'solid',
                                       paddingY: context.theme.spacing(.5),
                                       paddingX: context.theme.spacing(1.5),
                                       width: 'max-content'
                                   }}
                                   spacing={2}>
                                <Typography variant={'body2'}>Feroleto: <strong style={{
                                    ...data.stock_feroleto > 0
                                        ? {} : {color: context.theme.palette.warning.main}
                                }}>{data.stock_feroleto}</strong></Typography>
                                <div><WarehouseRounded fontSize={'small'}/></div>
                                <Typography variant={'body2'}>Rende: <strong style={{
                                    ...data.stock_rende > 0
                                        ? {} : {color: context.theme.palette.warning.main}
                                }}>{data.stock_rende}</strong></Typography>
                            </Stack>
                            <br/>
                            <Typography variant={'h4'}
                                        sx={{lineHeight: 'normal'}}
                                        fontSize={context.theme.typography.fontSize * 1.2}>
                                {data.descrizione}
                            </Typography>
                            {data.attrs?.length &&
                                <>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <Typography variant={'subtitle1'} align={'center'}>
                                        Proprietà
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {data.attrs.map(a => a.valore &&
                                                    <TableRow>
                                                        <TableCell align={'right'}>
                                                            {a.nome}
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                {
                                                                    a.tipo === 'bool'
                                                                        ? (a.valore == '1' ? "sì" : "no")
                                                                        : a.valore
                                                                }
                                                            </strong>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>}
                        </Grid>
                    </Grid>
                    : <Grid container sx={{textAlign: 'center'}} spacing={1}>
                        <Grid item xs={12}>
                            <CircularProgress/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>Caricamento...</Typography>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Feedback
                    info={`(${data?.cod || data?.old_cod}) ${data?.uf_cod} - ${data?.descrizione}`}/>
                <Button disabled={disabled} onClick={() => handleClose()}>Chiudi</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default ProductExtendedPopup;