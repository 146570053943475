import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {Card, Divider, Grid, Typography} from "@mui/material";

const ListInventoryDisplay = ({
                                  id_prod,
                                  extended,
                                  style,
                                  long,
                                  useDirectStocks = false,
                                  directFeroleto,
                                  directRende,
                                  directOfferte,
                                  directUseOnlyOfferte = false,
                                  withTotal = false
                              }: {
    id_prod: number;
    extended?: boolean;
    style?: React.CSSProperties;
    long?: boolean;
    useDirectStocks?: boolean;
    directFeroleto?: number;
    directRende?: number;
    directOfferte?: number;
    directUseOnlyOfferte?: boolean;
    withTotal?: boolean;
}) => {
    const context = useContext(Context);

    const [stockRende, setStockRende] = useState(0);
    const [stockFeroleto, setStockFeroleto] = useState(0);
    const [stockOfferte, setStockOfferte] = useState(null);
    const [useOnlyOfferte, setUseOnlyOfferte] = useState(false);
    const [total, setTotal] = useState(0);
    const [fetching, setFetching] = useState(false);

    const getData = (callback: boolean | (() => void) = false) => {
        setFetching(true);
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                `inventario/?simple&id_prod=${id_prod}&token=${context.user.token}`
            )
            .then((res) => {
                finalData = res.data.map((d: {
                    stock_rende: number;
                    stock_feroleto: number;
                    stock_offerte: number;
                    stato_prod: number;
                }) => ({
                    //   ...d,
                    stock_rende: d.stock_rende,
                    stock_feroleto: d.stock_feroleto,
                    stock_offerte: d.stock_offerte,
                    _stato: d.stato_prod
                    //   id: parseInt(d.id),
                }))[0];
                // console.log(finalData);
                setStockOfferte(finalData?.stock_offerte)
                setStockRende(finalData?.stock_rende || 0);
                setStockFeroleto(finalData?.stock_feroleto || 0);
                if (finalData._stato == 6) { // stock offerte is defined and can be also 0
                    setUseOnlyOfferte(true);
                }
                setFetching(false);
                (typeof callback === 'function') && callback();
            });
    }

    useEffect(() => {
        let updateTimer = null;
        if (useDirectStocks) {
            if (directUseOnlyOfferte) {
                setUseOnlyOfferte(true);
            }
        } else {
            getData();
            updateTimer = setInterval(() => getData(), Constants.heartbeat.slow);
        }
        return () => clearInterval(updateTimer);
    }, []);

    useEffect(() => {
        setTotal((useDirectStocks ? directRende : stockRende)
            + (useDirectStocks ? directFeroleto : stockFeroleto)
            + (useDirectStocks ? directOfferte : stockOfferte));
    }, [
        useDirectStocks,
        directRende,
        directFeroleto,
        directOfferte,
        stockRende,
        stockFeroleto,
        stockOfferte
    ]);

    return (!(useOnlyOfferte && !(typeof (useDirectStocks ? directOfferte : stockOfferte) == 'string'
        || typeof (useDirectStocks ? directOfferte : stockOfferte) == 'number')))
        ? (<Card
            elevation={0}
            variant={extended ? "outlined" : "elevation"}
            style={{
                overflow: 'visible',
                ...(extended ? {
                        paddingLeft: context.theme.spacing(1),
                        paddingRight: context.theme.spacing(1),
                        paddingTop: context.theme.spacing(0.5),
                        paddingBottom: context.theme.spacing(0.5),
                    }
                    : {background: 'none'}),
                minWidth: context.theme.spacing(4),
                ...style,
            }}
        >
            {!(useOnlyOfferte) ?
                <Grid container spacing={long ? 2 : 0}>
                    <Grid container item xs={long ? 6 : 12}>
                        <Grid item xs={2}>
                            <Typography variant="caption">
                                {extended ? "Feroleto" : "F"}
                            </Typography>
                        </Grid>
                        <Grid item xs={10} style={{textAlign: "right"}}>
                            <Typography variant="caption">
                                <strong>{useDirectStocks ? (directFeroleto || 0) : stockFeroleto}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={long ? 6 : 12}
                        style={{
                            ...(long
                                ? {
                                    borderLeftWidth: 1,
                                    borderLeftColor: context.theme.palette.divider,
                                    borderLeftStyle: "solid",
                                }
                                : {}),
                        }}
                    >
                        <Grid item xs={2}>
                            <Typography variant="caption">
                                {extended ? "Rende" : "R"}
                            </Typography>
                        </Grid>
                        <Grid item xs={10} style={{textAlign: "right"}}>
                            <Typography variant="caption">
                                <strong>{useDirectStocks ? (directRende || 0) : stockRende}</strong>
                            </Typography>
                        </Grid>
                        {
                            withTotal && <>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="caption">
                                        {extended ? "Totale" : "T"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} style={{textAlign: "right"}}>
                                    <Typography variant="caption">
                                        <strong>{total}</strong>
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                : <Grid>
                    <Grid container spacing={long ? 2 : 0}>
                        <Grid container item xs={long ? 6 : 12} style={{
                            ...(long
                                ? {
                                    borderLeftWidth: 1,
                                    borderLeftColor: context.theme.palette.divider,
                                    borderLeftStyle: "solid",
                                }
                                : {}),
                        }}>
                            <Grid item xs={2} sx={{display: "flex", alignSelf: "center"}}>
                                <Typography variant="caption" style={{color: context.theme.palette.warning.main}}>
                                    {extended ? "Disponibilità offerta" : "D"}
                                </Typography>
                            </Grid>
                            <Grid item xs={10}
                                  style={{justifyContent: "flex-end", display: "flex", alignSelf: "center"}}>
                                <Typography variant="caption" style={{color: context.theme.palette.warning.main}}>
                                    <strong>{useDirectStocks ? directOfferte : stockOfferte}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Card>) : <Card></Card>
}

export default ListInventoryDisplay;
