import React from "react";
import Context from "../context/Context";
import axios from "axios";
import Constants from "../context/Constants";
import {Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";


type User = {
    token: string;
}
type CellNotesContext = {
    user: User;
};

type TCellNotesProps = {};
type TCellNotesState = {
    value: string;
    cloudValue: string;
};

type TCellNotesCommercialiProps = {
    idProd: number;
};

type TCellNotesCommercialiState = {};

type TCellNotes = {
    apiPath: string;
    get: () => Promise<void>;
    put: (note: string) => Promise<boolean>;
    // delete:()=>Promise<void>;
    reset: () => Promise<void>;
    isUpToDate: () => boolean;
}

class CellNotes extends React.Component<any, any> {
    static contextType = Context;
    context: React.ContextType<any>;
    apiPath = "FILL_HERE";
    static defaultProps: {
        isDirect: false,
        requestUpdate: () => {}
    };

    constructor(props: any) {
        super(props);
        // States
        // @ts-ignore
        this.state = {
            value: "",
            cloudValue: ""
        }
    }

    // Functions
    protected get = async () => {
    }

    protected put = async (note: string) => false;

    protected reset = async () => {
        await this.get();
    }

    // @ts-ignore
    protected isUpToDate = () => this.state.value === this.state.cloudValue;

    componentDidMount() {
        this.get();
    }


    render() {
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return <>
            {/*<Popover open={!isUpToDate()} anchorEl={ref.current?.target}>*/}
            {/*    Modifiche non salvate!*/}
            {/*</Popover>*/}
            <TextField
                className={this.props.className}
                // ref={ref}
                variant={'outlined'}
                label={'Note'}
                value={this.state.value}
                onChange={ev => this.setState({value: ev.target.value})}
                multiline={true}
                minRows={5}
                // helperText={isUpToDate() || "Modifiche non salvate"}
                InputProps={{
                    // style: !saved ? {} : {borderColor: context.theme.palette.warning.main},
                    endAdornment:
                        <InputAdornment position={'end'} style={{textAlign: 'right'}}>
                            <Grid container justifyContent={'flex-end'}>
                                <Grid item xs={12}>
                                    <IconButton
                                        onClick={_ => this.reset()}
                                        disabled={this.isUpToDate() || !this.state.cloudValue}
                                        size="large">
                                        <FontAwesomeIcon size={'xs'} icon={faHistory}/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <IconButton
                                        disabled={!this.state.value}
                                        onClick={_ => this.put("").then(r => this.props.isDirect ? this.props.requestUpdate ? this.props.requestUpdate() : null : null)}
                                        size="large">
                                        <FontAwesomeIcon size={'xs'} icon={faTrash}/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <IconButton
                                        onClick={_ => this.put(this.state.value).then(r => this.props.isDirect ? this.props.requestUpdate ? this.props.requestUpdate() : null : null)}
                                        disabled={this.isUpToDate()}
                                        size="large">
                                        <FontAwesomeIcon size={'xs'} icon={faSave}
                                                         style={this.isUpToDate() ? {} : {
                                                             //@ts-ignore
                                                             color: this.context.theme.palette.warning.main
                                                         }}
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </InputAdornment>
                }}
            />
        </>;
    }
}

class CellNotesCommerciali extends CellNotes {
    // static contextType = Context;
    // static contextType = Context;
    //
    // context: React.ContextType<any>;
    apiPath = `${Constants.paths.ajaxBasePath}prodotti/meta/note-commerciali/`;

    componentDidUpdate(prevProps: any, prevState: any, snapshot?: any) {
        if (this.props.idProd !== prevProps.idProd) {
            this.get();
        }
    }

    protected get = async () => {
        if (this.props.directNotes) {
            this.setState({value: this.props.directNotes, cloudValue: this.props.directNotes});
            return;
        }
        // @ts-ignore
        let note = await axios.get(`${this.apiPath}?idProd=${this.props.idProd}&token=${this.context.user.token}`);
        this.setState({value: note.data, cloudValue: note.data});
    };

    protected put = async (note: string) => {
        let body: string | object = {
            // @ts-ignore
            token: this.context.user.token,
            idProd: this.props.idProd,
            note: note
        };
        body = JSON.stringify(body);
        try {
            let resp = await axios.put(`${this.apiPath}`, body);
        } catch (e) {
            console.error(e);
            alert("Le note non sono state salvate a causa di un errore di rete. [" + e + "]");
            return false;
        }
        await this.get();
        return true;
    }

    protected reset = async () => {
        await this.get();
    }
}

class CellNotesInventarioCompleto extends CellNotes {
    apiPath = `${Constants.paths.ajaxBasePath}inventario/completo/note/`;
    protected get = async () => {
        if (this.props.isDirect) {
            this.setState({value: this.props.value, cloudValue: this.props.value});
            return;
        }
        try {
            // @ts-ignore
            let note = await axios.get(`${this.apiPath}?id_row=${this.props.idRiga}&token=${this.context.user.token}`);
            this.setState({value: note.data, cloudValue: note.data});
        } catch (e) {
            console.error(e);
            // alert("Le note non sono state caricate a causa di un errore di rete. [" + e + "]");
            this.setState({value: "[ERRORE]", cloudValue: "[ERRORE]"});
        }
    };
    protected put = async (note: string) => {
        let body: string | object = {
            // @ts-ignore
            token: this.context.user.token,
            id_row: this.props.idRiga,
            note: note
        };
        body = JSON.stringify(body);
        try {
            let resp = await axios.put(`${this.apiPath}`, body);
        } catch (e) {
            console.error(e);
            alert("Le note non sono state salvate a causa di un errore di rete. [" + e + "]");
            return false;
        }
        if (!this.props.isDirect)
            await this.get();
        return true;
    };

    componentDidUpdate(prevProps: any, prevState: any, snapshot?: any) {

        if (this.props.isDirect)
            if (prevProps.value !== this.props.value)
                this.get();
        if (this.props.idRiga !== prevProps.idRiga) {
            this.get();
        }
    }
}

export {CellNotesCommerciali, CellNotesInventarioCompleto}