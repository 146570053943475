import {Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Constants from "../context/Constants";
import axios from "axios";
import Context from "../context/Context";
import moment from "moment";
import localization from "moment/locale/it";

moment.locale("it", localization);

function StoricoPrezziAcquisto({open, onClose, anchorEl, priceId}) {
    const [data, setData] = useState([]);
    const context = useContext(Context);
    const getData = () =>
        axios
            .get(
                Constants.paths.ajaxBasePath +
                `prezzi/storico/prezzo/?ids=${priceId}&token=${context.user.token}`
            )
            .then((res) => setData(res.data));
    //Al montaggio
    useEffect(() => {
        if (priceId) {
            getData();
        }
    }, [priceId]);
    return (
        <Popover
            onClose={onClose}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>UV</TableCell>
                            <TableCell>Prezzo</TableCell>
                            <TableCell>Prezzo U.</TableCell>
                            <TableCell>% 1</TableCell>
                            <TableCell>% 2</TableCell>
                            <TableCell>% 3</TableCell>
                            <TableCell>% 4</TableCell>
                            <TableCell>% 5</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((d) => (
                            <TableRow>
                                <TableCell>{d.legacy_uv}</TableCell>
                                <TableCell>{d.prezzo_1.replace(".", ",") + " €"}</TableCell>
                                <TableCell>
                                    {d.legacy_uv
                                        ? (parseFloat(d.prezzo_1) / parseInt(d.legacy_uv))
                                        .toFixed(3)
                                        .replace(".", ",") + " €"
                                        : d.prezzo_1}
                                </TableCell>
                                <TableCell>{d.sconto_1.replace(".", ",")}</TableCell>
                                <TableCell>{d.sconto_2.replace(".", ",")}</TableCell>
                                <TableCell>{d.sconto_3.replace(".", ",")}</TableCell>
                                <TableCell>{d.sconto_4.replace(".", ",")}</TableCell>
                                <TableCell>{d.sconto_5.replace(".", ",")}</TableCell>
                                <TableCell>
                                    {moment(d.data).format("DD/MM/YYYY") +
                                        " • " +
                                        moment(d.data).fromNow()}
                                </TableCell>
                                <TableCell>{d.note}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Popover>
    );
}

StoricoPrezziAcquisto.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.object.isRequired,
    priceId: PropTypes.number.isRequired,
};

export {StoricoPrezziAcquisto};
