import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Constants from "../context/Constants";
import Context from "../context/Context";
import {
    Autocomplete,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Checkbox,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Paper,
    Popover,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {SlideUpTransition} from "./Transitions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode, faEye, faEyeSlash, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ProductImage from "../components/ProductImage";

import "handy-scroll/dist/handy-scroll.css";
import BrandsDropdown from "./BrandsDropdown";
import CategoriesDropdown from "./CategoriesDropdown";
import ClassificationsDropdown from "./ClassificationsDropdown";

// Import
//import Sticky from "react-sticky-el";
import TipicalNamesDropdown from "./TipicalNamesDropdown";
import {ProductSelector, ProductTagsSelector} from "./DialogSelector";

// Dev
import PropTypes, {number} from "prop-types";
import escapeRegex from "../functions/escapeRegex";
import {enqueueSnackbar} from "notistack";

const isEqual = require("lodash.isequal");
const diff = require("fast-diff");

function AssortmentVariations({value, onAdd, onRemove}) {
    const [distinct, setDistinct] = useState([]);
    const context = useContext(Context);

    const getDistinct = async () => {
        let resp = await axios.get(
            `${Constants.paths.ajaxBasePath}prodotti/assortimento/varianti/valori-distinti/?token=${context.user.token}`
        );
        setDistinct(resp.data);
    }

    useEffect(() => {
        getDistinct().then();
    }, []);

    return <Card variant={'outlined'}>
        <CardContent>
            <Typography
                style={{
                    width: "100%",
                    textAlign: "center",
                    display: "block",
                }}
                variant="overline"
            >
                Varianti
            </Typography>
            <Autocomplete
                multiple
                autoHighlight={false}
                options={distinct}
                value={value || ""}
                freeSolo
                fullWidth
                size={'small'}
                onChange={(e, v) =>
                    onAdd(v)
                }
                renderTags={
                    (values, getTagProps) =>
                        values.map((v, index) => (
                            // console.log(v);
                            <Chip variant="outlined"
                                  label={v}
                                  {...getTagProps({index})}
                            />
                        ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{width: '100%'}}
                        variant="outlined"
                        label=""
                        placeholder="Aggiungi varianti..."
                        fullWidth
                    />
                )}
            />
        </CardContent>
    </Card>
}

class UVFields extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            src_uv_tipo: [],
            src_uv_misura: [],
            qta: 1,
        };
    }

    componentDidMount() {
        this.getData().then();
    }

    async getData() {
        let promises = [];
        promises.push(new Promise((res, rej) => axios
            .get(
                `${Constants.paths.ajaxBasePath}prodotti/utils/uv/misure/?token=${this.context.user.token}`
            )
            .then((resp) => {
                this.setState({src_misura: resp.data});
                res();
            }).catch(err => rej(err))));
        promises.push(new Promise((res, rej) => axios
            .get(
                `${Constants.paths.ajaxBasePath}prodotti/utils/uv/tipi/?token=${this.context.user.token}`
            )
            .then((resp) => {
                this.setState({src_tipo: resp.data});
                res();
            }).catch(err => rej(err))));
        await Promise.allSettled(promises);
    }

    render() {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Autocomplete
                            size="small"
                            disabled={this.props.disabled}
                            autoSelect
                            autoHighlight
                            options={this.state.src_tipo || []}
                            getOptionLabel={(option) => option.nome || ""}
                            value={this.props.tipo || ""}
                            onChange={(e, val) => this.props.onTipoChange(val)}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    style={{margin: 0}}
                                    {...params}
                                    label="Tipo"
                                    // margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled={this.props.disabled}
                            variant="outlined"
                            type="number"
                            label="Quantità"
                            size="small"
                            value={this.props.qta}
                            onChange={(e) => this.props.onQtaChange(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            autoSelect
                            autoHighlight
                            disabled={this.props.disabled}
                            size="small"
                            options={this.state.src_misura || []}
                            value={this.props.misura || {}}
                            getOptionLabel={(option) => option.nome || ""}
                            onChange={(e, val) => this.props.onMisuraChange(val)}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    style={{margin: 0}}
                                    {...params}
                                    label="Misura"
                                    // margin="normal"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
}

class CreateProduct extends React.Component {
    static contextType = Context;
    static defaultProps = {
        pUfCod: ""
    };
    static DialogTitle = "Crea prodotto";
    static SuccessMessage = "Prodotto creato";
    static ConfirmButton = "Crea prodotto";
    static Type = "Create";

    constructor(props) {
        super(props);
        this.bundleProductDescriptionData = this.bundleProductDescriptionData.bind(this);
        this.handleClassificationChange = this.handleClassificationChange.bind(this);
        this.canSend = this.canSend.bind(this);
        this.checkBarcode = this.checkBarcode.bind(this);
        this.checkUniqueSpotDesc = this.checkUniqueSpotDesc.bind(this);
        this.checkUniqueUfCod = this.checkUniqueUfCod.bind(this);
        this.generateAttributeSelector = this.generateAttributeSelector.bind(this);
        this.getDescriptionPreview = this.getDescriptionPreview.bind(this);
        this.getDescriptionPreviewCanceltoken = null;
        this.getNewSKU = this.getNewSKU.bind(this);
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.handleCodBarreNdChange = this.handleCodBarreNdChange.bind(this);
        this.handleNomeTipicoChange = this.handleNomeTipicoChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleProductTypeChange = this.handleProductTypeChange.bind(this);
        this.handleSpotChange = this.handleSpotChange.bind(this);
        this.handleSpotDescriptionChange = this.handleSpotDescriptionChange.bind(this);
        this.handleDimNdChange = this.handleDimNdChange.bind(this);
        this.handleUvTipoChange = this.handleUvTipoChange.bind(this);
        this.handleUvQtaChange = this.handleUvQtaChange.bind(this);
        this.handleUvMisuraChange = this.handleUvMisuraChange.bind(this);
        this.initState = this.initState.bind(this);
        this.reset = this.reset.bind(this);
        this.setDimData = this.setDimData.bind(this);
        this.handleProductTagsSelectorOpen = this.handleProductTagsSelectorOpen.bind(this);
        this.getDefaultTags = this.getDefaultTags.bind(this);
        this.handleSellChannel = this.handleSellChannel.bind(this);
        this.state = this.initState(true);
    }

    onTransitionEnter() {
    }

    getDescriptionPreview(meta = null) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (this.getDescriptionPreviewCanceltoken)
            this.getDescriptionPreviewCanceltoken.cancel(
                "Annullo richiesta descrizione precedente"
            );
        this.getDescriptionPreviewCanceltoken = source;
        if (!meta) meta = this.bundleProductDescriptionData();
        const toPost = JSON.stringify({
            token: this.context.user.token,
            directMeta: [{...meta}],
            checkUnique: true,
        });
        axios
            .post(
                Constants.paths.ajaxBasePath + "prodotti/utils/generate-description/",
                toPost,
                {
                    cancelToken: source.token,
                }
            )
            .then((res) => {
                this.setState({
                    descrizione_legacy: res.data[0].legacy,
                    descrizione: res.data[0].desc,
                    descrizione_breve: res.data[0].breve,
                    descrizione_legacy_unique: res.data[0].legacy_unique,
                    descrizione_unique: res.data[0].desc_unique,
                    descrizione_breve_unique: res.data[0].breve_unique,
                });
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                } else {
                    console.warn("Errore durante la ricerca: ", e);
                }
            });
    }

    async sendData() {
        let stateObj = this.state;
        // const bundleAttrData = (so) => {};
        const bundleConfigData = (so) => {
            let options_attrs_visibility = {};
            Object.entries(so.attributi_visibilita).forEach((e) => {
                options_attrs_visibility[e[0]] = {
                    visibile: e[1],
                    legacy_visibile: e[1], //* È qui che vado ad unire legacy con std
                };
            });
            return {
                extra_descrizione: so.extra_descrizione,
                attrs: {
                    ...options_attrs_visibility,
                },
            };
        };

        const toPost = JSON.stringify(
            stateObj.spot
                ? {
                    token: this.context.user.token,
                    spotOnly: true,
                    sku: stateObj.sku,
                    id_brand: stateObj.brand.id,
                    cod_produttore: stateObj.cod_produttore,
                    uf_cod: stateObj.generatedUf,
                    descrizione: stateObj.descrizione_breve,
                    cod_barre: stateObj.cod_barre || null,
                    dim_a: stateObj.dim_a || null,
                    dim_l: stateObj.dim_l || null,
                    dim_p: stateObj.dim_p || null,
                    dim_peso: stateObj.dim_peso || null,
                    imballo: stateObj.imballo,
                    tipo: stateObj.tipo,
                    canale_vendita: stateObj.canale_vendita,
                    x_code: stateObj.x_code || "0",
                }
                : {
                    token: this.context.user.token,
                    sku: stateObj.sku,
                    id_brand: stateObj.brand.id,
                    cod_produttore: stateObj.cod_produttore,
                    uf_cod: stateObj.generatedUf,
                    nome: stateObj.nome,
                    descrizione: stateObj.descrizione_breve,
                    descrizione_estesa: stateObj.descrizione,
                    cash_descrizione: stateObj.descrizione_legacy,
                    id_nome_tipico: stateObj.nome_tipico.id,
                    id_classificazione: stateObj.classificazione.id,
                    id_cat: stateObj.categoria.id,
                    cod_famiglia: stateObj.cod_famiglia,
                    cod_barre: stateObj.cod_barre || null,
                    uv_qta: stateObj.uv.qta,
                    id_uv_tipo: stateObj.uv.tipo.id,
                    id_uv_misura: stateObj.uv.misura.id,
                    dim_a: stateObj.dim_a || null,
                    dim_l: stateObj.dim_l || null,
                    dim_p: stateObj.dim_p || null,
                    dim_peso: stateObj.dim_peso || null,
                    imballo: stateObj.imballo,
                    attrs: stateObj.attributi_valori,
                    tipo: stateObj.tipo,
                    assortimento_varianti: stateObj.assortimento_varianti,
                    tags: stateObj.tags || null,
                    config: bundleConfigData(stateObj),
                    canale_vendita: stateObj.canale_vendita,
                    x_code: stateObj.x_code || "0",
                }
        );

        await new Promise((resolve) =>
            axios
                .post(
                    Constants.paths.ajaxBasePath + "prodotti/utils/create-product/",
                    toPost
                )
                .then((res) => {
                    enqueueSnackbar(this.constructor.SuccessMessage, {
                        variant: "success",
                    });
                    resolve(res.data);
                })
                .catch((err) => {
                    console.error(err);
                    enqueueSnackbar("Errore: " + err.message, {
                        variant: "error",
                    });
                })
                .finally(() => {
                    this.setState({sendingAJAX: false});
                })
        );
    }

    initState(returnOnly = false) {
        const rawState = {
            dim_a: "",
            dim_l: "",
            dim_p: "",
            imballo: 1,
            peso: "",
            cod_barre: "",
            cod_ricerca_extra: "",
            cod_famiglia: "",
            descrizione: "",
            descrizione_unique: true,
            descrizione_breve: "",
            descrizione_breve_unique: true,
            descrizione_legacy: "",
            descrizione_legacy_unique: true,
            extra_descrizione: "",
            brand: {},
            cod_produttore: "",
            dialogOpen: false,
            sendingAJAX: false,
            _fetching: false,
            sku: "",
            x_code: "",
            generatedUf: "",
            ufSuffix: "",
            ufCod_unique: false,
            cod_barre_unique: false,
            canale_vendita: "all",
            spot: false,
            tipo: "semplice",
            classificazione: {
                id: "",
                attrs: [],
                nome: "",
            },
            categoria: {},
            attributi_valori: {},
            attributi_errori: {},
            attributi_autocomplete: {},
            attributi_visibilita: {},
            tags: [],
            nome: "",
            nome_tipico: {},
            assortimento_varianti: [],
            uv: {
                tipo: {},
                qta: 1,
                misura: {},
            },
            _opened: false,
            cloneProductDialog: {
                open: false,
            },
            productTagsSelectorDialog: {
                open: false,
            },
            cloneProductPopopver: {
                open: false,
                anchorEl: null,
            }
        };
        !returnOnly && this.setState(rawState);
        return rawState;
    }

    reset(callback = false) {
        this.initState();
        this.getNewSKU().then();
        if (callback) callback();
    }

    handleCloneDialogOpen(to = true) {
        this.setState((prev) => ({
            cloneProductDialog: {
                ...prev.cloneProductDialog,
                open: to,
            },
        }));
    }

    handleCopyAttrsDialogOpen(to = true, id, sku) {
        this.setState(
            (prev) => ({
                copyAttrsDialog: {
                    ...prev.copyAttrsDialog,
                    open: to,
                    destId: to ? id : null,
                    destSku: to ? sku : null,
                },
            }),
            () => this.handleCopyAttrsProductSelectorOpen(false)
        );
    }

    handleCopyAttrsProductSelectorOpen(to = true) {
        this.setState((prev) => ({
            copyAttrsProductSelectorDialog: {
                ...prev.copyAttrsProductSelectorDialog,
                open: to,
            },
        }));
    }

    handleProductTagsSelectorOpen(to = true) {
        this.setState((prev) => ({
            productTagsSelectorDialog: {
                ...prev.productTagsSelectorDialog,
                open: to,
            },
        }));
    }

    handleAssortmentVariationChange(names, callback = false) {
        this.setState(prev => ({
            ...prev,
            assortimento_varianti: names
        }), () => {
            if (callback) callback()
        });
    }

    // handleAssortmentVariationRemove(name) {
    //     this.setState(prev => ({
    //         ...prev,
    //         assortimento_varianti: [...prev.assortimento_varianti.filter((v) => v !== name)]
    //     }));
    // }

    generateUfCod(setState = true) {
        let generated =
            (this.state.tipo === "virtuale" ? "V-" : "") +
            (this.state.brand?.["id_char"] || "...") +
            "-" +
            (this.state.cod_produttore || "...") +
            (this.state.ufSuffix ? "/" + this.state.ufSuffix : "");
        if (setState)
            this.setState(
                {
                    generatedUf: generated,
                },
                this.checkUniqueUfCod
            );

        return generated;
    }

    testUserDescription(desc) {
        const pattern = /^(?![\n])[A-zÀ-ú0-9,'":@\/\.\-\+\&\(\);\s]*$/;
        return pattern.test(desc);
    }

    handleBrandChange(newVal, callback = false) {
        this.setState({brand: newVal}, () => {
            this.generateUfCod();
            if (callback) callback();
        });
    }

    handleProductTypeChange(to = "semplice", callback = false) {
        // console.log(to);
        let newTipo_prodotto = to;
        // let newCanale_vendita = "all";
        // if (to === "virtuale") {
        //     newCanale_vendita = "cash";
        // }
        // newTipo_prodotto = to;
        if (to !== 'assortimento') this.handleAssortmentVariationChange([]);
        this.setState(
            {
                tipo: newTipo_prodotto,
            },
            () => {
                this.generateUfCod(true);
                if (callback) callback();
            });
    }

    handleSpotChange(to, callback = false) {
        this.setState(
            () => ({spot: to, descrizione_breve: ""}),
            () => {
                this.handleProductTypeChange("semplice");
                if (!to) {
                    this.handleProductTypeChange("semplice"); //Si occupa anche del canale di vendita
                    this.getDescriptionPreview();
                } else {
                    this.handleSellChannel('cash');
                }
                if (callback) callback();
            }
        );
    }

    handleSellChannel(to, callback = false) {
        if (to) {
            this.setState(
                () => ({canale_vendita: to}),
                () => {
                    if (callback) callback();
                }
            );
        }
    }

    handleSpotDescriptionChange(val, callback = false) {
        this.setState({descrizione_breve: val}, () => {
            this.checkUniqueSpotDesc();
            if (callback) callback();
        });
    }

    handleDimNdChange(checked, callback = false) {
        this.setState(
            (_) => {
                if (checked) {
                    return {
                        dim_nd: true,
                        dim_a: null,
                        dim_l: null,
                        dim_p: null,
                        dim_peso: null,
                    };
                } else {
                    return {
                        dim_nd: false,
                    };
                }
            },
            () => {
                if (callback) callback();
            }
        );
    }

    handleCodBarreNdChange(checked, callback = false) {
        this.setState(
            (_) => {
                if (checked) {
                    return {
                        cod_barre_nd: true,
                        cod_barre: null,
                    };
                } else {
                    return {
                        cod_barre_nd: false,
                    };
                }
            },
            () => {
                if (callback) callback();
            }
        );
    }

    async getNewSKU(returnOnly = false) {
        let toReturn;
        await axios
            .get(
                Constants.paths.ajaxBasePath +
                `prodotti/utils/generate-sku/?token=${this.context.user.token}&n=1`
            )
            .then((res) => {
                //? Strato di sicurezza extra data l'importanza dello SKU
                if (/^[0-9]{9}$/.test(res.data[0])) {
                    if (returnOnly) toReturn = res.data[0];
                    else this.setState({sku: res.data[0]});
                } else throw "SKU non valido!";
            })
            .catch();
        return toReturn;
    }

    componentDidMount() {
        this.getNewSKU();
    }

    shouldComponentUpdate(nextProps, nextState) {
        //Evita aggiornamenti inutili
        if (this.props.open) {
            return true;
        } else return this.props.open == false && nextProps.open == true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState) {
            if (prevState.cod_barre != this.state.cod_barre) this.checkBarcode();
            if (!this.state.spot) {
                if (
                    !isEqual(
                        this.bundleProductDescriptionData(prevState),
                        this.bundleProductDescriptionData(this.state)
                    )
                ) {
                    this.getDescriptionPreview();
                }
                if (
                    prevState.brand?.id !== this.state.brand?.id
                    || prevState.categoria?.id !== this.state.categoria?.id
                    || prevState.classificazione?.id !== this.state.classificazione?.id
                    || prevState.nome_tipico?.id !== this.state.nome_tipico?.id
                ) this.getDefaultTags();
            }
        }
    }

    handleUvTipoChange(newT, callback = false) {
        this.setState(
            (prev) => ({
                uv: {...prev.uv, tipo: newT},
            }),
            () => {
                if (callback) callback();
            }
        );
    }

    handleUvQtaChange(newQ, callback = false) {
        this.setState(
            (prev) => ({
                uv: {...prev.uv, qta: newQ},
            }),
            () => {
                if (callback) callback();
            }
        );
    }

    handleUvMisuraChange(newM, callback = false) {
        this.setState(
            (prev) => ({
                uv: {...prev.uv, misura: newM},
            }),
            () => {
                if (callback) callback();
            }
        );
    }

    handleTagChange(tagObj, action = "add", callback = false) {
        this.setState(
            (prev) => {
                let newTags = [];
                if (action === "add") {
                    newTags = prev.tags;
                    if (!newTags.map(t => t.id).includes(tagObj.id))  //Se già presente abortisci
                        newTags.push(tagObj);
                } else if (action === "remove") {
                    newTags = prev.tags.filter((t) => t.id != tagObj.id);
                }
                return {
                    tags: newTags,
                };
            },
            () => {
                if (callback) callback();
            }
        );
    }

    async getDefaultTags() {
        let res = await axios.get(
            `${Constants.paths.ajaxBasePath}prodotti/tags/defaults-for-meta/
            ?id_brand=${this.state.brand?.id || null}
            &with_meta
            &id_cat=${this.state.categoria?.id || null}
            &id_classificazione=${this.state.classificazione?.id || null}
            &id_nome_tipico=${this.state.nome_tipico?.id || null}
            &token=${this.context.user.token}`.replaceAll(/\s+/g, '')
        );
        const existingTags = this.state.tags;
        const newTags = res.data;

        // Aggiungi
        for (const nT of newTags) {
            let shouldAdd = true;
            if (existingTags.map(t => t.id).includes(nT.id)) { //Già presente
                shouldAdd = false;
                const eT = existingTags.find(t => t.id == nT.id);
                if (!eT.is_default) {
                    //Incubo async
                    //Rimuovi perché non default, ma dovrebbe
                    shouldAdd = true;
                    await new Promise(res => this.handleTagChange(eT, 'remove', res));

                }
            }
            if (shouldAdd) {
                nT.is_default = true;
                await new Promise(res => this.handleTagChange(nT, 'add', res));
            }
        }
        // RIMUOVI
        for (const eT of existingTags) {
            if (eT.is_default) {
                if (!newTags.map(nT => nT.id).includes(eT.id)) {
                    await new Promise(res => this.handleTagChange(eT, 'remove', res));
                }
            }
        }
    }

    setDimData(prop, val, callback = false) {
        const thisReplaceRules = ["a", "l", "p"].includes(prop)
            ? Constants.replaceRules.numerico
            : Constants.replaceRules.numericoIntero;
        const thisMatchRule = ["a", "l", "p"].includes(prop)
            ? Constants.regexMatchRules.numerico
            : Constants.regexMatchRules.numericoIntero;
        thisReplaceRules.forEach((r) => {
            val = val.replace(r[0], r[1]);
        });
        this.setState(
            (prev) => ({
                ...prev,
                [`dim_${prop}`]: val,
                [`dim_${prop}_error`]: !val.match(thisMatchRule),
            }),
            () => callback && callback()
        );
    }

    canSend() {
        let required = this.state.spot
            ? [
                "sku",
                "descrizione_breve",
                "brand.id",
                "dim_a",
                "dim_l",
                "dim_p",
                "dim_peso",
                "cod_barre",
                "imballo",
            ]
            : [
                "sku",
                "dim_a",
                "dim_l",
                "dim_p",
                "dim_peso",
                "cod_barre",
                // "nome",
                "descrizione",
                "descrizione_breve",
                "descrizione_legacy",
                "brand.id",
                "categoria.id",
                "classificazione.id",
                // "cod_famiglia",
                "cod_produttore",
                "generatedUf",
                "imballo",
                "nome_tipico.id",
                "uv.misura.id",
                "uv.qta",
                "uv.tipo.id",
            ];
        if (this.state.cod_barre_nd)
            required = required.filter((val) => val != "cod_barre");
        if (this.state.dim_nd)
            required = required.filter(
                (val) => !["dim_a", "dim_l", "dim_p", "dim_peso"].includes(val)
            );
        for (let i = 0; i < required.length; i++) {
            let thisStateVal = this.state;
            let val = required[i];
            val = val.split(".");
            for (let y = 0; y < val.length; y++) {
                const v = val[y];
                thisStateVal = thisStateVal?.[v];
            }
            if (!thisStateVal || thisStateVal == 0 || thisStateVal == "") {
                // console.log("Cansend: no " + required[i]);
                return false;
            }
        }
        // console.log('Cansend: yes');
        return true;
    }

    checkBarcode(exceptions = []) {
        let actualBarcode = this.state.cod_barre;
        if (exceptions.includes(actualBarcode)) {
            this.setState({
                cod_barre_unique: true,
                cod_barre_valid: true,
            });
        } else {
            if (actualBarcode) {
                if (actualBarcode.match(Constants.regexMatchRules.barcode)) {
                    axios
                        .get(
                            Constants.paths.ajaxBasePath +
                            "prodotti/utils/check-if-unique/codici/?" +
                            "token=" +
                            this.context.user.token +
                            "&col=barcode" +
                            "&val=" +
                            encodeURIComponent(actualBarcode) +
                            "&thisSku=" +
                            encodeURIComponent(this.state.sku)
                        )
                        .then((res) => {
                            this.setState({
                                cod_barre_unique: res.data,
                                cod_barre_error: false,
                            });
                            console.log(res.data);
                        });
                } else {
                    this.setState({
                        cod_barre_unique: false,
                        cod_barre_error: true,
                    });
                }
            } else {
                this.setState({
                    cod_barre_unique: false,
                    cod_barre_error: false,
                });
            }
        }
    }

    checkUniqueUfCod(exceptions = []) {
        //*IDEA: Controlla eccezione nel backend
        if (this.state.brand?.["id_char"] && this.state.cod_produttore) {
            let actualUf =
                this.state.brand["id_char"] +
                "-" +
                this.state.cod_produttore +
                this.state.ufSuffix;
            // if (this.isUpdate) exceptions.push(this.props.pUfCod);
            if (exceptions.includes(actualUf)) return false;
            //Axios req for actualUf
            axios
                .get(
                    Constants.paths.ajaxBasePath +
                    "prodotti/utils/check-if-unique/codici/?" +
                    "token=" +
                    this.context.user.token +
                    "&col=uf_cod" +
                    "&val=" +
                    encodeURIComponent(actualUf) +
                    "&thisSku=" +
                    encodeURIComponent(this.state.sku)
                )
                .then((res) => {
                    this.setState({
                        ufCod_unique: !res.data,
                    });
                });
        } else {
            this.setState({ufCod_unique: false});
        }
    }

    checkUniqueSpotDesc() {
        const actualDesc = this.state.descrizione_breve;
        console.log(this.state.sku, actualDesc);
        axios
            .get(
                Constants.paths.ajaxBasePath +
                "prodotti/utils/check-if-unique/descrizioni/?" +
                "token=" +
                this.context.user.token +
                "&col=breve" +
                "&val=" +
                encodeURIComponent(actualDesc) +
                "&thisSku=" +
                encodeURIComponent(this.state.sku)
            )
            .then((res) => {
                this.setState({
                    descrizione_breve_unique: res.data,
                });
            });
    }

    handleClassificationChange(classificationObj, callback = false) {
        this.setState({classificazione: classificationObj}, () => {
            //Imposta visibilità attr di default
            let newAttributi_visibilita = {};
            if (classificationObj)
                classificationObj.attrs.forEach((a) => {
                    newAttributi_visibilita[a.id] = !!parseInt(a.essenziale);
                });

            // console.log("src", classificationObj);
            // console.log("new", newAttributi_visibilita);

            this.setState(
                {
                    attributi_visibilita: newAttributi_visibilita,
                },
                () => {
                    this.getAttributesAutocompleteData();
                    if (callback) callback();
                }
            );
        });
        console.log(classificationObj);
    }

    handleCategoryChange(newVal, callback = false) {
        this.setState({categoria: newVal || {}}, () => {
            if (callback) callback();
        });
    }

    handleFamilyChange(newVal, callback = false) {
        this.setState({cod_famiglia: newVal}, () => {
            if (callback) callback();
        });
    }

    handleAttrValChange(attrId, value, callback = false) {
        this.setState(
            (prev) => ({
                attributi_valori: {
                    ...prev.attributi_valori,
                    [attrId]: value,
                },
            }),
            () => {
                if (callback) callback();
            }
        );
    }

    handleAttrErrChange(attrId, isError, callback = false) {
        this.setState(
            (prev) => ({
                attributi_errori: {
                    ...prev.attributi_errori,
                    [attrId]: isError,
                },
            }),
            () => callback && callback()
        );
    }

    handleNomeTipicoChange(nomeTipicoObj, callback = false) {
        this.setState({nome_tipico: nomeTipicoObj || {}}, () => {
            if (callback) callback();
        });
    }

    getAttributesAutocompleteData() {
        if (this.state.classificazione?.id) {
            let attrIdsString = this.state.classificazione.attrs
                .map((a) => a.id)
                .join(";");
            axios
                .get(
                    `${Constants.paths.ajaxBasePath}prodotti/attributi/valori-distinti/?token=${this.context.user.token}&ids=${attrIdsString}`
                )
                .then((res) => {
                    this.setState({attributi_autocomplete: res.data});
                });
        }
    }

    generateAttributeSelector(attrObj) {
        // console.log(attrObj);
        // console.log(attrObj);
        // console.log(this.state.attributi_valori);
        const generateTextfield = () => {
            switch (attrObj.tipo) {
                case "regex":
                    return (
                        <Tooltip title={attrObj.tooltip || ""} arrow>
                            <Autocomplete
                                value={this.state.attributi_valori[attrObj.id] || ""}
                                freeSolo
                                size="small"
                                disabled={this.state.spot}
                                options={this.state.attributi_autocomplete[attrObj.id] || []}
                                autoComplete
                                autoHighlight
                                onChange={(e, val) => this.handleAttrValChange(attrObj.id, val)}
                                filterOptions={(input) => {
                                    return (
                                        this.state.attributi_autocomplete[attrObj.id] || []
                                    ).filter(
                                        (o) => (o || "").search(
                                                escapeRegex(this.state.attributi_valori[attrObj.id]) || ""
                                            ) !== -1
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        error={this.state.attributi_errori[attrObj.id]}
                                        fullWidth
                                        onChange={(e) => {
                                            let thisVal = e.target.value || "";
                                            let thisPattern = "";
                                            let thisReplaceRules = [];
                                            try {
                                                thisPattern = attrObj.regola.split(";")[0];
                                                thisReplaceRules = JSON.parse(
                                                    attrObj.regola.split(";")[1]
                                                );
                                                // thisReplaceRules = attrObj.regola.split(";")[1];
                                            } catch (error) {
                                                alert(
                                                    "Errore nella regola Regex, rivolgersi a me (Samuele)"
                                                );
                                            }
                                            thisReplaceRules.forEach(
                                                (r) => (thisVal = thisVal.replace(r[0], r[1]))
                                            );
                                            this.handleAttrValChange(attrObj.id, thisVal, () => {
                                                this.handleAttrErrChange(
                                                    attrObj.id,
                                                    !(
                                                        this.state.attributi_valori[attrObj.id] || ""
                                                    ).match(thisPattern)
                                                );
                                            });
                                        }}
                                        style={{margin: 0}}
                                        required={!!parseInt(attrObj.essenziale)}
                                        label={attrObj.abbreviazione || attrObj.nome}
                                        // margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: attrObj.prefisso ? (
                                                <InputAdornment position={"start"}>{attrObj.prefisso}</InputAdornment>
                                            ) : false,
                                            endAdornment: attrObj.suffisso ? (
                                                <InputAdornment position={"end"}>{attrObj.suffisso}</InputAdornment>
                                            ) : false,
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </Tooltip>
                    );
                    break;
                case "libero":
                case "colore":
                    return (
                        <Tooltip title={attrObj.tooltip || ""} arrow>
                            <Autocomplete
                                value={this.state.attributi_valori[attrObj.id] || ""}
                                freeSolo
                                size="small"
                                disabled={this.state.spot}
                                options={this.state.attributi_autocomplete[attrObj.id] || []}
                                autoComplete
                                autoHighlight
                                onChange={(e, val) => this.handleAttrValChange(attrObj.id, val)}
                                filterOptions={(input) => {
                                    return (
                                        this.state.attributi_autocomplete[attrObj.id] || []
                                    ).filter(
                                        (o) =>
                                            (o?.replace(/[^a-z\d]/gi, "") || "").search(
                                                this.state.attributi_valori[attrObj.id]?.replace(
                                                    /[^a-z\d]/gi,
                                                    ""
                                                ) || ""
                                            ) !== -1
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        onChange={(e) =>
                                            this.handleAttrValChange(attrObj.id, e.target.value)
                                        }
                                        style={{margin: 0}}
                                        required={!!parseInt(attrObj.essenziale)}
                                        label={attrObj.abbreviazione || attrObj.nome}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: attrObj.prefisso ? (
                                                <InputAdornment position={"start"}>{attrObj.prefisso}</InputAdornment>
                                            ) : false,
                                            endAdornment: attrObj.suffisso ? (
                                                <InputAdornment position={"end"}>{attrObj.suffisso}</InputAdornment>
                                            ) : false,
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </Tooltip>
                    );
                case "selezione":
                    const vals = attrObj["regola"].split(";") || [];
                    return (
                        <Tooltip title={attrObj.tooltip || ""} arrow>
                            <Autocomplete
                                size="small"
                                disabled={this.state.spot}
                                options={vals}
                                getOptionLabel={(option) => option || ""}
                                value={this.state.attributi_valori[attrObj.id] || ""}
                                onChange={(e, val) => this.handleAttrValChange(attrObj.id, val)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        style={{margin: 0}}
                                        required={!!parseInt(attrObj.essenziale)}
                                        InputProps={{
                                            startAdornment: attrObj.prefisso ? (
                                                <InputAdornment position={"start"}>{attrObj.prefisso}</InputAdornment>
                                            ) : false,
                                            endAdornment: attrObj.suffisso ? (
                                                <InputAdornment position={"end"}>{attrObj.suffisso}</InputAdornment>
                                            ) : false,
                                        }}
                                        label={attrObj.abbreviazione || attrObj.nome}
                                        margin="normal"
                                        variant="outlined"
                                        {...params}
                                    />
                                )}
                            />
                        </Tooltip>
                    );

                case "bool":
                    return (
                        <Tooltip title={attrObj.tooltip || ""} arrow>
                            <FormGroup
                                // style={{ marginTop: "18px", marginBottom: "8px" }}
                            >
                                <FormControlLabel
                                    disabled={this.state.spot}
                                    style={{
                                        border: "1px solid",
                                        borderRadius: "4px",
                                        borderColor: this.context.theme.palette.grey["600"],
                                        paddingLeft: "6px",
                                        marginLeft: 0,
                                        marginRight: 0,
                                    }}
                                    control={
                                        <Checkbox
                                            size="small"
                                            color="primary"
                                            checked={this.state.attributi_valori[attrObj.id] || false}
                                            onChange={(e) =>
                                                this.handleAttrValChange(attrObj.id, e.target.checked)
                                            }
                                        />
                                    }
                                    label={attrObj.abbreviazione || attrObj.nome}
                                />
                                {/* <FormHelperText>{attrObj.tooltip || ""}</FormHelperText> */}
                            </FormGroup>
                        </Tooltip>
                    );
                case "numerico":
                    return (
                        <Tooltip title={attrObj.tooltip || ""} arrow>
                            <Autocomplete
                                value={this.state.attributi_valori[attrObj.id] || ""}
                                freeSolo
                                size="small"
                                disabled={this.state.spot}
                                options={attrObj.regola?.split(";") || []}
                                autoComplete
                                autoHighlight
                                onChange={(e, val) => {
                                    let thisVal = val || "";
                                    Constants.replaceRules.numerico.forEach(
                                        (r) => (thisVal = thisVal.replace(r[0], r[1]))
                                    );
                                    this.handleAttrValChange(attrObj.id, thisVal, () =>
                                        this.handleAttrErrChange(
                                            attrObj.id,
                                            !(this.state.attributi_valori[attrObj.id] || "").match(
                                                Constants.regexMatchRules.numerico
                                            )
                                        )
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        onChange={(e) => {
                                            let thisVal = e.target.value;
                                            Constants.replaceRules.numerico.forEach(
                                                (r) => (thisVal = thisVal.replace(r[0], r[1]))
                                            );
                                            this.handleAttrValChange(attrObj.id, thisVal, () =>
                                                this.handleAttrErrChange(
                                                    attrObj.id,
                                                    !(
                                                        this.state.attributi_valori[attrObj.id] || ""
                                                    ).match(Constants.regexMatchRules.numerico)
                                                )
                                            );
                                        }}
                                        required={!!parseInt(attrObj.essenziale)}
                                        InputProps={{
                                            startAdornment: attrObj.prefisso ? (
                                                <InputAdornment>{attrObj.prefisso}</InputAdornment>
                                            ) : false,
                                            endAdornment: attrObj.suffisso ? (
                                                <InputAdornment>{attrObj.suffisso}</InputAdornment>
                                            ) : false,
                                        }}
                                        error={this.state.attributi_errori[attrObj.id]}
                                        variant="outlined"
                                        label={attrObj.abbreviazione || attrObj.nome}
                                        {...params}
                                    />
                                )}
                            />
                        </Tooltip>
                    );
                case "dimensione":
                case "dimensione1d":
                case "dimensione2d":
                case "dimensione3d":
                    return (
                        <Tooltip
                            title={
                                attrObj.tooltip ||
                                (attrObj.tipo === "dimensione1d"
                                    ? ""
                                    : attrObj.tipo === "dimensione2d"
                                        ? "NN X NN"
                                        : attrObj.tipo === "dimensione3d"
                                            ? "NN X NN X NN"
                                            : "")
                            }
                            arrow
                        >
                            <Autocomplete
                                value={this.state.attributi_valori[attrObj.id] || ""}
                                freeSolo
                                size="small"
                                disabled={this.state.spot}
                                options={attrObj.regola?.split(";") || []}
                                autoComplete
                                autoHighlight
                                onChange={(e, val) => this.handleAttrValChange(attrObj.id, val)}
                                filterOptions={(input) => {
                                    // console.log('GUARDAMI ', attrObj);
                                    return [
                                        ...(attrObj.regola?.split(";") || []),
                                        ...(this.state.attributi_autocomplete[attrObj.id] || []),
                                    ].filter(
                                        (o) =>
                                            o.search(
                                                this.state.attributi_valori[attrObj.id]
                                                    ? escapeRegex(this.state.attributi_valori[attrObj.id])
                                                    : ""
                                            ) !== -1
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        required={!!parseInt(attrObj.essenziale)}
                                        onChange={(e) => {
                                            let thisVal = e.target.value;
                                            (Constants.replaceRules[attrObj.tipo] || []).forEach(
                                                (r) => (thisVal = thisVal.replace(r[0], r[1]))
                                            );
                                            this.handleAttrValChange(attrObj.id, thisVal, () => {
                                                this.handleAttrErrChange(
                                                    attrObj.id,
                                                    !(
                                                        !!(
                                                            String(this.state.attributi_valori[attrObj.id]) ||
                                                            ""
                                                        ).match(
                                                            Constants.regexMatchRules[attrObj.tipo] || ".*"
                                                        ) ||
                                                        (attrObj.regola
                                                            ? attrObj.regola?.includes(
                                                                this.state.attributi_valori[attrObj.id]
                                                            )
                                                            : false)
                                                    )
                                                );
                                            });
                                        }}
                                        InputProps={{
                                            startAdornment: attrObj.prefisso ? (
                                                <InputAdornment>{attrObj.prefisso}</InputAdornment>
                                            ) : (
                                                false
                                            ),
                                            endAdornment: attrObj.suffisso ? (
                                                <InputAdornment>{attrObj.suffisso}</InputAdornment>
                                            ) : (
                                                false
                                            ),
                                        }}
                                        size="small"
                                        error={this.state.attributi_errori[attrObj.id]}
                                        variant="outlined"
                                        label={attrObj.abbreviazione || attrObj.nome}
                                        {...params}
                                    />
                                )}
                            />
                        </Tooltip>
                    );
                case "quantita":
                    return (
                        <Tooltip title={attrObj.tooltip || "g | kg | ml | cl | l"} arrow>
                            <TextField
                                disabled={this.state.spot}
                                fullWidth
                                required={!!parseInt(attrObj.essenziale)}
                                InputProps={{
                                    startAdornment: attrObj.prefisso ? (
                                        <InputAdornment>{attrObj.prefisso}</InputAdornment>
                                    ) : (
                                        false
                                    ),
                                    endAdornment: attrObj.suffisso ? (
                                        <InputAdornment>{attrObj.suffisso}</InputAdornment>
                                    ) : (
                                        false
                                    ),
                                }}
                                size="small"
                                error={this.state.attributi_errori[attrObj.id]}
                                value={this.state.attributi_valori[attrObj.id] || ""}
                                onChange={(e) => {
                                    let thisVal = e.target.value;
                                    Constants.replaceRules.quantita.forEach(
                                        (r) => (thisVal = thisVal.replace(r[0], r[1]))
                                    );
                                    this.handleAttrValChange(attrObj.id, thisVal, () =>
                                        this.handleAttrErrChange(
                                            attrObj.id,
                                            !(this.state.attributi_valori[attrObj.id] || "").match(
                                                Constants.regexMatchRules.quantita
                                            )
                                        )
                                    );
                                }}
                                variant="outlined"
                                label={attrObj.abbreviazione || attrObj.nome}
                            />
                        </Tooltip>
                    );
                case "peso":
                    return (
                        <Tooltip title={attrObj.tooltip || "mg | g | kg"} arrow>
                            <TextField
                                disabled={this.state.spot}
                                fullWidth
                                required={!!parseInt(attrObj.essenziale)}
                                InputProps={{
                                    startAdornment: attrObj.prefisso ? (
                                        <InputAdornment>{attrObj.prefisso}</InputAdornment>
                                    ) : (
                                        false
                                    ),
                                    endAdornment: attrObj.suffisso ? (
                                        <InputAdornment>{attrObj.suffisso}</InputAdornment>
                                    ) : (
                                        false
                                    ),
                                }}
                                size="small"
                                error={this.state.attributi_errori[attrObj.id]}
                                value={this.state.attributi_valori[attrObj.id] || ""}
                                onChange={(e) => {
                                    let thisVal = e.target.value;
                                    Constants.replaceRules.quantita.forEach(
                                        (r) => (thisVal = thisVal.replace(r[0], r[1]))
                                    );
                                    this.handleAttrValChange(attrObj.id, thisVal, () =>
                                        this.handleAttrErrChange(
                                            attrObj.id,
                                            !(this.state.attributi_valori[attrObj.id] || "").match(
                                                Constants.regexMatchRules.peso
                                            )
                                        )
                                    );
                                }}
                                variant="outlined"
                                label={attrObj.abbreviazione || attrObj.nome}
                            />
                        </Tooltip>
                    );
                default:
                    return "NON TROVATO:" + attrObj.tipo;
            }
        };
        return (
            <Grid
                xs={4}
                container
                item
                alignItems="center"
                key={attrObj.id}
                style={{alignSelf: "center"}}
            >
                <Grid item xs={11}>
                    {generateTextfield()}
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        disabled={this.state.spot}
                        size="small"
                        style={{marginLeft: this.context.theme.spacing(0.5)}}
                        checked={!!this.state.attributi_visibilita[attrObj.id]}
                        onClick={() =>
                            this.setState((prev) => ({
                                attributi_visibilita: {
                                    ...prev.attributi_visibilita,
                                    [attrObj.id]: !!!prev.attributi_visibilita[attrObj.id],
                                },
                            }))
                        }
                    >
                        <FontAwesomeIcon
                            size="sm"
                            icon={
                                this.state.attributi_visibilita[attrObj.id] ? faEye : faEyeSlash
                            }
                        />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    bundleProductDescriptionData(stateObj = null) {
        if (!stateObj) stateObj = this.state;
        let options_attrs_visibility = {};
        Object.entries(stateObj.attributi_visibilita).forEach((e) => {
            options_attrs_visibility[e[0]] = {
                visibile: e[1],
                legacy_visibile: e[1],
            };
        });

        let attrsArray = (stateObj.classificazione?.attrs || []).map((a) => ({
            id: a.id,
            nome: a.nome,
            valore: stateObj.attributi_valori[a.id],
            prefisso: a.prefisso,
            suffisso: a.suffisso,
            legacy_prefisso: a.legacy_prefisso,
            legacy_suffisso: a.legacy_suffisso,
            priorita: a.priorita,
            essenziale: a.essenziale,
            regola: a.regola,
            legacy_regola: a.legacy_regola,
            tipo: a.tipo,
        }));

        let returnData = {
            sku: stateObj.sku,
            nome: stateObj.nome,
            nome_tipico: {
                nome: stateObj.nome_tipico?.nome,
                abbreviazione: stateObj.nome_tipico?.abbreviazione,
                legacy_abbreviazione: stateObj.nome_tipico?.legacy_abbreviazione,
            },
            // brand_nome: stateObj.brand?.nome,
            // brand_nome_abbreviazione: stateObj.brand?.id_char,
            brand: {
                id: stateObj.brand?.id,
                id_char: stateObj.brand?.id_char,
                nome: stateObj.brand?.nome,
            },
            attrs: [...attrsArray],
            uv: {
                tipo: {
                    id: stateObj.uv.tipo?.id,
                    nome: stateObj.uv.tipo?.nome,
                    abbreviazione: stateObj.uv.tipo?.abbreviazione,
                    legacy_abbreviazione: stateObj.uv.tipo?.legacy_abbreviazione,
                },
                qta: stateObj.uv.qta,
                misura: {
                    id: stateObj.uv.misura?.id,
                    nome: stateObj.uv.misura?.nome,
                    singolare: stateObj.uv.misura?.nome_singolare,
                    abbreviazione: stateObj.uv.misura?.abbreviazione,
                    legacy_abbreviazione: stateObj.uv.misura?.legacy_abbreviazione,
                },
            },
            options: {
                extra_descrizione: stateObj.extra_descrizione || "",
                attrs: {
                    ...options_attrs_visibility,
                },
            },
        };
        return returnData;
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    TransitionProps={{
                        onEnter: () => this.onTransitionEnter(),
                    }}
                    scroll="body"
                    fullWidth={true}
                    fullScreen={!window.matchMedia(this.context.theme.breakpoints.up('md').replace("@media ", "")).matches}
                    maxWidth="lg"
                    open={this.props.open}
                    TransitionComponent={SlideUpTransition}
                    onClose={this.props.onClose}
                >
                    {this.state._fetching && <LinearProgress/>}
                    <DialogTitle>{this.constructor.DialogTitle}</DialogTitle>
                    <DialogContent
                        style={{
                            alignSelf: "center",
                            overflow: "hidden",
                            padding: this.context.theme.spacing(3),
                        }}
                    >
                        <Grid container spacing={4}>
                            <Grid
                                className="sticky-boundary"
                                item
                                xs={12}
                                md={4}
                                style={{textAlign: "center"}}
                            >
                                <ProductImage
                                    style={{textAlign: "center"}}
                                    containerSize={"100%"}
                                    sku={this.state.sku}
                                />
                                <FormControlLabel
                                    style={{
                                        marginTop: this.context.theme.spacing(2),
                                        color: this.context.theme.palette.warning.main,
                                        textAlign: 'center'
                                    }}
                                    label="Caricamento in spot"
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={this.state.spot}
                                            onChange={(e) =>
                                                this.handleSpotChange(e.target.checked)
                                            }
                                        />
                                    }
                                />
                                {/*</Grid>*/}
                                <Paper
                                    variant="outlined"
                                    style={{
                                        // width: "100%",
                                        margin: this.context.theme.spacing(0),
                                        marginTop: this.context.theme.spacing(4),
                                        marginBottom: this.context.theme.spacing(2),
                                        padding: this.context.theme.spacing(2),
                                        ...(this.state.spot
                                            ? {
                                                borderColor: this.context.theme.palette.warning.main,
                                            }
                                            : {}),
                                    }}
                                >
                                    <Typography
                                        variant="overline"
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            display: "block",
                                            color: this.state.spot
                                                ? this.context.theme.palette.warning.main
                                                : "inherit",
                                        }}
                                    >
                                        {this.state.spot ? "Descrizione manuale" : "Anteprima"}
                                    </Typography>
                                    <br/>
                                    <br/>
                                    <Grid item xs={12}>
                                        <Tooltip
                                            title={"Era: " + this.state.actual?.descrizione_breve}
                                        >
                                            <TextField
                                                InputProps={{readOnly: !this.state.spot}}
                                                label="Descrizione"
                                                onChange={(e) =>
                                                    this.handleSpotDescriptionChange(e.target.value)
                                                }
                                                error={!this.state.descrizione_breve_unique}
                                                InputLabelProps={{
                                                    ...(this.state.spot ? {} : {shrink: true}),
                                                }}
                                                variant={this.state.spot ? "outlined" : "standard"}
                                                style={{
                                                    width: "100%",
                                                }}
                                                multiline
                                                fullWidth
                                                helperText={
                                                    this.state.descrizione_breve_unique
                                                        ? ""
                                                        : "Descrizione duplicata"
                                                }
                                                value={this.state.descrizione_breve}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Collapse in={!this.state.spot}>
                                        <br/>
                                        <Grid item xs={12}>
                                            <Tooltip title={"Era: " + this.state.actual?.descrizione}>
                                                <TextField
                                                    InputProps={{readOnly: true}}
                                                    InputLabelProps={{shrink: true}}
                                                    label="Descrizione"
                                                    variant="standard"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    multiline
                                                    fullWidth
                                                    //rows={4}
                                                    // style={{ width: "100%" }}
                                                    value={this.state.descrizione}
                                                    error={!this.state.descrizione_unique}
                                                    onChange={(e) =>
                                                        this.testUserDescription(e.target.value) &&
                                                        this.setState({descrizione: e.target.value})
                                                    }
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <br/>
                                        <Grid item xs={12}>
                                            <Tooltip
                                                title={"Era: " + this.state.actual?.descrizione_legacy}
                                            >
                                                <TextField
                                                    InputProps={{readOnly: true}}
                                                    InputLabelProps={{shrink: true}}
                                                    label="Descrizione legacy"
                                                    variant="standard"
                                                    style={{width: "100%"}}
                                                    multiline
                                                    fullWidth
                                                    value={this.state.descrizione_legacy}
                                                    error={!this.state.descrizione_legacy_unique}
                                                    // onChange={(e) =>
                                                    //   this.testUserDescription(e.target.value) &&
                                                    //   this.setState({ descrizione: e.target.value })
                                                    // }
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Collapse>
                                </Paper>
                                <Paper
                                    variant="outlined"
                                    style={{
                                        // width: "100%",
                                        margin: this.context.theme.spacing(0),
                                        marginTop: this.context.theme.spacing(2),
                                        marginBottom: this.context.theme.spacing(2),
                                        padding: this.context.theme.spacing(2)
                                    }}
                                >
                                    <Typography
                                        variant="overline"
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            display: "block",
                                        }}
                                    >
                                        TAGS{" "}
                                        {this.state.tags.length
                                            ? "(" + this.state.tags.length + ")"
                                            : ""}
                                    </Typography>
                                    <br/>
                                    <Grid container spacing={1}>
                                        {this.state.tags.map((t) => (
                                            <Grid item key={t.id}>
                                                <Chip
                                                    disabled={this.state.spot}
                                                    size="small"
                                                    label={t.nome}
                                                    variant={t.is_default ? "outlined" : "default"}
                                                    onDelete={t.is_default ? false : () => {
                                                        this.handleTagChange(t, "remove");
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                        <Grid item xs={12}>
                                            <ButtonGroup fullWidth disabled={this.state.spot}>
                                                <Button
                                                    style={{width: '100%'}}
                                                    onClick={(e) => {
                                                        this.handleProductTagsSelectorOpen(true);
                                                    }}
                                                >
                                                    Aggiungi
                                                </Button>
                                                <Button
                                                    style={{width: '100%'}}
                                                    onClick={(e) => {
                                                        this.state.tags.map(t => this.handleTagChange(t, 'remove'))
                                                    }}
                                                >
                                                    Svuota
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid container item xs={12} md={8} alignItems="flex-start">
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="baseline"
                                    spacing={2}
                                >
                                    <Grid item xs={3}>
                                        <Tooltip
                                            title={
                                                "ID: " +
                                                (this.props.prodId
                                                    ? this.props.prodId
                                                    : "Prodotto nuovo")
                                            }
                                        >
                                            <TextField
                                                variant="outlined"
                                                value={this.state.sku}
                                                label="SKU"
                                                disabled
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <BrandsDropdown
                                            onChange={(newVal) => this.handleBrandChange(newVal)}
                                            value={this.state.brand?.id}
                                            initialId={this.props.pBrandId}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            label="Cod. forn."
                                            value={this.state.cod_produttore}
                                            variant="outlined"
                                            onChange={(e) =>
                                                this.testUserDescription(e.target.value) &&
                                                this.setState(
                                                    {
                                                        cod_produttore: e.target.value?.toUpperCase(),
                                                    },
                                                    this.generateUfCod
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            label="Suff. UF"
                                            value={this.state.ufSuffix}
                                            variant="outlined"
                                            onChange={(e) =>
                                                this.testUserDescription(e.target.value) &&
                                                this.setState(
                                                    {
                                                        ufSuffix: e.target.value?.toUpperCase(),
                                                    },
                                                    this.generateUfCod
                                                )
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <ToggleButtonGroup
                                            fullWidth
                                            size={'small'}
                                            value={this.state.canale_vendita}
                                            exclusive
                                            onChange={(e, val) => this.handleSellChannel(val)}
                                            disabled={this.state.spot}
                                        >
                                            <ToggleButton value="all" style={{width: '100%'}}>
                                                Tutto
                                            </ToggleButton>
                                            <ToggleButton value="cash" style={{width: '100%'}}>
                                                Cash
                                            </ToggleButton>
                                            <ToggleButton value="online" style={{width: '100%'}}>
                                                Online
                                            </ToggleButton>
                                            <ToggleButton value={"onlineS"} style={{width: '100%'}}>
                                                Dettaglio
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item xs={3} style={{
                                        textAlign: 'center',
                                        width: '100%'
                                    }}>
                                        <ToggleButtonGroup
                                            exclusive
                                            value={this.state.tipo}
                                            onChange={(_, v) => this.handleProductTypeChange(v)}
                                            size={"small"}
                                            sx={{width: '100%'}}
                                            disabled={this.state.spot}
                                        >
                                            <ToggleButton sx={{width: '100%'}}
                                                          value={'semplice'}>Semplice</ToggleButton>
                                            <ToggleButton sx={{width: '100%'}}
                                                          value={'assortimento'}>Assortito</ToggleButton>
                                            {/*<ToggleButton sx={{width: '100%'}}*/}
                                            {/*              value={'espositore'}>Espositore</ToggleButton>*/}
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" textAlign={'left'}>
                                            UF Code: <b>{this.state.generatedUf}</b>
                                        </Typography>
                                        {
                                            this.state.ufCod_unique && (
                                                <Typography variant="caption" color="error">
                                                    Il codice UF esiste già.
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Collapse in={this.state.tipo === 'assortimento'}>
                                            <AssortmentVariations
                                                value={this.state.assortimento_varianti}
                                                onAdd={v => this.handleAssortmentVariationChange(v)}
                                                onRemove={v => this.handleAssortmentVariationRemove(v)}
                                            />
                                        </Collapse>
                                    </Grid>
                                    <Grid xs={4} item>
                                        <ClassificationsDropdown
                                            disabled={this.state.spot}
                                            onChange={(newVal) =>
                                                this.handleClassificationChange(newVal)
                                            }
                                            value={this.state.classificazione?.id}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TipicalNamesDropdown
                                            disabled={this.state.spot}
                                            onChange={(newVal) => this.handleNomeTipicoChange(newVal)}
                                            value={this.state.nome_tipico.id}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label="Nome"
                                            disabled={this.state.spot}
                                            variant="outlined"
                                            style={{
                                                width: "100%",
                                            }}
                                            fullWidth
                                            rows={1}
                                            // style={{ width: "100%" }}
                                            value={this.state.nome}
                                            onChange={(e) =>
                                                this.testUserDescription(e.target.value) &&
                                                this.setState({nome: e.target.value})
                                            }
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <CategoriesDropdown
                                            key={this.state.categoria?.id}
                                            disabled={this.state.spot}
                                            onChange={(newVal) => this.handleCategoryChange(newVal)}
                                            value={this.state.categoria.id}
                                            // initialId={this.props.pCategoryId}
                                        />
                                    </Grid>

                                    <Paper
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                            margin: this.context.theme.spacing(1),
                                            marginTop: this.context.theme.spacing(1),
                                            marginBottom: this.context.theme.spacing(1),
                                            padding: this.context.theme.spacing(2),
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                width: "100%",
                                                textAlign: "center",
                                                display: "block",
                                            }}
                                            variant="overline"
                                        >
                                            Unità di vendita
                                        </Typography>
                                        <br/>
                                        <UVFields
                                            disabled={this.state.spot}
                                            tipo={this.state.uv.tipo}
                                            misura={this.state.uv.misura}
                                            qta={this.state.uv.qta}
                                            onTipoChange={(newT) => this.handleUvTipoChange(newT)}
                                            onQtaChange={(newQ) => this.handleUvQtaChange(newQ)}
                                            onMisuraChange={(newM) => this.handleUvMisuraChange(newM)}
                                        />
                                    </Paper>
                                    <Paper
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                            margin: this.context.theme.spacing(1),
                                            marginTop: this.context.theme.spacing(1),
                                            marginBottom: this.context.theme.spacing(1),
                                            padding: this.context.theme.spacing(2),
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                width: "100%",
                                                textAlign: "center",
                                                display: "block",
                                            }}
                                            variant="overline"
                                        >
                                            Attributi
                                        </Typography>
                                        <br/>

                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} spacing={2}>
                                                {this.state.classificazione?.attrs?.map((c) =>
                                                    this.generateAttributeSelector(c)
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <Grid item xs={12} key="extra_desc">
                                        <TextField
                                            fullWidth
                                            disabled={this.state.spot}
                                            label="Extra descrizione"
                                            size="small"
                                            variant="outlined"
                                            value={this.state.extra_descrizione}
                                            onChange={(e) =>
                                                this.testUserDescription(e.target.value) &&
                                                this.setState({extra_descrizione: e.target.value})
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper
                                            variant="outlined"
                                            style={{
                                                margin: this.context.theme.spacing(0),
                                                marginTop: this.context.theme.spacing(2),
                                                marginBottom: this.context.theme.spacing(2),
                                                padding: this.context.theme.spacing(2),
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Typography variant={'overline'} sx={{textAlign: 'center'}}>
                                                Descrizione estesa
                                            </Typography>
                                            <Typography>
                                                {'In sviluppo'}
                                            </Typography>
                                            <Button size='small' fullWidth variant={'contained'}
                                                    style={{marginTop: this.context.theme.spacing(2)}}>
                                                Apri
                                            </Button>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                {/*</Grid>*/}
                                {/*<div style={{height: this.context.theme.spacing(3)}}></div>*/}
                            </Grid>
                            <Grid item container spacing={2}>
                                {/*<React.Fragment>*/}
                                <Grid item xs={2}>
                                    <TextField
                                        error={this.state.dim_a_error}
                                        disabled={this.state.dim_nd}
                                        label="Alt."
                                        variant="outlined"
                                        InputProps={{
                                            type: "numeric",
                                            endAdornment: (
                                                <InputAdornment position="end">cm</InputAdornment>
                                            ),
                                        }}
                                        value={this.state.dim_a?.toString().replace(".", ",") || ""}
                                        onChange={(e) => this.setDimData("a", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        error={this.state.dim_l_error}
                                        disabled={this.state.dim_nd}
                                        label="Larg."
                                        variant="outlined"
                                        InputProps={{
                                            type: "numeric",
                                            endAdornment: (
                                                <InputAdornment position="end">cm</InputAdornment>
                                            ),
                                        }}
                                        value={this.state.dim_l?.toString().replace(".", ",") || ""}
                                        onChange={(e) => this.setDimData("l", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        disabled={this.state.dim_nd}
                                        label="Prof."
                                        error={this.state.dim_p_error}
                                        variant="outlined"
                                        InputProps={{
                                            type: "numeric",
                                            endAdornment: (
                                                <InputAdornment position="end">cm</InputAdornment>
                                            ),
                                        }}
                                        value={this.state.dim_p?.toString().replace(".", ",") || ""}
                                        onChange={(e) => this.setDimData("p", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        fullWidth
                                        error={this.state.dim_peso_error}
                                        disabled={this.state.dim_nd}
                                        label="Peso"
                                        variant="outlined"
                                        InputProps={{
                                            type: "numeric",
                                            endAdornment: (
                                                <InputAdornment position="end">g</InputAdornment>
                                            ),
                                        }}
                                        value={this.state.dim_peso || ""}
                                        onChange={(e) => this.setDimData("peso", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        style={{width: "100%"}}
                                        label="Cod barre"
                                        variant="outlined"
                                        InputProps={{
                                            type: "numeric",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FontAwesomeIcon icon={faBarcode}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={this.state.cod_barre || ""}
                                        onChange={(e) =>
                                            this.setState(
                                                {
                                                    cod_barre: e.target.value,
                                                },
                                                () => this.checkBarcode()
                                            )
                                        }
                                        error={this.state.cod_barre_error}
                                        disabled={this.state.cod_barre_nd}
                                    />
                                    {!this.state.cod_barre ||
                                        this.state.cod_barre_error ||
                                        this.state.cod_barre_unique || (
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    color: this.context.theme.palette.warning.main,
                                                }}
                                            >
                                                Barcode già associato.
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={this.state.dim_nd}
                                                onChange={(e) =>
                                                    this.handleDimNdChange(e.target.checked)
                                                }
                                            />
                                        }
                                        label="Dimensioni assenti"
                                    />
                                </Grid>
                                <Grid item xs={'auto'} style={{alignSelf: "center"}}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.cod_barre_nd}
                                                    onChange={(e) =>
                                                        this.handleCodBarreNdChange(e.target.checked)
                                                    }
                                                />
                                            }
                                            label="Codice a barre assente"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <DialogActions>
                                {this.constructor.Type === "Update" && (
                                    <Button
                                        onClick={() => this.reset(() => this.getData())}
                                        style={{color: this.context.theme.palette.warning.main}}
                                    >
                                        Reset
                                    </Button>
                                )}
                                {this.constructor.Type !== "Clone" &&
                                    this.constructor.Type !== "CopyAttrs" && (
                                        <>
                                            <Tooltip title="Il prodotto verrà prima salvato.">
                                                <Button
                                                    disabled={this.state.sendingAJAX || !this.canSend()}
                                                    onClick={async (e) => {
                                                        await this.sendData();
                                                        this.setState((prev) => ({
                                                            cloneProductPopopver: {
                                                                ...prev.cloneProductPopopver,
                                                                anchorEl: e.target,
                                                                open: true,
                                                            },
                                                        }));
                                                    }}
                                                >
                                                    Clona
                                                </Button>
                                            </Tooltip>
                                            <Popover
                                                open={this.state.cloneProductPopopver?.open}
                                                onClose={(e) =>
                                                    this.setState((prev) => ({
                                                        cloneProductPopopver: {
                                                            ...prev.cloneProductPopopver,
                                                            open: false,
                                                        },
                                                    }))
                                                }
                                                anchorEl={this.state.cloneProductPopopver?.anchorEl}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                            >
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => this.handleCloneDialogOpen(true)}
                                                    >
                                                        Nuovo
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            this.handleCopyAttrsProductSelectorOpen(true)
                                                        }
                                                    >
                                                        Esistente
                                                    </Button>
                                                </ButtonGroup>
                                            </Popover>
                                        </>
                                    )}
                            </DialogActions>
                        </Grid>
                        <Grid item>
                            <DialogActions>
                                {this.constructor.Type === 'Update' &&
                                    <Typography color={this.context.theme.palette.warning.main}>
                                        Stai aggiornando un prodotto esistente (senza clonazione)!
                                    </Typography>}
                                <Button
                                    onClick={(e) => {
                                        this.props.onClose();
                                        this.reset();
                                    }}
                                >
                                    Annulla
                                </Button>
                                <Button
                                    disabled={this.state.sendingAJAX || !this.canSend()}
                                    onClick={() =>
                                        this.setState({sendingAJAX: true}, async () => {
                                            await this.sendData();
                                            this.setState({_opened: false}); //A che cazzo serve samuele del passato?
                                            this.reset();
                                            this.props.onClose();
                                        })
                                    }
                                    variant="contained"
                                    color="primary"
                                >
                                    {this.constructor.ConfirmButton}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Dialog>
                {
                    this.constructor.Type !== "Clone" && (
                        <CloneProduct
                            srcProdId={this.props.prodId}
                            open={this.state.cloneProductDialog?.open}
                            onClose={() => this.handleCloneDialogOpen(false)}
                            enqueueSnackbar={this.props.enqueueSnackbar}
                        />
                    )
                }
                {
                    this.constructor.Type === "Update" && (
                        <>
                            <CopyAttrs
                                srcProdId={this.props.prodId}
                                prodId={this.state.copyAttrsDialog?.destId}
                                open={this.state.copyAttrsDialog?.open}
                                onClose={() => this.handleCopyAttrsDialogOpen(false)}
                                enqueueSnackbar={this.props.enqueueSnackbar}
                            />
                            <ProductSelector
                                showProdImg={!this.context.device.lowSpec}
                                open={this.state.copyAttrsProductSelectorDialog?.open}
                                filter={"nuovi"}
                                onClose={(e) => this.handleCopyAttrsProductSelectorOpen(false)}
                                onSelect={(p) =>
                                    this.handleCopyAttrsDialogOpen(true, p.id, p.cod)
                                }
                            />
                        </>
                    )
                }
                <ProductTagsSelector
                    showTitle={true}
                    open={this.state.productTagsSelectorDialog.open}
                    onClose={(e) => this.handleProductTagsSelectorOpen(false)}
                    onSelect={(tagObjs) => {
                        tagObjs.forEach((tagObj, i, src) => {
                            delete tagObj.optionsLabel;
                            this.handleTagChange(
                                tagObj,
                                "add",
                                i === src.length - 1
                                    ? this.handleProductTagsSelectorOpen(false)
                                    : false
                            );
                        });
                    }}
                    selectedIds={this.state.tags.map((t) => t.id)}
                    multiple
                />
            </React.Fragment>
        )
            ;
    }
}

class UpdateProduct extends CreateProduct {
    static DialogTitle = "Aggiorna prodotto";
    static SuccessMessage = "Prodotto aggiornato";
    static ConfirmButton = "Aggiorna prodotto";
    static Type = "Update";

    constructor(props) {
        super(props);
        this.reset = this.reset.bind(this);
        this.onTransitionEnter = this.onTransitionEnter.bind(this);
    }

    onTransitionEnter() {
        this.reset(() => this.getData());
    }

    reset(callback = false) {
        this.setState(
            {
                ...this.initState(true),
            },
            () => {
                if (callback) callback();
            }
        );
    }

    componentDidMount() {
    }

    static extractUfSuffix = (producerCode = "", ufCode = "") => {
        if (!producerCode) return "";
        // Find differences between uf cod and producer nr.
        const cutUfCod = ufCode.replace(/^(V-)?[A-Z0-9]{4}\-/, "");
        // console.log(cutUfCod);
        // console.log("Cutted:" + cutUfCod);
        // if (!this.props.pCodProduttore) console.warn(this.props);
        const thisDiff = diff(cutUfCod, producerCode);
        // console.log("Diff:" + thisDiff);
        let thisReturn = "";
        thisDiff.forEach((e, i) => {
            if (e[0] == -1) {
                thisReturn = e[1].replace(/\/|…/, "");
            }
        }, thisReturn);
        return thisReturn;
    };

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.constructor.Type === "Update") {
            if (prevProps.prodId != this.props.prodId) {
                //Resetta e riscarica i dati se cambia l'id
                this.reset(() => this.getData());
            }
        } else if (this.constructor.Type === "Clone") {
            if (prevProps.srcProdId != this.props.srcProdId) {
                //Resetta e riscarica i dati se cambia l'id
                this.reset(() => this.getData());
            }
        }
        if (!prevProps.open && this.props.open && !this.state._opened) {
            // Scarica dati se è la prima apertura
            // this.getData();
            this.setState({_opened: true});
        }
    }

    async getData(injectSku = false, idToGet = false, returnOnly = false) {
        const _debug = false;
        let data;
        if (!idToGet) idToGet = this.props.prodId;
        // let prodIdToGet = this.isUpdate
        //   ? this.props.prodId
        //   : this.isClone
        //   ? this.props.srcProdId
        //   : this.props.prodId;
        _debug && console.group("getdata");
        this.setState({_fetching: true});
        await new Promise((resolve, reject) =>
            axios
                .get(
                    `${Constants.paths.ajaxBasePath}prodotti/utils/create-product/?token=${this.context.user.token}&id=${idToGet}`
                )
                .then((res) => {
                    data = res.data;
                    resolve();
                })
                .catch((err) => reject(err))
        );
        if (returnOnly) {
            this.setState({_fetching: false});
            return data;
        }
        _debug && console.log(0);
        await new Promise((res) => this.handleSpotChange(data.stato == 4, res));
        _debug && console.log(1);
        await new Promise((res) =>
            this.setState({sku: injectSku ? injectSku : data.sku}, res)
        );
        _debug && console.log(2);
        await new Promise((resolve, reject) => {
            try {
                this.setState(
                    {
                        //sku: data.sku, //? Gestisci con promessa separata
                        cod_produttore: data.cod_produttore,
                        generatedUf: data.uf_cod,
                        ufSuffix: UpdateProduct.extractUfSuffix(
                            data.cod_produttore,
                            data.uf_cod
                        ),
                        nome: data.nome,
                        cod_barre: data.cod_barre,
                        dim_a: data.dim_a,
                        dim_l: data.dim_l,
                        dim_p: data.dim_p,
                        dim_peso: data.dim_peso,
                        x_code: data.x_code,
                        extra_descrizione: data.options?.extra_descrizione || '',
                        actual: data.actual,
                        imballo: data.imballo,
                        canale_vendita: data.canale_vendita,
                        tipo: data.tipo
                    },
                    () => {
                        resolve();
                    }
                );
            } catch (err) {
                console.error(err);
                this.props.onClose();
                reject();
            }
        });
        _debug && console.log(3);
        await new Promise(res => this.handleAssortmentVariationChange(data.assortimento_varianti, res));
        await new Promise(res => this.handleProductTypeChange(data.tipo, res));
        await new Promise((res) =>
            this.handleBrandChange(
                {
                    id: data.brand?.id,
                    id_char: data.brand?.id_char,
                    nome: data.brand?.nome,
                },
                res
            )
        );
        _debug && console.log(4);
        if (data.stato == 4) {
            await new Promise((res) =>
                this.handleSpotDescriptionChange(data.actual.descrizione_breve, res)
            );
            _debug && console.log("5A");
        } else {
            await new Promise((res) =>
                this.handleNomeTipicoChange(
                    {
                        id: data.meta_nome_tipico?.id,
                        abbreviazione: data.meta_nome_tipico?.abbreviazione,
                        legacy_abbreviazione: data.meta_nome_tipico?.legacy_abbreviazione,
                        nome: data.meta_nome_tipico?.nome,
                    },
                    res
                )
            );
            _debug && console.log("5B");
            await new Promise((res) => {
                if (data.meta_categoria) {
                    this.handleCategoryChange(
                        {
                            id: data.meta_categoria.id,
                            cat0: data.meta_categoria.cat0,
                            cat1: data.meta_categoria.cat1,
                            cat2: data.meta_categoria.cat2,
                            cat3: data.meta_categoria.cat3,
                            cat4: data.meta_categoria.cat4,
                            cat5: data.meta_categoria.cat5,
                            percorso: data.meta_categoria.percorso,
                        },
                        res
                    );
                } else res();
            });
            _debug && console.log(6);
            await new Promise((res) =>
                this.handleFamilyChange(data.cod_famiglia, res)
            );
            _debug && console.log(7);
            await new Promise((res) =>
                this.handleClassificationChange(
                    {
                        id: data.meta_classificazione?.id,
                        nome: data.meta_classificazione?.nome,
                        attrs: data.meta_classificazione?.attrs,
                    },
                    res
                )
            );
            _debug && console.log(8);
            await new Promise((res) => {
                let promises = [
                    new Promise((res) => this.handleUvTipoChange(data.uv.tipo, res)),
                    new Promise((res) => this.handleUvQtaChange(data.uv.qta, res)),
                    new Promise((res) => this.handleUvMisuraChange(data.uv.misura, res)),
                ];
                Promise.all(promises).then(res);
            });
            _debug && console.log(9);
            await new Promise((res, rej) => {
                let promises = [];
                data.attrs?.forEach((a) => {
                    promises.push(
                        new Promise((res) => this.handleAttrValChange(a.id, a.valore, res))
                    );
                });
                //Tutte in contemporanea in async
                Promise.all(promises).then(res).catch(rej);
            });
            _debug && console.log(10);
            await new Promise((res) => {
                _debug && console.group("Attrs");
                if (!data.options.attrs) res();
                let attributi_visibilita = {};
                for (const [id, opts] of Object.entries(data.options.attrs)) {
                    attributi_visibilita[id] = opts["visibile"] || false;
                }
                this.setState(
                    {
                        attributi_visibilita: attributi_visibilita,
                    },
                    res
                );
                _debug && console.groupEnd("Attrs");
            });
            _debug && console.log(11);
            await new Promise((res) => {
                _debug && console.group("Tags");
                if (!data.meta_tags) res();
                let promises = [];
                data.meta_tags?.forEach((t) => {
                    promises.push(
                        new Promise((res2) => this.handleTagChange(t, 'add', res2)));
                    _debug && console.log("Added", t);
                });
                Promise.all(promises).then(res);
                _debug && console.groupEnd("Tags");
            });
        }
        _debug && console.groupEnd("getdata");
        this.setState({_fetching: false});
    }
}

class CloneProduct extends UpdateProduct {
    static SuccessMessage = "Prodotto creato";
    static DialogTitle = "Clona prodotto";
    static Type = "Clone";
    static ConfirmButton = "Clona e crea prodotto";

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
    }

    async getData() {
        let sku = await this.getNewSKU(true); //? Ricevi sku da iniettare con il this.getData
        await super.getData(sku, this.props.srcProdId);
    }
}

class CopyAttrs extends UpdateProduct {
    static SuccessMessage = "Prodotto aggiornato";
    static DialogTitle = (
        <>
            Eredita attributi
            <Tooltip
                title={`Verranno ereditati i soli campi:
        - Classe
        - Categoria
        - Famiglia
        - Tipo prodotto
        - Varianti prodotto
        - Nome tipico
        - Nome
        - Unità di vendita
        - Attributi
        - Tags
        - Extra descrizione
        - Dimensioni e peso
        - Imballo`}
            >
                <IconButton size="small">
                    <FontAwesomeIcon icon={faInfoCircle}/>
                </IconButton>
            </Tooltip>
        </>
    );
    static Type = "CopyAttrs";

    constructor(props) {
        super(props);
    }

    async getData() {
        let srcData = await super.getData(false, this.props.srcProdId, true);
        await super.getData();
        await new Promise((res) => this.handleSpotChange(srcData.stato == 4, res));
        await new Promise((resolve, reject) => {
            try {
                this.setState(
                    {
                        nome: srcData.nome,
                        dim_a: srcData.dim_a,
                        dim_l: srcData.dim_l,
                        dim_p: srcData.dim_p,
                        dim_peso: srcData.dim_peso,
                        tipo: srcData.tipo,
                        extra_descrizione: srcData.options?.extra_descrizione || ""
                    },
                    () => {
                        resolve();
                    }
                );
            } catch (err) {
                console.error(err);
                alert("Errore nella migrazione");
                reject();
            }
        });
        await new Promise(res => this.handleAssortmentVariationChange(srcData.assortimento_varianti, res));
        await new Promise(res => this.handleProductTypeChange(srcData.tipo, res));
        await new Promise((res) =>
            this.handleBrandChange(
                {
                    id: srcData.brand?.id,
                    id_char: srcData.brand?.id_char,
                    nome: srcData.brand?.nome,
                },
                res
            )
        );

        if (srcData.stato != 4) {
            await new Promise((res) =>
                this.handleNomeTipicoChange(
                    {
                        id: srcData.meta_nome_tipico?.id,
                        abbreviazione: srcData.meta_nome_tipico?.abbreviazione,
                        legacy_abbreviazione:
                        srcData.meta_nome_tipico?.legacy_abbreviazione,
                        nome: srcData.meta_nome_tipico?.nome,
                    },
                    res
                )
            );

            await new Promise((res) => {
                if (srcData.meta_categoria) {
                    this.handleCategoryChange(
                        {
                            id: srcData.meta_categoria.id,
                            cat0: srcData.meta_categoria.cat0,
                            cat1: srcData.meta_categoria.cat1,
                            cat2: srcData.meta_categoria.cat2,
                            cat3: srcData.meta_categoria.cat3,
                            cat4: srcData.meta_categoria.cat4,
                            cat5: srcData.meta_categoria.cat5,
                            percorso: srcData.meta_categoria.percorso,
                        },
                        res
                    );
                } else res();
            });

            await new Promise((res) =>
                this.handleFamilyChange(srcData.cod_famiglia, res)
            );
            await new Promise((res) =>
                this.handleClassificationChange(
                    {
                        id: srcData.meta_classificazione.id,
                        nome: srcData.meta_classificazione.nome,
                        attrs: srcData.meta_classificazione.attrs,
                    },
                    res
                )
            );
            await new Promise((res) => {
                let promises = [
                    new Promise((res) => this.handleUvTipoChange(srcData.uv.tipo, res)),
                    new Promise((res) => this.handleUvQtaChange(srcData.uv.qta, res)),
                    new Promise((res) =>
                        this.handleUvMisuraChange(srcData.uv.misura, res)
                    ),
                ];
                Promise.all(promises).then(res);
            });
            await new Promise((res, rej) => {
                let promises = [];
                srcData.attrs?.forEach((a) => {
                    promises.push(
                        new Promise((res) => this.handleAttrValChange(a.id, a.valore, res))
                    );
                });
                //Tutte in contemporanea in async
                Promise.all(promises).then(res).catch(rej);
            });
            await new Promise((res) => {
                if (!srcData.options.attrs) res();
                let attributi_visibilita = {};
                for (const [id, opts] of Object.entries(srcData.options.attrs)) {
                    attributi_visibilita[id] = opts["visibile"] || false;
                }
                this.setState(
                    {
                        attributi_visibilita: attributi_visibilita,
                    },
                    res
                );
            });

            await new Promise(res => {

                for (const t of srcData?.meta_tags) {
                    this.handleTagChange(t, 'add', res);
                }
            });
        }
    }
}

CreateProduct.propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    open:
    PropTypes.bool.isRequired,
    onClose:
    PropTypes.func.isRequired,
    key:
    number,
};

UpdateProduct.propTypes = {
    prodId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

CloneProduct.propTypes = {
    srcProdId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
};

CopyAttrs.propTypes = {
    srcProdId: PropTypes.number.isRequired,
    prodId:
    PropTypes.number.isRequired,
};

export {
    CreateProduct, UpdateProduct, CloneProduct, CopyAttrs
};