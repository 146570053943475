import React from "react";
import Context from "../context/Context";
import ProfileCard from "../components/ProfileCard";
import MaterialTable from "material-table";
import materialTableIcons from "../support/materialTableIcons";
import materialTableLocalisationIT from "../support/materialTableLocalisationIT";
import axios from "axios";
import Constants from "../context/Constants";
import {Paper, Tab, Tabs} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCheck, faUserPlus, faUsers, faUserTimes,} from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../components/SearchBar";
import stringIsType from "../functions/stringIsType";
import MiniSearch from "minisearch";

class Clienti extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.filterData = this.filterData.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.search = this.search.bind(this);
        this.state = {
            activeTab: 0,
            forcedSearchValue: null,
        };
    }

    componentDidMount() {
        // this.context.functions.setActiveRouteName("clienti");
        this.getData();
    }

    getData() {
        this.setState({fetching: true});
        let finalData = [];
        axios
            .get(
                Constants.paths.ajaxBasePath +
                "clienti/?token=" +
                this.context.user.token
            )
            .then((res) => {
                finalData = res.data;
                this.setState(
                    {
                        allData: finalData,
                    },
                    () => {
                        this.initMiniSearch(finalData);
                        this.filterData();
                    }
                );
                console.log(finalData);
            });
    }

    filterData(tabIndex = 0, data = undefined) {
        if (!data) {
            data = this.state.allData;
        }
        let newData = {};
        switch (tabIndex) {
            case 0: //Tutti
                newData = data;
                break;
            case 1: //Attivi
                newData = data.filter((d) => d.status == 1);
                break;
            case 2: //Potenziali
                newData = data.filter((d) => d.status == 2);
                break;
            case 3: //Inattivi
                newData = data.filter((d) => d.status == 3);
                break;
            default:
                newData = data;
                return;
        }
        this.setState({data: newData}, this.setState({fetching: false}));
    }

    handleTabChange(e, newVal) {
        this.setState(
            (prev) => ({
                activeTab: newVal,
            }),
            () => {
                //this.filterData(newVal);
                this.search(this.state.lastQuery);
            }
        );
    }

    initMiniSearch(finalData) {
        const defaultSearchOptions = {
            boost: {
                id: 10,
            },
            fuzzy: 0.3,
            combineWith: "AND",
            prefix: true,
        };
        const searchFields = [
            "id",
            "ragione_sociale",
            "p_iva",
            "cod_fi",
            "email",
            "pec",
            "tel",
            "cell",
            "cap",
            "via",
            "prov",
            "comune",
            "cod_sdi",
            "note",
        ];
        if (finalData.length > 0) {
            this.minisearch = new MiniSearch({
                fields: searchFields,
                storeFields: Object.keys(finalData[0]),
                searchOptions: {
                    ...defaultSearchOptions,
                    boost: {
                        id: 10,
                        p_iva: 10,
                        cod_fi: 10,
                        ragione_sociale: 8,
                    },
                },
            });
            this.minisearch.addAllAsync(finalData).then(() => {
                this.setState({fetching: false}, this.search);
            });
        } else {
            this.setState({fetching: false});
        }
    }

    search(query) {
        this.setState({fetching: true, lastQuery: query});
        let promise = new Promise((res, rej) => {
            const minChars = 3;
            const thisSearch = typeof query == "undefined" ? "" : query;
            const data = this.state.allData;
            let results = [];
            if (thisSearch == "" || thisSearch.length < minChars) {
                results = data;
            } else {
                if (stringIsType(query, "codFamiglia")) {
                    //Sii preciso con il cod famiglia
                    results = data.filter((d) => d.this_cod_famiglia === query);
                } else {
                    results = this.minisearch.search(thisSearch); //Cerca con fuzzy
                }
            }
            // this.setState(
            //   (prev) => ({
            //     data: results,
            //     fetching: false,
            //   }),
            //   res
            // );
            this.filterData(this.state.activeTab, results);
        });
    }

    onSearchChange(s) {
        if (!this.context.device.lowSpec) this.search(s);
    }

    render() {
        return (
            <Context.Consumer>
                {(consumer_data) => (
                    <React.Fragment>
                        <ProfileCard/>
                        <SearchBar
                            forcedQuery={this.state.forcedSearchValue}
                            onChange={s => this.onSearchChange(s)}
                            onBlur={
                                this.context.device.lowSpec
                                    ? (s) => this.search(s)
                                    : null
                            }
                            onClear={() =>
                                this.setState(
                                    {
                                        forcedSearchValue: null,
                                    },
                                    () => {
                                        this.search();
                                    }
                                )
                            }
                        />
                        <Paper>
                            <Tabs
                                style={
                                    this.state.fetching
                                        ? {
                                            cursor: "progress",
                                            pointerEvents: "none",
                                        }
                                        : {}
                                }
                                indicatorColor="primary"
                                textColor="primary"
                                value={this.state.activeTab}
                                onChange={this.handleTabChange}
                                centered
                            >
                                {" "}
                                <Tab label="Tutti" icon={<FontAwesomeIcon icon={faUsers}/>}/>
                                <Tab
                                    label="Attivi"
                                    icon={<FontAwesomeIcon icon={faUserCheck}/>}
                                />
                                <Tab
                                    label="Potenziali"
                                    icon={<FontAwesomeIcon icon={faUserPlus}/>}
                                />
                                <Tab
                                    label="Inattivi"
                                    icon={<FontAwesomeIcon icon={faUserTimes}/>}
                                />
                            </Tabs>
                        </Paper>
                        <br/>
                        <div className="data-table clienti dense">
                            <MaterialTable
                                isLoading={this.state.fetching}
                                options={{
                                    tableLayout: "auto",
                                    pageSize: 15,
                                    pageSizeOptions: [15, 30, 40, 50],
                                    addRowPosition: "first",
                                    detailPanelColumnAlignment: "right",
                                    actionsColumnIndex: 100,
                                    actionsCellStyle: {
                                        textAlign: "center",
                                    },
                                    filtering: false,
                                    search: false,
                                }}
                                columns={[
                                    {
                                        title: "ID",
                                        field: "id",
                                        type: "numeric",
                                        editable: "onAdd",
                                        initialEditValue: "0",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Ragione Sociale",
                                        field: "ragione_sociale",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {
                                            minWidth: "300px",
                                        },
                                    },
                                    {
                                        title: "Partita IVA",
                                        field: "p_iva",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Codice Fiscale",
                                        field: "cod_fi",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Email",
                                        field: "email",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "PEC",
                                        field: "pec",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Telefono",
                                        field: "tel",
                                        type: "numeric",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Cellulare",
                                        field: "cell",
                                        type: "numeric",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Via",
                                        field: "via",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Comune",
                                        field: "comune",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Prv",
                                        field: "prov",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {
                                            maxWidth: "40px",
                                        },
                                        cellStyle: {
                                            maxWidth: "40px",
                                        },
                                    },
                                    {
                                        title: "CAP",
                                        field: "cap",
                                        type: "numeric",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "SDI",
                                        field: "cod_sdi",
                                        type: "string",
                                        editable: "always",
                                        initialEditValue: "",
                                        headerStyle: {},
                                        cellStyle: {},
                                    },
                                    {
                                        title: "Stato",
                                        field: "status",
                                        editable: "always",
                                        initialEditValue: "1",
                                        headerStyle: {},
                                        cellStyle: {},
                                        filtering: false,
                                        lookup: {
                                            1: "Attivo",
                                            2: "Potenziale",
                                            3: "Inattivo",
                                        },
                                    },
                                ]}
                                // data={this.state.data.filter(r => {
                                //   return (
                                //     this.state.activeTab === 0 ||
                                //     r.status == this.state.activeTab
                                //   );
                                // })}
                                data={this.state.data}
                                title="Clienti"
                                icons={materialTableIcons}
                                localization={materialTableLocalisationIT}
                                editable={{
                                    onRowAdd: (newData) =>
                                        new Promise((resolve, reject) => {
                                            const data = this.state.allData;
                                            data.push(newData);
                                            const toPost = JSON.stringify({
                                                row: newData,
                                                token: consumer_data.user.token,
                                                action: "create",
                                            });
                                            console.log(toPost);
                                            axios
                                                .post(Constants.paths.ajaxBasePath + "clienti/", toPost)
                                                .then((res) => {
                                                    if (res.status >= 200 && res.status < 300) {
                                                        this.setState({allData: data}, () => {
                                                            this.filterData(this.state.activeTab);
                                                            resolve();
                                                        });
                                                    } else {
                                                        reject();
                                                        throw new Error(res);
                                                    }
                                                })
                                                .catch((e) => {
                                                    reject();
                                                    alert("Errore nel form:" + e.response.status);
                                                });
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            const data = this.state.allData;
                                            const index = data.indexOf(oldData);
                                            data[index] = newData;
                                            const toPost = JSON.stringify({
                                                row: newData,
                                                token: consumer_data.user.token,
                                                action: "update",
                                            });
                                            console.log(toPost);
                                            axios
                                                .post(Constants.paths.ajaxBasePath + "clienti/", toPost)
                                                .then((res) => {
                                                    if (res.status >= 200 && res.status < 300) {
                                                        this.setState({allData: data}, () => {
                                                            this.filterData(this.state.activeTab);
                                                            resolve();
                                                        });
                                                    } else {
                                                        throw new Error(res);
                                                    }
                                                })
                                                .catch((e) => {
                                                    alert(
                                                        "Il form non è stato inviato a causa di un errore: " +
                                                        e
                                                    );
                                                    reject();
                                                });
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve, reject) => {
                                            let data = this.state.allData;
                                            const index = data.indexOf(oldData);
                                            data.splice(index, 1);

                                            const toPost = JSON.stringify({
                                                row: oldData,
                                                token: consumer_data.user.token,
                                                action: "delete",
                                            });

                                            axios
                                                .post(Constants.paths.ajaxBasePath + "clienti/", toPost)
                                                .then((res) => {
                                                    if (res.status >= 200 && res.status < 300) {
                                                        this.setState({allData: data}, () => {
                                                            this.filterData(this.state.activeTab);
                                                            resolve();
                                                        });
                                                    } else {
                                                        throw new Error(res);
                                                    }
                                                })
                                                .catch((e) => {
                                                    alert(
                                                        "La riga non è stata eliminata a causa di un errore: " +
                                                        e
                                                    );
                                                    reject();
                                                });
                                        }),
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
            </Context.Consumer>
        );
    }
}

export default Clienti;
